import React, { useState, useEffect } from 'react';

import './CreateSite.scss'

import { useHistory } from "react-router-dom";

import { ArrowLeft } from 'react-feather';

import axios from 'axios';

import { connect } from 'react-redux'

import { hostDetect } from '../../config/envConfig';

import { setAlert } from '../../redux/actions/alertActions';

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'


const CreateSite = ({ componentUpdate, setAlert }) => {
  const [submitDisable, setSubmitDisable] = useState(true);

  const [siteURLValidation, setSiteURLValidation] = useState({valid: false, class: '', text: ''});
  const [siteURL, setSiteURL] = useState('');

  const [siteNameValidation, setSiteNameValidation] = useState({valid: false, class: '', text: ''});
  const [siteName, setSiteName] = useState('');

  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailed, setAlertFailed] = useState(false);
  const [alertFailedText, setAlertFailedText] = useState('')

  useEffect(()=>{
    if(siteURLValidation.valid && siteNameValidation.valid){
      setSubmitDisable(false)
    } else {
      setSubmitDisable(true)
    }
  });

	let history = useHistory();
	const handleBackClick = ()=>{
  	// history.push("/settings/sites");
  	history.goBack()
	}

  let handleSiteURL = event => {
    let link = event.target.value,
        linkRegex = /(http(s?)):\/\//i;

    setSiteURL(link)

    if(link.length === 0){
      setSiteURLValidation({valid: false, class: '', text: ''})
    } else if(link.length > 0 && !linkRegex.test(link)){
      setSiteURLValidation({valid: false, class:'is-invalid', text: 'Неверный формат записи...'})
    } else if(link.length > 0 && linkRegex.test(link)){
      setSiteURLValidation({valid: true, class: 'is-valid', text: ''})
    }
  }

  const handleSiteName = event => {
    let name = event.target.value;
    setSiteName(name);

    if(name.length === 0){
      setSiteNameValidation({valid: false, class: '', text: ''})
    } else if(name.length < 3){
      setSiteNameValidation({valid: false, class:'is-invalid', text: 'Слишком короткое название...'})
    } else {
      setSiteNameValidation({valid: true, class: 'is-valid', text: ''})
    }
  }

  const onSiteSave = () => {

    const formData = new FormData();
    formData.append("url", siteURL);
    formData.append("name", siteName);

    axios.post(`${hostDetect('api')}/site/save`, formData, { withCredentials: true })
      .then(res => {
        if(res.data.success){
          setAlertSuccess(true);
          setTimeout(()=> {
            setAlertSuccess(false);
            setTimeout(()=>{
              componentUpdate();
              history.push("/settings/sites");
            }, 1000)
          }, 2000)
        } else {
          const errorMessage = res.data.data.message
          setAlertFailedText(errorMessage)
          setAlert('error', errorMessage);

          setAlertFailed(true);
          setTimeout(()=> {
            setAlertFailed(false)
            setAlertFailedText('')
          }, 2000)
        }
      })
      .catch(err => {
        setAlert('error', 'Не получилось отправить запрос!');
      })
  }

	return (
		<div className="create-site">

			<MobileHeader/>

			<div className="create-site__back-btn">
        <button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
          <ArrowLeft size={15} style={{marginRight: 10}}/>Назад
        </button>
      </div>

      <div className="create-site__form-place">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <strong>Добавление нового сайта</strong>
              </div>
              <div className="card-body">

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">Адрес сайта (URL)</label>
                  <div className="col-sm-8">
                    <input type="text" className={"form-control " + siteURLValidation.class} placeholder="http://" value={siteURL} onChange={handleSiteURL}/>
                    {
                      siteURLValidation.valid === false ? 
                        <ul className="list-unstyled text-sm mt-1 text-muted filled create-site__input-error-text">
                          <li className="parsley-required">{siteURLValidation.text}</li>
                        </ul> : ''
                    }
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">Название</label>
                  <div className="col-sm-8">
                    <input type="text" className={"form-control " + siteNameValidation.class} value={siteName} onChange={handleSiteName} placeholder="Введите название"/>
                    {
                      siteNameValidation.valid === false ? 
                        <ul className="list-unstyled text-sm mt-1 text-muted filled create-site__input-error-text">
                          <li className="parsley-required">{siteNameValidation.text}</li>
                        </ul> : ''
                    }
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center create-site__save-btn-alert">
                  <button className="btn w-sm btn-primary" onClick={onSiteSave} disabled={submitDisable}>Добавить</button>

                  {
                    alertSuccess == true ? (
                    <div className="alert alert-success" role="alert">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        className="feather feather-check">
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      <span className="mx-2">Сайт добавлен! Переходим в список сайтов...</span>
                    </div>
                    ) : ''
                  }

                  {
                    alertFailed == true ? (
                    <div className="alert alert-warning" role="alert">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        className="feather feather-alert-circle">
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12" y2="16"></line>
                      </svg>
                      <span className="mx-2">{alertFailedText}</span>
                    </div>
                    ) : ''
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      
		</div>
	)
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(CreateSite)