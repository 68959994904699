import { 
  SET_ALERT,
  REMOVE_ALERT
} from "../types";


export const setAlert = (type, text) => async dispatch => {

  dispatch(showAlert(type, text));

  setTimeout(()=> {
    dispatch(hideAlert());
  }, 4000)
}

const showAlert = (type, text) => {

  return {
    type: SET_ALERT,
    payload: {
      type,
      text
    }
  }
}

const hideAlert = () => {
  return {
    type: REMOVE_ALERT
  }
}
