 import React, { useState, useEffect } from 'react'
 import './NewPersonData.scss'

 import Axios from 'axios'

 import { connect } from 'react-redux'

 import { hostDetect } from '../../config/envConfig'

 import { CheckCircle } from 'react-feather'

 import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'

 import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

 import { setAlert } from '../../redux/actions/alertActions.js'

 const NewPersonData = ({setAlert}) => {
 	
	const [apiLoading, setApiLoading] = useState(false)

	const [userId, setUserId] = useState('')
  const [userName, setUserName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [userSocials, setUserSocials] = useState('')
  const [userAgreement, setUserAgreement] = useState(false)

  const [personalDataError, setPersonalDataError] = useState({status: false, message: ''})
  const [personalDataSuccess, setPersonalDataSuccess] = useState({status: false, message: ''})


	useEffect(() => {
		
    setApiLoading(true)

    Axios.get(`${hostDetect('api')}/users/get`, {
      withCredentials: true
    })
    .then(response => {
      const success = response.data.success;

      setApiLoading(false)

      if(success){
        const {first_name, last_name, email, phone, agreement_is_accepted, user_id, metaList} = response.data.data;

				setUserId(user_id)
				setUserName(first_name)
				setUserLastName(last_name)
				setUserEmail(email)
				phoneMask(phone)
				setUserAgreement(agreement_is_accepted == 1 ? true : false)

				if(metaList.length){
					metaList.forEach(metaItem => {
						if(metaItem.name === 'socials' && metaItem.value){
							setUserSocials(metaItem.value)
						}
					})
				}

      } else {
        let errMessage = response.data.data.message;
				setAlert('error', errMessage);
      }
		
    })
    .catch(error => {
      setApiLoading(false)
			setAlert('error', 'Не получилось отправить запрос!');
    })

  }, [])


const handleSubmitUserInfo = () => {

	setApiLoading(true)

	setPersonalDataError({status: false, message: ''})
	setPersonalDataSuccess({status: false, message: ''})

	// clear userPhone
	let regex = /\s+|\(|\)|-/g,
			result = userPhone.replace(regex,'');

	let formData = new FormData();
		formData.append('first_name', userName);
		formData.append('last_name', userLastName);
		formData.append('email', userEmail);
		formData.append('phone', result);
		// formData.append('agreement_is_accepted', userAgreement ? 1 : 0);
		formData.append('agreement_is_accepted', 1);

		Axios.post(`${hostDetect('api')}/users/save`, formData, {withCredentials: true})
		.then(response => {

			setApiLoading(false)

			let success = response.data.success;

			if(success){

				// if(userSocials.length){
					let formDataMeta = new FormData();
					formDataMeta.append('name', 'socials')
					formDataMeta.append('value', userSocials)

					Axios.post(`${hostDetect('api')}/users/meta-save?user_id=${userId}`, formDataMeta, {withCredentials: true}).then(res => {
						let successMeta = res.data.success;

						if(successMeta){
							setPersonalDataError({status: false, message: ''})
							setPersonalDataSuccess({status: true, message: res.data.data.message})
						} else {
							setPersonalDataError({status: true, message: res.data.data.message})
							setPersonalDataSuccess({status: false, message: ''})
						}
					}).catch(err=> {
						setPersonalDataError({status: true, message: 'Не удалось отправить запрос. Обновите страницу и попробуйте снова.'})
					})
					
				// } else {
				// 	setPersonalDataError({status: false, message: ''})
				// 	setPersonalDataSuccess({status: true, message: 'Данные обновлены'})
				// }
				
				
			} else {
				setApiLoading(false)
				setPersonalDataError({status: true, message: response.data.data.message})
				setPersonalDataSuccess({status: false, message: ''})
			}
		})
		.catch(err => {
			setApiLoading(false)
			setPersonalDataError({status: true, message: 'Не удалось отправить запрос. Обновите страницу и попробуйте снова.'})
		})
  }

	const phoneMask = (eventValue)=>{
		const value = eventValue.replace(/\D+/g, "");
		const numberLength = 11;

		const prefixNumber = (str) => {
			if (str === "7") {
				return "7 (";
			}
			if (str === "8") {
				return "8 (";
			}
			if (str === "9") {
				return "7 (9";
			}
			return "7 (";
		};

		let result;
		if (eventValue.includes("+8") || eventValue[0] === "8") {
			result = "";
		} else {
			result = "+";
		}

		for (let i = 0; i < value.length && i < numberLength; i++) {
			switch (i) {
				case 0:
					result += prefixNumber(value[i]);
					continue;
				case 4:
					result += ") ";
					break;
				case 7:
					result += "-";
					break;
				case 9:
					result += "-";
					break;
				default:
					break;
			}
			result += value[i];
		}
		setUserPhone(result)
	}

 	return( 
		<div className="edit-person-data">
			<MobileHeader/>

		 <PageHeading mainText={"Добро пожаловать в систему Sparrow"} mutedText={"Перед началом работы заполните поля с личными данными"}/>


		 <div className="row">
			 <div className="col-12 col-md-6">
				 <div className="card">
					 <div className="card-header">
						 <strong>Личные данные</strong>

						 {
							 apiLoading ? 
								 (
									 <div className="spinner-border spinner-border-sm text-primary ml-2" role="status">
										 <span className="sr-only">Loading...</span>
									 </div>
								 ) : null
						 }
					 </div>
					 <div className="card-body">
						 <div className="form-group">
							 <label className="text-muted">Имя</label>
							 <input type="text" name="name" className="form-control" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Ваше имя" />
							 {
								 !userName.length ? <small className="form-text small-text-danger">Поле не должно быть пустым</small> : null
							 }
						 </div>

						 <div className="form-group">
							 <label className="text-muted">Фамилия</label>
							 <input type="text" name="name" className="form-control" value={userLastName} onChange={(e) => setUserLastName(e.target.value)} placeholder="Ваша фамилия" />
							 {
								 !userLastName.length ? <small className="form-text small-text-danger">Поле не должно быть пустым</small> : null
							 }
						 </div>

						 <div className="form-group">
							 <label className="text-muted">Email</label>
							 <input type="email" name="email" className="form-control" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} placeholder="Ваш email" />
							 {
								 !userEmail.length ? <small className="form-text small-text-danger">Поле не должно быть пустым</small> : null
							 }
						 </div>

						 <div className="form-group">
							 <label className="text-muted">Телефон</label>
							 <input className="form-control" value={userPhone} onChange={(e) => phoneMask(e.target.value)} />
							 {
								 !userPhone.length ? <small className="form-text small-text-danger">Поле не должно быть пустым</small> : null
							 }
						 </div>

						 <div className="form-group">
							 <label className="text-muted">Telegram или Skype</label>
							 <input type="text" name="socials" className="form-control" value={userSocials} onChange={(e) => setUserSocials(e.target.value)} placeholder="Как связаться?" />
						 </div>

						 {/* <div className="form-group">
							 <div className="form-check d-flex align-items-center">
								 <input type="checkbox" className="form-check-input" name="agreement_is_accepted" checked={userAgreement} onChange={(e) => setUserAgreement(e.target.checked)} />
								 <label className="form-check-label">
									 Я согласен с <a href="https://google.ru" target="_blank">правилами и политикой сервиса</a>
								 </label>
							 </div>
						 </div> */}

						 {
							 personalDataError.status ? <p className="form-text text-danger">{personalDataError.message}</p> : null
						 }

						 {
							 personalDataSuccess.status ? <p className="form-text text-success d-flex align-items-center"><CheckCircle color={'#31c971'} size={14} className="mr-2" />{personalDataSuccess.message}</p> : null
						 }

						 <button className="btn btn-primary w-sm" onClick={handleSubmitUserInfo} disabled={apiLoading}>Сохранить</button>
					 </div>
				 </div>
			 </div>
		 </div>
		
		</div>
	)
 }


 const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(NewPersonData)