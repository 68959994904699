import React, { useState, useEffect } from 'react'
import './PageIncome.scss'

import {
  BrowserView,
  MobileView
} from "react-device-detect";

import {Link as LinkIcon} from "react-feather";

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   Redirect,
//   useLocation
// } from "react-router-dom";
import { hostDetect } from '../../config/envConfig';

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'
import DateRange from './DateRange/DateRange'
import GroupbyButtonsDesk from './GroupbyButtons/GroupbyButtons'
import GroupbyButtonsMobile from './GroupbyButtonsSelect/GroupbyButtons'
import SiteStatus from './SiteStatus/SiteStatus.js';
import WidgetsList from './WidgetsList/WidgetsList'
import WidgetsListMobile from './WidgetsListSelect/WidgetsList'
import InfoTable from './InfoTable/InfoTable'
import InfoTableMobile from './InfoTableMobile/InfoTable'
import DetailsTable from './DetailsTable/DetailsTable'
import ChartTable from './ChartTable/ChartTable'
import ChartTableMobile from './ChartTableMobile/ChartTableMobile'
import WidgetsPreview from './WidgetsPreview/WidgetsPreview.js';

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import axios from "axios/index";

import { connect } from 'react-redux';

import { Link, useParams, useLocation } from "react-router-dom";

import InfoAlert from '../../components/commonComponents/InfoAlert/InfoAlert.js';
import gifLenta from '../../img/desktop-svk-lenta.gif';

import { setAlert } from '../../redux/actions/alertActions.js';



const Income = (props) => {

  // let { devFeature } = useParams();

  const [apiLoading, setApiLoading] = useState(false)

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  let type = queryParameters.get("type")
  
  // const showDevFeatures = () =>{
  //   if(devFeature){
  //     switch(devFeature){
  //       case "devInfoBadge":{
  //         return <InfoAlert/>
  //       }
  //     }
  //   }
  // }
 

  useEffect(()=>{

    let getSiteFullInfo = async () =>{

      // 10 - classic ad, 60 - banner ad
      const getAdType = () => {
        if(type == '30'){
          return '&type=30'
        }
        return ''
      }

      // show loader while loading
      setApiLoading(true)
      props.totalStatisticsLoading(true)

      await axios.get(`${hostDetect('api')}/site-statistics/income?site_id=${props.currentSiteID}&start_date=${props.startStatisticsDate}&end_date=${props.endStatisticsDate}&group=${props.groupByQuery}${getAdType()}`, {
        withCredentials: true
      })
      .then(res => {
        if(res.data.success){

          // ALL API RESPONSE
          let dataAPI = res.data.data;

          // TOTAL and WIDGETS info
          let total = dataAPI.total,
              widgets = dataAPI.widgets;

          // TOTAL and WIDGETS to REDUX state
          props.setTotalSiteStatistics(total);
          props.setWidgets(widgets)

          // REDUX common array [total, ...widgets]
          let totalStatiscticsArray = [ total.total ];
          widgets.map(widg =>{
            totalStatiscticsArray.push(widg.total)
          })
          props.setTotalWidgetStatistics(totalStatiscticsArray);
        } else {
          const errorMessage = res.data.data.message
          props.setAlert('error', errorMessage);
        }

        // hide loader
        setApiLoading(false)
        props.totalStatisticsLoading(false)
      })
      .catch(err => {
        // hide loader
        setApiLoading(false)
        props.totalStatisticsLoading(false)
        props.setAlert('error', 'Не получилось отправить запрос!');
      })
    }
    
    if(props.startStatisticsDate && props.endStatisticsDate){
      getSiteFullInfo();
    }
    
  }, [props.currentSiteID, props.startStatisticsDate, props.endStatisticsDate, props.groupByQuery, type])



    return (
      <div className="page-income">
        <div className="page-income__wrap">

          <MobileHeader/>

          {/*<PageHeading mainText={"Монетизация"} mutedText={""}/>*/}

          {/* {
            showDevFeatures()
          } */}

          {
            apiLoading ? 
              <div className="loader__small">
                  Загрузка данных...
              </div> : null
          }

          <div className="row">
            <MobileView style={{width: '100%', marginBottom: '15px'}}>
              <div className="mobile-site-badge">
                <div className="mobile-site-badge__name">
                  <span>{props.currentSiteInfo.full_name}</span>
                </div>
                <div className="d-flex">
                  <div className="mobile-site-badge__icon">
                    <LinkIcon size={12} />
                  </div>
                  <div className="mobile-site-badge__links">
                    <div className="mobile-site-badge__url">{props.currentSiteInfo.url}</div>
                    <div className="mobile-site-badge__id"># {props.currentSiteInfo.id}</div>
                  </div>
                </div>
                
              </div>
            </MobileView>

            <div className="col-md-4 col-sm-12">
              <DateRange />
            </div>

            <div className="col-md-4 col-sm-12">
              <BrowserView>
                <GroupbyButtonsDesk />
              </BrowserView>
              <MobileView>
                <GroupbyButtonsMobile />
              </MobileView>
            </div>

            <div className="col-md-4 col-sm-12">
              <SiteStatus />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12">
              <BrowserView>
                <WidgetsList />
              </BrowserView>
              <MobileView>
                <WidgetsListMobile/>
              </MobileView>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <BrowserView>
                <InfoTable />
              </BrowserView>
              <MobileView>
                <InfoTableMobile/>
              </MobileView>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <DetailsTable />
            </div>
          </div>

          <div>
            <BrowserView>
              <ChartTable/>
            </BrowserView>
            <MobileView>
              <ChartTableMobile/>
            </MobileView>
          </div>

          <div>
            <WidgetsPreview />
          </div>
          
        </div>
      </div>
    )
}

function mapStateToProps(state){
  return{
    currentSiteInfo: state.siteInfo.site,
    currentSiteID: state.siteInfo.site.id,
    startStatisticsDate: state.siteInfo.site.statisticsDate.startFormatted,
    endStatisticsDate: state.siteInfo.site.statisticsDate.endFormatted,
    groupByQuery: state.siteInfo.site.groupBy,
    widgetActiveId: state.siteInfo.site.widgetActive
  }
}

function mapDispatchToProps(dispatch){
  return {
    setTotalSiteStatistics: total => dispatch({type: 'ADD_SITE_TOTAL_STATISTICS', payload: total}),
    setTotalWidgetStatistics: totalSiteAndWidgets => dispatch({type: 'ADD_SITE_AND_WIDGETS_STATISTICS', payload: totalSiteAndWidgets}),
    setWidgets: widgets => dispatch({type: 'ADD_SITE_WIDGETS', payload: widgets}),
    setCurrentWidgetStatistics: totalStatisticsByDate =>dispatch({type: 'ADD_CURRENT_WIDGET_STATISTICS', payload: totalStatisticsByDate}),
    totalStatisticsLoading: status => dispatch({type: 'TOTAL_STATISTICS_LOADING', payload: status}),
    setAlert: (type, text) => dispatch(setAlert(type, text))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Income)