import React, { useState, useEffect } from 'react'

import './PageWidgets.scss'

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'

import { hostDetect } from '../../config/envConfig'

import { connect } from 'react-redux'

import axios from 'axios'

import { PlusCircle, X } from "react-feather";

import { Link } from "react-router-dom";

import { setAlert } from '../../redux/actions/alertActions.js'

// moment js
import moment from 'moment'

const Widgets = (props)=> {

  const [apiLoading, setApiLoading] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [widgetIdToDelete, setWidgetIdToDelete] = useState('')
  const [widgetNameToDelete, setWidgetNameToDelete] = useState('')
  const [widgetList, setWidgetList] = useState([])

  useEffect(()=> {
    setApiLoading(true)

    let startDate = moment().subtract(29, 'days').format('YYYY-MM-DD'),
        finishDate = moment().format('YYYY-MM-DD');

    let getSiteWidgets = async () =>{
      await axios.get(`${hostDetect('api')}/site-statistics/income?site_id=${props.currentSiteID}&start_date=${startDate}&end_date=${finishDate}&group=day&type=all`, {
        withCredentials: true
      })
      .then(res => {
        setApiLoading(false)

        if(res.data.success){
          setWidgetList(res.data.data.widgets)
        } else {
          const errorMessage = res.data.data.message
          props.setAlert('error', errorMessage);
        }
      })
      .catch(err => {
        setApiLoading(false)

        props.setAlert('error', 'Не получилось отправить запрос!');
      })
    }
    getSiteWidgets();
  }, [props.currentSiteID, widgetNameToDelete])


  const applyDeleting = ()=> {
    
    if(widgetIdToDelete){
      const formData = new FormData();
      formData.append("id", String(widgetIdToDelete));

      axios.post(`${hostDetect('api')}/widget/delete`, formData, {
        withCredentials: true
      })
      .then(res => {
        if(res.data.success){
          setWidgetIdToDelete('')
          setWidgetNameToDelete('')
          setShowModal(false)
        } else {
          const errorMessage = res.data.data.message
          props.setAlert('error', errorMessage);
        }
      })
      .catch(err => {
        props.setAlert('error', 'Не получилось отправить запрос!');
      })
    }
  }

  const deleteItem = (id, name)=> {
    setWidgetIdToDelete(id)
    setWidgetNameToDelete(name)
  }

  return (
    <div className="pageWidgets">

      <MobileHeader/>

      <div className="widgets-block">

        {
          apiLoading ? 
            <div className="loader__small">
                Загрузка данных...
            </div> : null
        }

        <PageHeading mainText={"Блоки"} mutedText={"Добавьте новый блок или настройте существующий"}/>

        <div className="widgets-block__wrapper d-flex">

          <div className="widgets-block__item card">
            <div className="media media-2x1">
              <Link to={`/settings/widgets/createWidget/${props.currentSiteID}`} className="add-widget-btn media-content">
                <span className="add-widget-btn__top">
                  Добавить
                </span>
                <PlusCircle color="#212529" size={30} className="add-widget-btn__icon"/>
                <span className="add-widget-btn__bottom">
                  блок
                </span>
              </Link>
            </div>
          </div>

          
          {
            widgetList.map(widget => {
              return (
                <div className="widgets-block__item card" key={widget.id}>
                  <div className="media media-2x1 gd-primary">
                    <Link to={`/settings/widgets/widgetInfo/${props.currentSiteID}/${widget.id}`} className="widget-btn media-content">
                      <span className="widget-btn__id badge badge-light text-uppercase">
                        # {widget.id}
                      </span>
                      <span className="widget-btn__text">
                        {widget.name}
                      </span>
                    </Link>
                    <button className="btn btn-icon btn-white widgets-block__delete-item" onClick={()=>deleteItem(widget.id, widget.name)} data-toggle="modal" data-target="#exampleModal">
                      <X size={15}/>
                    </button>
                  </div>
                </div>
              )
            })
          }

        </div>

        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Вы уверены, что хотите удалить блок?</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                 <strong>
                  {widgetNameToDelete} - #{widgetIdToDelete}
                 </strong>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={applyDeleting} data-tag="accept">Удалить</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal">Не удалять</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

function mapStateToProps(state){
  return{
    currentSiteID: state.siteInfo.site.id
  }
}

function mapDispatchToProps(dispatch){
  return {
    setAlert: (type, text) => dispatch(setAlert(type, text))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Widgets)