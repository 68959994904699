import React, { useState, useEffect } from 'react'

import './BalanceTop.scss'

import Axios from 'axios';

import { connect } from 'react-redux'

import { Link } from "react-router-dom";

import { hostDetect } from '../../../config/envConfig';

import { setAlert } from '../../../redux/actions/alertActions';

import { RefreshCw } from 'react-feather';

const BalanceTop = ({ setAlert }) => {
  const [balanceLoading, setBalanceLoading] = useState(false)

  const [balance, setBalance] = useState(0)
  const [onHold, setOnHold] = useState(0)

  useEffect(() => {
    getBalance()
  }, [])

  const getBalance = () => {

    setBalanceLoading(true)

    Axios.get(`${hostDetect('api')}/publisher-finance/get-balance`, { withCredentials: true })
    .then(response => {
      setBalanceLoading(false)

      let success = response.data.success
      if(success){
        let { balance, on_hold } = response.data.data
        setBalance(balance)
        setOnHold(on_hold)
      } else {
        let errMessage = response.data.data.message;
        setAlert('error', errMessage);
      }
    })
    .catch(err => {
      setBalanceLoading(false)
      setAlert('error', 'Не получилось отправить запрос!');
    })

  }


  const formatNumbers = ( numbers ) => {
    if(!numbers){
      return '0'
    }
    // return new Intl.NumberFormat("ru", {maximumFractionDigits: 3}).format(numbers)
    return new Intl.NumberFormat().format(numbers)
  }


  return (
    <div className="balance-top">
      <div className="balance-top__wrap">

        <div className="balance-top__item">
          <Link to="/finance" title="Перейти на страницу Финансы">Баланс: 
            <strong className="text-success ml-2">
              {
                balanceLoading ? (
                  <div className="spinner-border spinner-border-sm text-primary ml-2" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    { formatNumbers(balance) } ₽
                  </>
                )
              }
            </strong>
          </Link>
        </div>
        <div className="balance-top__item">
          <Link to="/finance" title="Перейти на страницу Финансы">Ожидаемый доход: 
            <strong className="text-success ml-2">
              {
                balanceLoading ? (
                  <div className="spinner-border spinner-border-sm text-primary ml-2" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    { formatNumbers(onHold) } ₽
                  </>
                )
              }
            </strong>
          </Link>
        </div>
        {/* <div className="balance-top__item">
          <button className={`balance-top__spinner ${balanceLoading === true ? 'loading-spin' : ''}`} onClick={() => getBalance()} title="Обновить показатели баланса">
            <RefreshCw size={12} />
              обновить
            </button>
        </div> */}

      </div>
    </div>
  )
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(BalanceTop);