import React from 'react'

import './PageStatistics.scss'

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'

const PageStatistics = () => {
	return (
		<div className="page-statistics">

			<MobileHeader/>

			<PageHeading mainText={"Статистика редакции"} mutedText={"С помощью этого инструмента оцените эффективность статей вашего сайта и составьте рейтинг авторов"}/>

			<div className="alert alert-warning" role="alert">
	        <svg 
	        	xmlns="http://www.w3.org/2000/svg" 
	        	width="16" 
	        	height="16" 
	        	viewBox="0 0 24 24" 
	        	fill="none" 
	        	stroke="currentColor" 
	        	strokeWidth="2" 
	        	strokeLinecap="round" 
	        	strokeLinejoin="round" 
	        	className="feather feather-alert-circle">
	        		<circle cx="12" cy="12" r="10"></circle>
	        		<line x1="12" y1="8" x2="12" y2="12"></line>
	        		<line x1="12" y1="16" x2="12" y2="16"></line>
	        </svg>
	        <span className="mx-2">Инструмент находится в разработке и скоро будет доступен</span>
	      </div>
		</div>
	)
}

export default PageStatistics;