import React from 'react'

const Banner = ({deviceType, bannerType, setBannerType, headerTitle, setHeaderTitle}) => {
  return (
    <>
      {/* card type */}
      <div className="form-group">
        <label>Размер</label>
        {/* mobile */}
        {
          deviceType === 'mobile' ? (
            <>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="300x300"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "300x300"} />
                    300x300
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="240x400"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "240x400"} />
                    240x400
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="300x600"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "300x600"} />
                    300x600
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="300x250"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "300x250"} />
                    300x250
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="120x600"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "120x600"} />
                    120x600
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="160x600"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "160x600"} />
                    160x600
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="320x50"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "320x50"} />
                    320x50
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="320x100"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "320x100"} />
                    320x100
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="336x280"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "336x280"} />
                    336x280
                </label>
              </div>
            </>
          ) : null
        }
        
        {/* desktop */}
        {
          deviceType === 'desktop' ? (
            <>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="728x90"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "728x90"} />
                    728x90
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="970x90"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "970x90"} />
                    970x90
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="970x250"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "970x250"} />
                    970x250
                </label>
              </div>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="banner-size" 
                    value="580x400"
                    onChange={e => setBannerType(e.target.value)}
                    checked={bannerType == "580x400"} />
                    580x400
                </label>
              </div>
            </>
          ) : null
        }

      </div>
    </>
  )
}

export default Banner