import React from 'react'

import './WidgetsPreview.scss'

import { connect } from 'react-redux'

const WidgetsPreview = ({widgets}) => {

  let formatLongNumber = tableNumber => {
    return new Intl.NumberFormat('ru-RU').format(tableNumber)
  }

  let formatFixed = (tableValue, fixLength) => {
    if(tableValue) {
      let statNumber = String(tableValue),
          dotPosition = statNumber.indexOf('.');

      if(dotPosition === -1){
        return statNumber + '.00';
      } else {
        let numberBeforeDot = statNumber.slice(0, dotPosition),
            numberAfterDot = statNumber.slice(dotPosition+1);

        return numberBeforeDot + '.' + numberAfterDot.slice(0, fixLength);
      }
    } else {
      return '0'
    }
  }



  return (
    <div className="widgets-preview">
        <h4>Блоки</h4>
        <p className="text-muted">Общая информация о ваших блоках</p>

        <div className="widgets-preview__list">
          {
            widgets.length ? (
              <>
                {
                  widgets.map((item, id) => {
                    return (
                      <div className="widgets-preview__list-item" key={item.id}>
                        <div className="widgets-preview__list-item-head">
                          <strong>{ item.name }</strong>
                          <small>#{ item.id }</small>
                        </div>
                        <div className="widgets-preview__list-item-body">
                          <div className="widgets-preview__list-item-row">
                            <strong>Загрузки</strong>
                            <small className="text-muted">{ formatLongNumber(item.total.visits) }</small>
                          </div>
                          <div className="widgets-preview__list-item-row">
                            <strong>Показы</strong>
                            <small className="text-muted">{ formatLongNumber(item.total.views) }</small>
                          </div>
                          <div className="widgets-preview__list-item-row">
                            <strong>Доход</strong>
                            <small className="text-muted">{ formatFixed(item.total.income, 1) } ₽</small>
                          </div>
                          <div className="widgets-preview__list-item-row">
                            <strong>CPM</strong>
                            <small className="text-muted">{ formatFixed(item.total.cpm, 1) }</small>
                          </div>
                          <div className="widgets-preview__list-item-row widgets-preview__list-item-row--column">
                            <strong>Переходы</strong>
                            <ul className="w-100">
                              <li>
                                <div className="widgets-preview__list-item-row">
                                  <small>Органические</small>
                                  <small className="text-muted">{ formatLongNumber(item.total.organic_clicks) }</small>
                                </div>
                              </li>
                              <li>
                                <div className="widgets-preview__list-item-row">
                                  <small>Промо</small>
                                  <small className="text-muted">{ formatLongNumber(item.total.ad_clicks) }</small>
                                </div>
                              </li>
                              <li>
                                <div className="widgets-preview__list-item-row">
                                  <small>Обменные</small>
                                  <small className="text-muted">{ formatLongNumber(item.total.exchange_clicks) }</small>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="widgets-preview__list-item-row">
                            <strong>CTR</strong>
                            <small className="text-muted">{ formatFixed(item.total.ctr, 2) }</small>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </>
            ) : (
              <div style={{margin: '0 5px'}}>Блоки отсутствуют</div>
            )
          }
        </div>
    </div>
  )
}



function mapStateToProps(state){
  return{
    widgets: state.siteInfo.site.widgets
  }
}


export default connect(mapStateToProps, null)(WidgetsPreview)