import React, { useEffect, useState } from 'react';
//react router
import { Link } from "react-router-dom";

import './SideMenu.scss'

//sidemenu components
import Dashboard from './Dashboard/Dashboard'
import AddSite from './AddSite/AddSite'
import HomeSite from './HomeSite/HomeSite'
import NavLinks from './NavLinks/NavLinks'
import SettingsLink from './SettingsLink/SettingsLink'
import ExitLink from './ExitLink/ExitLink';
import ManagerCard from './ManagerCard/ManagerCard'

import { connect } from 'react-redux'

import SVKLogo from '../commonComponents/svkLogo/svkLogo.js'
import SparrowLogo from '../commonComponents/sparrowLogo/SparrowLogo.js'

import { PieChart } from "react-feather";

const SideMenu = ({userName}) => {


  return (
    <div id="aside" className="page-sidenav no-shrink bg-light nav-dropdown fade our_header sticky" aria-hidden="true">
      <div className="sidenav h-100 modal-dialog bg-light">
        <div className="navbar m-0 mt-4 p-0 justify-content-center align-items-center">
          <div className="navbar-brand pr-0 mr-0">
            <span className="hidden-folded d-inline l-s-n-1x ml-1 left">
              <Link to="/" title="На главную">
                <SparrowLogo />
              </Link>
            </span>
          </div>
        </div>
        <div className="text-center user-name">
          <small className="text-muted">Добро пожаловать, <strong>{ userName !== '' ? userName : 'Гость' }</strong></small>
        </div>
          
        <div className="flex scrollable hover right-header" style={{overflow: 'visible'}}>
          <div className="nav-active-text-primary" data-nav>
            <ul className="nav bg pb-3">

              <Dashboard />

              <AddSite />
                  
              <HomeSite />

              <NavLinks />

              {/* <SettingsLink /> */}

              <ExitLink />

            </ul>
            <ManagerCard/>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state){
  return{
    userName: state.userInfo.user.name
  }
}


export default connect(mapStateToProps, null)(SideMenu)
