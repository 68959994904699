import React, { useState, useEffect } from 'react'
import './HomeSite.scss'
import { Home, PlusCircle, RefreshCw, Settings } from 'react-feather';

// redux
import { connect } from 'react-redux'

import { Link } from "react-router-dom";

import debounce from "lodash.debounce";

import { useHistory } from "react-router-dom";

import axios from 'axios'

import { hostDetect } from '../../../config/envConfig';

import { setAlert } from '../../../redux/actions/alertActions';

const HomeSite = (props) => {

  const [userSites, setUserSites ] = useState(props.userSitesRedux)
  const [searchedSite, setSearchedSite] = useState('')

  const [sitesPerPage, setSitesPerPage] = useState(20)
  const [sitesPage, setSitesPage] = useState(1)
  const [sitesMaxPage, setSitesMaxPage] = useState(1)
  const [sitesList, setSitesList] = useState([])
  const [sitesListScrolled, setSitesListScrolled] = useState([])

  const [userApiLoading, setUserApiLoading] = useState(false)


  useEffect(() => {
    if(userSites && userSites.length){

      let maxPage = Math.ceil(userSites.length / sitesPerPage);
      setSitesMaxPage(maxPage)

      setSitesList([...userSites].slice(0, sitesPerPage))
      setSitesListScrolled([...userSites].slice(0, sitesPerPage))
    }
  }, [userSites])

  let history = useHistory();

  let selectSite = (siteInfo)=> {
    let newSiteInfo = {
      id: siteInfo.id,
      full_name: siteInfo.full_name,
      url: siteInfo.url,
      status: siteInfo.status
    }

    props.selectNewSite(newSiteInfo);
    localStorage.setItem('current_site_information', JSON.stringify(newSiteInfo));

    props.switchWidget(0)
    props.addCurrentWidgetStatistics([])
    
    // redirect to / if site status is not active
    if(newSiteInfo.status !== 'active'){
      history.push("/");
    }
  }

  useEffect(() => {
    if (props.userSitesRedux) {
      setUserSites(props.userSitesRedux)
    }
  }, [props.userSitesRedux]);

  let cutHTTP = (siteUrl)=> {
    let correctName = siteUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
    return correctName
  }


  // search 
  const handleSearchSite = (searchQuery) => {
    debounceSearch(searchQuery)
  }

  const debounceSearch = debounce((searchQuery) => {
    
    if(searchQuery.length){
      setSearchedSite(searchQuery)

      let searchList = [...userSites].filter(site => {
        if(site.full_name && site.url && site.id && site.full_name.toLowerCase().includes(searchQuery.toLowerCase()) || site.url.toLowerCase().includes(searchQuery.toLowerCase()) || String(site.id).includes(searchQuery)){
          return true
        } else {
          return false
        }
      }) 

      setSitesList(searchList)
    } else {
      setSearchedSite('')
      setSitesList([...sitesListScrolled])
    }
  }, 1000);



  const handleMenuScroll = (e) => {

    if(e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight) && searchedSite.length === 0){

      if(sitesPage+1 <= sitesMaxPage){
        let pageIndex = sitesPage + 1;
        setSitesPage(pageIndex)

        let startPosition = (pageIndex - 1) * sitesPerPage;
        let endPosition = pageIndex * sitesPerPage;

        let newSites = [...userSites].slice(startPosition, endPosition)

        setSitesList([...sitesList, ...newSites])
        setSitesListScrolled([...sitesList, ...newSites])
      }

    }
  }


  const handleRefresh = () => {
    setUserApiLoading(true);

    let apiOptions = {
      withCredentials: true
    }
   // get all user`s sites
    axios.get(`${hostDetect('api')}/publisher/get-sites`, apiOptions)
      .then(response => {
        let status = response.data.success,
            sitesArray = response.data.data;

        if(status){
          // get sites array from api
          props.getSitesFromApi(sitesArray)
          // add first site to user
          if(sitesArray.length > 0){
            if (localStorage.getItem('current_site_information') === null) {
              props.getFirstSite(sitesArray[0]);
              localStorage.setItem('current_site_information', JSON.stringify(sitesArray[0]));

              getStatus(sitesArray[0].url)
            } else {
              let previousEnteredSite = JSON.parse(localStorage.getItem('current_site_information'));
              props.getFirstSite(previousEnteredSite);

              getStatus(previousEnteredSite.url)
            }

            // find site in all array and get it status
            function getStatus(domain){
              let site = sitesArray.filter(siteObject => siteObject.url === domain)
              props.getSiteStatus(site[0].status)
            }

            setUserApiLoading(false);
            // history.push("/");
          } else {
            setUserApiLoading(false);
          }
        } else {
          // window.location.href = `${process.env.REACT_APP_SITE_URL}/site/login`;
          setUserApiLoading(false);
          let errMessage = response.data.data.message;
          setAlert('error', errMessage);
        }
      })
      .catch(err => {
        // window.location.href = `${process.env.REACT_APP_SITE_URL}/site/login`;
        setUserApiLoading(false);
        setAlert('error', 'Не получилось отправить запрос!');
      })
  }

  return (
    <React.Fragment>
      <li className="pb-2">
        <div className="home-site">
          <span className="nav-icon text-primary" style={{display: 'none'}}>
            <Home size={16}/>
          </span>

          <div className="btn-group">
            {
              props.userSitesRedux.length > 0 ? (
                <>
                  {/* settings */}
                  <button type="button" className="btn btn-primary" onClick={() => history.push(`/settings/sites/editSite/${props.siteId}`)} title="Настройки сайта">
                    <Settings size={15} />
                  </button>

                  {/* sites list - dropdown */}
                  <button type="button" className="button-home btn btn-primary dropdown-toggle dropdown-toggle-split justify-content-around" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Выбрать сайт">
                    
                    <div style={{textAlign: 'left'}}>
                      <span className="button-home__name" title={props.siteName}>{props.siteName}</span>

                      <p  className="button-home__href" title={props.siteHref}>{props.siteHref}</p>
                      
                      <span className="button-home__id">
                        # {props.siteId} 
                        {
                          props.status === 'new' ? <span className="ml-1 mt-1 badge badge-warning text-uppercase" title="Статус сайта">Модерация</span> : null 
                        }
                        {
                          props.status === 'active' ? <span className="ml-1 mt-1 badge badge-success text-uppercase" title="Статус сайта">Активно</span> : null 
                        }
                        {
                          props.status === 'disabled' ? <span className="ml-1 mt-1 badge badge-danger text-uppercase" style={{background: '#F75959'}} title="Статус сайта">Заблокирован</span> : null 
                        }
                        </span>
                    </div>
                  </button>
                </>
              ) : (
                <Link className="btn btn-primary text-white create-first-site-btn" to="/settings/sites/createSite">
                  <span className="nav-icon text-primary">
                    <PlusCircle size={15} color={'#fff'}/>
                  </span>
                  <span className="nav-text ml-0">Добавить сайт</span>
                </Link>
              )
            }
            
            <div className="dropdown-menu">
              <input
                className="dropdown-menu__search form-control mr-sm-2"
                type="search"
                placeholder="Название, url или id сайта..."
                aria-label="Search"
                onChange={(e) => handleSearchSite(e.target.value)}
              />
              <div className="dropdown-menu__maxheight" onScroll={(e) => handleMenuScroll(e)}>
                {
                  sitesList.length > 0 ? 
                    sitesList.map(site => {
                      return(
                        <div
                          className={"dropdown-item " + (site.id === props.siteId ? 'active' : '')}
                          onClick={()=>selectSite({
                            id: site.id,
                            full_name: site.full_name,
                            url: site.url,
                            status: site.status
                          })}
                          key={site.id}>
                            {/* {
                              cutHTTP(site.url)
                            } */}
                            <p className="dropdown-item__site-name" title={site.full_name}>
                              {
                                site.full_name
                              }
                            </p>
                            <p className="dropdown-item__site-url" title={site.url}>
                              {
                                site.url
                              }
                            </p>
                            <p  className="dropdown-item__site-id">
                              # { site.id }
                            </p>
                          </div>
                        )
                      }) :
                      (<div style={{fontSize: 12, textAlign: 'center'}}>Нет повторений</div>)
                }
              </div>

              <div className="dropdown-divider"></div>
              <div
                className="dropdown-item"
                style={{fontSize: 12}}>Всего сайтов: <b>({sitesList.length})</b> {userSites.length}</div>
            </div>
          </div>
          
          {/* <div className="status">
            <div>
              статус: 
              {
                props.status === 'new' ? <span className="badge badge-warning text-uppercase">Модерация</span> : null 
              }
              {
                props.status === 'active' ? <span className="badge badge-success text-uppercase">Активно</span> : null 
              }
              {
                props.status === 'disabled' ? <span className="badge badge-danger text-uppercase" style={{background: '#F75959'}}>Заблокирован</span> : null 
              }
            </div>
            
            <button className={`status__refresh-button ${userApiLoading === true ? 'loading-spin' : ''}`} onClick={() => handleRefresh()}>
              <RefreshCw size={12} />
              обновить статус
            </button>
          </div> */}

        </div>

      </li>
    </React.Fragment>
  )
}

function mapStateToProps(state){
  return{
    siteName: state.siteInfo.site.full_name,
    siteHref: state.siteInfo.site.url,
    siteId: state.siteInfo.site.id,
    userSitesRedux: state.userInfo.user.sites,
    status: state.siteInfo.site.status
  }
}

function mapDispatchToProps(dispatch){
  return {
    selectNewSite: site => dispatch({type: 'SELECT_SITE', payload: site}),
    switchWidget: widgetIndex => dispatch({type: 'SWITCH_WIDGET', payload: widgetIndex}),
    addCurrentWidgetStatistics: statistics => dispatch({type: 'ADD_CURRENT_WIDGET_STATISTICS', payload: statistics}),
    getSitesFromApi: sites => dispatch({type: 'ADD_SITES_FROM_API', payload: sites}),
    getFirstSite: site => dispatch({type: 'ADD_FIRST_SITE', payload: site}),
    getSiteStatus: status => dispatch({type: 'GET_SITE_STATUS', payload: status}),
    setAlert
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeSite)