import React from 'react'

import DesktopSubtab from './DesktopSubtab'
import MobileSubtab from './MobileSubtab'
import InfiniteSubtab from './InfiniteSubtab'
import Backpage from './Backpage'
import Turbo from './Turbo'
import Banner from './Banner'

const Tab2 = ({blockType, setTabActive, deviceType, textPosition, setTextPosition, grid_x, setGrid_x, grid_y, setGrid_y, headerTitle, setHeaderTitle, infiniteTGBType, setInfiniteTGBType, bannerType, setBannerType, submitChanges}) => {

  return (
    <>
      {
        blockType === 'native' || blockType === 'amp' ? 
          (deviceType === 'desktop' ? <DesktopSubtab 
                                        textPosition={textPosition} 
                                        setTextPosition={setTextPosition} 
                                        grid_x={grid_x} 
                                        setGrid_x={setGrid_x} 
                                        grid_y={grid_y} 
                                        setGrid_y={setGrid_y} 
                                        headerTitle={headerTitle} 
                                        setHeaderTitle={setHeaderTitle} /> 
                                    : 
                                      <MobileSubtab 
                                        textPosition={textPosition} 
                                        setTextPosition={setTextPosition} 
                                        grid_x={grid_x} 
                                        setGrid_x={setGrid_x} 
                                        grid_y={grid_y} 
                                        setGrid_y={setGrid_y} 
                                        headerTitle={headerTitle} 
                                        setHeaderTitle={setHeaderTitle} />) 
          : null
      }
      {
        blockType === 'infinite' ? <InfiniteSubtab 
                                    deviceType={deviceType}
                                    infiniteTGBType={infiniteTGBType} 
                                    setInfiniteTGBType={setInfiniteTGBType} 
                                    headerTitle={headerTitle} 
                                    setHeaderTitle={setHeaderTitle} /> 
                                  : null
      }
      {
        blockType === 'backpage' ? <Backpage deviceType={deviceType}
                                    infiniteTGBType={infiniteTGBType} 
                                    setInfiniteTGBType={setInfiniteTGBType} 
                                    headerTitle={headerTitle} 
                                    setHeaderTitle={setHeaderTitle} /> 
                                  : null
      }
      {
        blockType === 'turbo' ? <Turbo 
                                  textPosition={textPosition} 
                                  setTextPosition={setTextPosition} 
                                  grid_x={grid_x} 
                                  setGrid_x={setGrid_x} 
                                  grid_y={grid_y} 
                                  setGrid_y={setGrid_y} 
                                  headerTitle={headerTitle} 
                                  setHeaderTitle={setHeaderTitle} /> 
                                : null
      }
      {
        blockType === 'banner' ? <Banner 
                                    deviceType={deviceType} 
                                    bannerType={bannerType}
                                    setBannerType={setBannerType}
                                    headerTitle={headerTitle} 
                                    setHeaderTitle={setHeaderTitle} /> 
                                  : null
      }
      <div className="wiz-tab__buttons">
        <button className="btn w-sm mb-1 mr-1 btn-primary" onClick={() => setTabActive(1)}>Назад</button>
        <button className="btn w-sm mb-1 btn-primary" onClick={() => submitChanges()}>Сохранить</button>
        {/* {
          headerTitle.length < 3 || headerTitle.length > 64
            ? <button className="btn w-sm mb-1 bg-dark-lt" disabled>Сохранить</button>
            : <button className="btn w-sm mb-1 btn-primary" onClick={() => submitChanges()}>Сохранить</button>
        } */}
      </div>
    </>
  )
}

export default Tab2