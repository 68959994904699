import React from 'react'
import { connect } from "react-redux";

import ExportStatistics from './ExportStatistics/exportStatistics';

import './InfoTable.scss'

import { useLocation } from 'react-router-dom';

const InfoTable = (props)=>{

  let location = useLocation();

  let formatLongNumber = tableNumber => {
    return new Intl.NumberFormat('ru-RU').format(tableNumber)
  }

  let formatFixed = (tableValue, fixLength) => {
    if(tableValue) {
      // return tableValue.toFixed(fixLength)

      let statNumber = String(tableValue),
          dotPosition = statNumber.indexOf('.');

      if(dotPosition === -1){
        return statNumber + '.00';
      } else {
        let numberBeforeDot = statNumber.slice(0, dotPosition),
            numberAfterDot = statNumber.slice(dotPosition+1);

        return numberBeforeDot + '.' + numberAfterDot.slice(0, fixLength);
      }
    } else {
      return '0'
    }
  }

  return (
    <div className="card info-table">
      <table className="table">
        <tbody>
          <tr>
            <th scope="row">Загрузки</th>
            <td>{ formatLongNumber(props.widgetInfo.visits) }</td>
          </tr>
          <tr>
            <th scope="row">Видимость</th>
            <td>{ formatFixed(props.widgetInfo.view_percent, 1) } %</td>
          </tr>
          <tr>
            <th scope="row">Показы</th>
            <td>{ formatLongNumber(props.widgetInfo.views) }</td>
          </tr>
          <tr>
            <th scope="row">CTR</th>
            <td>{ formatFixed(props.widgetInfo.ctr, 2) }</td>
          </tr>
          {/* <tr>
            <th scope="row">Клики</th>
            <td>{ formatLongNumber(props.widgetInfo.clicks) }</td>
          </tr> */}
          {
            location.pathname !== '/income/banner' ? (
              <tr>
                <th scope="row">Органические переходы</th>
                <td>{ formatLongNumber(props.widgetInfo.organic_clicks) }</td>
              </tr>
            ) : null 
          }
          <tr>
            <th scope="row">Промо переходы</th>
            <td>{ formatLongNumber(props.widgetInfo.ad_clicks) }</td>
          </tr>
          {
            location.pathname !== '/income/banner' ? (
              <tr>
                <th scope="row">Обменные переходы</th>
                <td>{ formatLongNumber(props.widgetInfo.exchange_clicks) }</td>
              </tr>
            ) : null 
          }
          <tr>
            <th scope="row">CPM</th>
            <td>{ formatFixed(props.widgetInfo.cpm, 2) }</td>
          </tr>
          <tr>
            <th scope="row">Доход</th>
            <td>{ formatFixed(props.widgetInfo.income, 1) } ₽</td>
          </tr>
          {
            location.pathname !== '/income/banner' ? (
              <tr>
                <th scope="row">Продвижение</th>
                <td>0</td>
              </tr>
            ) : null 
          }
          
          {
            props.widgetActiveIndex === 0 && location.pathname !== '/income/banner' ? (
              <>
                <tr>
                  <th scope="row">Возврат</th>
                  <td>{ formatLongNumber(props.widgetInfo.returned) }</td>
                </tr>
                <tr>
                  <th scope="row">Коэффициент возврата</th>
                  <td>{ formatFixed(props.widgetInfo.exchange_mult, 2) }</td>
                </tr>
              </>
            ) : null
          }
          
          {
            location.pathname !== '/income/banner' ? (
              <tr>
                <th scope="row">Прибыль</th>
                <td>{ formatFixed(props.widgetInfo.profit, 1) } ₽</td>
              </tr>
            ) : null 
          }
          
        </tbody>
      </table>
      
      <ExportStatistics/>

    </div>
  )
}

function mapStateToProps(state){
  return{
    widgetInfo: state.siteInfo.site.totalWidgetList[state.siteInfo.site.widgetActive],
    widgetActiveIndex: state.siteInfo.site.widgetActive
  }
}

export default connect(mapStateToProps)(InfoTable)