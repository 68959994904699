import React, { useState, useEffect } from 'react';

import './SettingsModal.scss';

import Limits from './Limits/Limits'
import Schedule from './Schedule/Schedule';

const SettingsModal = ({closeModal, advId}) => {
	
	return(
		<div className="settings-modal">
			<div className="settings-modal__inner modal-content box-shadow">

				<div className="modal-header">
					<div>
						<h5 className="modal-title">Настройки</h5>
						<small className="text-muted">id объявления - #{advId}</small>
					</div>
					
					<button className="close" onClick={closeModal}>×</button>
				</div>

				<div className="p-3 d-flex align-items-center">
					<strong>Лимиты</strong>
				</div>

				<Limits advId={advId}/>				

				<div className="p-3 d-flex align-items-center">
					<strong>Даты</strong>
				</div>

				<Schedule advId={advId}/>

				<div className="settings-modal__submit pl-3">
					<button className="btn w-sm mb-1 btn-success" onClick={closeModal}>Закрыть</button>
				</div>
				
			</div>
		</div>
	)
}

export default SettingsModal