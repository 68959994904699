import React, { useState } from 'react'

import './GroupbyButtons.scss'

import { connect } from 'react-redux'

const GroupbyButtons = (props)=> {
  const [activeBtn, setActiveBtn] = useState(props.groupBy)

  const handleChange = (event)=>{
    setActiveBtn(event.target.value);
    props.changeGroupByQuery(event.target.value)
  }

  return (
    <div className="groupby">
      <p className="nav-header hidden-folded">
        Группировать по:
      </p>
      <div className="input-group">
        <select className="custom-select" value={activeBtn} onChange={handleChange}>
          <option value="day">По дням</option>
          <option value="week">По неделям</option>
          <option value="month">По месяцам</option>
        </select>
      </div>
    </div>
  )
}

function mapStateToProps(state){
  return{
    groupBy: state.siteInfo.site.groupBy
  }
}

function mapDispatchToProps(dispatch){
  return {
    changeGroupByQuery: groupByQuery => dispatch({type: 'CHANGE_GROUPBY_QUERY', payload: groupByQuery})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupbyButtons)