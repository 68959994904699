import React from 'react'

const Backpage = ({deviceType, infiniteTGBType, setInfiniteTGBType, headerTitle, setHeaderTitle}) => {
  return (
    <>
      <>
      {/* card type */}
      <div className="form-group">
        <label>Шаблоны*</label>
        {/* mobile */}
        {
          deviceType === 'mobile' ? (
            <>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="tgb-type" 
                    value="2"
                    onChange={e => setInfiniteTGBType(e.target.value)}
                    checked={infiniteTGBType == 2} />
                    Графический 2 анонса
                </label>
              </div>
            </>
          ) : null
        }
        
        {/* desktop */}
        {
          deviceType === 'desktop' ? (
            <>
              <div className="form-check mb-1">
                <label className="form-check-label">
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name="tgb-type" 
                    value="5"
                    onChange={e => setInfiniteTGBType(e.target.value)}
                    checked={infiniteTGBType == 5} />
                    Графический 5 анонсов
                </label>
              </div>
            </>
          ) : null
        }
        

        <small className="text-muted">*для повторяющейся строки</small>

        {/* header title */}
        <div className="form-group mt-3">
          <label>Заголовок</label>
          <input 
            type="text" 
            className="form-control" 
            value={headerTitle} 
            onChange={e => setHeaderTitle(e.target.value)} 
            placeholder='Например: "Читайте также:"' />
          {
            headerTitle.length < 3 || headerTitle.length > 64
              ? <small className="form-text text-muted">Заголовок должен содержать минимум 3 и максимум 64 знака</small>
              : null
          }
        </div>

      </div>
    </>
    </>
  )
}

export default Backpage