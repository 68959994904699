import React, { useState, useEffect } from 'react'
import './WidgetsList.scss'

import { connect } from 'react-redux'

const WidgetsList = (props)=>{

  useEffect(()=>{
    setDefaultVal('Суммарно')
    props.switchWidget(0)
    props.addCurrentWidgetStatistics(props.totalStatistics)
  }, [props.groupByLabel, props.statisticsDate])

  useEffect(()=>{
    if(props.activeWidgetIndex === 0){
      setDefaultVal('Суммарно')
      props.switchWidget(0)
      props.addCurrentWidgetStatistics(props.totalStatistics)
    }
  }, [props.totalStatistics])

  const [defaultVal, setDefaultVal] = useState('Суммарно')

  const handleChange = event => {
    let index = event.target.selectedIndex,
        widgetStats;

    setDefaultVal(event.target.value)
    props.switchWidget(index)

    if(props.siteWidgets[index-1]){
      widgetStats = props.siteWidgets[index-1].data
    }

    if(widgetStats){
      props.addCurrentWidgetStatistics(widgetStats)
    } else{
      props.addCurrentWidgetStatistics([])
    }
  }

  return(
    <div className="widgets-list-select">
      <p className="nav-header hidden-folded">
        Выберите виджет:
      </p>

      <div className="widgets-list-select__wrap">

        <div className="input-group">
          <select className="custom-select" value={defaultVal} onChange={handleChange}>
            <option value="Суммарно">Суммарно</option>
            {
              props.siteWidgets.map((widget, indexW)=>{
                return (
                  <option value={widget.name} key={widget.id}>
                    {widget.name}
                  </option>
                )
              })
            }
          </select>
        </div>

      </div>
    </div>
  )
}

function mapStateToProps(state){
  return{
    siteWidgets: state.siteInfo.site.widgets,
    activeWidgetIndex: state.siteInfo.site.widgetActive,
    groupByLabel: state.siteInfo.site.groupBy,
    statisticsDate: state.siteInfo.site.statisticsDate,
    totalStatistics: state.siteInfo.site.totalSite.by_date
  }
}

function mapDispatchToProps(dispatch){
  return {
    addCurrentWidgetStatistics: statistics => dispatch({type: 'ADD_CURRENT_WIDGET_STATISTICS', payload: statistics}),
    switchWidget: widgetIndex => dispatch({type: 'SWITCH_WIDGET', payload: widgetIndex})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsList)