 import React, { useState, useEffect, useRef } from 'react'

 import './WidgetInfo.scss'

 import {
  BrowserView
} from "react-device-detect";

 import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'
 import FeedCards from './FeedCards/FeedCards'
//  import WidgetPreview from './WidgetPreview/WidgetPreview'
//  import PreviewSpinner from '../../components/commonComponents/previewSpinner/PreviewSpinner'

 import { hostDetect } from '../../config/envConfig'

 import { useHistory, useParams, Link } from "react-router-dom";

 import { ArrowLeft, X, Sun, Moon, Maximize2, Minimize2, Save, Copy } from "react-feather";

 import axios from 'axios'

 import { connect } from 'react-redux'

 import { setAlert } from '../../redux/actions/alertActions';
	

 const WidgetInfo = (props) => {

		const [apiLoading, setApiLoading] = useState(false)
    
		const [widgetType, setWidgetType] = useState('5')
		const [widgetName, setWidgetName] = useState('')
		const [widgetTitle, setWidgetTitle] = useState('')
		const [widgetColumn, setWidgetColumn] = useState('1')
		const [widgetRow, setWidgetRow] = useState('1')
		const [widgetCSS, setWidgetCSS] = useState('')
		const [widgetAmp, setWidgetAmp] = useState(0)
    const [bannerType, setBannerType] = useState('300x300')

		const [widgetJavascriptCode, setWidgetJavascriptCode] = useState('')
		const [widgetAMPCode, setWidgetAMPCode] = useState('')
		const [widgetTurboCode, setWidgetTurboCode] = useState('')
		const [widgetBackpageCode, setWidgetBackpageCode] = useState('')

		const [widgetReady, setWidgetReady] = useState(false)

		const [cardsList, setCardsList] = useState([])

		const [alertSuccess, setAlertSuccess] = useState(false)
		const [alertFailed, setAlertFailed] = useState(false)

		const [lentaOrderChanged, setLentaOrderChanged] = useState('')

     //params get from URL /widgetInfo/siteId/widgetId
		let {siteId, widgetId} = useParams()


		// CSS
		let textareaCSS = useRef(null)

		// JS codes
		const textareaJS = useRef(null);
		const [textareaJsCode, setTextareaJsCode] = useState(false)
		const copyToClipboardJS = (e) => {
			textareaJS.current.select();
			document.execCommand('copy');
			// e.target.focus();
			setTextareaJsCode(true)

			setTimeout(()=>setTextareaJsCode(false), 2000)
		};

		const textareaAMP = useRef(null);
		const [textareaAMPCode, setTextareaAMPCode] = useState(false)
		const copyToClipboardAMP = (e) => {
			textareaAMP.current.select();
			document.execCommand('copy');
			// e.target.focus();
			setTextareaAMPCode(true)

			setTimeout(()=>setTextareaAMPCode(false), 2000)
		};

		const textareaTurbo = useRef(null);
		const [textareaTurboCode, setTextareaTurboCode] = useState(false)
		const copyToClipboardTurbo = (e) => {
			textareaTurbo.current.select();
			document.execCommand('copy');
			// e.target.focus();
			setTextareaTurboCode(true)

			setTimeout(()=>setTextareaTurboCode(false), 2000)
		};

		const textareaBackpage = useRef(null);
		const [textareaBackpageCode, setTextareaBackpageCode] = useState(false)
		const copyToClipboardBackpage = (e) => {
			textareaBackpage.current.select();
			document.execCommand('copy');
			// e.target.focus();
			setTextareaBackpageCode(true)

			setTimeout(()=>setTextareaBackpageCode(false), 2000)
		};


    useEffect(()=>{
      window.scrollTo(0, 0);
    }, [])

		useEffect(()=>{ 
		
			setApiLoading(true)

			let getCurrentWidgetInfo = async ()=> {
				await axios.get(`${hostDetect('api')}/widget/get?site_id=${siteId}&id=${widgetId}`, {
					withCredentials: true
				})
				.then(res => {

					setApiLoading(false)

					if(res.data.success){
						initWidgetWithApiCall(res.data.data)
					} else {
						const errorMessage = res.data.data.message
						props.setAlert('error', errorMessage);
					}
				})
				.catch(err => {
					setApiLoading(false)
					props.setAlert('error', 'Не получилось отправить запрос!');
				})
			}

			getCurrentWidgetInfo()

			if(props.siteId.toString() !== siteId){
				history.push("/settings/widgets");
			}

		}, [])

    useEffect(() => {
     	if(widgetReady){
     		history.push("/settings/widgets");
     	}
	}, [props.siteId])

     let history = useHistory();

     const handleBackClick = () => {
         history.push("/settings/widgets");
     }

     const initWidgetWithApiCall = widgetInfo => {

     		setWidgetType(widgetInfo.type)
     		setWidgetName(widgetInfo.name)
     		setWidgetTitle(widgetInfo.title)
     		setWidgetColumn(widgetInfo.columns)
     		setWidgetRow(widgetInfo.rows)
     		setWidgetCSS(widgetInfo.css)
			if(widgetInfo.banner_type){
				setBannerType(widgetInfo.banner_type)
			}
     		
     		if(widgetInfo.serve_amp == null){
					widgetInfo.serve_amp = 0
     		}
     		setWidgetAmp(widgetInfo.serve_amp)

     		setWidgetJavascriptCode(widgetInfo.embed_code)
     		setWidgetAMPCode(widgetInfo.embed_amp_code)
				setWidgetTurboCode(widgetInfo.ext_code)
				setWidgetBackpageCode(widgetInfo.back_code)

     		setCardsList(widgetInfo.cards)

     		// set to "true", to redirect when sidebar link to main site was changed
     		setWidgetReady(true)
     }


     const handleWidgetType = (event) => {
     		setWidgetType(event.target.value)
     }

     const handleWidgetName = (event) => {
     		setWidgetName(event.target.value)
     }

     const handleWidgetTitle = (event) => {
     		setWidgetTitle(event.target.value)
     }

     const handleWidgetColumn = (event) => {
     		setWidgetColumn(event.target.value)
     }

     const handleWidgetRow = (event) => {
     		setWidgetRow(event.target.value)
     }

     const handleTextareaTheme = () => {
     		props.switchTextareTheme()
     }

     const handleTextareaCSSHeigthMax = () => {
     		textareaCSS.current.style.height = textareaCSS.current.scrollHeight + 20 + 'px'
     }
     const handleTextareaCSSHeigthMin = () => {
     		textareaCSS.current.style.height = 'auto'
     }

     const handleWidgetCSS = (event) => {
     		setWidgetCSS(event.target.value)
     }

     const handleAmpUse = (event) => {
     		if(event.target.checked){
     			setWidgetAmp(1)
     		} else {
     			setWidgetAmp(0)
     		}
     }

     const handleCardsUpdate = cards => {
     		setCardsList(cards)
     }

     const handleBannerType = event => {
      setBannerType(event.target.value)
     }

     const onWidgetSave = () => {
     	 	const formData = new FormData();
				formData.append("type", widgetType);
				formData.append("name", widgetName);
				formData.append("title", widgetTitle);
				formData.append("columns", String(widgetColumn));
				formData.append("rows", String(widgetRow));
				formData.append("css", widgetCSS);
				formData.append("serve_amp", String(widgetAmp));
        if(widgetType == 30){
          formData.append("bannerType", bannerType);
        }

				axios.post(`${hostDetect('api')}/widget/save?site_id=${siteId}&id=${widgetId}`, formData, { withCredentials: true })
				  .then(res => {
				    if(res.data.success){
		          setAlertSuccess(true);
		          setTimeout(()=> setAlertSuccess(false), 2000)
		        } else if(!res.data.success){
							const errorMessage = res.data.data.message
							props.setAlert('error', errorMessage);

		          setAlertFailed(true);
		          setTimeout(()=> setAlertFailed(false), 2000)
		        }
				  })
				  .catch(err => {
				    props.setAlert('error', 'Не получилось отправить запрос!');
				  })
			}

			const handleLentaOrderChanging = () => {
				setLentaOrderChanged('show');

				setTimeout(()=>setLentaOrderChanged(''), 500)
			}

     return (
      <div className="widgetInfo">

 				<MobileHeader/>

 				<div className="widgetInfo__back-btn">
		    	<button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
		      	<ArrowLeft size={15} style={{marginRight: 10}}/>Назад
		      </button>
		    </div>

		    <div className="createWidgets__form-place">
	        <div className="row">
	          <div className="col-md-6">
	            <div className="card">
	              <div className="card-header d-flex justify-content-between align-items-center">
	                <strong>
										Редактирование виджета 
										{
											apiLoading ? 
												(
													<div className="spinner-border spinner-border-sm text-primary ml-2" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												) : null
										}
									</strong>
	                <small>#{widgetId}</small>
	              </div>
	              <div className="card-body">

	                <div className="form-group row">
	                  <label className="col-sm-4 col-form-label">Тип</label>
	                  <div className="col-sm-8">
	                    <select className="form-control" value={widgetType} onChange={handleWidgetType}>
	                      <option value="0">Картинка+заголовок</option>
	                      <option value="5">Картинка+заголовок+описание</option>
	                      <option value="10">Заголовок+описание</option>
	                      <option value="20">Лента</option>
                        {
                          props.userId == 94313 || props.userId == 94472 || props.userId == 94568 ? (
                            <option value="30">Баннер</option>
                          ) : null
                        }
                        {/* <option value="30">Баннер</option> */}
						            
	                      <option value="100">Турбо</option>
	                    </select>
	                  </div>
	                </div>

	                <div className="form-group row">
	                  <label className="col-sm-4 col-form-label">Название</label>
	                  <div className="col-sm-8">
	                    <input type="text" className="form-control" placeholder="Введите название" value={widgetName} onChange={handleWidgetName}/>
	                  </div>
	                </div>

                  {
                    widgetType != 30 ? (
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Заголовок</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" placeholder="Введите заголовок" value={widgetTitle} onChange={handleWidgetTitle}/>
                        </div>
                      </div>
                    ) : null
                  }

                  {
                    // widgetType == 30 ? (
                    widgetType == 30 && (props.userId == 94068 || props.userId == 94313 || props.userId == 94472 || props.userId == 94568) ? (
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Размер</label>
                        <div className="col-sm-8">
                          <select className="form-control" value={bannerType} onChange={handleBannerType}>
                            <option value="300x300">300x300</option>
                            <option value="240x400">240x400</option>
                            <option value="300x600">300x600</option>
                            <option value="728x90">728x90</option>
                            <option value="970x90">970x90</option>
                            <option value="970x250">970x250</option>
                            <option value="300x250">300x250</option>
                            <option value="120x600">120x600</option>
                            <option value="160x600">160x600</option>
                            <option value="320x50">320x50</option>
                            <option value="320x100">320x100</option>
                            <option value="336x280">336x280</option>
                            <option value="580x400">580x400</option>
                          </select>
                        </div>
                      </div>
                      
                    ) : null
                  }
	                
	                {
	                	widgetType != 20 && widgetType != 30 ? (
	                		<div className="form-group row">
			                  <label className="col-sm-4 col-form-label">Колонок * строк</label>
			                  <div className="col-sm-8">
			                    <div className="d-flex">
			                      <select className="form-control" value={widgetColumn} onChange={handleWidgetColumn}>
			                        <option value="1">1</option>
			                        <option value="2">2</option>
			                        <option value="3">3</option>
			                        <option value="4">4</option>
			                        <option value="5">5</option>
															<option value="6">6</option>
															<option value="7">7</option>
															<option value="8">8</option>
															<option value="9">9</option>
															<option value="10">10</option>
			                      </select>
			                      <div className="ml-2 mr-2 d-flex justify-content-center align-items-center">
			                        <X size={12}/>
			                      </div>
			                      <select className="form-control" value={widgetRow} onChange={handleWidgetRow}>
			                        <option value="1">1</option>
			                        <option value="2">2</option>
			                        <option value="3">3</option>
			                        <option value="4">4</option>
			                        <option value="5">5</option>
			                        <option value="6">6</option>
			                        <option value="7">7</option>
			                        <option value="8">8</option>
			                        <option value="9">9</option>
			                        <option value="10">10</option>
			                      </select>
			                    </div>
			                  </div>
			                </div>
	                	) : ''
	                }
	                

	                <div className="form-group row d-flex flex-column" style={{padding: '0 15px'}}>
		                <div className="d-flex align-items-center justify-content-between">
		                	<label className="col-form-label">CSS стили</label>

		                	<div>
		                		<button className="btn" onClick={handleTextareaCSSHeigthMax} style={{marginRight: 10}} title="Показать весь код">
		                			<Maximize2 size={12} color="#99A0AC"/>
		                		</button>

												<button className="btn" onClick={handleTextareaCSSHeigthMin} style={{marginRight: 40}} title="Свернуть">
		                			<Minimize2 size={12} color="#99A0AC"/>
		                		</button>
		                		
		                		<Sun size="12" color="#99A0AC" style={{marginRight: 5}}/>

		                		<label className="md-switch" title="Сменить тему">
	                      	<input type="checkbox" checked={props.textareaTheme} onChange={handleTextareaTheme}/>
	                      	<i className="indigo"></i>
	                  		</label>

	                  		<Moon size="12" color="#99A0AC"/>
		                	</div>
		                	
		                </div>
	                    
                    <div>
                        <textarea 
                        	className={"form-control " + (props.textareaTheme ? 'dark' : 'light')}  
                        	rows="7" 
                        	style={{marginTop: "0px", marginBottom: "0px", height: "171px", fontSize: 13, fontWeight: 500}} 
                        	placeholder="*Ваш CSS код*"
                        	value={widgetCSS}
                        	onChange={handleWidgetCSS}
                        	ref={textareaCSS}
                        	></textarea>
                    </div>
	                </div>

	                <div className="form-group row">
                    <div className="d-flex align-items-center justify-content-between w-100" style={{padding: '0 15px'}}>
                      <div>Переходы на AMP страницы</div>
                      <label className="ui-switch ui-switch-md info m-t-xs">
	                      <input type="checkbox" checked={widgetAmp} onChange={handleAmpUse}/>
	                      <i></i>
	                    </label>
                    </div>
	                </div>              

	                <div className="d-flex justify-content-between align-items-center widgetInfo__save-btn-alert">
	                  <button className="btn w-sm btn-primary" onClick={onWidgetSave}>Сохранить</button>

	                  {
	                    alertSuccess == true ? (
	                    <div className="alert alert-success" role="alert">
	                      <svg 
	                        xmlns="http://www.w3.org/2000/svg" 
	                        width="16" 
	                        height="16" 
	                        viewBox="0 0 24 24" 
	                        fill="none" 
	                        stroke="currentColor" 
	                        strokeWidth="2" 
	                        strokeLinecap="round" 
	                        strokeLinejoin="round" 
	                        className="feather feather-check">
	                          <polyline points="20 6 9 17 4 12"></polyline>
	                        </svg>
	                      <span className="mx-2">Виджет сохранен!</span>
	                    </div>
	                    ) : ''
	                  }

	                  {
	                    alertFailed == true ? (
	                    <div className="alert alert-warning" role="alert">
	                      <svg 
	                        xmlns="http://www.w3.org/2000/svg" 
	                        width="16" 
	                        height="16" 
	                        viewBox="0 0 24 24" 
	                        fill="none" 
	                        stroke="currentColor" 
	                        strokeWidth="2" 
	                        strokeLinecap="round" 
	                        strokeLinejoin="round" 
	                        className="feather feather-alert-circle">
	                          <circle cx="12" cy="12" r="10"></circle>
	                          <line x1="12" y1="8" x2="12" y2="12"></line>
	                          <line x1="12" y1="16" x2="12" y2="16"></line>
	                      </svg>
	                      <span className="mx-2">Произошла ошибка при сохранении виджета</span>
	                    </div>
	                    ) : ''
	                  }
	                </div>

	              </div>
	            </div>

	            {
	            	widgetType == 20 && props.userRole === 'manager' ? (
	            		<div className="card">
			            	<div className="card-header d-flex justify-content-between align-items-center">
			                <strong>Параметры ленты</strong>
			                <Save size={15} color={'#31c971'} className={"lenta-card-save-icon " + lentaOrderChanged }/>
			                <div className="dropdown">
											  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											    Добавить
											  </button>
											  <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
											    <Link 
											    	className="dropdown-item" 
											    	to={`/settings/widgets/cardAdd/${siteId}/${widgetId}/SVK_TGB_1`}>SVK_TGB_1</Link>
											    <Link 
											    	className="dropdown-item" 
											    	to={`/settings/widgets/cardAdd/${siteId}/${widgetId}/SVK_TGB_2`}>SVK_TGB_2</Link>
											    <Link 
											    	className="dropdown-item" 
											    	to={`/settings/widgets/cardAdd/${siteId}/${widgetId}/SVK_TGB_3`}>SVK_TGB_3</Link>
											    <Link 
											    	className="dropdown-item" 
											    	to={`/settings/widgets/cardAdd/${siteId}/${widgetId}/SVK_TGB_4`}>SVK_TGB_4</Link>
											    <Link 
											    	className="dropdown-item" 
											    	to={`/settings/widgets/cardAdd/${siteId}/${widgetId}/SVK_TGB_5`}>SVK_TGB_5</Link>
											    <Link 
											    	className="dropdown-item" 
											    	to={`/settings/widgets/cardAdd/${siteId}/${widgetId}/SVK_TGB_6`}>SVK_TGB_6</Link>
											    <Link 
											    	className="dropdown-item" 
											    	to={`/settings/widgets/cardAdd/${siteId}/${widgetId}/SVK_CustomHTML`}>SVK_CustomHTML</Link>
											  </div>
											</div>
			              </div>

			              {
			              	cardsList && cardsList.length > 0 ? (
			              		<div className="card-body">
			              			<FeedCards 
			              				cards={cardsList} 
			              				setCards={handleCardsUpdate} 
			              				siteId={siteId} 
			              				widgetId={widgetId}
			              				lentaOrderChanging={handleLentaOrderChanging}/>
			              		</div> 
			              	) : null
										}

			            </div>
	            	) : (
									<div className="alert alert-info" role="alert">
										<svg 
											xmlns="http://www.w3.org/2000/svg" 
											width="16" 
											height="16" 
											viewBox="0 0 24 24" 
											fill="none" 
											stroke="currentColor" 
											strokeWidth="2" 
											strokeLinecap="round" 
											strokeLinejoin="round" 
											className="feather feather-info">
												<circle cx="12" cy="12" r="10"></circle>
												<line x1="12" y1="16" x2="12" y2="12"></line>
												<line x1="12" y1="8" x2="12" y2="8"></line>
										</svg>
										<span className="mx-2">Уважаемый партнер! Для редактирования расположения блоков и управления рекламой свяжитесь с Вашим менеджером</span>
									</div>
								)
	            }
	            

	          </div>

	          <div className="col-md-6">
		          <div className="card">
		          	<div className="card-header">
		          		<strong>Установка виджета</strong><br/>
		          		<small>Разместите данный код в том месте страницы где должен быть виджет</small>
		          	</div>
		          </div>

							{/* JAVASCRIPT */}
		          <div className="card mb-2">
                <div className="card-header no-border d-flex justify-content-between align-items-center">
                    <a data-toggle="collapse" data-target="#js_code" className="collapsed flex-grow-1 dropdown-toggle" aria-expanded="false">
                        <strong>Javascript</strong>
                    </a>
                    <a href="#" className="btn btn-sm btn-secondary" style={{display: "none"}}>Скачать данные</a>
                </div>
                <div id="js_code" className="in b-t collapse">
                    <div className="card-body">
                        <textarea 
                        	readOnly 
                        	className="form-control" 
                        	value={widgetJavascriptCode}
													rows="25"
													ref={textareaJS}></textarea>

												<div className="d-flex align-items-center mt-2">
													<button className='btn w-sm btn-sm btn-primary' onClick={copyToClipboardJS} title="Скопировать код в буфер обмена">
														<Copy size={15} style={{marginRight: 10}}/>
														Копировать
													</button>
													{
														textareaJsCode ? <span className="badge badge-success text-uppercase ml-2">Скопировано</span> : null
													}
												</div>
													
                    </div>
                </div>
            	</div>

							{/* 100 - turbo */}
							{
								widgetType != '100' ? (
									<>
										{/* AMP */}
										<div className="card mb-2">
											<div className="card-header no-border d-flex justify-content-between align-items-center">
													<a data-toggle="collapse" data-target="#amp_code" className="collapsed flex-grow-1 dropdown-toggle" aria-expanded="false">
															<strong>AMP</strong>
													</a>
													<a href="#" className="btn btn-sm btn-secondary" style={{display: "none"}}>Скачать данные</a>
											</div>
											<div id="amp_code" className="in b-t collapse">
													<div className="card-body">
														<textarea 
															readOnly 
															className="form-control"  
															value={widgetAMPCode} 
															rows="10"
															ref={textareaAMP}></textarea>

														<div className="d-flex align-items-center mt-2">
															<button className='btn w-sm btn-sm btn-primary' onClick={copyToClipboardAMP} title="Скопировать код в буфер обмена">
																<Copy size={15} style={{marginRight: 10}}/>
																Копировать
															</button>
															{
																textareaAMPCode ? <span className="badge badge-success text-uppercase ml-2">Скопировано</span> : null
															}
														</div>

													</div>
											</div>
										</div>
									</>
								) : null
							}

							

							{
								widgetType == '100' ? (
									<>
										{/* TURBO */}
										<div className="card mb-2">
											<div className="card-header no-border d-flex justify-content-between align-items-center">
													<a data-toggle="collapse" data-target="#turbo_code" className="collapsed flex-grow-1 dropdown-toggle" aria-expanded="false">
															<strong>Яндекс Turbo</strong>
													</a>
													<a href="#" className="btn btn-sm btn-secondary" style={{display: "none"}}>Скачать данные</a>
											</div>
											<div id="turbo_code" className="in b-t collapse">
													<div className="card-body">
														<textarea 
															readOnly 
															className="form-control" 
															value={widgetTurboCode} 
															rows="10"
															ref={textareaTurbo}></textarea>

														<div className="d-flex align-items-center mt-2">
															<button className='btn w-sm btn-sm btn-primary' onClick={copyToClipboardTurbo} title="Скопировать код в буфер обмена">
																<Copy size={15} style={{marginRight: 10}}/>
																Копировать
															</button>
															{
																textareaTurboCode ? <span className="badge badge-success text-uppercase ml-2">Скопировано</span> : null
															}
														</div>

													</div>
											</div>
										</div>
									</>
								) : null
							}


							{/* BACKPAGE */}
							<div className="card mb-2">
                <div className="card-header no-border d-flex justify-content-between align-items-center">
                    <a data-toggle="collapse" data-target="#backpage_code" className="collapsed flex-grow-1 dropdown-toggle" aria-expanded="false">
                        <strong>Отказная страница</strong>
                    </a>
                    <a href="#" className="btn btn-sm btn-secondary" style={{display: "none"}}>Скачать данные</a>
                </div>
                <div id="backpage_code" className="in b-t collapse">
                    <div className="card-body">
                      <textarea 
                      	readOnly 
                      	className="form-control" 
                      	value={widgetBackpageCode} 
												rows="10"
												ref={textareaBackpage}></textarea>

											<div className="d-flex align-items-center mt-2">
												<button className='btn w-sm btn-sm btn-primary' onClick={copyToClipboardBackpage} title="Скопировать код в буфер обмена">
													<Copy size={15} style={{marginRight: 10}}/>
													Копировать
												</button>
												{
													textareaBackpageCode ? <span className="badge badge-success text-uppercase ml-2">Скопировано</span> : null
												}
											</div>

                    </div>
                </div>
            	</div>

							<BrowserView>
								{/* <WidgetPreview widgetJavascriptCode={widgetJavascriptCode} /> */}
							</BrowserView>

	          </div>
	        </div>
	      </div>

	      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Вы уверены, что хотите удалить виджет?</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Лента мобильный #16432
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-dismiss="modal">Удалить</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal">Не удалять</button>
              </div>
            </div>
          </div>
        </div>

 			</div>
     )
 }

	function mapStateToProps(state){
		return{
			textareaTheme: state.siteThemeSettings.widgetInfo.textarea,
			siteId: state.siteInfo.site.id,
			userRole: state.userInfo.user.role,
      userId: state.userInfo.user.id
		}
	}

	function mapDispatchToProps(dispatch){
 		return {
    	switchTextareTheme: () => dispatch({type: 'SWITCH_CSS_TEXTAREA_THEME'}),
    	selectNewSite: site => dispatch({type: 'SELECT_SITE', payload: site}),
			setAlert: (type, text) => dispatch(setAlert(type, text))
  	}
	}

	export default connect(mapStateToProps, mapDispatchToProps)(WidgetInfo)