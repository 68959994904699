import React, {useState, useEffect} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";

import './App.css';

import axios from 'axios'

// detect hostname to provide .env variables
import {hostDetect} from './config/envConfig.js'

import LoginPage from './pages/Authorization/LoginPage';
// import AuthCheck from './pages/AuthCheck/AuthCheck'
import PageIncome from './pages/Income/PageIncome.jsx';

import PageWidgets from './pages/Widgets/PageWidgets.js';
// wizard
// import CreateWidget from './pages/CreateWidget/CreateWidget.js';
import CreateWidgetWizard from './pages/CreateWidget/CreateWidget_wizard.js';
import WidgetInfo from './pages/WidgetInfo/WidgetInfo.js';
import LentaAddCard from './pages/LentaAddCard/LentaAddCard.js';
import LentaEditCard from './pages/LentaEditCard/LentaEditCard.js';

import Dashboard from './pages/Dashboard/Dashboard';

import Finance from './pages/Finance/Finance.js';

import PageSites from './pages/Sites/PageSites.js';
import CreateSite from './pages/CreateSite/CreateSite.js';
import EditSite from './pages/EditSite/EditSite.js';

import PageExchange from './pages/PageExchange/PageExchange.js';
import PageExchangeAdd from './pages/PageExchange/PageExchangeAdd.js';
import PageExchangeActive from './pages/PageExchange/PageExchangeActive.js';
import PageExchangeArchive from './pages/PageExchange/PageExchangeArchive.js';
import AddArticleExchange from './pages/AddArticleExchange/AddArticleExchange.js';
import PageStatistics from './pages/PageStatistics/PageStatistics.js';

import EditPersonData from './pages/EditPersonData/EditPersonData.js';

import NewPersonData from './pages/NewPersonData/NewPersonData.js';

import ContentUp from './pages/ContentUp/ContentUp.js';
import InternalPromotion from './pages/InternalPromotion/InternalPromotion.js';
import AddInternalPromotion from './pages/AddInternalPromotion/AddInternalPromotion.js';
import SettingsInnerPromotion from './pages/NewArticleSettingsPromotion/SettingsInnerPromotion.js';

import Manual from './pages/Manual/Manual.js';

import SideMenu from './components/SideMenu/SideMenu.jsx';
// import Header from './components/Header/Header.jsx';
// import Footer from './components/Footer/Footer.jsx';

import NotFoundPage from './pages/404page/404page';

// import InfoAlert from './components/commonComponents/InfoAlert/InfoAlert.js';
// import InfoAlertModals from './components/commonComponents/InfoAlertModals/InfoAlertModals.js';

import { connect } from 'react-redux'

import FlashAlert from './components/commonComponents/flashAlert/FlashAlert';

import BlockTeaser from './pages/BlockTeaser/BlockTeaser';

import ApiAlerts from './components/commonComponents/apiAlerts/ApiAlerts';

import { setAlert } from './redux/actions/alertActions.js';

import BalanceTop from './components/commonComponents/balanceTop/BalanceTop.js';

// import { AlertTriangle } from 'react-feather';

const App = (props) => {

    // show top modal Privacy, delete if modal removed
    const [showPrivacyAlert, setShowPrivacyAlert] = useState(false)


    const [userApiLoading, setUserApiLoading] = useState(true)

    let history = useHistory();

    const location = useLocation();

    let blockTeaser = false;
    if(location.pathname === '/block-teaser'){
      blockTeaser = true;
    }


    const checkUserSites = () => {
      if(props.userSites && props.userSites.length == 0){
        return false
      } else {
        return true
      }
    }

    useEffect(()=>{
      getUserInfo();
    },[])

    useEffect(()=>{
      setUserApiLoading(true)
      if(props.loggedIn && !blockTeaser){
        getUserInfo();
        initApiCall();
      }
    },[props.loggedIn])


    // get USER information. Name, Phone, Role
    const getUserInfo = () => {

      let apiOptions = {
        withCredentials: true
      }

      // get user data
      axios.get(`${hostDetect('api')}/users/get`, apiOptions)
        .then(response => {
          let success = response.data.success;

          if(success){
            props.userLogin(true)

            let userInfo = response.data.data;
            props.getUserInfo(userInfo);

            checkCreationDate(userInfo.ofertaId, userInfo.agreement_is_accepted)
            
            // setUserApiLoading(false);
          } else {
            if(!blockTeaser){
              history.push('/')
            } 
            props.userLogin(false)

            setUserApiLoading(false);

            let errMessage = response.data.data.message;
            setAlert('error', errMessage);
          }
        })
        .catch(err => {
          
          props.userLogin(false)
          setUserApiLoading(false);

          setAlert('error', 'Не получилось отправить запрос!');
        })
    }

    // get SITES of user
    const initApiCall = () =>{

      let apiOptions = {
        withCredentials: true
      }
     // get all user`s sites
      axios.get(`${hostDetect('api')}/publisher/get-sites`, apiOptions)
        .then(response => {
          let status = response.data.success,
              sitesArray = response.data.data;

          if(status){
            // get sites array from api
            props.getSitesFromApi(sitesArray)
            // add first site to user
            if(sitesArray.length > 0){
              if (localStorage.getItem('current_site_information') === null) {
                props.getFirstSite(sitesArray[0]);
                localStorage.setItem('current_site_information', JSON.stringify(sitesArray[0]));

                getStatus(sitesArray[0].url)
              } else {
                let previousEnteredSite = JSON.parse(localStorage.getItem('current_site_information'));
                props.getFirstSite(previousEnteredSite);

                getStatus(previousEnteredSite.url)
              }

              // find site in all array and get it status
              function getStatus(domain){
                let site = sitesArray.filter(siteObject => siteObject.url === domain)
                props.getSiteStatus(site[0].status)
              }

              setUserApiLoading(false);
              history.push("/");
            } else {
              setUserApiLoading(false);
            }
          } else {
            // window.location.href = `${process.env.REACT_APP_SITE_URL}/site/login`;
            let errMessage = response.data.data.message;
            setAlert('error', errMessage);

            setUserApiLoading(false);
          }
        })
        .catch(err => {
          // window.location.href = `${process.env.REACT_APP_SITE_URL}/site/login`;
          setAlert('error', 'Не получилось отправить запрос!');

          setUserApiLoading(false);
        })
    }

    const handleExitClick = () => {

      const formData = new FormData();

      axios.post(`${hostDetect('api')}/users/logout`, formData, { withCredentials: true })
        .then(response => {

          let success = response.data.success;

          if(success){
            localStorage.removeItem('current_site_information');
            window.location.reload();
          } else{
            let errMessage = response.data.data.message;
            setAlert('error', errMessage);
          }
        })
        .catch(err => {
          setAlert('error', 'Не получилось отправить запрос!');
        })
    }


    // close sidenav mobile if location changed
    useEffect(() => {
      let asideMenu = document.querySelector('#aside');
      let asideBackdrop = document.querySelector('.modal-backdrop.show')
      if(asideMenu && asideBackdrop){
        let mobileButton = document.querySelector('a[data-target="#aside"]');
        mobileButton.click()
      }
    }, [location]);


    // check user creation date from offerId. If less then 01 April 2023, show modal
    const checkCreationDate = (offerId, agreement_is_accepted) => {
      let creationDate = offerId.split('-');
      creationDate = Number(creationDate[1]);

      // getCookie
      function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
      }
      let checkCookie = getCookie('personal-cab-privacy-agree2');
      
      // check creation date and cookie
      if(creationDate >= 0  && creationDate < 20230401 && !checkCookie && (agreement_is_accepted === 0 || agreement_is_accepted === null)){
        setShowPrivacyAlert(true)
      }
    }

    const addCookie = (name, value, expiresAfterDay) => {
      let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

      if(expiresAfterDay){
        // 1 Day = 24 Hrs = 24*60*60 = 86400
        updatedCookie += '; max-age=86400';
      } else {
        // 5 years = 5*365*24*60*60 = 157 680 000
        updatedCookie += '; max-age=157680000'
      }

      setShowPrivacyAlert(false)

      if(!expiresAfterDay){
        axios.get(`${hostDetect('api')}/users/set-agreement`, {withCredentials: true}).then(res=> {
          const success = res.data.success;
    
          if(success){
            console.log('Соглашение принято.')
          } else {
            console.log(res)
          }
        }).catch(err => console.log(err));
      }
  

      document.cookie = updatedCookie;
    }



    // show this component if BLOCK-TEASER in url
    if(props.loggedIn && blockTeaser){
      return (
        <BlockTeaser />
      )
    }


    return (
      <div id="publisher" className="layout-row">

        {
          props.loggedIn ? (
            <>
              <SideMenu />
              
              <div id="main" className="layout-column flex pt-3">

                {/*<Header />*/}

                {/* show balance top if manager */}
                {
                  props.userRole && props.userRole == 'manager' ? (
                    <BalanceTop />
                  ) : null
                }

                <FlashAlert />

                {/* info-alert */}

                {/* <BrowserView>
                  <InfoAlert/>
                </BrowserView> */}

                {/* modal alert */}
                
                {/* {
                  showPrivacyAlert ? (
                    <div className="alert alert-info privacy-modal-alert" role="alert" style={{margin: '0 1rem 1rem', display: 'flex', alignItems: 'center'}}>
                      <span className="mx-2">
                        Внимание! Обновлен раздел Правовая информация. <br /> Ознакомьтесь с Политикой конфиденциальности, 
                        Пользовательским Соглашением и договорами, заключенными на условиях Оферты
                      </span>
                      <button className="btn w-sm mb-1 btn-primary privacy-modal-alert__open-modal-btn"  data-toggle="modal" data-target="#modal-lg">Подробнее</button>
                      <button type="button" className="close privacy-modal-alert__close" onClick={() => setShowPrivacyAlert(false)}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  ) : null
                } */}

                <ApiAlerts />
                
                <Switch>
                  
                  {/* Редирект на домашнюю страницу */}
                  <Route 
                    exact 
                    path="/" 
                    render={
                      () => checkUserSites() ? <Redirect to='/income/main'/> : <Redirect to='/newPersonData'/>
                  }/>

                  {/* Монетизация */}
                  <Route 
                    exact 
                    path="/income/main" 
                    key="main"
                    render={
                      () => checkUserSites() ? <PageIncome/> : <Redirect to='/newPersonData'/>
                  }/>

                  <Route 
                    exact 
                    path="/income/banner" 
                    key="banner"
                    render={
                      () => checkUserSites() ? <PageIncome/> : <Redirect to='/newPersonData'/>
                  }/>

                  {/* Dashboard */}
                  <Route 
                    exact 
                    path="/dashboard" 
                    render={
                      () => checkUserSites() ? <Dashboard/> : <Redirect to='/newPersonData'/>
                  }/>

                  {/* Finance */}
                  <Route 
                    exact 
                    path="/finance" 
                    render={
                      () => checkUserSites() ? <Finance/> : <Redirect to='/newPersonData'/>
                  }/>

                  {/* Статистика редакции */}
                  <Route
                    exact 
                    path="/statistics" 
                    render={
                      () => checkUserSites() ? <PageStatistics/> : <Redirect to='/newPersonData'/>
                  }/>

                  {/* Обмен трафиком */}
                  <Route 
                    exact 
                    path="/exchange" 
                    render={
                      () => checkUserSites() ? <PageExchange/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/exchange/add" 
                    render={
                      () => checkUserSites() ? <PageExchangeAdd/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/exchange/active" 
                    render={
                      () => checkUserSites() ? <PageExchangeActive/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/exchange/archive" 
                    render={
                      () => checkUserSites() ? <PageExchangeArchive/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/exchange/addArticle" 
                    render={
                      () => checkUserSites() ? <AddArticleExchange/> : <Redirect to='/newPersonData'/>
                  }/>

                  {/* Продвижение контента */}
                  <Route 
                    exact 
                    path="/contentUp" 
                    render={
                      () => checkUserSites() ? <ContentUp/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/contentUp/internalPromotion" 
                    render={
                      () => checkUserSites() ? <InternalPromotion/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/contentUp/internalPromotion/addArticle" 
                    render={
                      () => checkUserSites() ? <AddInternalPromotion/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/contentUp/internalPromotion/newArticleSettingsPromotion/:adv_id" 
                    render={
                      () => checkUserSites() ? <SettingsInnerPromotion/> : <Redirect to='/newPersonData'/>
                  }/>
                  
                  {/* Добавить сайт */}
                  <Route exact path="/add/sites/createSite" >
                    <CreateSite componentUpdate={()=>initApiCall()}/>
                  </Route>

                  {/* Добавить блок/виджет */}
                  {/* ---OLD--- */}
                  {/* <Route 
                    exact
                    path="/add/widgets/createWidget/:siteId" 
                    render={
                      () => checkUserSites() ? <CreateWidget/> : <Redirect to='/newPersonData'/>
                  }/> */}
                  {/* ---NEW--- */}
                  <Route 
                    exact
                    path="/add/widgets/createWidget/:siteId" 
                    render={
                      () => checkUserSites() ? <CreateWidgetWizard/> : <Redirect to='/newPersonData'/>
                  }/>

                  {/* Настройки - Личные данные */}
                  <Route exact path="/settings/editPersonData">
                    <EditPersonData/>
                  </Route>

                  {/* Настройки - Настроить блоки */}
                  <Route 
                    exact 
                    path="/settings/widgets" 
                    render={
                      () => checkUserSites() ? <PageWidgets/> : <Redirect to='/newPersonData'/>
                  }/>
                  {/* Добавить блок/виджет через settings */}
                  {/* ---old--- */}
                  {/* <Route 
                    exact 
                    path="/settings/widgets/createWidget/:siteId" 
                    render={
                      () => checkUserSites() ? <CreateWidget/> : <Redirect to='/newPersonData'/>
                  }/> */}
                  {/* ---new--- */}
                  <Route 
                    exact 
                    path="/settings/widgets/createWidget/:siteId" 
                    render={
                      () => checkUserSites() ? <CreateWidgetWizard/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/settings/widgets/widgetInfo/:siteId/:widgetId" 
                    render={
                      () => checkUserSites() ? <WidgetInfo/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/settings/widgets/cardAdd/:siteId/:widgetId/:cardType" 
                    render={
                      () => checkUserSites() ? <LentaAddCard/> : <Redirect to='/newPersonData'/>
                  }/>
                  <Route 
                    exact 
                    path="/settings/widgets/cardEdit/:siteId/:widgetId/:cardType/:cardId" 
                    render={
                      () => checkUserSites() ? <LentaEditCard/> : <Redirect to='/newPersonData'/>
                  }/>

                  {/* Настройки - Настройки сайтов */}
                  <Route exact path="/settings/sites" >
                    <PageSites componentUpdate={()=>initApiCall()}/>
                  </Route>

                  <Route exact path="/settings/sites/createSite" >
                    <CreateSite componentUpdate={()=>initApiCall()}/>
                  </Route>

                  <Route 
                    exact 
                    path="/settings/sites/editSite/:siteId" 
                    render={
                      () => checkUserSites() ? <EditSite componentUpdate={()=>initApiCall()}/> : <Redirect to='/newPersonData'/>
                  }/>

                  {/* Мануал */}
                  <Route 
                    exact 
                    path="/help" 
                    render={
                      () => checkUserSites() ? <Manual/> : <Redirect to='/newPersonData'/>
                  }/>
                  
                  {/* Личные данные */}
                  <Route exact path="/newPersonData">
                    <NewPersonData/>
                  </Route>  

                  <Route path="*"> 
                    <NotFoundPage/>
                  </Route>

                </Switch>


                {/*<Footer />*/}

                {/* --- modal EXIT system start ---*/}
                <div id="modalExit" className="modal fade" data-backdrop="true">
                  <div className="modal-dialog ">
                    <div className="modal-content ">
                      <div className="modal-header ">
                        <div className="modal-title text-md">Выход</div>
                        <button className="close" data-dismiss="modal">×</button>
                      </div>
                      <div className="modal-body">
                        <div className="p-2">
                          <p className="text-left mb-0 d-flex align-items-center">
                            Вы уверены, что хотите выйти?
                          </p>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleExitClick}>Выйти</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Отмена</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* --- modal EXIT system end ---*/}


                {/* warning alert */}

                {
                  showPrivacyAlert ? (
                    <div className="modal-privacy">
                      <div id="modal-lg" className="modal fade show" data-backdrop="true" aria-modal="true" style={{display: "block"}}>
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content " style={{maxHeight: 'calc(100vh)', overflowY: 'auto'}}>
                            {/* <div className="modal-header ">
                              <button className="close" onClick={() => addCookie('personal-cab-privacy-agree', 'yes', true)}>×</button>
                            </div> */}
                            <div className="modal-body">
                              <div className="pl-4 pr-4 f" style={{fontSize: 15}}>
                                
                                <p>
                                  Уважаемый Партнер! На нашем сайте в разделе <a href="https://sparrow.ru/pravovaya-info.html" target="_blank">Правовая информация</a> обновился список документов, 
                                  регулирующих договорные отношения между Компанией и Владельцем Новостного сайта. Являясь пользователем Сайта, 
                                  Вы соглашаетесь с Обработкой персональных данных,
                                  <a href="https://sparrow.ru/pravovaya-info.html#privacy_policy" target="_blank"> Политикой по обработке персональных данных и конфиденциальности информации</a>, 
                                  <a href="https://sparrow.ru/pravovaya-info.html#terms_of_use" target="_blank"> Пользовательским Соглашением</a>, 
                                  <a href="https://sparrow.ru/pravovaya-info.html#offer_2" target="_blank"> Офертой на возмездное размещение Платформы рекомендаций Sparrow</a> и/или 
                                  <a href="https://sparrow.ru/pravovaya-info.html#offer_1" target="_blank"> Офертой на участие в новостной обменной сети Sparrow</a>.
                                </p>

                                <p>
                                  В зависимости от вида размещения Блока Sparrow на Вашем(их) Сайте(ах), участие в обменной сети и/или монетизация, наши отношения 
                                  регулирует договор, заключенный на условиях Оферты. Номер договора доступен в Вашем Личном кабинете по 
                                  адресу <a href="https://publisher.sparrow.ru">publisher.sparrow.ru</a> в разделе Настройки/Личные Данные. Ваш номер Оферты состоит из уникального 
                                  номера пользователя и даты регистрации Личного кабинета. Просим ознакомиться с документами по ссылке <a href="https://sparrow.ru/pravovaya-info.html" target="_blank">https://sparrow.ru/pravovaya-info.html</a>.
                                </p>

                                <p className="mb-0">
                                  С уважением, команда Sparrow!
                                </p>

                                <div className="mt-4 d-flex justify-content-center">
                                  <button className="btn btn-primary mr-2" onClick={() => addCookie('personal-cab-privacy-agree2', 'yes')}>Принять всё</button>
                                  <button className="btn btn-light" onClick={() => addCookie('personal-cab-privacy-agree2', 'yes', true)}>Напомнить позже</button>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
                
                {/* warning alert */}


                {/* <InfoAlertModals/> */}

                <div className="footer">
                  <ul>
                    <li>
                      <a href="https://help.sparrow.ru">Помощь</a>
                    </li>
                    <li>
                      <a href="https://blog.sparrow.ru">Блог</a>
                    </li>
                    <li>
                      <a href="https://sparrow.ru/contacts.html">Связаться</a>
                    </li>
                  </ul>
                </div>

              </div>
            </>
          ) :  <LoginPage />
        }
              
              

        {/* --- id=main end --- */}

        {
          userApiLoading ? (
            <div className={"create-site__spinner"}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : null
        }
        

      </div>
    );
}

function mapStateToProps(state){
  return{
    loggedIn: state.userInfo.user.loggedIn,
    userSites: state.userInfo.user.sites,
    currentSiteID: state.siteInfo.site.id,
    userRole: state.userInfo.user.role
  }
}

function mapDispatchToProps(dispatch){
  return {
    getSitesFromApi: sites => dispatch({type: 'ADD_SITES_FROM_API', payload: sites}),
    getFirstSite: site => dispatch({type: 'ADD_FIRST_SITE', payload: site}),
    getUserInfo: userInfo => dispatch({type: 'ADD_USER_INFO', payload: userInfo}),
    userLogin: logged => dispatch({type: 'LOGIN_USER', payload: logged}),
    getSiteStatus: status => dispatch({type: 'GET_SITE_STATUS', payload: status}),
    setAlert
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
