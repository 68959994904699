import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './AddArticleExchange.scss'

import Axios from 'axios';

import { connect } from 'react-redux'

import { hostDetect } from '../../config/envConfig'

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import { useHistory } from "react-router-dom";

import { ArrowLeft } from "react-feather";

import { setAlert } from '../../redux/actions/alertActions.js';


const AddArticleExchange = ({setAlert}) => {
	const [submitDisable, setSubmitDisable] = useState(true);

	const [articleLinkValidation, setArticleLinkValidation] = useState({valid: false, class: '', text: ''});
 	const [articleLink, setArticleLink] = useState('');

	const [siteId, setSiteId] = useState(null)

  const [postTimeRadio, setPostTimeRadio] = useState('immediately')
  const [postTime, setPostTime] = useState(null)

 	useEffect(()=>{
 		if(articleLinkValidation.valid && (postTimeRadio === 'immediately' || (postTimeRadio === 'custom' && postTime?.length))){
 			setSubmitDisable(false)
 		} else {
 			setSubmitDisable(true)
 		}
 	});

	const site_id = useSelector(state => state.siteInfo.site.id)
	
	useEffect(()=>{
		if(siteId && siteId !== site_id){
			history.push("/exchange/addArticle");
		}
		setSiteId(site_id)
	}, [site_id])


	let history = useHistory();

	const handleBackClick = () => {
		history.goBack()
	}

	let handleArticleLinkInput = event => {
		let link = event.target.value,
				linkRegex = /(http(s?)):\/\//i;

		setArticleLink(link)

		if(link.length === 0){
 			setArticleLinkValidation({valid: false, class: '', text: ''})
 		} else if(link.length > 0 && !linkRegex.test(link)){
 			setArticleLinkValidation({valid: false, class:'is-invalid', text: 'Неверный формат записи...'})
 		} else if(link.length > 0 && linkRegex.test(link)){
 			setArticleLinkValidation({valid: true, class: 'is-valid', text: ''})
 		}
	}

	const handleSubmit = () => {

		let formData = new FormData();
		formData.append('url', articleLink);
    if(postTime){
      formData.append('published', postTime);
    }
    
		let apiOptions = {
			withCredentials: true
		}

		Axios.post(`${hostDetect('api')}/exchange/add-task?site_id=${siteId}`, formData, apiOptions)
			.then(response => {
				const success = response.data.success;
				if(success){
          let successMessage = response.data.data.message;
          setAlert('success', successMessage);

          history.push(`/exchange`);
				} else {
					let errMessage = response.data.data.message;

          setAlert('error', errMessage);
				}
			})
			.catch(err => setAlert('error', 'Не получилось отправить запрос!'))
	}


  const handlePostTimeRadio = (radioValue) => {
    setPostTimeRadio(radioValue)
    setPostTime(null)
  }
  

	return(
		<div className="add-internal-promotion">
			
			<div className="add-internal-promotion__back-btn">
	    	<button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
	      	<ArrowLeft size={15} style={{marginRight: 10}}/>Назад
	      </button>
	    </div>

	    <PageHeading mainText={"Обмен трафиком"} mutedText={"Добавление новой статьи"}/>

	    <div className="row">
	    	<div className="col-md-6">
	    		<div className="card">
	    			<div className="card-header">
	    				Добавить статью в обмен
	    			</div>
	    			<div className="card-body">

	    				<div className="form-group row">
                <label className="col-sm-4 col-form-label">Адрес статьи (URL)</label>
                <div className="col-sm-8">
                  <input type="text" className={"form-control " + articleLinkValidation.class} placeholder="https://" value={articleLink} onChange={handleArticleLinkInput}/>
                  {
 										articleLinkValidation.valid === false ? 
 											<ul className="list-unstyled text-sm mt-1 text-muted filled add-internal-promotion__input-error-text">
 												<li className="parsley-required text-danger">{articleLinkValidation.text}</li>
 											</ul> : ''
 									}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Опубликовать:</label>
                <div className="col-sm-8">
                  <div className="mt-2">
                    <div className="form-check form-check-inline">
                      <label htmlFor="immediately" className="form-check-label">
                        <input id="immediately" type="radio" className="form-check-input" name="post-time" value="immediately" onChange={() => handlePostTimeRadio('immediately')} checked={postTimeRadio === 'immediately' ? true : false} />
                        Немедленно
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label htmlFor="custom" className="form-check-label">
                        <input id="custom" type="radio" className="form-check-input" name="post-time" value="custom" onChange={() => setPostTimeRadio('custom')} checked={postTimeRadio === 'custom' ? true : false} />
                        Указать время
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {
                postTimeRadio === 'custom' ? (
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Дата:</label>
                    <div className="col-sm-8">
                      <input type="datetime-local" className="form-control" name="post-date" onChange={(e) => setPostTime(e.target.value)} checked={postTime === 'immediately' ? true : false} />
                    </div>
                  </div>
                ) : null
              }

              <div className="add-internal-promotion__save-btn">
 								<button className="btn w-sm mb-1 btn-success" disabled={submitDisable} onClick={handleSubmit}>Сохранить</button>
 							</div>

	    			</div>
	    		</div>
	    	</div>
	    </div>

	  </div>	
	)
}

const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(AddArticleExchange)