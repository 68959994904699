import React, { useState, useEffect } from 'react'
import './InfoTable.scss'

import ExportStatisticsButton from './ExportStatistics/exportStatistics'

// redux
import { connect } from 'react-redux'

import { HelpCircle } from "react-feather";

import { useLocation } from 'react-router-dom';


const InfoTable = (props)=>{

  let location = useLocation();

  let formatLongNumber = tableNumber => {
    return new Intl.NumberFormat('ru-RU').format(tableNumber)
  }

  let formatFixed = (tableValue, fixLength) => {
    if(tableValue) {
      // return tableValue.toFixed(fixLength)

      let statNumber = String(tableValue),
          dotPosition = statNumber.indexOf('.');

      if(dotPosition === -1){
        return statNumber + '.00';
      } else {
        let numberBeforeDot = statNumber.slice(0, dotPosition),
            numberAfterDot = statNumber.slice(dotPosition+1);

        return numberBeforeDot + '.' + numberAfterDot.slice(0, fixLength);
      }
    } else {
      return '0'
    }
  }



  return (
    <div style={{textAlign: 'end', marginBottom: 20}}>
      <div className="card" style={{marginBottom: 0}}>
        <div className="table-responsive info-tables">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr className="text-center">
                <th scope="col" rowSpan="2">Загрузки</th>
                <th scope="col" rowSpan="2">Видимость</th>
                <th scope="col" rowSpan="2">Показы <span className="question-marker" title="Показы блока по стандарту MRC Viewable Ad Impression Measurement Guidelines Version 2.0"><HelpCircle size={14} color={'#333'} /></span></th>
                <th scope="col" rowSpan="2">CTR</th>
                <th scope="col" colSpan={`${location.pathname !== '/income/banner' ? '3' : '1'}`}>Переходы</th>
                <th scope="col" rowSpan="2">CPM</th>
                <th scope="col" rowSpan="2">Доход</th>
                {
                  location.pathname !== '/income/banner' ? (
                    <th scope="col" rowSpan="2">Продвижение</th>
                  ) : null
                }
                
                {
                  props.widgetActiveIndex === 0 && location.pathname !== '/income/banner' ? <th scope="col" rowSpan="2">Возврат <span className="question-marker" title="Переходы от Sparrow"><HelpCircle size={14} color={'#333'} /></span></th> : null
                }
                {
                  props.widgetActiveIndex === 0 && location.pathname !== '/income/banner' ? <th scope="col" rowSpan="2">Коэффициент<br/>возврата</th> : null
                }
                {
                  location.pathname !== '/income/banner' ? (
                    <th scope="col" rowSpan="2">Прибыль</th>
                  ) : null
                }
              </tr>
              <tr className="table-subhead">

                {
                  location.pathname !== '/income/banner' ? (
                    <th className="text-center" scope="col">Органические</th>
                  ) : null
                }
                
                <th className="text-center" scope="col">Промо</th>

                {
                  location.pathname !== '/income/banner' ? (
                    <th className="text-center" scope="col">Обменные</th>
                  ) : null
                }
                
              </tr>
            </thead>
            <tbody>
              <tr className="text-center font-weight-bold">
                <td>
                  { 
                    props.totalStatisticsLoading ? (
                      <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                        <span className="sr-only">Загрузка...</span>
                      </div>
                      ) : formatLongNumber(props.widgetInfo.visits) 
                  }
                </td>
                <td>
                  { 
                    props.totalStatisticsLoading ? (
                      <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                        <span className="sr-only">Загрузка...</span>
                      </div>
                      ) : <> { formatFixed(props.widgetInfo.view_percent, 1) } %</>
                  } 
                </td>
                <td>
                  { 
                    props.totalStatisticsLoading ? (
                      <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                        <span className="sr-only">Загрузка...</span>
                      </div>
                      ) : formatLongNumber(props.widgetInfo.views) 
                  }
                </td>
                <td>
                  { 
                    props.totalStatisticsLoading ? (
                      <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                        <span className="sr-only">Загрузка...</span>
                      </div>
                      ) : formatFixed(props.widgetInfo.ctr, 2)
                  }
                </td>

                  {/* remove from banners */}
                {
                  location.pathname !== '/income/banner' ? (
                    <td>
                      { 
                        props.totalStatisticsLoading ? (
                          <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                            <span className="sr-only">Загрузка...</span>
                          </div>
                          ) : formatLongNumber(props.widgetInfo.organic_clicks) 
                      }
                    </td>
                  ) : null
                }

                
                <td>
                  { 
                    props.totalStatisticsLoading ? (
                      <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                        <span className="sr-only">Загрузка...</span>
                      </div>
                      ) : formatLongNumber(props.widgetInfo.ad_clicks) 
                  }
                </td>

                  {/* remove from banners */}
                {
                  location.pathname !== '/income/banner' ? (
                    <td>
                      { 
                        props.totalStatisticsLoading ? (
                          <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                            <span className="sr-only">Загрузка...</span>
                          </div>
                          ) : formatLongNumber(props.widgetInfo.exchange_clicks) 
                      }
                    </td>
                  ) : null
                }

                
                <td>
                  { 
                    props.totalStatisticsLoading ? (
                      <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                        <span className="sr-only">Загрузка...</span>
                      </div>
                      ) : formatFixed(props.widgetInfo.cpm, 2) 
                  }
                </td>
                <td>
                  { 
                    props.totalStatisticsLoading ? (
                      <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                        <span className="sr-only">Загрузка...</span>
                      </div>
                      ) : <>{ formatFixed(props.widgetInfo.income, 1) } ₽ </>
                  } 
                </td>

                {
                  location.pathname !== '/income/banner' ? (
                    <td>
                      { 
                        props.totalStatisticsLoading ? (
                          <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                            <span className="sr-only">Загрузка...</span>
                          </div>
                          ) : 0
                      }
                    </td>
                  ) : null
                }
                
                {
                  props.widgetActiveIndex === 0 && location.pathname !== '/income/banner' ? <td>
                    { 
                      props.totalStatisticsLoading ? (
                        <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                          <span className="sr-only">Загрузка...</span>
                        </div>
                        ) : formatLongNumber(props.widgetInfo.returned) 
                    }</td> : null
                }
                {
                  props.widgetActiveIndex === 0 && location.pathname !== '/income/banner' ? <td>
                    { 
                      props.totalStatisticsLoading ? (
                        <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                          <span className="sr-only">Загрузка...</span>
                        </div>
                        ) : formatFixed(props.widgetInfo.exchange_mult, 2) 
                    }</td> : null
                }

                {
                  location.pathname !== '/income/banner' ? (
                    <td>
                      { 
                        props.totalStatisticsLoading ? (
                          <div className="spinner-border spinner-border-sm spinner-border-sm__custom" role="status">
                            <span className="sr-only">Загрузка...</span>
                          </div>
                          ) : <> { formatFixed(props.widgetInfo.profit, 1) } ₽ </>
                      } 
                    </td>
                  ) : null
                }
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <ExportStatisticsButton/>

    </div>
  )
}

function mapStateToProps(state){
  return{
    widgetInfo: state.siteInfo.site.totalWidgetList[state.siteInfo.site.widgetActive],
    widgetActiveIndex: state.siteInfo.site.widgetActive,
    totalStatisticsLoading: state.siteInfo.site.totalInfoLoading
  }
}

export default connect(mapStateToProps)(InfoTable)