import React, { useState, useEffect } from 'react';
import { Settings } from 'react-feather';
import { Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

import './SettingsLink.scss'

const SettingsLink = props => {
  const [settingLinks, setSettingLinks] = useState([
    {
      text: 'Личные данные',
      href: '/settings/editPersonData',
      siteStatus: false
    },
    {
      text: 'Настройки сайтов',
      href: '/settings/sites',
      siteStatus: false
    },
    {
      text: 'Настроить блоки',
      href: '/settings/widgets',
      siteStatus: 'active'
    }
    // {
    //   text: 'FAQ',
    //   href: '/help'
    // }
  ])

  const routeLocation = useLocation();

  let activeMain = false;
  let urlPaths = routeLocation.pathname.split('/');
  if(urlPaths.includes('settings')){
    activeMain = true;
  }

  const checkUrlIncludes = currentUrl => {
    let navUrl = currentUrl.split('/').slice(1);
    let pathUrl = routeLocation.pathname.split('/');

    if(navUrl.every(query => pathUrl.includes(query))){
      return true
    } else {
      return false
    }
  }

  return(
    <li className={ activeMain ? ' active' : ''}>
      <a href={void(0)} className="" data-pjax-state="anchor-empty">
        <span className="nav-icon">
          <Settings color="#f54394" size={16}/>
        </span>
        <span className="nav-text">Настройки</span>
        <span className="nav-caret"></span>
      </a>
      <ul className="nav-sub nav-mega">

        {
          settingLinks.filter(item => {
            if(props.userSites.length > 0){
              return item
            } else if(props.userSites.length == 0){
              if(item.href != "/settings/widgets"){
                return item
              }
            }
          }).map(linkInfo => {
            return(
              <li className={ checkUrlIncludes(linkInfo.href) ? ' active' : ''} key={linkInfo.text}>
                <Link to={ linkInfo.href } className={`${linkInfo.siteStatus && linkInfo.siteStatus !== props.siteStatus ? 'disabled-link' : ''}`}>
                  <span className="nav-text">{ linkInfo.text }</span>
                </Link>
              </li>
            )
          })
        }

      </ul>
    </li>
  )
}

function mapStateToProps(state){
  return{
    userSites: state.userInfo.user.sites,
    siteStatus: state.siteInfo.site.status
  }
}

export default connect(mapStateToProps)(SettingsLink)