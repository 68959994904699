import React, { useState, useEffect } from 'react'

const Pagination = (props)=>{

  let indexOfLastPost = props.currentPage * props.postsPerPage;
  let indexOfFirstPost = indexOfLastPost - props.postsPerPage;

  useEffect(()=>{
    props.indexOfFirst(indexOfFirstPost)
    props.indexOfLast(indexOfLastPost)
  })

  let pageNumbers = [];
  for(let i=1; i <= Math.ceil(props.size / props.postsPerPage); i++){
    pageNumbers.push(i)
  }

  let middleArr = []
  let middleArrLength = 5
  let middleArrFirst = 2


  if(props.currentPage == pageNumbers[pageNumbers.length-1]){
    middleArrFirst = pageNumbers[pageNumbers.length-1] - 5
  } else if(props.currentPage > 3){
    if(props.currentPage + 3 > pageNumbers[pageNumbers.length-1]){
      middleArrFirst =pageNumbers[pageNumbers.length-1] - 5
    } else {
      middleArrFirst = props.currentPage - 2
    }
  }

  if(props.currentPage < 3){
    middleArrFirst = 2
  }

  for(let i = middleArrFirst; i<middleArrFirst+middleArrLength; i++){
    middleArr.push(i)
  }


  let buttonsSet = (
    <React.Fragment>
      <li
        className={'page-item ' + (props.currentPage == 1 ? 'active' : '')}
        onClick={()=>props.setCurrentPage(1)}>
        <a className="page-link">
          {pageNumbers[0]}
        </a>
      </li>

      { 
        middleArrFirst >= pageNumbers[0]+2 ? 
        (
          <li className="page-item">
            <a className="page-link">
              <span aria-hidden="true">...</span>
            </a>
          </li>
        ) : '' 
      }

      {
        middleArr.map(item =>{
          return (
            <li
              className={'page-item ' + (props.currentPage == item ? 'active' : '')}
              onClick={()=>props.setCurrentPage(item)}
              key={item}>
              <a className="page-link">
                {item}
              </a>
            </li>
          )
        })
      }

      { 
        middleArrFirst+5 !== pageNumbers[pageNumbers.length-1] ? 
        (
          <li className="page-item">
            <a className="page-link">
              <span aria-hidden="true">...</span>
            </a>
          </li>
        ) : '' 
      }
      
      <li
        className={'page-item ' + (props.currentPage == pageNumbers[pageNumbers.length-1] ? 'active' : '')}
        onClick={()=>props.setCurrentPage(pageNumbers[pageNumbers.length-1])}>
          <a className="page-link">
            {pageNumbers[pageNumbers.length-1]}
          </a>
      </li>
    </React.Fragment>
  )


  let buttonsSetSmall = (
    pageNumbers.map((page, index) => {
      return (
        <li
          className={'page-item ' + (page == props.currentPage ? 'active' : '')}
          key={pageNumbers[index]}
          onClick={()=>props.setCurrentPage(page)}>
            <a className="page-link">
              {page}
            </a>
        </li>
      )
    })
  )


  const NextPage = ()=> {
    props.setCurrentPage(prevPage =>prevPage+1)
  }

  const PrevPage = ()=> {
    props.setCurrentPage(prevPage =>prevPage-1)
  }


  return (
    <nav>
      <ul className="pagination">
        <li className={`page-item ${props.currentPage == 1 ? 'disabled' : ''}`} onClick={()=> PrevPage()}>
          <a className="page-link">
            <span aria-hidden="true">«</span>
          </a>
        </li>
        
        {
          pageNumbers.length>10 ? (
            buttonsSet
            ) : (
            buttonsSetSmall
            )
        }

        <li className={`page-item ${props.currentPage == pageNumbers.length ? 'disabled' : ''}`} onClick={()=> NextPage()}>
          <a className="page-link">
            <span aria-hidden="true">»</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination