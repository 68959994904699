import React, { useState, useEffect } from 'react';

import './LentaEditCard.scss';

import { hostDetect } from '../../config/envConfig';

import PreviewSpinner from '../../components/commonComponents/previewSpinner/PreviewSpinner';

import axios from 'axios';

import { useHistory, useParams } from "react-router-dom";

import { connect } from 'react-redux';

import { setAlert } from '../../redux/actions/alertActions';

import { ArrowLeft } from "react-feather";

const LentaEditCard = props => {
	const [submitDisable, setSubmitDisable] = useState(true);
	const [nameValidation, setNameValidation] = useState({valid: false, class: '', text: ''});
 	const [cardName, setCardName] = useState('');
 	const [cardCSS, setCardCSS] = useState('');
 	const [cardHTML, setCardHTML] = useState('');
 	const [cardJS, setCardJS] = useState('');
 	const [repeatMarker, setRepeatMarker] = useState(0);

 	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertFailed, setAlertFailed] = useState(false);

	let {siteId, widgetId, cardId, cardType} = useParams();

	let history = useHistory();

	useEffect(()=>{
		window.scrollTo(0, 0);
	}, [])

	useEffect(()=>{
		axios.get(`${hostDetect('api')}/lenta/get-card?id=${cardId}`, {
        withCredentials: true
    })
		.then(res => {
			if(res.data.success){
				initLentaCardWithApiCall(res.data.data)
			} else {
				const errorMessage = res.data.data.message
				props.setAlert('error', errorMessage);
			}
		})
		.catch(err => {
			props.setAlert('error', 'Не получилось отправить запрос!');
		})
	}, [alertSuccess])

	useEffect(()=>{
    if(siteId !== String(props.siteId)){
      history.push("/settings/widgets");
    }
  }, [props.siteId])

	useEffect(()=>{
 		if(nameValidation.valid){
 			setSubmitDisable(false)
 		} else {
 			setSubmitDisable(true)
 		}
 	})

 	const initLentaCardWithApiCall = lentaCardInfo => {
 		if(String(lentaCardInfo.widget_id) === widgetId){
			validateCardName(lentaCardInfo.name);
			setCardCSS(lentaCardInfo.css);
			setRepeatMarker(lentaCardInfo.do_not_repeat);

			if(lentaCardInfo.type === "SVK_CustomHTML"){
				let parsedJson = JSON.parse(lentaCardInfo.data_json);
				setCardHTML(parsedJson.html);
				setCardJS(parsedJson.js);
			}
 		}
 	}

  const handleBackClick = () => {
  	history.push(`/settings/widgets/widgetInfo/${siteId}/${widgetId}`);
  }

  const handleNameInput = event => {
 		let name = event.target.value;
		
		validateCardName(name);
 	}

 	const validateCardName = name => {
 		setCardName(name)

 		if(name.length === 0){
 			setNameValidation({valid: false, class: '', text: ''})
 		} else if(name.length <= 2){
 			setNameValidation({valid: false, class:'is-invalid', text: 'Слишком короткое имя...'})
 		} else if(name.length > 2){
 			setNameValidation({valid: true, class: 'is-valid', text: ''})
 		}
 	}

 	const handleRepeatMarkerUse = event => {
		if(event.target.checked){
			setRepeatMarker(1)
		} else {
			setRepeatMarker(0)
		}
 	}

 	const handleCardCSS = event => {
 		setCardCSS(event.target.value);
 	}

 	const handleCardHTML = event => {
 		setCardHTML(event.target.value);
 	}

 	const handleCardJS = event => {
 		setCardJS(event.target.value);
 	}

 	const submitCardEdit = () => {
 		const formData = new FormData();
 		formData.append("name", cardName);
 		formData.append("css", cardCSS);
 		formData.append("do_not_repeat", String(repeatMarker));
 		formData.append("js_field", cardJS);
 		formData.append("widgetId", widgetId);
 		formData.append("html_field", cardHTML);

 		axios.post(`${hostDetect('api')}/lenta/card-save?site_id=${siteId}&widget_id=${widgetId}&type=${cardType}&id=${cardId}`, formData, { withCredentials: true })
      .then(res => {
				if(res.data.success){
					setAlertSuccess(true);
					setTimeout(()=> setAlertSuccess(false), 2000)
				} else if(!res.data.success){
					setAlertFailed(true);
					setTimeout(()=> setAlertFailed(false), 2000)

					const errorMessage = res.data.data.message
					props.setAlert('error', errorMessage);
				}
      })
      .catch(err => {
        props.setAlert('error', 'Не получилось отправить запрос!');
      })
 	}

	return(
		<div className="lenta-add-card">
			<div className="lenta-add-card__back-btn">
	    	<button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
	      	<ArrowLeft size={15} style={{marginRight: 10}}/>Назад
	      </button>
	    </div>

	    <div className="row">
 				<div className="col-md-6">
 					<div className="card">
 						<div className="card-header d-flex justify-content-between align-items-center">
 							<strong>Редактировать карточку</strong>
 							<strong style={{fontSize: 12}}>
 								# {cardId}
 							</strong>
 						</div>
 						<div className="card-body">

 							<PreviewSpinner delay={600}/>

 							<div className="form-group">
 								<label className="text-muted">Название</label>
								<input 
									type="text" 
									className={"form-control " + nameValidation.class} 
									onChange={handleNameInput} 
									value={cardName} 
									maxLength="35"
									placeholder="Введите название"/>
								{
									nameValidation.valid === false ? 
										<ul className="list-unstyled text-sm mt-1 text-muted filled person-data__input-error-text">
											<li className="parsley-required">{nameValidation.text}</li>
										</ul> : ''
								}
 							</div>

 							<div className="form-group">
		          	<label className="text-muted">CSS стили</label>	                    
                <div>
                	<textarea 
					        	className="form-control"  
							    	rows="7" 
              			style={{marginTop: "0px", marginBottom: "0px", height: "171px", fontSize: 13}} 
                    placeholder="*Ваш CSS код*"
                    value={cardCSS}
                    onChange={handleCardCSS}></textarea>
								</div>
							</div>

							{
								cardType === 'SVK_CustomHTML' ? (
									<React.Fragment>
										<div className="form-group">
					          	<label className="text-muted">HTML код</label>	                    
			                <div>
			                	<textarea 
								        	className="form-control"  
										    	rows="7" 
			              			style={{marginTop: "0px", marginBottom: "0px", height: "171px", fontSize: 13}} 
			                    placeholder="*Ваш HTML код*"
			                    value={cardHTML}
			                    onChange={handleCardHTML}></textarea>
											</div>
										</div>
										<div className="form-group">
					          	<label className="text-muted">JavaScript код</label>	                    
			                <div>
			                	<textarea 
								        	className="form-control"  
										    	rows="7" 
			              			style={{marginTop: "0px", marginBottom: "0px", height: "171px", fontSize: 13}} 
			                    placeholder="*Ваш JavaScript код*"
			                    value={cardJS}
			                    onChange={handleCardJS}></textarea>
											</div>
										</div>
									</React.Fragment>
								) : ''
							}

							<div className="form-group row">
                <label className="col-sm-8 col-form-label">Не повторять карточку</label>
                <div className="col-sm-4 d-flex align-items-center justify-content-end">
                  <label className="ui-switch ui-switch-md info m-t-xs">
                    <input type="checkbox" checked={repeatMarker} onChange={handleRepeatMarkerUse}/>
                    <i></i>
                  </label>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center lenta-add-card__save">
 								<button className="btn w-sm mb-1 btn-success" disabled={submitDisable} onClick={submitCardEdit}>Сохранить</button>
 								{
                  alertSuccess == true ? (
                  <div className="alert alert-success" role="alert">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="16" 
                      height="16" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      className="feather feather-check">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    <span className="mx-2">Виджет сохранен!</span>
                  </div>
                  ) : ''
                }

                {
                  alertFailed == true ? (
                  <div className="alert alert-warning" role="alert">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="16" 
                      height="16" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      className="feather feather-alert-circle">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12" y2="16"></line>
                    </svg>
                    <span className="mx-2">Произошла ошибка при сохранении виджета</span>
                  </div>
                  ) : ''
                }
 							</div>

 						</div>
 					</div>
 				</div>
 			</div>
		</div>
	)
} 

function mapStateToProps(state){
  return {
    siteId: state.siteInfo.site.id
  }
}

function mapDispatchToProps(dispatch){
	return {
	 setAlert: (type, text) => dispatch(setAlert(type, text))
 }
}


export default connect(mapStateToProps, mapDispatchToProps)(LentaEditCard)