 import React, { useState, useEffect } from 'react'

 import { hostDetect } from '../../../config/envConfig'

 import { DragDropContext, Droppable } from 'react-beautiful-dnd'

 import FeedCard from './FeedCard'

 import axios from 'axios'

 import { connect } from 'react-redux'

 import { setAlert } from '../../../redux/actions/alertActions'



 const FeedCards = ({cards, setCards, siteId, widgetId, lentaOrderChanging, setAlert})=> {

 const onDragEnd = result => {

 	if(!result.destination) return;

 	const items = Array.from(cards)
 	const [reoderedItem] = items.splice(result.source.index, 1)
 	items.splice(result.destination.index, 0, reoderedItem)

	setCards(items)
	let ordersArr = [];
	
	for(let orderItem of items){
		ordersArr.push(orderItem.id);
	}

	let formData = new FormData;
	
	for (var i = 0; i < ordersArr.length; i++) {
	    formData.append('order[]', ordersArr[i]);
	}

	axios.post(`${hostDetect('api')}/lenta/set-cards-order?site_id=${siteId}&widget_id=${widgetId}`, formData, { withCredentials: true })
    .then(res => {
      if(res.data.success){
      	lentaOrderChanging(); 
      } else {
				const errorMessage = res.data.data.message
				setAlert('error', errorMessage);
			}
    })
    .catch(err => {
      setAlert('error', 'Не получилось отправить запрос!');
    })
 }

	const handleRemoveCard = id => {
		let refreshedList = cards.filter(item => item.id !== id)
		setCards(refreshedList)
	}

 	return (
 		<div className="FeedCards">
 			
 			<DragDropContext onDragEnd={onDragEnd}>
	 			<div>
	 				<Droppable droppableId="drop">
	 				{ provided =>(
	 						<div
	 							ref={provided.innerRef}
	 							{...provided.droppableProps}
	 						>
	 						{
			 					cards.map((card, index) => {
			 						return <FeedCard 
			 										key={card.id} 
			 										id={card.id} 
			 										type={card.type} 
			 										text={card.name} 
			 										index={index}
			 										siteId={siteId}
			 										widgetId={widgetId}
			 										removeCard={handleRemoveCard}/>
			 					})
			 				}
			 				{provided.placeholder}
			 				</div>
	 				)}	
	 				</Droppable>
	 			</div>
 			</DragDropContext>
 		</div>
 	)
 }


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(FeedCards)