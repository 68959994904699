import React, { useState, useEffect } from 'react';

import Axios from 'axios';

import { connect } from 'react-redux'

import './EditModal.scss'

import { hostDetect } from '../../../../config/envConfig';

import { Image } from "react-feather";

import CropImage from './CropImage/CropImage.js'

import { setAlert } from '../../../../redux/actions/alertActions.js';


const EditModal = ({closeModal, advId, updatePaginationList, setAlert}) => {
	const [submitDisable, setSubmitDisable] = useState(true);

	const [articleLink, setArticleLink] = useState('');
	const [articleLinkValidation, setArticleLinkValidation] = useState({valid: false, class: '', text: ''});

	const [articleTitle, setArticleTitle] = useState('');
	const [articleTitleValidation, setArticleTitleValidation] = useState({valid: false, class: '', text: ''});

	const [articleDescription, setArticleDescription] = useState('');
	const [articleDescriptionValidation, setArticleDescriptionValidation] = useState({valid: true, class: '', text: ''});

	// const [articleImageValidation, setArticleImageValidation] = useState({valid: false, class: '', text: ''});
 	const [articleImage, setArticleImage] = useState(null);
	const [articleImagePrev, setArticleImagePrev] = useState(null);

	// profile update status
	const [profileUpdateSuccess, setProfileUpdateSuccess] = useState(false)


	// load initial settings
	useEffect(()=>{
		let apiOptions = {
			withCredentials: true
		}

		Axios.get(`${hostDetect('api')}/ad/get/?id=${advId}`, apiOptions)
		.then(response => { 
			const success = response.data.success;
				if(success){

					// get edit params
					const { url, title, description, image } = response.data.data.entry

					if(url){
						setArticleLink(url)
						checkLinkValidation(url)
					}

					if(title){
						setArticleTitle(title)
						checkTitleValidation(title)
					}

					if(description){
						checkDescriptionValidation(description)
					}
					
					if(image){
						setArticleImagePrev(image)
					}
				} else {
					let errMessage = response.data.data.message;
					setAlert('error', errMessage);
				}
		})
		.catch(err => {
			setAlert('error', 'Не получилось отправить запрос!');
		})
	}, [])

 	useEffect(()=>{
 		if(articleLinkValidation.valid &&
 			 articleTitleValidation.valid &&
 			 (articleImage || articleImagePrev)){

 			setSubmitDisable(false)
 		} else {
 			setSubmitDisable(true)
 		}
 	})

	//  handle link input
	let handleArticleLinkInput = event => {
		let link = event.target.value;
				
		setArticleLink(link)
		checkLinkValidation(link)
	}

	// link validation
	const checkLinkValidation = (link) => {
		const linkRegex = /(http(s?)):\/\//i;

		if(link.length === 0){
			setArticleLinkValidation({valid: false, class: '', text: ''})
		} else if(link.length > 0 && !linkRegex.test(link)){
			setArticleLinkValidation({valid: false, class:'is-invalid', text: 'Неверный формат записи...'})
		} else if(link.length > 0 && linkRegex.test(link)){
			setArticleLinkValidation({valid: true, class: 'is-valid', text: ''})
		}
	}



	// handle title input
	let handleArticleTitleInput = event => {
		let title = event.target.value;

		setArticleTitle(title);
		checkTitleValidation(title)
	}

	// title validation
	const checkTitleValidation = (title) => {
		if(title.length === 0){
			setArticleTitleValidation({valid: false, class: '', text: ''})
		} else if(title.length <= 4){
			setArticleTitleValidation({valid: false, class:'is-invalid', text: 'Слишком короткий заголовок...'})
		} else if(title.length > 4){
			setArticleTitleValidation({valid: true, class: 'is-valid', text: ''})
		}
	}


	// handle description input
	const handleArticleDescriptionInput = (event) => {
		let description = event.target.value;

		checkDescriptionValidation(description)
	}

	// description validation
	const checkDescriptionValidation = (description) => {
		if(description.length < 250){
			setArticleDescription(description)
			setArticleDescriptionValidation({valid: true, class: 'is-valid', text: ''})
		} else {
			setArticleDescriptionValidation({valid: false, class:'is-invalid', text: 'Слишком длинное описание...'})
		}
	}


	// image
	const getCroppedImage = image => {
		setArticleImage(image);
		setArticleImagePrev(null)
	}

	// submit
	const handleSubmit = () => {
		let formData = new FormData();
		formData.append('title', articleTitle);
		formData.append('url', articleLink);
		formData.append('description', articleDescription);
		if(!articleImagePrev && articleImage){
			formData.append('image', articleImage);
		}
		formData.append('type', 'internal');

		let apiOptions = {
			withCredentials: true
		}

		Axios.post(`${hostDetect('api')}/ad/update/?id=${advId}`, formData, apiOptions)
			.then(response => {
				const success = response.data.success;
				if(success){
					const updatedEntry = response.data.data.entry;
					handleUpdateSuccess();
					updatePaginationList(updatedEntry);
				} else {
					let errMessage = response.data.data.message;
					setAlert('error', errMessage);
				}
			})
			.catch(err => {
				setAlert('error', 'Не получилось отправить запрос!');
			})
	}


	// profile date update status
	const handleUpdateSuccess = () => {
		setProfileUpdateSuccess(true);

		setTimeout(()=> {
			setProfileUpdateSuccess(false);
		}, 3000)
	}


	return (
		<div className="settings-modal">
			<div className="settings-modal__inner modal-content box-shadow">
				<div className="modal-header">
					<h5 className="modal-title">Редактировать</h5>
					<button className="close" onClick={closeModal}>×</button>
				</div>

				<div className="p-3">
					<div className="form-group">
            <label className="text-muted">Адрес статьи (URL)</label>
            <input type="text" className={"form-control " + articleLinkValidation.class} placeholder="http://" value={articleLink} onChange={handleArticleLinkInput}/>
            {
							articleLinkValidation.valid === false ? 
								<ul className="list-unstyled text-sm mt-1 text-muted filled add-internal-promotion__input-error-text">
									<li className="parsley-required text-danger">{articleLinkValidation.text}</li>
								</ul> : ''
						}
          </div>

          <div className="form-group">
            <label className="text-muted">Заголовок статьи</label>
            <input type="text" className={"form-control " + articleTitleValidation.class} placeholder="Добавьте заголовок" value={articleTitle} onChange={handleArticleTitleInput}/>
              {
								articleTitleValidation.valid === false ? 
									<ul className="list-unstyled text-sm mt-1 text-muted filled add-internal-promotion__input-error-text">
										<li className="parsley-required text-danger">{articleTitleValidation.text}</li>
									</ul> : ''
							}
          </div>

					<div className="form-group">
						<label className="text-muted">Описание статьи</label>
						<input type="text" className={"form-control " + articleDescriptionValidation.class} placeholder="Добавьте описание статьи (необязательно)" value={articleDescription} onChange={handleArticleDescriptionInput}/>
							{
								articleDescriptionValidation.valid === false ? 
									<ul className="list-unstyled text-sm mt-1 text-muted filled add-internal-promotion__input-error-text">
										<li className="parsley-required text-danger">{articleDescriptionValidation.text}</li>
									</ul> : ''
							}
					</div>

          <div className="form-group">
						<CropImage sendCroppedImage={getCroppedImage}/>

						{
							articleImagePrev ? 
							(
								<div className="image-set">
									<img src={articleImagePrev} alt="Изображение" />
								</div>
							) : 
							articleImage ? 
								null : 
								(
									<div className="image-unset">
										<Image size={18} style={{marginRight: '15px'}}/>
											Изображение отсутствует <br/>
											Добавьте изображение для данного объявления
									</div>
								) 
						}
          </div>


					{
            profileUpdateSuccess && (
              <div className="alert alert-success process-status-popup" role="alert">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="16" 
                  height="16" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  className="feather feather-check">
                    <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
                <span className="mx-2">Профиль успешно обновлен</span>
              </div>
            )
          }


				</div>

				<div className="settings-modal__submit pl-3">
					<button className="btn w-sm mb-1 btn-success" disabled={submitDisable} onClick={handleSubmit}>Сохранить</button>
					<button className="btn w-sm mb-1 btn-danger" onClick={closeModal}>Отмена</button>
				</div>
				
			</div>
		</div>
	)
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(EditModal)