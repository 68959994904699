import { 
  SET_ALERT,
  REMOVE_ALERT
} from "../types";


const initialState = {
  alertArray: []
}

export default function appAlerts(state = initialState, action) {
  switch(action.type){
    case SET_ALERT: {
      const newData = { ...state }
      let prevArray = newData.alertArray
      newData.alertArray = [...prevArray, {type:action.payload.type, text:action.payload.text}]
      return newData
    }
    case REMOVE_ALERT: {
      const newData = { ...state }
      let prevArray = newData.alertArray
      let slicedArray = prevArray.slice(1)
      newData.alertArray = [...slicedArray];
      return newData
    }
  }
  return state
}
