export const tgb_1 = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.SVKNATIVE_Lenta {
    margin: 10px auto!important;
    position: relative;
    background-color: transparent;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header {
    border-top: unset;
    border-bottom: unset;
    margin: 0 0 12px;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header .SVKNATIVE_Lenta_Title {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    padding: 0;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Logo img {
    height: 15px!important;
}
.SVKNATIVE_Lenta_Card {
    border-bottom: unset;
    padding: 5px 0;
}
.SVK_TGB_1 .svk-units-container .svk-unit {
    padding: 0;
    height: 235px;
    overflow: hidden;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
    z-index: 1;
}
.SVK_TGB_1 .svk-units-container .svk-unit {
    margin-right: 10px;
    margin-left: 0;
    padding: 0;
    height: 275px;
    overflow: hidden;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
    z-index: 1;
}
.SVK_TGB_1 .svk-units-container .svk-unit:before {
    display: none;
}
.SVKNATIVE_Lenta .SVK_TGB_1 .svk-unit-image-container, .SVKNATIVE_Lenta .SVK_TGB_1 .svk-unit-image-container {
    height: 65%;
}
.SVKNATIVE_Lenta .svk-unit-image-container .svk-unit-image {
    transition: transform .3s ease;
    will-change: transform;
}

.SVKNATIVE_Lenta .svk-unit-image-container:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.SVKNATIVE_Lenta .SVK_TGB_1 .svk-unit-text  {
    position: absolute;
    color: #222!important;
    bottom: 35px;
    padding: 0px 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 21px;
    margin-top: 0px;
    word-break: break-word;
    max-height: calc(21px*5);
    height: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.SVKNATIVE_Lenta .SVK_TGB_1 .svk-unit .svk-unit-ad-notice {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: auto;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 15px;
    opacity: 1;
    letter-spacing: .2px;
}
.svk-ad-block-button-outer {
    width: 55px;
}
.svk-ad-block-button-inner label {
    line-height: 6em;
    float: unset;
    display: unset;
}
`;

export const tgb_2 = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.SVKNATIVE_Lenta {
    margin: 10px auto!important;
    position: relative;
    background-color: transparent;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header {
    border-top: unset;
    border-bottom: unset;
    margin: 0 0 12px;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header .SVKNATIVE_Lenta_Title {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    padding: 0;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Logo img {
    height: 15px!important;
}
.SVKNATIVE_Lenta_Card {
    border-bottom: unset;
    padding: 5px 0;
}
.SVK_TGB_2 .svk-units-container .svk-unit {
    margin-right: 10px;
    margin-left: 0;
    padding: 0;
    height: 275px;
    overflow: hidden;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
    z-index: 1;
}
.SVK_TGB_2 .svk-units-container .svk-unit:before {
    display: none;
}
.SVKNATIVE_Lenta .SVK_TGB_2 .svk-unit-image-container {
    height: 65%;
}
.SVKNATIVE_Lenta .svk-unit-image-container .svk-unit-image {
    transition: transform .3s ease;
    will-change: transform;
}

.SVKNATIVE_Lenta .svk-unit-image-container:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.SVKNATIVE_Lenta .SVK_TGB_2 .svk-unit-text  {
    position: absolute;
    color: #222!important;
    bottom: 35px;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    margin-top: 0px;
    word-break: break-word;
    max-height: calc(20px*5);
    height: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.SVKNATIVE_Lenta .SVK_TGB_2 .svk-unit .svk-unit-ad-notice {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: auto;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 15px;
    opacity: 1;
    letter-spacing: .2px;
}
.svk-ad-block-button-outer {
    width: 55px;
}
.svk-ad-block-button-inner label {
    line-height: 6em;
    float: unset;
    display: unset;
}
`;


export const tgb_3 = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.SVKNATIVE_Lenta {
    margin: 10px auto!important;
    position: relative;
    background-color: transparent;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header {
    border-top: unset;
    border-bottom: unset;
    margin: 0 0 12px;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header .SVKNATIVE_Lenta_Title {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    padding: 0;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Logo img {
    height: 15px!important;
}
.SVKNATIVE_Lenta_Card {
    border-bottom: unset;
    padding: 5px 0;
}
.SVK_TGB_3 .svk-units-container .svk-unit {
    margin-right: 10px;
    margin-left: 0;
    padding: 0;
    height: 275px;
    overflow: hidden;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
    z-index: 1;
}
.SVK_TGB_3 .svk-units-container .svk-unit:before {
    display: none;
}
.SVKNATIVE_Lenta .SVK_TGB_3 .svk-unit-image-container {
    height: 65%;
}
.SVKNATIVE_Lenta .svk-unit-image-container .svk-unit-image {
    transition: transform .3s ease;
    will-change: transform;
}

.SVKNATIVE_Lenta .svk-unit-image-container:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.SVKNATIVE_Lenta .SVK_TGB_3 .svk-unit-text {
    position: absolute;
    color: #222!important;
    bottom: 35px;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    margin-top: 0px;
    word-break: break-word;
    max-height: calc(20px*4);
    height: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.SVKNATIVE_Lenta .SVK_TGB_3 .svk-unit .svk-unit-ad-notice {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: auto;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 15px;
    opacity: 1;
    letter-spacing: .2px;
}
.svk-ad-block-button-outer {
    width: 55px;
}
.svk-ad-block-button-inner label {
    line-height: 6em;
    float: unset;
    display: unset;
}

.SVK_TGB_3 .svk-units-container .svk-unit:nth-child(1){
  width: 25%;
}
.SVK_TGB_3 .svk-units-container .svk-unit:nth-child(2){
  width: 50%;
}
.SVK_TGB_3 .svk-units-container .svk-unit:nth-child(3){
  width: 25%;
}
`;


export const tgb_4 = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.SVKNATIVE_Lenta {
    margin: 10px auto!important;
    position: relative;
    background-color: transparent;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header {
    border-top: unset;
    border-bottom: unset;
    margin: 0 0 12px;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header .SVKNATIVE_Lenta_Title {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    padding: 0;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Logo img {
    height: 15px!important;
}
.SVKNATIVE_Lenta_Card {
    border-bottom: unset;
    padding: 5px 0;
}
.SVK_TGB_4 .svk-units-container .svk-unit {
    margin-right: 10px;
    margin-left: 0;
    padding: 0;
    height: 275px;
    overflow: hidden;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
    z-index: 1;
}
.SVK_TGB_4 .svk-units-container .svk-unit:before {
    display: none;
}
.SVKNATIVE_Lenta .SVK_TGB_4 .svk-unit-image-container {
    height: 65%;
}
.SVKNATIVE_Lenta .svk-unit-image-container .svk-unit-image {
    transition: transform .3s ease;
    will-change: transform;
}

.SVKNATIVE_Lenta .svk-unit-image-container:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.SVKNATIVE_Lenta .SVK_TGB_4 .svk-unit-text {
    position: absolute;
    color: #222!important;
    bottom: 35px;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    margin-top: 0px;
    word-break: break-word;
    max-height: calc(20px*4);
    height: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.SVKNATIVE_Lenta .SVK_TGB_4 .svk-unit .svk-unit-ad-notice {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: auto;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 15px;
    opacity: 1;
    letter-spacing: .2px;
}
.svk-ad-block-button-outer {
    width: 55px;
}
.svk-ad-block-button-inner label {
    line-height: 6em;
    float: unset;
    display: unset;
}


.svk-units-container{
    flex-wrap: wrap;
}
.SVK_TGB_4 .svk-units-container .svk-unit:nth-child(1){
    width: calc(65% - 10px);
    margin-bottom: 10px;
}
.SVK_TGB_4 .svk-units-container .svk-unit:nth-child(1) .svk-unit-text{
    font-size: 16px;
}
.SVK_TGB_4 .svk-units-container .svk-unit:nth-child(2){
    width: calc(35% - 10px);
    margin-right: 0;
    margin-bottom: 10px;
}
.SVK_TGB_4 .svk-units-container .svk-unit:nth-child(3){
  width: calc(35% - 10px);
  margin-right: 10px;
  margin-bottom: 0px;
}
.SVK_TGB_4 .svk-units-container .svk-unit:nth-child(4){
  width: 65%;
}
.SVK_TGB_4 .svk-units-container .svk-unit:nth-child(4) .svk-unit-text{
    font-size: 16px;
}
`;

export const tgb_5 = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.SVKNATIVE_Lenta {
    margin: 10px auto!important;
    position: relative;
    background-color: transparent;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header {
    border-top: unset;
    border-bottom: unset;
    margin: 0 0 12px;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header .SVKNATIVE_Lenta_Title {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    padding: 0;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Logo img {
    height: 15px!important;
}
.SVKNATIVE_Lenta_Card {
    border-bottom: unset;
    padding: 5px 0;
}
.SVK_TGB_5 .svk-units-container .svk-unit {
    margin-right: 10px;
    margin-left: 0;
    padding: 0;
    height: 275px;
    overflow: hidden;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
    z-index: 1;
}
.SVK_TGB_5 .svk-units-container .svk-unit:before {
    display: none;
}
.SVKNATIVE_Lenta .SVK_TGB_5 .svk-unit-image-container {
    height: 65%;
}
.SVKNATIVE_Lenta .svk-unit-image-container .svk-unit-image {
    transition: transform .3s ease;
    will-change: transform;
}

.SVKNATIVE_Lenta .svk-unit-image-container:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.SVKNATIVE_Lenta .SVK_TGB_5 .svk-unit-text {
    position: absolute;
    color: #222!important;
    bottom: 35px;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    margin-top: 0px;
    word-break: break-word;
    max-height: calc(20px*4);
    height: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.SVKNATIVE_Lenta .SVK_TGB_5 .svk-unit .svk-unit-ad-notice {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: auto;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 15px;
    opacity: 1;
    letter-spacing: .2px;
}
.svk-ad-block-button-outer {
    width: 55px;
}
.svk-ad-block-button-inner label {
    line-height: 6em;
    float: unset;
    display: unset;
}


.svk-units-container{
    flex-wrap: wrap;
}
.SVK_TGB_5 .svk-units-container .svk-unit:nth-child(1){
    width: calc(50% - 10px);
    margin-bottom: 10px;
}
.SVK_TGB_5 .svk-units-container .svk-unit:nth-child(1) .svk-unit-text{
    font-size: 16px;
}
.SVK_TGB_5 .svk-units-container .svk-unit:nth-child(2){
    width: calc(50% - 10px);
    margin-right: 0;
    margin-bottom: 10px;
}
.SVK_TGB_5 .svk-units-container .svk-unit:nth-child(2) .svk-unit-text{
    font-size: 16px;
}
.SVK_TGB_5 .svk-units-container .svk-unit:nth-child(3){
    width: calc(33.3333% - 10px);
    margin-right: 10px;
}
.SVK_TGB_5 .svk-units-container .svk-unit:nth-child(4){
    width: calc(33.3333% - 10px);
    margin-right: 10px;
}
.SVK_TGB_5 .svk-units-container .svk-unit:nth-child(5){
   width: calc(33.3333% - 10px);
}
`;


export const tgb_6 = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.SVKNATIVE_Lenta {
    margin: 10px auto!important;
    position: relative;
    background-color: transparent;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header {
    border-top: unset;
    border-bottom: unset;
    margin: 0 0 12px;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header .SVKNATIVE_Lenta_Title {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    padding: 0;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Logo img {
    height: 15px!important;
}
.SVKNATIVE_Lenta_Card {
    border-bottom: unset;
    padding: 5px 0;
}
.SVK_TGB_6 .svk-units-container .svk-unit {
    margin-right: 10px;
    margin-left: 0;
    padding: 0;
    height: 275px;
    overflow: hidden;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
    z-index: 1;
}
.SVK_TGB_6 .svk-units-container .svk-unit:before {
    display: none;
}
.SVKNATIVE_Lenta .SVK_TGB_6 .svk-unit-image-container {
    height: 65%;
}
.SVKNATIVE_Lenta .svk-unit-image-container .svk-unit-image {
    transition: transform .3s ease;
    will-change: transform;
}

.SVKNATIVE_Lenta .svk-unit-image-container:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.SVKNATIVE_Lenta .SVK_TGB_6 .svk-unit-text {
    position: absolute;
    color: #222!important;
    bottom: 35px;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    margin-top: 0px;
    word-break: break-word;
    max-height: calc(20px*4);
    height: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.SVKNATIVE_Lenta .SVK_TGB_6 .svk-unit .svk-unit-ad-notice {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: auto;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 15px;
    opacity: 1;
    letter-spacing: .2px;
}
.svk-ad-block-button-outer {
    width: 55px;
}
.svk-ad-block-button-inner label {
    line-height: 6em;
    float: unset;
    display: unset;
}


.svk-units-container{
    flex-wrap: wrap;
}
.SVK_TGB_6 .svk-units-container .svk-unit:nth-child(1){
    width: calc(50% - 10px);
    margin-bottom: 10px;
}
.SVK_TGB_6 .svk-units-container .svk-unit:nth-child(1) .svk-unit-text{
    font-size: 16px;
}
.SVK_TGB_6 .svk-units-container .svk-unit:nth-child(2){
    width: calc(50% - 10px);
    margin-right: 0;
    margin-bottom: 10px;
}
.SVK_TGB_6 .svk-units-container .svk-unit:nth-child(2) .svk-unit-text{
    font-size: 16px;
}
.SVK_TGB_6 .svk-units-container .svk-unit:nth-child(3){
    width: calc(25% - 10px);
    margin-right: 10px;
}
.SVK_TGB_6 .svk-units-container .svk-unit:nth-child(4){
    width: calc(25% - 10px);
    margin-right: 10px;
}
.SVK_TGB_6 .svk-units-container .svk-unit:nth-child(5){
   width: calc(25% - 10px);
   margin-right: 10px;
}
.SVK_TGB_6 .svk-units-container .svk-unit:nth-child(6){
   width: calc(25% - 10px);
}
`;

export const backpage_5x = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.SVKNATIVE_Lenta {
    margin: 10px auto!important;
    position: relative;
    background-color: transparent;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header {
    border-top: unset;
    border-bottom: unset;
    margin: 0 0 12px;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Header .SVKNATIVE_Lenta_Title {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    padding: 0;
}
.SVKNATIVE_Lenta .SVKNATIVE_Lenta_Logo img {
    height: 15px!important;
}
.SVKNATIVE_Lenta_Card {
    border-bottom: unset;
    padding: 5px 0;
}
.SVK_TGB_5 .svk-units-container .svk-unit {
    margin-right: 10px;
    margin-left: 0;
    padding: 0;
    height: 275px;
    overflow: hidden;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
    z-index: 1;
}
.SVK_TGB_5 .svk-units-container .svk-unit:before {
    display: none;
}
.SVKNATIVE_Lenta .SVK_TGB_5 .svk-unit-image-container {
    height: 65%;
}
.SVKNATIVE_Lenta .svk-unit-image-container .svk-unit-image {
    transition: transform .3s ease;
    will-change: transform;
}

.SVKNATIVE_Lenta .svk-unit-image-container:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.SVKNATIVE_Lenta .SVK_TGB_5 .svk-unit-text  {
    position: absolute;
    color: #222!important;
    bottom: 35px;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    margin-top: 0px;
    word-break: break-word;
    max-height: calc(20px*5);
    height: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.SVKNATIVE_Lenta .SVK_TGB_5 .svk-unit .svk-unit-ad-notice {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: auto;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    line-height: 15px;
    opacity: 1;
    letter-spacing: .2px;
}
.svk-ad-block-button-outer {
    width: 55px;
}
.svk-ad-block-button-inner label {
    line-height: 6em;
    float: unset;
    display: unset;
}
`;


export const native_1x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 275px;
    width: 100%;
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;


export const native_2x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 275px;
    width: calc(50% - 10px);
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;


export const native_3x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 275px;
    width: calc(33.3333% - 10px);
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;

export const native_4x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 275px;
    width: calc(25% - 10px);
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;
export const native_5x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 275px;
    width: calc(20% - 10px);
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;
export const native_6x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 240px;
    width: calc(16.66666% - 10px);
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;
export const native_7x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 240px;
    width: calc(14.28571% - 10px);
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;
export const native_8x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 240px;
    width: calc(12.5% - 10px);
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;
export const native_9x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 240px;
    width: calc(11.11111% - 10px);
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;
export const native_10x_gradient = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.article-list {
    margin: 12px -4px 0;
    padding-bottom: 10px;
}
li.article-promo > a {
    display: block;
    height: 100%;
}

div#PR > ul > li {
    margin: 0 5px 15px;
    overflow: hidden;
    height: 240px;
    width: calc(10% - 10px);
    background: #fff;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.has-image .image-link {
    position: relative;
    height: 62%;
    margin-bottom: 0px;
}
.has-image .image-link:after {
    content: '';
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff, rgba(0, 0, 0, 0));
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    will-change: transform;
}
.has-image .article-title {
    position: absolute;
    bottom: 40px;
    font-weight: 600;
    height: auto;
    margin: 0;
    padding: 0px 10px;
    max-height: calc(21px*4);
    font-size: 16px;
    line-height: 21px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
`;

export const native_combined = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
.in-row-1 li.article:first-child{
    margin-bottom: 30px;
}
.in-row-1 li.article:first-child .image-link{
    position: relative;
    width: 100%;
    height: 170px;
    float: none;
    margin-bottom: 10px;
}
.in-row-1 li.article:first-child .article-image{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.in-row-1 li.article:first-child .article-title{
    height: auto;
    max-height: 60px;
}
.in-row-1 li.article:first-child .ad_notice {
    display: block;
    position: absolute;
    bottom: -25px;
    left: 0;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}

.has-image.article-list>li {
    margin-bottom: 10px;
}
.has-image .image-link {
    position: relative;
    height: 80px;
    width: 105px;
    overflow: hidden;
    margin-bottom: 0;
    margin-right: 10px;
    float: left;
}
.article-image {
    position: absolute;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%,-50%);
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 115px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
`;


export const native_text_right = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

div#PR {
    background: transparent;
    padding-top: 10px;
}
.w_heading{
    margin-bottom: 15px;
}
.page-heading {
    margin-bottom: 0px;
}

.ad_notice {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
.svk-ad-block-button-outer {
    width: 57px;
    height: 45px;
}
.has-image.article-list>li {
    margin-bottom: 10px;
}
.has-image .image-link {
    position: relative;
    height: 80px;
    width: 105px;
    overflow: hidden;
    margin-bottom: 0;
    margin-right: 10px;
    float: left;
}
.article-image {
    position: absolute;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%,-50%);
}
.ad_notice {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 115px;
    color: #999;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
}
`; 