import React, { useState, useEffect } from 'react'

import './PreviewSpinner.scss'

const PreviewSpinner = (props) => {

	const [visible, setVisible] = useState(true)

	useEffect(()=> EnableTimer(), [])

	const EnableTimer = ()=> {
		setTimeout(()=>{
			setVisible(false)
		}, props.delay)
	}

	let spinnerCode = (
		<div className="preview-spinner">
			<div className="spinner-border text-primary" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	)

	
	return(
		<React.Fragment>
			{
				visible === true ? spinnerCode : ''
			}
		</React.Fragment>
	)
}

export default PreviewSpinner