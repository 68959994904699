import React, { useState, useEffect } from 'react'

import './DetailsTable.scss'

import Pagination from './Pagination/Pagination'

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const DetailsTable = ({detailedStat, groupBy, showTable, setShowTable}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(10)
  const [paginationStart, setPaginationStart] = useState(0)
  const [paginationEnd, setPaginationEnd] = useState(0)

  const [detailedButtonActive, setDetailedButtonActive] = useState(false)
  useEffect(() => {
    if(detailedStat && detailedStat.length){
      setDetailedButtonActive(true);
    } else {
      setDetailedButtonActive(false);
    }
  }, [detailedStat])

  useEffect(()=>{
    setCurrentPage(1)
  }, [groupBy])

  let formatDate = dateString =>{
    if(groupBy == 'day'){
      return moment(new Date(dateString)).format('DD.MM.YYYY')
    }
    if(groupBy == 'week'){
      return moment(new Date(dateString)).format('DD.MM.YYYY')
    }
    if(groupBy == 'month'){
      return moment(dateString).format('DD MMMM YYYY')
    }
    return dateString
  }

  let changePerPage = e =>{
    setPostsPerPage(e.target.value)
    setCurrentPage(1)
  }

  const formatFixed = (value, fixLength) => {
    if(value) {
      let statNumber = String(value),
          dotPosition = statNumber.indexOf('.');
      if(dotPosition === -1){
        return statNumber + '.00';
      } else {
        let numberBeforeDot = statNumber.slice(0, dotPosition),
            numberAfterDot = statNumber.slice(dotPosition+1);

        return numberBeforeDot + '.' + numberAfterDot.slice(0, fixLength);
      }
    } else {
      return '0'
    }
  }


  let formatLongNumber = valueNumber => {
    return new Intl.NumberFormat('ru-RU').format(valueNumber)
  }


  // create new array with id - [{id: 1, ...}, {id:2, ...}]
  let listForPagination = [];

  if(detailedStat && detailedStat.length){
    for(let item in detailedStat){
      listForPagination[item] = {
        ...detailedStat[item],
        id: ++item
      }
    }
  }


  let indexFirst = num => {
    setPaginationStart(num)
  }

  let indexLast = num => {
    setPaginationEnd(num)
  }

  let detailsTable = (
    <div className="details__table">
      <div className="card">
        <table className="table table-hover table-bordered">
          <thead className="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Дата</th>
            {/* <th scope="col">Загрузки</th> */}
            {/* <th scope="col">Видимость</th> */}
            <th scope="col">Показы</th>
            <th scope="col">Клики</th>
            <th scope="col">CTR</th>
            {/* <th scope="col">Доход</th> */}
            {/* <th scope="col">Потрачено</th> */}
            {/* <th scope="col">Прибыль</th> */}
          </tr>
          </thead>
          <tbody style={{fontSize: 12, color: '#666'}}>
            {
              listForPagination.length && listForPagination.slice(paginationStart, paginationEnd).map((statField, index) => {
                return(
                  <tr className="text-capitalize" key={statField.id}>
                    <th scope="row">{statField.id}</th>
                    <th className="details__table-date-cell">{formatDate(statField.date)}</th>
                    {/* <th>0</th> */}
                    {/* <th>0</th> */}
                    <th>{formatLongNumber(statField.views)}</th>
                    <th>{formatLongNumber(statField.clicks)}</th>
                    <th>{formatFixed(statField.ctr, 2)}</th>
                    {/* <th>0</th> */}
                    {/* <th>{formatLongNumber(formatFixed(statField.spent, 1))} ₽</th> */}
                    {/* <th>0</th> */}
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col">
          <div className="details__pagination-pagesPerPage">
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              notPaginateStat={listForPagination}
              setCurrentPage={setCurrentPage}
              size={listForPagination.length}
              indexOfFirst={indexFirst}
              indexOfLast={indexLast}
              key={listForPagination}/>

            <div className="details__pagesPerPage">
              <span style={{marginRight: 10}}>Кол-во материалов на странице: </span>
              <select className="browser-default custom-select" style={{width: '80px'}} onChange={(e)=>changePerPage(e)} value={postsPerPage}>
                <option defaultValue>10</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="details">

      {/* Statistics button */}
      <div className="text-center mb-2">
        <button 
          type="button" 
          onClick={()=>setShowTable(!showTable)} 
          className="btn btn-primary dropdown-toggle btn-sm">Подробная статистика</button>
      </div>

      {/* Statistics table */}
      {
        showTable ?  
          listForPagination.length ? 
            detailsTable
            : "" 
        : null
      }

    </div>
  )
}

export default DetailsTable