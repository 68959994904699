import React, { useState, useEffect } from 'react'

// redux
import { connect } from 'react-redux'

import {Calendar} from "react-feather";

// daterange picker
import './DateRange.scss'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css';

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')



const DateRange = (props) => {
  
  const handleCallback = (start, end) => {
    props.addDate({startFormatted: start.format('YYYY-MM-DD'), endFormatted: end.format('YYYY-MM-DD')})
    props.switchWidget(0)
  };


  return(
    <div className="date-range">
      <p className="nav-header hidden-folded" style={{fontWeight: 500}}>
        Выберите период :
      </p>
      <div className="date-range__input-wrap">
        <Calendar color={'#a6a6a6'} size={13} />
        <DateRangePicker
          initialSettings={
            { startDate: moment(props.dates.startFormatted),
              endDate: moment(props.dates.endFormatted),
              ranges: {
                'Сегодня': [moment(), moment()],
                'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
                'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
                'Последние 6 месяцев': [moment().subtract(6, 'month'), moment()],
                'Этот месяц': [moment().startOf('month'), moment()],
                'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
              },
              locale: {
                'customRangeLabel': 'Выбор даты',
                "daysOfWeek": [
                  "Вс",
                  "Пн",
                  "Вт",
                  "Ср",
                  "Чт",
                  "Пт",
                  "Сб"
                ],
                "monthNames": [
                  "Январь",
                  "Февраль",
                  "Март",
                  "Апрель",
                  "Май",
                  "Июнь",
                  "Июль",
                  "Август",
                  "Сентябрь",
                  "Октябрь",
                  "Ноябрь",
                  "Декабрь"
                ],
                "firstDay": 1,
                "applyLabel": "Применить",
                "cancelLabel": "Отмена",
              }
            }
          } onCallback={handleCallback}>
          {/* <button className="btn btn-primary text-capitalize">
            {label}
          </button> */}
          <input type="text" className="date-range__income-main form-control" />
        </DateRangePicker>
      </div>
      
    </div>
  )
}


function mapStateToProps(state){
  return{
    dates: state.siteInfo.site.statisticsDate
  }
}

function mapDispatchToProps(dispatch){
  return {
    addDate: dates => dispatch({type: 'ADD_STATISTICS_DATE', payload: dates}),
    switchWidget: widgetIndex => dispatch({type: 'SWITCH_WIDGET', payload: widgetIndex})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateRange)