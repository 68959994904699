// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')


const initialStateSiteInfo = {
  site: {
    id: '',
    url: '',
    full_name: '',
    status: '',
    widgetActive: 0,
    widgetActiveStatistics: [],
    totalSite: {
      total: {},
      by_date: {}
    },
    totalInfoLoading: false,
    totalWidgetList: [{}],
    widgets: [],
    statisticsDate: {
      startFormatted: moment().startOf('month').format('YYYY-MM-DD'),
      endFormatted: moment().format('YYYY-MM-DD')
    },
    groupBy: 'day'
  }
} 

export default function siteInfo(state = initialStateSiteInfo, action){
  switch(action.type){
    case 'SELECT_SITE': {
      const stateUpdated = { ...state }
      stateUpdated.site.id = action.payload.id
      stateUpdated.site.url = action.payload.url
      stateUpdated.site.full_name = action.payload.full_name
      stateUpdated.site.status = action.payload.status
      return stateUpdated
    }
    case 'ADD_FIRST_SITE': {
      const stateUpdated = {...state}
      stateUpdated.site.id = action.payload.id
      stateUpdated.site.url = action.payload.url
      stateUpdated.site.full_name = action.payload.full_name
      return stateUpdated
    }
    case 'GET_SITE_STATUS': {
      const stateUpdated = {...state}
      stateUpdated.site.status = action.payload
      return stateUpdated
    }
    case 'SWITCH_WIDGET': {
      const stateUpdated = { ...state };
      stateUpdated.site.widgetActive = action.payload
      return stateUpdated
    }
    case 'ADD_STATISTICS_DATE': {
      const stateUpdated = { ...state };
      stateUpdated.site.statisticsDate = action.payload
      return stateUpdated
    }
    case 'CHANGE_GROUPBY_QUERY': {
      const stateUpdated = { ...state }
      stateUpdated.site.groupBy = action.payload
      return stateUpdated
    }
    case 'ADD_SITE_TOTAL_STATISTICS': {
      const stateUpdated = { ...state }
      let { total, by_date } = action.payload
      stateUpdated.site.totalSite.total = total
      stateUpdated.site.totalSite.by_date = by_date
      return stateUpdated
    }
    case 'TOTAL_STATISTICS_LOADING': {
      const stateUpdated = { ...state }
      stateUpdated.site.totalInfoLoading = action.payload
      return stateUpdated
    }
    case 'ADD_SITE_WIDGETS': {
      const stateUpdated = { ...state }
      stateUpdated.site.widgets = action.payload
      return stateUpdated
    }
    case 'ADD_CURRENT_WIDGET_STATISTICS': {
      const stateUpdated = { ...state }
      stateUpdated.site.widgetActiveStatistics = action.payload
      return stateUpdated
    }
    case 'ADD_SITE_AND_WIDGETS_STATISTICS': {
      const stateUpdated = { ...state }
      stateUpdated.site.totalWidgetList = action.payload
      return stateUpdated
    }
  }
  return state
}