import React, { useState, useEffect } from 'react';

import './CreateWidget.scss';

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader';

import { useHistory, useParams } from "react-router-dom";

import { connect } from 'react-redux';

import { hostDetect } from '../../config/envConfig';

import axios from 'axios';

import { ArrowLeft, X } from "react-feather";

import Tab1 from './Tabs/Tab1/Tab1';
import Tab2 from './Tabs/Tab2/Tab2';
import Tab3 from './Tabs/Tab3/Tab3';
import Preview from './Preview/Preview';

import {tgb_1,
        tgb_2,
        tgb_3,
        tgb_4,
        tgb_5,
        tgb_6,
        backpage_5x,
        native_1x_gradient,
        native_2x_gradient,
        native_3x_gradient,
        native_4x_gradient,
        native_5x_gradient,
        native_6x_gradient,
        native_7x_gradient,
        native_8x_gradient,
        native_9x_gradient,
        native_10x_gradient,
        native_combined,
        native_text_right} from './export_css/export_css';

import { setAlert } from '../../redux/actions/alertActions';

const CreateWidget_wizard = ({siteIdRedux, setAlert}) => {
  // tab toggle
  const [tabActive, setTabActive] = useState(1)
  // 1 tab
  const [blockTitle, setBlockTitle] = useState('')
  const [blockType, setBlockType] = useState('native')
  const [deviceType, setDeviceType] = useState('mobile')
  // 2 tab
  const [textPosition, setTextPosition] = useState('bottom')
  // 2 tab (native)
  const [grid_x, setGrid_x] = useState(1)
  const [grid_y, setGrid_y] = useState(1)
  const [headerTitle, setHeaderTitle] = useState('')
  // 2 tab (infinite)
  const [infiniteTGBType, setInfiniteTGBType] = useState(2)
  // 2 tab (banner)
  const [bannerType, setBannerType] = useState('300x300')
  // 3 tab
  const [workModel, setWorkModel] = useState('exchange')

  // handle block type
  useEffect(()=>{
    if(blockType == 'native' && deviceType == 'mobile'){
      setGrid_x(2)
      setGrid_y(3)
    }
    if(blockType == 'native' && deviceType == 'desktop'){
      setGrid_x(3)
      setGrid_y(2)
    }
    if(blockType == 'infinite' && deviceType == 'mobile'){
      setInfiniteTGBType(2)
    }
    if(blockType == 'infinite' && deviceType == 'desktop'){
      setInfiniteTGBType(3)
    }
    if(blockType == 'turbo'){
      setGrid_x(1)
      setGrid_y(3)
      setDeviceType('mobile')
    }
    if(blockType == 'amp' && deviceType == 'mobile'){
      setGrid_x(2)
      setGrid_y(3)
    }
    if(blockType == 'amp' && deviceType == 'desktop'){
      setGrid_x(3)
      setGrid_y(2)
    }
    if(blockType == 'banner'){
      setHeaderTitle('')
    }
  },[blockType])

  // handle device type
  useEffect(() => {
    if(blockType == 'native' && deviceType == 'mobile'){
      setGrid_x(2)
      setGrid_y(3)
    }
    if(blockType == 'native' && deviceType == 'desktop'){
      setGrid_x(3)
      setGrid_y(2)
    }
    if(blockType == 'infinite' && deviceType == 'mobile'){
      setInfiniteTGBType(2)
    }
    if(blockType == 'infinite' && deviceType == 'desktop'){
      setInfiniteTGBType(3)
    }
    if(blockType == 'backpage' && deviceType == 'mobile'){
      setInfiniteTGBType(2)
    }
    if(blockType == 'backpage' && deviceType == 'desktop'){
      setInfiniteTGBType(5)
    }
    if(blockType == 'turbo'){
      setGrid_x(1)
      setGrid_y(3)
    }
    if(blockType == 'amp' && deviceType == 'mobile'){
      setGrid_x(2)
      setGrid_y(3)
    }
    if(blockType == 'amp' && deviceType == 'desktop'){
      setGrid_x(3)
      setGrid_y(2)
    }
    if(blockType == 'banner' && deviceType == 'mobile'){
      setBannerType('300x300')
    }
    if(blockType == 'banner' && deviceType == 'desktop'){
      setBannerType('728x90')
    }
  }, [deviceType])

  // handle grid type
  useEffect(() => {
    if(tabActive == 1){
      setTextPosition('bottom')
      setDeviceType('mobile')
    }
    if(tabActive == 2){
      if(deviceType == 'mobile'){
        setGrid_x(2)
        setGrid_y(3)
        setTextPosition('bottom')
      }
      if(deviceType == 'desktop'){
        setGrid_x(3)
        setGrid_y(2)
        setTextPosition('bottom')
      }
      if(blockType == 'turbo' && deviceType == 'mobile'){
        setGrid_x(1)
        setGrid_y(3)
        setTextPosition('right')
      }
      if(blockType == 'turbo' && deviceType == 'desktop'){
        setGrid_x(1)
        setGrid_y(3)
        setTextPosition('right')
      }
    }
  }, [tabActive])

  // handle text position
  useEffect(() => {
    if(blockType === 'native' || blockType === 'amp'){
      if(deviceType == 'mobile'){
        if(textPosition == 'bottom'){
          setGrid_x(2)
          setGrid_y(3)
        }
        if(textPosition == 'combined'){
          setGrid_x(1)
          setGrid_y(5)
        }
        if(textPosition == 'right'){
          setGrid_x(1)
          setGrid_y(6)
        }
      }
      if(deviceType == 'desktop'){
        if(textPosition == 'bottom'){
          setGrid_x(3)
          setGrid_y(2)
        }
        if(textPosition == 'right'){
          setGrid_x(2)
          setGrid_y(3)
        }
      }
    }
  }, [textPosition])

  

  let { siteId } = useParams();
  
  useEffect(()=>{
    if(siteId !== String(siteIdRedux)){
      history.push("/settings/widgets");
    }
  }, [siteIdRedux])

  let history = useHistory();
  const handleBackClick = ()=>{
    history.push("/settings/widgets");
  }

  const handleBlockType = () => {
    if(tabActive === 2){
      if(blockType === 'native' && deviceType === 'desktop'){
        return <div>&nbsp;- Нативный блок - Десктоп</div>
      }
      if(blockType === 'native' && deviceType === 'mobile'){
        return <div>&nbsp;- Нативный блок - Мобильный</div>
      }
      if(blockType === 'infinite' && deviceType === 'desktop'){
        return <div>&nbsp;- Бесконечная лента - Десктоп</div>
      }
      if(blockType === 'infinite' && deviceType === 'mobile'){
        return <div>&nbsp;- Бесконечная лента - Мобильный</div>
      }
      if(blockType === 'turbo'){
        return <div>&nbsp;- Яндекс Турбо - Мобильный</div>
      }
      if(blockType === 'amp' && deviceType === 'desktop'){
        return <div>&nbsp;- АМП - Десктоп</div>
      }
      if(blockType === 'amp' && deviceType === 'mobile'){
        return <div>&nbsp;- АМП - Мобильный</div>
      }
      if(blockType === 'banner' && deviceType === 'desktop'){
        return <div>&nbsp;- Баннер - Десктоп</div>
      }
      if(blockType === 'banner' && deviceType === 'mobile'){
        return <div>&nbsp;- Баннер - Мобильный</div>
      }
    }
  }

  // submit all changes
  const submitChanges = () => {

    let css = '';

    if((blockType === 'native' || blockType === 'amp') && textPosition === 'bottom'){
      switch (Number(grid_x)) {
        case 1:
          css = native_1x_gradient;
          break;
        case 2:
          css = native_2x_gradient;
          break;
        case 3:
          css = native_3x_gradient;
          break;
        case 4:
          css = native_4x_gradient;
          break;
        case 5:
          css = native_5x_gradient;
          break;
        case 6:
          css = native_6x_gradient;
          break;
        case 7:
          css = native_7x_gradient;
          break;
        case 8:
          css = native_8x_gradient;
          break;
        case 9:
          css = native_9x_gradient;
          break;
        case 10:
          css = native_10x_gradient;
          break;
      }
    }

    if((blockType === 'native' || blockType === 'amp') && textPosition === 'combined'){
      css = native_combined;
    }

    if((blockType === 'native' || blockType === 'amp') && textPosition === 'right'){
      css = native_text_right;
    }

    if(blockType === 'infinite'){
      switch (Number(infiniteTGBType)) {
        case 1:
          css = tgb_1;
          break;
        case 2:
          css = tgb_2;
          break;
        case 3:
          css = tgb_3;
          break;
        case 4:
          css = tgb_4;
          break;
        case 5:
          css = tgb_5;
          break;
        case 6:
          css = tgb_6;
          break;
      }
    }

    if(blockType === 'backpage'){
      switch (Number(infiniteTGBType)) {
        case 2:
          css = tgb_2;
          break;
        case 5:
          css = backpage_5x;
          break;
      }
    }

    if(blockType === 'turbo'){
      css = native_text_right;
    }

    const formData = new FormData();
    formData.append("blockTitle", blockTitle);    // Десктоп - под статьей
    formData.append("blockType", blockType);      // native, infinite, backpage, turbo, amp
    if(blockType == 'native' || blockType == 'turbo' || blockType == 'amp'){
      formData.append("grid_x", grid_x);            // 1 - 10
      formData.append("grid_y", grid_y);            // 1 - 10
    }
    formData.append("headerTitle", headerTitle);  // Читайте также
    if(blockType == 'infinite' || blockType == 'backpage'){
      formData.append("infiniteTGBType", infiniteTGBType);  // 1,2,3,4,5,6
    }
    if(blockType == 'banner'){
      formData.append("bannerType", bannerType);
    }
    if(blockType !== 'banner'){
      formData.append("workModel", workModel);  // exchange, income, exchange_income
    }
    formData.append("css", css);  // текст 


    axios.post(`${hostDetect('api')}/widget/new?site_id=${siteIdRedux}`, formData, { withCredentials: true })
      .then(res => {
        
        if(res.data.success){
          setAlert('success', 'Блок создан!');

          let newWidgetId = res.data.data.widget_id

          setTimeout(()=> {
            history.push(`/settings/widgets/widgetInfo/${siteId}/${newWidgetId}`);
          }, 1000)

        } else{
          let errMessage = res.data.data.message;
          setAlert('error', errMessage);
        }
      })
      .catch(err => {
        setAlert('error', 'Не получилось отправить запрос!');
      })

    // setTabActive(3)
  }

  return (
    <div className="createWidgets">

      <MobileHeader/>

      <div className="createWidgets__back-btn">
        <button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
          <ArrowLeft size={15} style={{marginRight: 10}}/>Назад
        </button>
      </div>

      <div className="createWidgets__form-place">
        
        <div className="card" style={{maxWidth: '1500px'}}>
          <div className="card-header d-flex">
            <strong>Создание нового блока </strong>
            {
              handleBlockType()
            }
          </div>
          <div className="card-body">

            {
              tabActive == 3 ? (
                // tab 3 - final
                <Tab3 />
              ) : (
                <div className="row">
                  <div className="col-md-5">
    
                    {/* tab 1 */}
                    <div className={`wiz-tab ${tabActive === 1 ? 'active' : ''}`}>
                      <Tab1 
                        blockTitle={blockTitle} 
                        setBlockTitle={setBlockTitle} 
                        blockType={blockType} 
                        setBlockType={setBlockType} 
                        deviceType={deviceType}
                        setDeviceType={setDeviceType}
                        tabActive={tabActive}
                        setTabActive={setTabActive}
                        workModel={workModel} 
                        setWorkModel={setWorkModel} />
                    </div>
                    {/* tab 2 */}
                    <div className={`wiz-tab ${tabActive === 2 ? 'active' : ''}`}>
                      <Tab2 
                        blockType={blockType} 
                        setTabActive={setTabActive} 
                        deviceType={deviceType}
                        textPosition={textPosition}
                        setTextPosition={setTextPosition}
                        grid_x={grid_x}
                        setGrid_x={setGrid_x}
                        grid_y={grid_y}
                        setGrid_y={setGrid_y}
                        headerTitle={headerTitle}
                        setHeaderTitle={setHeaderTitle}
                        infiniteTGBType={infiniteTGBType}
                        setInfiniteTGBType={setInfiniteTGBType}
                        bannerType={bannerType}
                        setBannerType={setBannerType}
                        submitChanges={submitChanges} />
                    </div>                   
                    
                  </div>
    
                  {/* preview */}
                  <div className="col-md-7">
                    <Preview 
                      headerTitle={headerTitle}
                      blockType={blockType} 
                      grid_x={grid_x} 
                      grid_y={grid_y} 
                      infiniteTGBType={infiniteTGBType} 
                      textPosition={textPosition} 
                      deviceType={deviceType} 
                      bannerType={bannerType} />
                  </div>
                </div>
              )
            }

          </div>
        </div>
          
      </div>

    </div>
  )
}

function mapStateToProps(state){
  return {
    siteIdRedux: state.siteInfo.site.id
  }
}

const mapDispatchToProps = {
  setAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWidget_wizard)