import React, { useState, useEffect } from 'react'
import './WidgetsList.scss'

import { connect } from 'react-redux'

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WidgetsList = props => {

  const [widgetSelect, setWidgetSelect] = useState('Суммарно');

  useEffect(()=>{
    activeWidget(0, props.totalStatistics)
  }, [props.groupByLabel, props.statisticsDate])

  useEffect(()=>{
    if(props.activeWidgetIndex === 0){
      activeWidget(0, props.totalStatistics)
    }
  }, [props.totalStatistics])

  let activeWidget = (index, widgetStats) => {
    setWidgetSelect('Суммарно');
    props.switchWidget(index);

    if(widgetStats){
      props.addCurrentWidgetStatistics(widgetStats)
    } else{
      props.addCurrentWidgetStatistics([])
    }
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  const handleSelectChange = (w_index, w_value) => {
    // let index = e.target.selectedIndex,
    //     widgetValue = e.target.value,
    //     widgetStats;
    let index = w_index,
        widgetValue = w_value,
        widgetStats;
    
    setWidgetSelect(widgetValue);
    props.switchWidget(index);

    if(props.siteWidgets[index-1]){
      widgetStats = props.siteWidgets[index-1].data;
    }

    if(widgetStats){
      props.addCurrentWidgetStatistics(widgetStats);
    } else if(index === 0 && widgetValue === 'Суммарно'){
      props.addCurrentWidgetStatistics(props.totalStatistics);
    } else{
      props.addCurrentWidgetStatistics([]);
    }
  }

  return (
    <div className="widgets-list">
      <p className="nav-header hidden-folded" style={{fontWeight: 600}}>
        Выберите блок: <span className="badge bg-primary-lt">{props.siteWidgets.length} шт.</span>
      </p>


      {/* <div className="widgets-list__slider-wrap" style={{display: 'none'}}>
        <Slider {...settings}>
            <button
              type="button"
              className={"btn widgets-list__btns " + (props.activeWidgetIndex == 0 ? 'active' : '')}
              onClick={()=>activeWidget(0, props.totalStatistics)}>Суммарно</button>
          {
            props.siteWidgets.map((widget, indexW) => {
              return (
                <button
                  type="button"
                  className={"btn widgets-list__btns "+(props.activeWidgetIndex == indexW+1 ? 'active' : '')}
                  key={widget.id}
                  onClick={()=>activeWidget(indexW+1, widget.data)}>
                    {
                      widget.name
                    }
                  <p>
                    # {widget.id}
                  </p>
                </button>
              )
            })
          }
        </Slider>
      </div> */}


      {/* <div className="widgets-list__wrap" style={{overflow: 'auto', justifyContent: 'space-between', display: 'none'}}>

        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            className={"btn btn-outline-primary widgets-list__btns " + (props.activeWidgetIndex == 0 ? 'active' : '')}
            onClick={()=>activeWidget(0, props.totalStatistics)}>Суммарно</button>
          {
            props.siteWidgets.map((widget, indexW) => {
              return (
                <button
                  type="button"
                  className={"btn btn-outline-primary widgets-list__btns "+(props.activeWidgetIndex == indexW+1 ? 'active' : '')}
                  key={widget.id}
                  onClick={()=>activeWidget(indexW+1, widget.data)}>
                    {
                      widget.name
                    }
                  <p style={{margin: 0, fontSize: 12}}>
                    # {widget.id}
                    </p>
                </button>
              )
            })
          }
        </div>
      </div> */}

      
      <select className="widgets-list__select form-control custom-select" value={widgetSelect} onChange={(e) => handleSelectChange(e.target.selectedIndex, e.target.value)} >
        <option value="Суммарно">Суммарно</option>
        {
          props.siteWidgets.map(widget => {
            return (
              <option value={widget.name} key={widget.id}>
                {widget.name}
              </option>
            )
          })
        }
      </select>
      
    </div>
  )
}

function mapStateToProps(state){
  return{
    siteWidgets: state.siteInfo.site.widgets,
    activeWidgetIndex: state.siteInfo.site.widgetActive,
    groupByLabel: state.siteInfo.site.groupBy,
    statisticsDate: state.siteInfo.site.statisticsDate,
    totalStatistics: state.siteInfo.site.totalSite.by_date
  }
}

function mapDispatchToProps(dispatch){
  return {
    addCurrentWidgetStatistics: statistics => dispatch({type: 'ADD_CURRENT_WIDGET_STATISTICS', payload: statistics}),
    switchWidget: widgetIndex => dispatch({type: 'SWITCH_WIDGET', payload: widgetIndex})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsList)