 const initialStateTheme = {
 	widgetInfo: {
 		textareaDark: false
 	}
 }

 export default function siteThemeSettings(state=initialStateTheme, action){
 	switch(action.type){
 		case 'SWITCH_CSS_TEXTAREA_THEME':
 			const newState = {...state}
 			newState.widgetInfo.textarea = !newState.widgetInfo.textarea
 			return newState
 	}
 	return state
 }