import React from 'react'

import './404page.scss'

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <span>404</span>
      <h1>страница <br/> не найдена</h1>
      <p>
        Страница которую вы ищите не найдена. <br/> Возможно она была удалена, переименована либо вообще не существует.
      </p>
    </div>
  )
}

export default NotFoundPage
