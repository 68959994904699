import React from 'react'

import './pageHeading.scss'

const PageHeading = props => {
	return(
		<div className="padding">
			<div className="page-heading page-title">
				<h1 className="text-md text-highlight">{props.mainText}</h1>
				<p className="text-muted">{props.mutedText}</p>
			</div>
		</div>
	)
}

export default PageHeading