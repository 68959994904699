import React, { useState, useEffect } from 'react'

const Pagination = (props)=>{

  let indexOfLastPost = props.currentPage * props.postsPerPage;
  let indexOfFirstPost = indexOfLastPost - props.postsPerPage;

  useEffect(()=>{
    props.indexOfFirst(indexOfFirstPost)
    props.indexOfLast(indexOfLastPost)
  })

  let pageNumbers = [];
  for(let i=1; i <= Math.ceil(props.size / props.postsPerPage); i++){
    pageNumbers.push(i)
  }

  let middleArr = []
  let middleArrLength = 5
  let middleArrFirst = 2


  if(props.currentPage == pageNumbers[pageNumbers.length-1]){
    middleArrFirst = pageNumbers[pageNumbers.length-1] - 5
  } else if(props.currentPage > 3){
    if(props.currentPage + 3 > pageNumbers[pageNumbers.length-1]){
      middleArrFirst =pageNumbers[pageNumbers.length-1] - 5
    } else {
      middleArrFirst = props.currentPage - 2
    }
  }

  if(props.currentPage < 3){
    middleArrFirst = 2
  }

  for(let i = middleArrFirst; i<middleArrFirst+middleArrLength; i++){
    middleArr.push(i)
  }

  let buttonsSet = (
    <React.Fragment>
      <button
        className={'btn btn-outline-primary ' + (props.currentPage == 1 ? 'active' : '')}
        onClick={()=>props.setCurrentPage(1)}>
        {pageNumbers[0]}
      </button>
      <div>
        { middleArrFirst >= pageNumbers[0]+2 ? (<div style={{height: '100%', padding: '0 5px', color: '#448bff', borderColor: '#448bff'}}>...</div>) : '' }
      </div>

      {
        middleArr.map(item =>{
          return (
            <button
              className={'btn btn-outline-primary ' + (props.currentPage == item ? 'active' : '')}
              onClick={()=>props.setCurrentPage(item)}
              key={item}>
              {item}
            </button>
          )
        })
      }

      <div>
        { middleArrFirst+5 !== pageNumbers[pageNumbers.length-1] ? (<div style={{height: '100%', padding: '0 5px', color: '#448bff', borderColor: '#448bff'}}>...</div>) : '' }
      </div>
      <button
        className={'btn btn-outline-primary ' + (props.currentPage == pageNumbers[pageNumbers.length-1] ? 'active' : '')}
        onClick={()=>props.setCurrentPage(pageNumbers[pageNumbers.length-1])}>
        {pageNumbers[pageNumbers.length-1]}
      </button>
    </React.Fragment>
  )


  let buttonsSetSmall = (
    pageNumbers.map((page, index) => {
      return (
        <button
          className={'btn btn-outline-primary ' + (page == props.currentPage ? 'active' : '')}
          key={pageNumbers[index]}
          onClick={()=>props.setCurrentPage(page)}>
          {page}
        </button>
      )
    })
  )


  const NextPage = ()=> {
    props.setCurrentPage(prevPage =>prevPage+1)
  }

  const PrevPage = ()=> {
    props.setCurrentPage(prevPage =>prevPage-1)
  }


  return (
    <nav>

      <div className="btn-group">
        <button
          className='btn btn-outline-primary pagination-prev'
          onClick={()=> PrevPage()}
          disabled={props.currentPage == 1 ? true : false}>
          Назад
        </button>
        {

          pageNumbers.length>10 ? (
            buttonsSet
            ) : (
            buttonsSetSmall
            )

        }
        <button
          className='btn btn-outline-primary pagination-next'
          onClick={()=> NextPage()}
          disabled={props.currentPage == pageNumbers.length ? true : false}>
          Вперед
        </button>
      </div>

    </nav>
  )
}

export default Pagination