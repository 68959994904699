import Axios from 'axios';
import React, { useState, useEffect, useRef, memo } from 'react'

import './Schedule.scss'

import { hostDetect } from '../../../../../config/envConfig';

// daterange picker
import DateRangePickerGraph from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

// moment js
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

const Schedule = ({advId}) => {

  // date:
	const [dateTypeRadiobutton, setDateTypeRadiobutton] = useState('')

	const [graphDates, setGraphDates] = useState({
    start: moment(),
    end: moment().add(1, 'Y')
  });
	const dateRangeComponent = useRef();

  // shedule
  const [graphTable, setGraphTable] = useState({
  	monday: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
  	tuesday: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
  	wednesday: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
  	thursday: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
  	friday: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
  	saturday: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
  	sunday: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]
  });

  // profile update status
	const [profileUpdateSuccess, setProfileUpdateSuccess] = useState(false)
  const [profileUpdateSuccessDate, setProfileUpdateSuccessDate] = useState(false)


  // load initial settings
	useEffect(()=>{
		let apiOptions = {
			withCredentials: true
		}

		Axios.get(`${hostDetect('api')}/ad/get/?id=${advId}`, apiOptions)
		.then(response => { 
			const success = response.data.success;
				if(success){

					// get dates
					const date_from = response.data.data.entry.date_from,
								date_to = response.data.data.entry.date_to;
					// detect type of date (1)
					let daysAtoB = null;

					if(date_from && date_to){
						const date_from_moment = moment(date_from),
									date_to_moment = moment(date_to);
						
						// detect type of date (2)
						daysAtoB = Math.abs(date_from_moment.diff(date_to_moment, 'days'))

						if(dateRangeComponent.current){
							dateRangeComponent.current.setStartDate(date_from_moment);
							dateRangeComponent.current.setEndDate(date_to_moment);
						}

						setGraphDates({
							start: moment(date_from_moment),
							end: moment(date_to_moment)
						})
					}

					// get schedule					
					const scheduleList = response.data.data.entry.scheduleList;

					// detect type of date (3)
					if(daysAtoB >= 365 && scheduleList.length === 0){
						setDateTypeRadiobutton('dateInfinity');
					} else {
						setDateTypeRadiobutton('dateCustom');
					}

					scheduleList.length && scheduleList.forEach(item => {
						const d = item.day,
									h = Number(item.hour);

						setGraphTable(prev => {
							let newSchedule = {...prev};
							newSchedule[d][h] = false
							return newSchedule
						})
					})
				}
		})
		.catch(err => console.log(err))
	}, [])

	// date type
	const handleDateType = (e) => {
		setDateTypeRadiobutton(e.target.value)
	}
	// post api Dates andSchedule if dateInfinity
	useEffect(()=>{
		if(dateTypeRadiobutton === 'dateInfinity'){
			if(advId){
				const dateSendSuccess = () => {
					let prevStatement = Object.assign({}, graphTable);
					for(let item in prevStatement){
						prevStatement[item] = new Array(24).fill(true);
					}
					let apiOptions = {
						withCredentials: true
					}
					Axios.get(`${hostDetect('api')}/ad/schedule-all/?id=${advId}&enable=true`, apiOptions)
						.then(response => {
							const success = response.data.success;
							if(success){
								setGraphTable(prevStatement);
								console.log('schedule filled and send successfully')
							}
						})
						.catch(err => console.log('error is', err))
				}

				const dateSendFailed = () => {
					console.log('Error in dates sending...')
				}
				
				let sendDate = new Promise((resolve, reject) => {
					const startDateFormatted = moment().format('YYYY-MM-DD'),
								endDateFormatted = moment(startDateFormatted).add(1, 'years').format('YYYY-MM-DD');

					let formData = new FormData();
					formData.append('date_from', startDateFormatted);
					formData.append('date_to', endDateFormatted);

					let apiOptions = {
						withCredentials: true
					}

					Axios.post(`${hostDetect('api')}/ad/update/?id=${advId}`, formData, apiOptions)
						.then(response => {
							const success = response.data.success;
							if(success){
								console.log(`dates send successfully: ${startDateFormatted} | ${endDateFormatted}`)
								setGraphDates({start: moment(startDateFormatted), end: moment(endDateFormatted)})
								resolve();
							}
						})
						.catch(err => {
							console.log('error is', err)
							reject();
						})
				})
				sendDate.then(dateSendSuccess, dateSendFailed);
			}
		}
	}, [dateTypeRadiobutton])



  // set date
	let handleCallback = (start, end) => {
		const startDateFormatted = moment(start).format('YYYY-MM-DD'),
					endDateFormatted = moment(end).format('YYYY-MM-DD');

		let formData = new FormData();
		formData.append('date_from', startDateFormatted);
		formData.append('date_to', endDateFormatted);

		let apiOptions = {
			withCredentials: true
		}

		Axios.post(`${hostDetect('api')}/ad/update/?id=${advId}`, formData, apiOptions)
			.then(response => {
				const success = response.data.success;
				if(success){
					handleUpdateSuccessDate();
					setGraphDates({start, end})
				}
			})
			.catch(err => console.log('error is', err))
	}

	let handleShowCalendar = () => {
		document.querySelector(".drp-buttons .custom").closest(".daterangepicker").classList.add('position-fixed');
	}

	// schedule
	let countTableHead = () => {
		let tableHead = [];
		for (let i=0; i < 24; i++) {
			if(i<10){
				i = '0'+i
			}
			tableHead.push(i);
		}
		return tableHead.map((item, index) => <th key={index} onClick={() => checkTimeTableColumn(index)}>{item}</th>)
	}

	let countTableCell = day => {
		return graphTable[day].map((item, index) => <td key={index}  className={item ? 'active' : ''}></td>)
	}

	let handleTimeTable = event => {
		if(event.target.tagName === "TD"){
			let row = event.target.parentNode.rowIndex,
					cell = event.target.cellIndex;

			let prevStatement = Object.assign({}, graphTable),
					currentCellState = prevStatement[Object.keys(graphTable)[row-1]][cell-1],
					newCellState = !currentCellState;
			prevStatement[Object.keys(graphTable)[row-1]][cell-1] = newCellState;

			let weekDay = Object.keys(graphTable)[row-1];
			let dayTime = cell-1;
			if(dayTime < 10) dayTime = '0' + dayTime;

			let formData = new FormData();
			formData.append('day', weekDay);
			formData.append('hour', dayTime);
			if(newCellState === true){
				formData.append('enable', false);
			}

			let apiOptions = {
				withCredentials: true
			}

			Axios.post(`${hostDetect('api')}/ad/set-schedule/?id=${advId}`, formData, apiOptions)
			.then(response => {
				console.log(response)
				const success = response.data.success;
				if(success){
					handleUpdateSuccess();
					setGraphTable(prevStatement);
				}
			})
			.catch(err => console.log('error is', err))

			
		}
	}

	// fill-clear row by "DAY" clicked
	let checkTimeTableRow = day => {
		let prevStatement = Object.assign({}, graphTable);
		if(prevStatement[day].every(item => item === true)){
			prevStatement[day] = new Array(24).fill(false);

			let apiOptions = {
				withCredentials: true
			}

			Axios.get(`${hostDetect('api')}/ad/schedule-all/?id=${advId}&day=${day}&enable=false`, apiOptions)
			.then(response => {
				console.log(response)
				const success = response.data.success;
				if(success){
					handleUpdateSuccess();
					setGraphTable(prevStatement);
				}
			})
			.catch(err => console.log('error is', err))

		} else {
			prevStatement[day] = new Array(24).fill(true);

			let apiOptions = {
				withCredentials: true
			}

			Axios.get(`${hostDetect('api')}/ad/schedule-all/?id=${advId}&day=${day}&enable=true`, apiOptions)
			.then(response => {
				console.log(response)
				const success = response.data.success;
				if(success){
					handleUpdateSuccess();
					setGraphTable(prevStatement);
				}
			})
			.catch(err => console.log('error is', err))

			setGraphTable(prevStatement);
		}
	}



	// fill-clear column by "HOUR" clicked
	let checkTimeTableColumn = index => {
		let prevStatement = Object.assign({}, graphTable);
		let cellsState = [];
		for(let item in prevStatement){
			cellsState.push(prevStatement[item][index])
		}

		if(cellsState.every(cell => cell === true)){
			for(let item in prevStatement){
				prevStatement[item][index] = false;
			}

			let hour = index;
			if(index < 10) hour = '0' + index;

			let apiOptions = {
				withCredentials: true
			}

			Axios.get(`${hostDetect('api')}/ad/schedule-all/?id=${advId}&hour=${hour}&enable=false`, apiOptions)
			.then(response => {
				console.log(response)
				const success = response.data.success;
				if(success){
					handleUpdateSuccess();
					setGraphTable(prevStatement);
				}
			})
			.catch(err => console.log('error is', err))
			
		} else {

			let hour = index;
			if(index < 10) hour = '0' + index;

			for(let item in prevStatement){
				prevStatement[item][index] = true;
			}

			let apiOptions = {
				withCredentials: true
			}

			Axios.get(`${hostDetect('api')}/ad/schedule-all/?id=${advId}&hour=${hour}&enable=true`, apiOptions)
			.then(response => {
				console.log(response)
				const success = response.data.success;
				if(success){
					handleUpdateSuccess();
					setGraphTable(prevStatement);
				}
			})
			.catch(err => console.log('error is', err))

		}
	}

	// schedule clear
	let clearTimeTable = () => {
		let prevStatement = Object.assign({}, graphTable);
		for(let item in prevStatement){
			prevStatement[item] = new Array(24).fill(false);
		}

		let apiOptions = {
			withCredentials: true
		}

		Axios.get(`${hostDetect('api')}/ad/schedule-all/?id=${advId}&enable=false`, apiOptions)
			.then(response => {
				console.log(response)
				const success = response.data.success;
				if(success){
					handleUpdateSuccess();
					setGraphTable(prevStatement);
				}
			})
			.catch(err => console.log('error is', err))
	}


	// schedule fill
	let fillTimeTable = () => {
		let prevStatement = Object.assign({}, graphTable);
		for(let item in prevStatement){
			prevStatement[item] = new Array(24).fill(true);
		}


		let apiOptions = {
			withCredentials: true
		}

		Axios.get(`${hostDetect('api')}/ad/schedule-all/?id=${advId}&enable=true`, apiOptions)
			.then(response => {
				console.log(response)
				const success = response.data.success;
				if(success){
					handleUpdateSuccess();
					setGraphTable(prevStatement);
				}
			})
			.catch(err => console.log('error is', err))
		
	}

  let freezeModal = () => {
		document.querySelector('.settings-modal__inner').classList.add('scroll-stop');
	}

	let unfreezeModal = () => {
		document.querySelector('.settings-modal__inner').classList.remove('scroll-stop');
	}


  // profile date update status
	const handleUpdateSuccessDate = () => {
		setProfileUpdateSuccessDate(true);

		setTimeout(()=> {
			setProfileUpdateSuccessDate(false);
		}, 3000)
	}


  // profile schedule update status
	const handleUpdateSuccess = () => {
		setProfileUpdateSuccess(true);

		setTimeout(()=> {
			setProfileUpdateSuccess(false);
		}, 3000)
	}


  return (
    <div className="settings-modal__field-card graph-field">


			<div className="form-check mb-2">
				<input 
					className="form-check-input" 
					type="radio" 
					name="dateType" 
					id="dateInfinity" 
					value="dateInfinity" 
					onChange={handleDateType} 
					checked={dateTypeRadiobutton === 'dateInfinity'}/>
				<label className="form-check-label" htmlFor="dateInfinity">
					Показывать бессрочно
				</label>
			</div>

			<div className="form-check">
				<input 
					className="form-check-input" 
					type="radio" 
					name="dateType" 
					id="dateCustom" 
					value="dateCustom" 
					onChange={handleDateType} 
					checked={dateTypeRadiobutton === 'dateCustom'}/>
				<label className="form-check-label" htmlFor="dateCustom">
					Выбрать свою дату
				</label>
			</div>

			{
				dateTypeRadiobutton === 'dateCustom' ? 
					(
						<div className="card p-3 mt-2">
							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="form-group">
										<label className="text-muted" htmlFor="totalLimit">Выберите дату</label>
											<DateRangePickerGraph
												ref={dateRangeComponent}
												initialSettings={
													{ startDate: graphDates.start,
														endDate: graphDates.end,
														minDate: moment(),
														locale: {
															'customRangeLabel': 'Выбор даты',
															"daysOfWeek": [
																"Вс",
																"Пн",
																"Вт",
																"Ср",
																"Чт",
																"Пт",
																"Сб"
															],
															"monthNames": [
																"Январь",
																"Февраль",
																"Март",
																"Апрель",
																"Май",
																"Июнь",
																"Июль",
																"Август",
																"Сентябрь",
																"Октябрь",
																"Ноябрь",
																"Декабрь"
															],
															"firstDay": 1,
															"applyLabel": "Применить",
															"cancelLabel": "Отмена",
														},
														opens: 'center',
														drops: 'auto',
														buttonClasses: 'custom btn'
													}
												} onShow={handleShowCalendar} onCallback={handleCallback} className="ttt" id="rrr">
												<input type="text" className="form-control" onFocus={freezeModal} onBlur={unfreezeModal}/>
											</DateRangePickerGraph>
										</div>
									</div>
								</div>
						
								{
									profileUpdateSuccessDate && (
										<div className="alert alert-success process-status-popup date" role="alert">
											<svg 
												xmlns="http://www.w3.org/2000/svg" 
												width="16" 
												height="16" 
												viewBox="0 0 24 24" 
												fill="none" 
												stroke="currentColor" 
												strokeWidth="2" 
												strokeLinecap="round" 
												strokeLinejoin="round" 
												className="feather feather-check">
													<polyline points="20 6 9 17 4 12"></polyline>
											</svg>
											<span className="mx-2">Дата успешно обновлена</span>
										</div>
									)
								}
											
								<div className="settings-modal__time-table">
									<label className="text-muted" htmlFor="totalLimit">Выберите время</label>
									<table className="table table-bordered" onClick={handleTimeTable}>
										<thead>
											<tr>
												<th scope="col"></th>
												{ countTableHead() }
											</tr>
										</thead>
										<tbody>
											<tr>
												<th scope="row" onClick={() => checkTimeTableRow('monday')}>Понедельник</th>
												{ countTableCell('monday') }
											</tr>
											<tr>
												<th scope="row" onClick={() => checkTimeTableRow('tuesday')}>Вторник</th>
												{ countTableCell('tuesday') }
											</tr>
											<tr>
												<th scope="row" onClick={() => checkTimeTableRow('wednesday')}>Среда</th>
												{ countTableCell('wednesday') }
											</tr>
											<tr>
												<th scope="row" onClick={() => checkTimeTableRow('thursday')}>Четверг</th>
												{ countTableCell('thursday') }
											</tr>
											<tr>
												<th scope="row" onClick={() => checkTimeTableRow('friday')}>Пятница</th>
												{ countTableCell('friday') }
											</tr>
											<tr>
												<th scope="row" onClick={() => checkTimeTableRow('saturday')}>Суббота</th>
												{ countTableCell('saturday') }
											</tr>
											<tr>
												<th scope="row" onClick={() => checkTimeTableRow('sunday')}>Воскресенье</th>
												{ countTableCell('sunday') }
											</tr>
										</tbody>
									</table>
								</div>
								<div className="settings-modal__time-table-controls">
									<button className="btn w-sm mb-1 btn-sm btn-secondary" onClick={clearTimeTable}>Очистить</button>
									<button className="btn w-sm mb-1 btn-sm btn-primary" onClick={fillTimeTable}>Выбрать все</button>
					
									{
										profileUpdateSuccess && (
											<div className="alert alert-success process-status-popup schedule" style={{display: 'inline-block'}} role="alert">
												<svg 
													xmlns="http://www.w3.org/2000/svg" 
													width="16" 
													height="16" 
													viewBox="0 0 24 24" 
													fill="none" 
													stroke="currentColor" 
													strokeWidth="2" 
													strokeLinecap="round" 
													strokeLinejoin="round" 
													className="feather feather-check">
														<polyline points="20 6 9 17 4 12"></polyline>
												</svg>
												<span className="mx-2">Профиль успешно обновлен</span>
											</div>
										)
									}
								</div>
						</div>
					) : null 
			}
    </div>
  )
}

export default memo(Schedule)
