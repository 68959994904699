import React, { useState } from 'react'

// import './GroupbyButtons.scss'

const GroupbyButtons = ({groupBy, setGroupBy})=> {

  const handleChange = (event)=>{
    setGroupBy(event.target.value);
  }

  return (
    <div className="groupby">
      <p className="nav-header hidden-folded">
        Группировать по:
      </p>
      <div className="input-group">
        <select className="custom-select" value={groupBy} onChange={handleChange}>
          <option value="day">По дням</option>
          <option value="week">По неделям</option>
          <option value="month">По месяцам</option>
        </select>
      </div>
    </div>
  )
}


export default GroupbyButtons