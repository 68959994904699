import React, {useState, useEffect} from 'react'
import './ManagerCard.scss'

import Axios from 'axios';

import { connect } from 'react-redux'

import { hostDetect } from '../../../config/envConfig';

import { setAlert } from '../../../redux/actions/alertActions';

import { Phone, Mail } from "react-feather";
import managerImg from '../../../img/manager.jpg';
import telegramLogo from '../../../img/telegram-logo.svg'

const ManagerCard = ({setAlert, siteID}) => {
  
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [avatar, setAvatar] = useState('')
  const [socials, setSocials] = useState('')

  useEffect(() => {
    if(siteID){
      Axios.get(`${hostDetect('api')}/publisher/get-site-manager?site_id=${siteID}`, { withCredentials: true })
			.then(response => {
				const success = response.data.success;
				if(success){
          let { manager } = response.data.data
          
          setName(manager.name)
          setEmail(manager.email)
          setPhone(manager.phone)
          setAvatar(manager.avatar)
          setSocials(manager.socials)
				} else {
					let errMessage = response.data.data.message;

          setAlert('error', errMessage);
				}
			})
			.catch(err => setAlert('error', 'Не получилось отправить запрос!'))
    }
    
  }, [siteID])

  const formatPhone = (phone_number) => {
    if(phone_number){
      let x = phone_number.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
      let maskedPhone = `+${x[1]} (${x[2]}) ${x[3]}-${x[4]}-${x[5]}`
      return maskedPhone
    }
  }

  return(
    <div className="managerCard">
      {
        siteID ? (
          <>
            <p className="managerCard__title">Ваш менеджер</p>
            <div className="managerCard__card">
              <div className="d-flex">
                <div className="managerCard__userpick" style={{backgroundImage: `url(${avatar})`}}></div>
                <ul className="managerCard__user-info-list">
                  <li className="managerCard__user-info-item name">
                    { name }
                  </li>
                  {/* <li className="managerCard__user-info-item lastname">
                  </li> */}
                  {/* <li className="managerCard__user-info-item position">
                    менеджер
                  </li> */}
                </ul>
              </div>

              <div className="managerCard__card-contacts">
                <ul className="managerCard__user-info-list">
                  <li className="managerCard__user-info-item phone">
                    <Mail size={12} color={'#666'} style={{marginRight: 10}}/> 
                    {/* <a href="mailto:info@sparrow.ru">{ email }</a> */}
                    <a href={`mailto:${email}`}>{ email }</a>
                  </li>
                  <li className="managerCard__user-info-item telegram">
                    <img src={telegramLogo} style={{marginRight: 10}} alt="telegram-logo" />
                    {/* <a href="https://telegram.me/sparrow_network">sparrow_network</a> */}
                    <a href={`https://telegram.me/${socials.replace(/^@/g, "")}`}>{ socials }</a>
                  </li>
                  <li className="managerCard__user-info-item email">
                    <Phone size={12} color={'#666'} style={{marginRight: 10}}/>
                    {/* +7 (495) 012-53-21 */}
                    { formatPhone(phone) }
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : null
      }
    </div>
  )
}


function mapStateToProps(state){
  return{
    siteID: state.siteInfo.site.id
  }
}

const mapDispatchToProps = {
  setAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerCard)