import React, {useState, useEffect} from 'react'

import './TeaserArchived.scss'

import Axios from 'axios';

import { Link } from "react-feather";

import { hostDetect } from '../../../config/envConfig';

import { connect } from 'react-redux'

import { setAlert } from '../../../redux/actions/alertActions';

import { useSelector } from 'react-redux';

import Pagination_api from './Pagination_api/Pagination_api';

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')


const TeaserArchived = ({setAlert}) => {

  const site_id = useSelector(state => state.siteInfo.site.id);

  const [archivedList, setArchivedList] = useState([]);
  const [archivedLength, setArchivedLength] = useState(0);

  const [apiLoading, setApiLoading] = useState(false);

  // pagination
  const [paginationCurrent, setPaginationCurrent] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(10);

  useEffect(()=>{
    getTeaserApi(0, paginationLimit)
  },[site_id])

  const getTeaserApi = (start, limit) => {

    setApiLoading(true)

    Axios.get(`${hostDetect('api')}/exchange/get-expired-ad-list?site_id=${site_id}&start=${start}&limit=${limit}`, {withCredentials: true})
		.then(response => {
			const success = response.data.success;

      setApiLoading(false)

			if(success){
        setArchivedLength(response.data.data.entries.count)
        setArchivedList(response.data.data.entries.ads)
			} else {
				let errMessage = response.data.data.message;
        setAlert('error', errMessage);
			}
		})
		.catch(err => {
      setApiLoading(false)
			setAlert('error', 'Не получилось отправить запрос!');
		})
  }

  // Date format
	const formatDate = (date) => {
		let formattedDate = moment(date).format('DD.MM.YYYY  HH:mm:ss')
		return formattedDate
	}

  const handlePageClick = (page) => {
    let newStart = (page - 1) * paginationLimit
    setPaginationCurrent(page)
    getTeaserApi(newStart, paginationLimit)
  }

  const handlePaginationSelect = (value) => {
    setPaginationLimit(value)
    setPaginationCurrent(1)
    getTeaserApi(0, value)
  }


  return (
    <div className="teaser-statistics">
      <div className="teaser-statistics__list">
        <table className="table table-theme table-row v-middle">
          <thead>
            <tr>
              <th className="text-muted" style={{width: 450, minWidth: 450}}>Статья</th>
              <th className="text-muted" style={{minWidth: 250}}>Даты</th>
              <th className="text-muted">Клики</th>
            </tr>
          </thead>
          <tbody>

            {
              archivedList.length ? (
                archivedList.map((archived_item, i) => {
                  return (
                    <tr className=" v-middle" key={archived_item.id}>
                      <td>
                        <div className="d-flex mb-2">
                          <div className="page-exchange__article-img" style={{backgroundImage: `url(${archived_item.image})`}}></div>
                          <div className="page-exchange__article-id-title-wrap">
                            <div className="page-exchange__article-id">
                              <span className="badge badge-sm badge-pill mx-1">id:</span> {archived_item.id}
                            </div>
                            <p className="page-exchange__article-title">
                              {archived_item.title}
                            </p>
                          </div>
                        </div>
                        <div className="page-exchange__article-link">
                          <Link size={12}/>
                          <a href={archived_item.url} title={archived_item.url}>
                            {archived_item.url}
                          </a>
                        </div>
                      </td>
                      <td>
                        <ul className="dates-list">
                          <li>
                            <span className="text-muted">Создана:</span>
                          </li>
                          <li className="mb-3">
                            <span className="pl-2" style={{fontSize: 12}}>{ formatDate(archived_item.created) }</span>
                          </li>
                          <li>
                            <span className="text-muted">Удалена:</span>
                          </li>
                          <li>
                            <span className="pl-2" style={{fontSize: 12}}>{ formatDate(archived_item.expired) }</span>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <span style={{fontSize: 12}}>{ archived_item.total_clicks }</span>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr className=" v-middle">
                  <td colSpan="3">Статьи отсутствуют</td>
                </tr>
              )
            }

            
          </tbody>
        </table>
              
      </div>

      {
        archivedList.length ? (
          <div className="teaser-statistics__controls">

            <div className="teaser-statistics__controls-pagination">
              <Pagination_api 
                currentPage={paginationCurrent}
                totalCount={archivedLength}
                pageSize={paginationLimit}
                onPageChange={page => handlePageClick(page)} />
            </div>
            
            <div className="teaser-statistics__controls-limit">
              <select className="form-control" value={paginationLimit} onChange={e => handlePaginationSelect(e.target.value)}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
        ) : null
      }

      {
        apiLoading ? <div className="loader-line"></div> : null
      }

    </div>
  )

}

const mapDispatchToProps = {
    setAlert
  }
  
  export default connect(null, mapDispatchToProps)(TeaserArchived)