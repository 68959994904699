import React, { useState, useEffect } from 'react'

import './PageSites.scss'

import { hostDetect } from '../../config/envConfig'

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'

import { connect } from 'react-redux'

import axios from 'axios'

import { PlusCircle, X } from "react-feather";
import { Link } from "react-router-dom";

import { setAlert } from '../../redux/actions/alertActions.js'


const Sites = props => {

  const [showModal, setShowModal] = useState(false)

  const [siteIdToDelete, setSiteIdToDelete] = useState('')
  const [siteNameToDelete, setSiteNameToDelete] = useState('')

  const [searchQuery, setSearchQuery] = useState('')
  const [searchQueryLength, setSearchQueryLength] = useState(0)

  const [sortBy, setSortBy] = useState('new') // new, old, alphabet

  const applyDeleting = ()=> {

    if(siteIdToDelete){

      const formData = new FormData();
      formData.append("site_id", String(siteIdToDelete));

      axios.post(`${hostDetect('api')}/site/delete`, formData, {
        withCredentials: true
      })
      .then(res => {

        if(res.data.success){
          let siteFromLocalStorage = JSON.parse(localStorage.getItem('current_site_information'));

          if(String(siteFromLocalStorage.id) === String(siteIdToDelete)){
            localStorage.removeItem('current_site_information');
          }
          
          setSiteIdToDelete('')
          setSiteNameToDelete('')
          setShowModal(false)
          props.componentUpdate();
        } else {
          const errorMessage = res.data.data.message
          props.setAlert('error', errorMessage);
        }
      })
      .catch(err => {
        props.setAlert('error', 'Не получилось отправить запрос!');
      })
    }
  }

  const deleteItem = (id, name)=> {
    setSiteIdToDelete(id)
    setSiteNameToDelete(name)
  }

  const handleSearch = event => {
    setSearchQuery(event.target.value)
  }

  return (
    <div className="page-sites">

      <MobileHeader/>

      <div className="page-sites__block">

        <PageHeading mainText={"Ваши сайты"} mutedText={"Добавьте новый сайт или настройте существующий"}/>
        
        <div className="input-group page-sites__search">
          <input 
            type="text" 
            className="form-control form-control-theme search" 
            placeholder="Поиск (Введите название, url или id сайта)"
            value={searchQuery}
            onChange={handleSearch}/>

            <div className="dropdown">
              <div className="btn-group" style={{height: '100%', marginLeft: 10}}>
                <div className="btn btn-white" title="Сортировать по">
                  {
                    sortBy === 'new' ? 'Новые' : null
                  }
                  {
                    sortBy === 'old' ? 'Старые' : null
                  }
                  {
                    sortBy === 'alphabet' ? 'По алфавиту' : null
                  }
                </div>
                <button className="btn btn-white " data-toggle="dropdown" aria-expanded="false" style={{padding: 5}} title="Сортировать по">
                  <i className="sorting"></i>
                </button>

                <div className="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end">
                  <a className={`dropdown-item ${sortBy == 'new' ? 'active' : ''}`} onClick={() => setSortBy('new')}>
                    Новые
                  </a>
                  <a className={`dropdown-item ${sortBy == 'old' ? 'active' : ''}`} onClick={() => setSortBy('old')} >
                    Старые
                  </a>
                  <a className={`dropdown-item ${sortBy == 'alphabet' ? 'active' : ''}`}  onClick={() => setSortBy('alphabet')}>
                    По алфавиту
                  </a>
                </div>

              </div>
            </div>

        </div>

        <div className="page-sites__wrapper d-flex">

          <div className="page-sites__item card">
            <div className="media media-2x1">
              <Link to="/settings/sites/createSite" className="add-widget-btn media-content">
                <span className="add-widget-btn__top">
                  Добавить
                </span>
                <PlusCircle color="#212529" size={30} className="add-widget-btn__icon"/>
                <span className="add-widget-btn__bottom">
                  сайт
                </span>
              </Link>
            </div>
          </div>
          

          {/* sorting */}
          {
            // new
            sortBy == 'new' && props.userSites.filter(site => site.full_name.toLowerCase().includes(searchQuery.toLowerCase()) || site.url.includes(searchQuery) || String(site.id).includes(searchQuery)).map(site => {
              return (
                <div className="page-sites__item card" key={site.id}>
                  <div className="media media-2x1 gd-primary">
                    <Link to={`/settings/sites/editSite/${site.id}`} className="site-card-btn media-content">
                      <span className="site-card-btn__id badge badge-light text-uppercase">
                        # {site.id}
                      </span>
                      <span className="site-card-btn__text">
                        {site.full_name}
                      </span>
                      <span className="site-card-btn__url">
                        {site.url}
                      </span>
                    </Link>
                    <button className="btn btn-icon btn-white widgets-block__delete-item" onClick={()=>deleteItem(site.id, site.full_name)} data-toggle="modal" data-target="#exampleModal">
                      <X size={15}/>
                    </button>
                  </div>
                </div>
              )
            })
            
          }

          {
            // old
            sortBy == 'old' && props.userSites.filter(site => site.full_name.toLowerCase().includes(searchQuery.toLowerCase()) || site.url.includes(searchQuery) || String(site.id).includes(searchQuery)).map(site => {
              return (
                <div className="page-sites__item card" key={site.id}>
                  <div className="media media-2x1 gd-primary">
                    <Link to={`/settings/sites/editSite/${site.id}`} className="site-card-btn media-content">
                      <span className="site-card-btn__id badge badge-light text-uppercase">
                        # {site.id}
                      </span>
                      <span className="site-card-btn__text">
                        {site.full_name}
                      </span>
                      <span className="site-card-btn__url">
                        {site.url}
                      </span>
                    </Link>
                    <button className="btn btn-icon btn-white widgets-block__delete-item" onClick={()=>deleteItem(site.id, site.full_name)} data-toggle="modal" data-target="#exampleModal">
                      <X size={15}/>
                    </button>
                  </div>
                </div>
              )
            }).reverse()
          }

          {
            // alphabet
            sortBy == 'alphabet' && props.userSites.filter(site => site.full_name.toLowerCase().includes(searchQuery.toLowerCase()) || site.url.includes(searchQuery) || String(site.id).includes(searchQuery)).sort((a, b) => {
                var textA = a.full_name.trim().toLowerCase();
                var textB = b.full_name.trim().toLowerCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              }).map(site => {
                return (
                  <div className="page-sites__item card" key={site.id}>
                    <div className="media media-2x1 gd-primary">
                      <Link to={`/settings/sites/editSite/${site.id}`} className="site-card-btn media-content">
                        <span className="site-card-btn__id badge badge-light text-uppercase">
                          # {site.id}
                        </span>
                        <span className="site-card-btn__text">
                          {site.full_name}
                        </span>
                        <span className="site-card-btn__url">
                          {site.url}
                        </span>
                      </Link>
                      <button className="btn btn-icon btn-white widgets-block__delete-item" onClick={()=>deleteItem(site.id, site.full_name)} data-toggle="modal" data-target="#exampleModal">
                        <X size={15}/>
                      </button>
                    </div>
                  </div>
                )
            })
          }

        </div>

        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Вы уверены, что хотите удалить данный сайт?</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                 <strong>
                  {siteNameToDelete} - #{siteIdToDelete}
                 </strong>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={applyDeleting} data-tag="accept">Удалить</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal">Не удалять</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

function mapStateToProps(state){
  return{
    userSites: state.userInfo.user.sites
  }
}

function mapDispatchToProps(dispatch){
  return {
    getSitesFromApi: sites => dispatch({type: 'ADD_SITES_FROM_API', payload: sites}),
    selectNewSite: site => dispatch({type: 'SELECT_SITE', payload: site}),
    setAlert: (type, text) => dispatch(setAlert(type, text))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sites)