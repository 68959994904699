import React from 'react'
import ExportStatistics from '../InfoTable/ExportStatistics/ExportStatistics'

import './InfoTableMobile.scss'

const InfoTable = ({totalStat, detailedStat, siteId, datesModal, groupBy, advId})=>{

  let formatLongNumber = tableNumber => {
    return new Intl.NumberFormat('ru-RU').format(tableNumber)
  }

  let formatFixed = (tablePercent, fixLength) => {
    if(tablePercent) {
      return tablePercent.toFixed(fixLength)
    } else {
      return '0'
    }
  }

  return (
    <div className="card info-table">
      <table className="table">
        <tbody>
          {/* <tr>
            <th scope="row">Загрузки</th>
            <td>0</td>
          </tr>
          <tr>
            <th scope="row">Видимость</th>
            <td>0 %</td>
          </tr> */}
          <tr>
            <th scope="row">Показы</th>
            <td>{totalStat ? formatLongNumber(totalStat.views) : 0}</td>
          </tr>
          <tr>
            <th scope="row">CTR</th>
            <td>{totalStat ? formatLongNumber(formatFixed(totalStat.ctr, 2)) : 0}</td>
          </tr>
          <tr>
            <th scope="row">Клики</th>
            <td>{totalStat ? formatLongNumber(totalStat.clicks) : 0}</td>
          </tr>
          {/* <tr>
            <th scope="row">CPM</th>
            <td>0</td>
          </tr>
          <tr>
            <th scope="row">Доход</th>
            <td>0 ₽</td>
          </tr> */}
          {/* <tr>
            <th scope="row">Потрачено</th>
            <td>{totalStat ? formatLongNumber(formatFixed(totalStat.spent, 0)) : 0} ₽</td>
          </tr> */}
          {/* <tr>
            <th scope="row">Прибыль</th>
            <td>0 ₽</td>
          </tr> */}
        </tbody>
      </table>

      <ExportStatistics siteId={siteId} advId={advId} groupBy={groupBy} datesModal={datesModal} detailedStat={detailedStat}/>

    </div>
  )
}

export default InfoTable