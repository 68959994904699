import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './AddInternalPromotion.scss'

import { hostDetect } from '../../config/envConfig'

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import CropImage from './CropImage/CropImage.js'

import { useHistory } from "react-router-dom";

import { ArrowLeft } from "react-feather";

import Axios from 'axios';

import { connect } from 'react-redux'

import { setAlert } from '../../redux/actions/alertActions.js'



const AddInternalPromotion = ({setAlert}) => {
	const [submitDisable, setSubmitDisable] = useState(true);

	const [articleLinkValidation, setArticleLinkValidation] = useState({valid: false, class: '', text: ''});
 	const [articleLink, setArticleLink] = useState('');

 	const [articleTitleValidation, setArticleTitleValidation] = useState({valid: false, class: '', text: ''});
 	const [articleTitle, setArticleTitle] = useState('');

	const [articleDescriptionValidation, setArticleDescriptionValidation] = useState({valid: true, class: '', text: ''});
 	const [articleDescription, setArticleDescription] = useState('');

 	const [articleImage, setArticleImage] = useState(null);

	const [siteId, setSiteId] = useState(null)

 	useEffect(()=>{
 		if(articleLinkValidation.valid &&
 			 articleTitleValidation.valid &&
			 articleDescriptionValidation.valid &&
 			 articleImage){

 			setSubmitDisable(false)
 		} else {
 			setSubmitDisable(true)
 		}
 	});

	const site_id = useSelector(state => state.siteInfo.site.id)
	
	useEffect(()=>{
		if(siteId && siteId !== site_id){
			history.push("/contentUp/internalPromotion");
		}
		setSiteId(site_id)
	}, [site_id])


	let history = useHistory();

	const handleBackClick = () => {
		history.push("/contentUp/internalPromotion");
	}

	let handleArticleLinkInput = event => {
		let link = event.target.value,
				linkRegex = /(http(s?)):\/\//i;

		setArticleLink(link)

		if(link.length === 0){
 			setArticleLinkValidation({valid: false, class: '', text: ''})
 		} else if(link.length > 0 && !linkRegex.test(link)){
 			setArticleLinkValidation({valid: false, class:'is-invalid', text: 'Неверный формат записи...'})
 		} else if(link.length > 0 && linkRegex.test(link)){
 			setArticleLinkValidation({valid: true, class: 'is-valid', text: ''})
 		}
	}

	let handleArticleTitleInput = event => {
		let title = event.target.value;

		setArticleTitle(title);

		if(title.length === 0){
 			setArticleTitleValidation({valid: false, class: '', text: ''})
 		} else if(title.length <= 4){
 			setArticleTitleValidation({valid: false, class:'is-invalid', text: 'Слишком короткий заголовок...'})
 		} else if(title.length > 4){
 			setArticleTitleValidation({valid: true, class: 'is-valid', text: ''})
 		}
	}

	const handleArticleDescriptionInput = (event) => {
		let description = event.target.value;

		if(description.length < 250){
			setArticleDescription(description)
			setArticleDescriptionValidation({valid: true, class: 'is-valid', text: ''})
		} else {
			setArticleDescriptionValidation({valid: false, class:'is-invalid', text: 'Слишком длинное описание...'})
		}
	}

	const getCroppedImage = image => {
		setArticleImage(image);
	}

	const handleSubmit = () => {
		let formData = new FormData();
		formData.append('title', articleTitle);
		formData.append('url', articleLink);
		formData.append('description', articleDescription);
		formData.append('image', articleImage);
		formData.append('type', 'internal');

		let apiOptions = {
			withCredentials: true
		}

		Axios.post(`${hostDetect('api')}/pinned/create/?site_id=${siteId}`, formData, apiOptions)
			.then(response => {
				const success = response.data.success;
				if(success){
					const adv_id = response.data.data.id,
								campaign_id = response.data.data.campaign_id;

					if(adv_id && campaign_id){
						// history.push(`/contentUp/internalPromotion/NewArticleSettingsPromotion/${adv_id}`);
						history.push(`/contentUp/internalPromotion`);
					}
				} else {
					const errorMessage = response.data.data.message
					setAlert('error', errorMessage);
				}
			})
			.catch(err => {
				setAlert('error', 'Не получилось отправить запрос!');
			})
	}

	return(
		<div className="add-internal-promotion">
			
			<div className="add-internal-promotion__back-btn">
	    	<button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
	      	<ArrowLeft size={15} style={{marginRight: 10}}/>Назад
	      </button>
	    </div>

	    <PageHeading mainText={"Внутреннее продвижение"} mutedText={"Добавление новой страницы"}/>

	    <div className="row">
	    	<div className="col-md-6">
	    		<div className="card">
	    			<div className="card-header">
	    				Добавить страницу в рекомендации
	    			</div>
	    			<div className="card-body">

	    				<div className="form-group row">
                <label className="col-sm-4 col-form-label">Адрес статьи (URL)</label>
                <div className="col-sm-8">
                  <input type="text" className={"form-control " + articleLinkValidation.class} placeholder="http://" value={articleLink} onChange={handleArticleLinkInput}/>
                  {
 										articleLinkValidation.valid === false ? 
 											<ul className="list-unstyled text-sm mt-1 text-muted filled add-internal-promotion__input-error-text">
 												<li className="parsley-required text-danger">{articleLinkValidation.text}</li>
 											</ul> : ''
 									}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Заголовок статьи</label>
                <div className="col-sm-8">
                  <input type="text" className={"form-control " + articleTitleValidation.class} placeholder="Добавьте заголовок" value={articleTitle} onChange={handleArticleTitleInput} spellCheck/>
                  {
 										articleTitleValidation.valid === false ? 
 											<ul className="list-unstyled text-sm mt-1 text-muted filled add-internal-promotion__input-error-text">
 												<li className="parsley-required text-danger">{articleTitleValidation.text}</li>
 											</ul> : ''
 									}
                </div>
              </div>

							<div className="form-group row">
                <label className="col-sm-4 col-form-label">Описание статьи</label>
                <div className="col-sm-8">
                  <input type="text" className={"form-control " + articleDescriptionValidation.class} placeholder="Добавьте описание статьи (необязательно)" value={articleDescription} onChange={handleArticleDescriptionInput} spellCheck/>
                  {
 										articleDescriptionValidation.valid === false ? 
 											<ul className="list-unstyled text-sm mt-1 text-muted filled add-internal-promotion__input-error-text">
 												<li className="parsley-required text-danger">{articleDescriptionValidation.text}</li>
 											</ul> : ''
 									}
                </div>
              </div>

              <CropImage sendCroppedImage={getCroppedImage}/>

              <div className="add-internal-promotion__save-btn">
 								<button className="btn w-sm mb-1 btn-success" disabled={submitDisable} onClick={handleSubmit}>Сохранить</button>
 							</div>

	    			</div>
	    		</div>
	    	</div>
	    </div>

	  </div>	
	)
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(AddInternalPromotion)