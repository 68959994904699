import React, { useState, useEffect } from 'react'

// daterange picker
import './DateRange.scss'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css';

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')


const DateRange = ({setStartDate, setEndDate}) => {
  const [state, setState] = useState({
    start: moment().startOf('month'),
    end: moment(),
  });
  const { start, end } = state;

  // pass start|end dates to parent component
  useEffect(()=>{
    if(start && end){
      handleCallback(start, end);
    }
  }, [])


  const handleCallback = (start, end) => {

    let startFormatted = start.format('YYYY-MM-DD'),
        endFormatted = end.format('YYYY-MM-DD');

    setState({ start, end });
    
    if(startFormatted && endFormatted){
      setStartDate(startFormatted);
      setEndDate(endFormatted)
    }
  };

  const label = start.format('DD MMMM YYYY') + ' - ' + end.format('DD MMMM YYYY');

  return(
    <div className="date-range">
      <DateRangePicker
        initialSettings={
          { startDate: start,
            endDate: end,
            ranges: {
              'Сегодня': [moment(), moment()],
              'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
              'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
              'Последние 6 месяцев': [moment().subtract(6, 'month'), moment()],
              'Этот месяц': [moment().startOf('month'), moment()],
              'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
              'customRangeLabel': 'Выбор даты',
              "daysOfWeek": [
                "Вс",
                "Пн",
                "Вт",
                "Ср",
                "Чт",
                "Пт",
                "Сб"
              ],
              "monthNames": [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь"
              ],
              "firstDay": 1,
              "applyLabel": "Применить",
              "cancelLabel": "Отмена",
            }
          }
        } onCallback={handleCallback}>
        {/* <button className="btn btn-primary text-capitalize">
          {label}
        </button> */}
        <input type="text" className="form-control" />
      </DateRangePicker>
    </div>
  )
}

export default DateRange