 import React from 'react'

 import './FeedStyle.scss'

 import { hostDetect } from '../../../config/envConfig';

 import { Draggable } from 'react-beautiful-dnd'

 import { MoreVertical } from "react-feather";

 import { Link } from "react-router-dom";

 import axios from 'axios'

 import { connect } from 'react-redux'

 import { setAlert } from '../../../redux/actions/alertActions';


 const FeedCard = ({id, text, type, index, siteId, widgetId, removeCard, setAlert})=> {

 	let cardId = id.toString();

 	const handleCardDelete = id => {
 		axios.get(`${hostDetect('api')}/lenta/remove-card?widget_id=${widgetId}&id=${id}`, {
      withCredentials: true
    })
    .then(res => {
      if(res.data.success){
 				removeCard(id);
      } else {
				const errorMessage = res.data.data.message
				setAlert('error', errorMessage);
			}
    })
    .catch(err => {
			setAlert('error', 'Не получилось отправить запрос!');
		})  
 	}

 	return(
	 	<div>
	 		<Draggable draggableId={cardId} index={index}>
	 		{ provided => (
	 			<div
	 				{...provided.draggableProps}
	 				{...provided.dragHandleProps}
	 				ref={provided.innerRef}
	 			>	
	 				<div className="feed-card">
	 					<div className="feed-card__wrap">
		 					<div className={"feed-card__label " + (type === 'SVK_CustomHTML' ? 'customHTML' : '')}>
		 						{type}
		 					</div>
		 					<div className="feed-card__name">
		 						{text}
		 					</div>

		 					<div className="btn-group dropright">
                <button 
                	className="btn btn-white dropdown-toggle" 
                	data-toggle="dropdown" 
                	aria-expanded="false">
                	<MoreVertical size={15}/>
                </button>

                <div className="dropdown-menu bg-dark" role="menu" x-placement="right-start">
                	<h6 className="dropdown-header">
                		# {id}
                	</h6>
                  <Link to={`/settings/widgets/cardEdit/${siteId}/${widgetId}/${type}/${id}`} className="dropdown-item">
                      Редактировать
                  </Link>
                  <button className="dropdown-item" onClick={()=> handleCardDelete(id)}>
                      Удалить 
                  </button>
                </div>
            	</div>

	 					</div>
	 				</div>

	 			</div>
	 			)
	 			
	 		}
	 		</Draggable>
	 	</div>
 	)
 }


 const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(FeedCard)