import React, { useState, useEffect } from 'react'

// redux
import { connect } from 'react-redux'

import {Calendar} from "react-feather";

// daterange picker
import './DateRange.scss'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css';

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')



const DateRange = ({dates, setDates}) => {
  
  const { start, end } = dates;

  const handleCallback = (start, end) => {
    setDates({ start, end });
  };


  return(
    <div className="date-range">
      {/* <p className="nav-header hidden-folded" style={{fontWeight: 500}}>
        Выберите период :
      </p> */}
      <div className="date-range__input-wrap">
        <Calendar color={'#a6a6a6'} size={13} />
        <DateRangePicker
          initialSettings={
            { startDate: start,
              endDate: end,
              ranges: {
                'Сегодня': [moment(), moment()],
                'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
                'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
                'Последние 6 месяцев': [moment().subtract(6, 'month'), moment()],
                'Этот месяц': [moment().startOf('month'), moment()],
                'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
              },
              locale: {
                'customRangeLabel': 'Выбор даты',
                "daysOfWeek": [
                  "Вс",
                  "Пн",
                  "Вт",
                  "Ср",
                  "Чт",
                  "Пт",
                  "Сб"
                ],
                "monthNames": [
                  "Январь",
                  "Февраль",
                  "Март",
                  "Апрель",
                  "Май",
                  "Июнь",
                  "Июль",
                  "Август",
                  "Сентябрь",
                  "Октябрь",
                  "Ноябрь",
                  "Декабрь"
                ],
                "firstDay": 1,
                "applyLabel": "Применить",
                "cancelLabel": "Отмена",
              }
            }
          } onCallback={handleCallback}>
          <input type="text" className="date-range__income-main form-control" />
        </DateRangePicker>
      </div>

    </div>
  )
}

export default DateRange