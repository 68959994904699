import React, {useState, useEffect} from 'react'

import './TeaserStatistics.scss'

import Axios from 'axios';

import { hostDetect } from '../../../config/envConfig';

import { connect } from 'react-redux'

import { setAlert } from '../../../redux/actions/alertActions';

import { useSelector } from 'react-redux';

import Pagination_api from './Pagination_api/Pagination_api';

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')


const TeaserStatistics = ({setAlert}) => {

  const site_id = useSelector(state => state.siteInfo.site.id);

  const [taskList, setTaskList] = useState([]);
  const [taskLength, setTaskLength] = useState(0);

  const [apiLoading, setApiLoading] = useState(false);

  // pagination
  const [paginationCurrent, setPaginationCurrent] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(10);


  useEffect(()=>{
    getTeaserApi(0, paginationLimit)
  },[site_id])

  const getTeaserApi = (start, limit) => {

    setApiLoading(true)

    Axios.get(`${hostDetect('api')}/exchange/get-tasks?site_id=${site_id}&start=${start}&limit=${limit}`, {withCredentials: true})
		.then(response => {
			const success = response.data.success;

      setApiLoading(false)

			if(success){
        setTaskLength(response.data.data.count)
        setTaskList(response.data.data.tasks)
			} else {
				let errMessage = response.data.data.message;
        setAlert('error', errMessage);
			}
		})
		.catch(err => {
      setApiLoading(false)
			setAlert('error', 'Не получилось отправить запрос!');
		})
  }

  // Date format
	const formatDate = (date) => {
		let formattedDate = moment(date).format('DD.MM.YYYY  HH:mm:ss')
		return formattedDate
	}

  const handlePageClick = (page) => {
    let newStart = (page - 1) * paginationLimit
    setPaginationCurrent(page)
    getTeaserApi(newStart, paginationLimit)
  }

  const handlePaginationSelect = (value) => {
    setPaginationLimit(value)
    setPaginationCurrent(1)
    getTeaserApi(0, value)
  }

  const checkStatus = (task_status, premoderation_status) => {
    if(task_status == 'new' || task_status == 'assigned'){
      return (
        <span className="badge badge-warning text-uppercase">На модерации</span>
      )
    }
    if(task_status == 'completed'){
      if(premoderation_status == 'completed'){
        return (
          <span className="badge badge-success text-uppercase">Прошел модерацию</span>
        )
      }
      if(premoderation_status == 'rejected'){
        return (
          <span className="badge badge-danger text-uppercase">Не прошёл модерацию</span>
        )
      }
    }
    if(task_status == 'canceled'){
      return (
        <span className="badge badge-danger text-uppercase">Не прошёл модерацию</span>
      )
    }
  }

  return (
    <div className="teaser-statistics">
      <div className="teaser-statistics__list">
        <table className="table table-theme table-row v-middle">
          <thead>
            <tr>
              <th className="text-muted">URL</th>
              <th className="text-muted" style={{width: 150, minWidth: 150}}>Статус</th>
              <th className="text-muted" style={{width: 200, minWidth: 200}}>Дата последнего изменения</th>
            </tr>
          </thead>
          <tbody>

            {
              taskList.length ? (
                taskList.map((task, i) => {
                  return (
                    <tr className=" v-middle" key={task.task_id}>
                      <td className="flex">
                        <a href={`${task.task_url}`} target="_blank" className="item-amount text-sm " title="Перейти по адресу">{task.task_url}</a>
                      </td>
                      <td>
                        <span className="item-amount text-sm ">
                          {
                            checkStatus(task.task_status, task.premoderation_status)
                          }
                        </span>
                      </td>
                      <td>
                        <span className="item-amount text-sm ">{ formatDate(task.task_updated) }</span>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr className=" v-middle">
                  <td colSpan="3">Статьи отсутствуют</td>
                </tr>
              )
            }

            
          </tbody>
        </table>
              
      </div>

      {
        taskList.length ? (
          <div className="teaser-statistics__controls">

            <div className="teaser-statistics__controls-pagination">
              <Pagination_api 
                currentPage={paginationCurrent}
                totalCount={taskLength}
                pageSize={paginationLimit}
                onPageChange={page => handlePageClick(page)} />
            </div>
            
            <div className="teaser-statistics__controls-limit">
              <select className="form-control" value={paginationLimit} onChange={e => handlePaginationSelect(e.target.value)}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
        ) : null
      }

      {
        apiLoading ? <div className="loader-line"></div> : null
      }

    </div>
  )
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(TeaserStatistics)