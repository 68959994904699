export const hostDetect = (linkType) => {
  let host = window.location.host;

  // check website host and call to .env api
  switch (host) {
    case 'publisher-new.svk-native.ru:12345': {
      return linkType === 'api' ? process.env.REACT_APP_URL_API_STAGE : process.env.REACT_APP_SITE_URL_STAGE
    }
    case 'publisher-new.svk-native.ru': {
      return linkType === 'api' ? process.env.REACT_APP_URL_API : process.env.REACT_APP_SITE_URL
    }
    case 'publisher.sparrow.ru:12345': {
      return linkType === 'api' ? process.env.REACT_APP_URL_API_SPARROW_STAGE : process.env.REACT_APP_SITE_URL_SPARROW_STAGE
    }
    case 'publisher.sparrow.ru': {
      return linkType === 'api' ? process.env.REACT_APP_URL_API_SPARROW : process.env.REACT_APP_SITE_URL_SPARROW
    }
    case 'localhost:3000': {
      return linkType === 'api' ? process.env.REACT_APP_URL_API : process.env.REACT_APP_SITE_URL
    }
    default: {
      return linkType === 'api' ? process.env.REACT_APP_URL_API : process.env.REACT_APP_SITE_URL
    }
  }
}