import React, { useState, useEffect } from 'react'

import { useHistory } from "react-router-dom";

import { ArrowLeft } from 'react-feather';

import axios from 'axios';

import { connect } from 'react-redux'

import { hostDetect } from '../../config/envConfig';

import { useParams } from "react-router-dom";

import './EditSite.scss'

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'

import { setAlert } from '../../redux/actions/alertActions';

const EditSite = ({ componentUpdate, setAlert, siteIDRedux }) => {
  const [apiLoading, setApiLoading] = useState(false)

	const [submitDisable, setSubmitDisable] = useState(true);

  const [siteURLValidation, setSiteURLValidation] = useState({valid: false, class: '', text: ''});
  const [siteURL, setSiteURL] = useState('');

  const [siteNameValidation, setSiteNameValidation] = useState({valid: false, class: '', text: ''});
  const [siteName, setSiteName] = useState('');

  const [siteMirror, setSiteMirror] = useState('');

  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailed, setAlertFailed] = useState(false);

  let { siteId } = useParams()

  useEffect(()=>{
    if(siteURLValidation.valid && siteNameValidation.valid){
      setSubmitDisable(false)
    } else {
      setSubmitDisable(true)
    }
  });


  useEffect(()=>{
    setApiLoading(true)

    axios.get(`${hostDetect('api')}/site/get?site_id=${siteId}`, {
      withCredentials: true
    })
    .then(res => {

      setApiLoading(false)

      if(res.data.success){
        initSiteWithApiCall(res.data.data);
      } else {
        let errMessage = res.data.data.message;
        setAlert('error', errMessage);
      }
    })
    .catch(err => {
      setApiLoading(false)
      setAlert('error', 'Не получилось отправить запрос!');
    })
    
  }, [])

  useEffect(() => {
    if(siteIDRedux && siteIDRedux !== siteId){
      history.push('/');
    }
  }, [siteIDRedux])

	let history = useHistory();
	const handleBackClick = ()=>{
  	// history.push("/settings/sites");
    history.goBack()
	}

	let handleSiteURL = event => {
    let link = event.target.value,
        linkRegex = /(http(s?)):\/\//i;

    setSiteURL(link);

    if(link.length === 0){
      setSiteURLValidation({valid: false, class: '', text: ''})
    } else if(link.length > 0 && !linkRegex.test(link)){
      setSiteURLValidation({valid: false, class:'is-invalid', text: 'Неверный формат записи...'})
    } else if(link.length > 0 && linkRegex.test(link)){
      setSiteURLValidation({valid: true, class: 'is-valid', text: ''})
    }
  }

  const initSiteWithApiCall = siteInfo => {
    if(siteInfo.url){
      setSiteURL(siteInfo.url);
      setSiteURLValidation({valid: true, class: 'is-valid', text: ''})
    }

    if(siteInfo.name){
      setSiteName(siteInfo.name);
      setSiteNameValidation({valid: true, class: 'is-valid', text: ''})
    }
  }

  const handleSiteName = event => {
    let name = event.target.value;
    setSiteName(name);

    if(name.length === 0){
      setSiteNameValidation({valid: false, class: '', text: ''})
    } else if(name.length < 3){
      setSiteNameValidation({valid: false, class:'is-invalid', text: 'Слишком короткое название...'})
    } else {
      setSiteNameValidation({valid: true, class: 'is-valid', text: ''})
    }
  }

  const handleSiteMirror = event => {
  	setSiteMirror(event.target.value)
  }

  const onSiteSave = () => {
    
    const formData = new FormData();
    formData.append("url", siteURL);
    formData.append("name", siteName);

    axios.post(`${hostDetect('api')}/site/save?site_id=${siteId}`, formData, { withCredentials: true })
      .then(res => {
        if(res.data.success){

          // update localstorage by new site NAME and URL
          let previousEnteredSite = JSON.parse(localStorage.getItem('current_site_information'));
          if(previousEnteredSite && siteId == previousEnteredSite.id){
            previousEnteredSite.url = siteURL
            previousEnteredSite.full_name = siteName
            localStorage.setItem('current_site_information', JSON.stringify(previousEnteredSite));
          }

          componentUpdate();
          setAlertSuccess(true);
          setTimeout(()=> setAlertSuccess(false), 2000)
        } else {
          setAlertFailed(true);
          setTimeout(()=> setAlertFailed(false), 2000)

          let errMessage = res.data.data.message;
          setAlert('error', errMessage);
        }
      })
      .catch(err => {
        setAlert('error', 'Не получилось отправить запрос!');
      })
  }

	return(
		<div className="edit-site">

			<MobileHeader/>

			<div className="create-site__back-btn">
        <button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
          <ArrowLeft size={15} style={{marginRight: 10}}/>Назад
        </button>
      </div>

      <div className="edit-site__form-place">
      	<div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <strong>Редактирование сайта</strong>
                {
                  apiLoading ? 
                    (
                      <div className="spinner-border spinner-border-sm text-primary ml-2" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null
                }
              </div>
              <div className="card-body">

              	<div className="form-group row">
                  <label className="col-sm-4 col-form-label">Адрес сайта (URL)</label>
                  <div className="col-sm-8">
                    <input type="text" className={"form-control " + siteURLValidation.class} placeholder="http://" value={siteURL} onChange={handleSiteURL}/>
                    {
                      siteURLValidation.valid === false ? 
                        <ul className="list-unstyled text-sm mt-1 text-muted filled create-site__input-error-text">
                          <li className="parsley-required">{siteURLValidation.text}</li>
                        </ul> : ''
                    }
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">Название</label>
                  <div className="col-sm-8">
                    <input type="text" className={"form-control " + siteNameValidation.class} value={siteName} onChange={handleSiteName} placeholder="Введите название"/>
                    {
                      siteNameValidation.valid === false ? 
                        <ul className="list-unstyled text-sm mt-1 text-muted filled create-site__input-error-text">
                          <li className="parsley-required">{siteNameValidation.text}</li>
                        </ul> : ''
                    }
                  </div>
                </div>

                {/*<div className="form-group row">
                  <label className="col-sm-4 col-form-label">Зеркало</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" value={siteMirror} onChange={handleSiteMirror} placeholder="http://"/>
                  </div>
                </div>*/}

                <div className="d-flex justify-content-between align-items-center edit-site__save-btn-alert">
                  <button className="btn w-sm btn-primary" onClick={onSiteSave} disabled={submitDisable}>Сохранить</button>

                  {
                    alertSuccess == true ? (
                    <div className="alert alert-success" role="alert">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        className="feather feather-check">
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      <span className="mx-2">Изменения сохранены!</span>
                    </div>
                    ) : ''
                  }

                  {
                    alertFailed == true ? (
                    <div className="alert alert-warning" role="alert">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        className="feather feather-alert-circle">
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12" y2="16"></line>
                      </svg>
                      <span className="mx-2">Произошла ошибка!</span>
                    </div>
                    ) : ''
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

		</div>
	)
}


function mapStateToProps(state){
  return{
    siteIDRedux: state.siteInfo.site.id
  }
}

const mapDispatchToProps = {
  setAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSite)