import React from 'react'
import './ContentUp.scss'

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader.js'

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import { Link } from "react-router-dom";

const ContentUp = () => {
	return (
		<div className="content-up">

			<MobileHeader/>
			
			<PageHeading mainText={"Продвижение контента"} mutedText={"Выберите подходящий для вас вид продвижения"}/>

			{/* <div className="alert alert-warning" role="alert">
        <svg 
        	xmlns="http://www.w3.org/2000/svg" 
        	width="16" 
        	height="16" 
        	viewBox="0 0 24 24" 
        	fill="none" 
        	stroke="currentColor" 
        	strokeWidth="2" 
        	strokeLinecap="round" 
        	strokeLinejoin="round" 
        	className="feather feather-alert-circle">
        		<circle cx="12" cy="12" r="10"></circle>
        		<line x1="12" y1="8" x2="12" y2="12"></line>
        		<line x1="12" y1="16" x2="12" y2="16"></line>
        </svg>
        <span className="mx-2">Инструмент находится в разработке и скоро будет доступен</span>
      </div> */}

			<div className="content-up__row-wrap">
				<div className="row">
					<div className="col-md-6">
						<div className="card">
							<div className="card-header text-center">
								Внутреннее продвижение
							</div>
							<div className="card-body">
								<p>
									С помощью этого инструмента вы можете закрепить 
									в рекомендательных виджетах конкретные страницы 
									вашего сайта для привлечения на них трафика, 
									увеличения CTR, глубины просмотра и других маркетинговых показателей
								</p>
								<div className="content-up__btns text-center">
									<Link to="/contentUp/internalPromotion" className="btn w-md mb-1 btn-primary">Далее</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="card">
							<div className="card-header text-center">
								Внешнее продвижение
							</div>
							<div className="card-body">
								<p>
									С помощью этого инструмента вы можете продвигать 
									материалы вашего сайта на сайтах внутри нашей сети по системе взаимозачета
								</p>
								<div className="content-up__btns text-center">
									<Link to="#advertiser" className="btn w-md mb-1 btn-primary disabled">Продвигать статью на сайтах</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContentUp