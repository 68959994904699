const initialStateUserInfo = {
  user: {
    id: '',
    email: '',
    name: '',
    role: '',
    sites: [],
    loggedIn: false
  }
}

export default function userInfo(state = initialStateUserInfo, action){
  switch (action.type){
    case 'ADD_SITES_FROM_API': {
      const stateUpdated = {...state}
      stateUpdated.user.sites = action.payload
      return stateUpdated
    }
    case 'ADD_USER_INFO': {
      const stateUpdated = { ...state };
      stateUpdated.user.name = action.payload.first_name;
      stateUpdated.user.email = action.payload.email;
      stateUpdated.user.role = action.payload.role;
      stateUpdated.user.id = action.payload.user_id;
      return stateUpdated
    }
    case 'LOGIN_USER': {
      const stateUpdated = { ...state };
      stateUpdated.user.loggedIn = action.payload;
      return stateUpdated
    }
  }
  return state
}