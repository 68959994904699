import React, {useEffect, useState} from 'react'

import './Dashboard.scss'

import axios from 'axios'

import { connect } from 'react-redux'

import { hostDetect } from '../../config/envConfig'

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'
import PageHeading from '../../components/commonComponents/pageHeading/pageHeading'
import DateRange from './DateRange/DateRange'

import Pagination from './Pagination/Pagination'

import { setAlert } from '../../redux/actions/alertActions'

import {Settings} from "react-feather";

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')



const Dashboard = ({setAlert}) => {

  const [apiLoading, setApiLoading] = useState(false);

  const [userId, setUserId] = useState('')

  const [dates, setDates] = useState({
    start: moment().startOf('month'),
    end: moment(),
  });
  let startFormatted = dates.start.format('YYYY-MM-DD'),
      endFormatted = dates.end.format('YYYY-MM-DD');

  const [mainStat, setMainStat] = useState({total_exchange_clicks: 0, total_returned: 0, total_ratio: 0, vCPM: 0, vCTR: 0, income: 0});
  const [tableStat, setTableStat] = useState([]);

  const [searchItem, setSearchItem] = useState('');

  useEffect(()=>{
    setApiLoading(true)

    getMainStat();
    getTableStat();

    getUserInfo();
  },[]);

  const getMainStat = () => {

    axios.get(`${hostDetect('api')}/publisher/get-total-stats?date_from=${startFormatted}&date_to=${endFormatted}`, {
      withCredentials: true
    }).then(res => {
      let success = res.data.success;

      setApiLoading(false)

      if(success){
        let {total_exchange_clicks, total_returned, total_ratio, vCPM, vCTR, income} = res.data.data;
        setMainStat({total_exchange_clicks, total_returned, total_ratio, vCPM, vCTR, income})
      } else {
        let errMessage = res.data.data.message;
        setAlert('error', errMessage);
      }
      
    }).catch(err=>{
      setApiLoading(false)
      setAlert('error', 'Не получилось отправить запрос!');
    })

  }

  const getTableStat = () => {

    axios.get(`${hostDetect('api')}/publisher/get-total-stats?date_from=${startFormatted}&date_to=${endFormatted}&group_by_sites=1`, {
      withCredentials: true
    }).then(res => {
      let success = res.data.success;

      setApiLoading(false)

      if(success){
        setApiLoading(false)
        setTableStat(res.data.data)
      } else {
        let errMessage = res.data.data.message;
        setAlert('error', errMessage);
      }
      
    }).catch(err=>{
      setApiLoading(false)
      setAlert('error', 'Не получилось отправить запрос!');
    })

  }

  const getUserInfo = () => {
    axios.get(`${hostDetect('api')}/users/get`, {
      withCredentials: true
    })
    .then(response => {
      const success = response.data.success;

      if(success){
        const {user_id, metaList} = response.data.data;

        setUserId(user_id)

        if(metaList.length){
          metaList.forEach(metaItem => {
            if(metaItem.name === 'dashboard_settings' && metaItem.value){
              let {total_exchange_clicks, total_returned, total_ratio, vCTR, income, vCPM} = JSON.parse(metaItem.value)
              setShowTotalClicks(total_exchange_clicks)
              setShowTotalReturned(total_returned)
              setShowTotalRatio(total_ratio)
              setShowVSTR(vCTR)
              setShowIncome(income)
              setShowVCPM(vCPM)
            }
          })
        }

      } else {
        let errMessage = response.data.data.message;
        setAlert('error', errMessage);
      }
      
    })
    .catch(error => {
      setAlert('error', 'Не получилось отправить запрос!');
    })
  }

  const formatNumber = (value) => {
    let formatter = new Intl.NumberFormat("ru");
    return formatter.format(value);
  }

  const roundNumber = (value) => {
    if(value){
      return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    } else {
      return 0;
    }
    
  }

  const handleSubmit = () => {
    setApiLoading(true)

    getMainStat();
    getTableStat();

    // reset pagination
    setCurrentPage(1)
  }

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(10)
  const [paginationStart, setPaginationStart] = useState(0)
  const [paginationEnd, setPaginationEnd] = useState(0)

  let indexFirst = num => {
    setPaginationStart(num)
  }

  let indexLast = num => {
    setPaginationEnd(num)
  }

  // per page
  let changePerPage = e =>{
    setPostsPerPage(e.target.value)
    setCurrentPage(1)
  }

  let listForPagination = tableStat.length ? tableStat.filter(itemToFilter => itemToFilter.site_id.includes(searchItem) || itemToFilter.name.toLowerCase().includes(searchItem.toLowerCase())) : [];


  // settings modal
  const [showModalSettings, setShowModalSettings] = useState(false)

  const [showTotalClicks, setShowTotalClicks] = useState(true)
  const [showTotalReturned, setShowTotalReturned] = useState(true)
  const [showTotalRatio, setShowTotalRatio] = useState(true)
  const [showVSTR, setShowVSTR] = useState(true)
  const [showIncome, setShowIncome] = useState(true)
  const [showVSPM, setShowVCPM] = useState(true)

  const toggleModalSettings = () => {
    let showSetting = showModalSettings;
    setShowModalSettings(!showSetting);
  }

  const handleSettingsCheckbox = (settingName, checked) => {

    let dashboardSettings = {
      total_exchange_clicks: showTotalClicks,
      total_returned: showTotalReturned,
      total_ratio: showTotalRatio,
      vCTR: showVSTR,
      income: showIncome,
      vCPM: showVSPM
    }

    switch (settingName) {
      case 'showTotalClicks': {
        setShowTotalClicks(checked)
        dashboardSettings.total_exchange_clicks = checked
        break;
      }
      case 'showTotalReturned': {
        setShowTotalReturned(checked)
        dashboardSettings.total_returned = checked
        break
      }
      case 'showTotalRatio': {
        setShowTotalRatio(checked)
        dashboardSettings.total_ratio = checked
        break
      }
      case 'showVSTR': {
        setShowVSTR(checked)
        dashboardSettings.vCTR = checked
        break
      }
      case 'showIncome': {
        setShowIncome(checked)
        dashboardSettings.income = checked
        break
      }
      case 'showVSPM': {
        setShowVCPM(checked)
        dashboardSettings.vCPM = checked
        break
      }
      default:
        break;
    }

    
    let jsonSettings = JSON.stringify(dashboardSettings);

    let formDataMeta = new FormData();
					formDataMeta.append('name', 'dashboard_settings')
					formDataMeta.append('value', jsonSettings)

    if(userId){
      axios.post(`${hostDetect('api')}/users/meta-save?user_id=${userId}`, formDataMeta, {withCredentials: true}).then(res => {
        let successMeta = res.data.success;
  
        if(successMeta){
          setAlert('success', 'Изменение принято!');
        } else {
          let errMessage = res.data.data.message;
          setAlert('error', errMessage);
        }
      }).catch(err=> {
        setAlert('error', 'Не получилось отправить запрос!');
      })
    }
    

  }

  return (
    <div className="dashboard">
      <div className="dashboard__wrap">
        <MobileHeader/>
        <PageHeading mainText={"Показатели"} />

        {
          apiLoading ? 
            <div className="loader__small">
                Загрузка данных...
            </div> : null
        }

        <div className="dashboard__controls">
          <DateRange handleSubmit={handleSubmit} dates={dates} setDates={setDates} />
          <button className="btn w-sm btn-primary dashboard__submit-btn" onClick={() => handleSubmit()}>Принять</button>
          <div className="dropdown dashboard__settings">
            <button className="btn btn-white dropdown-toggle" onClick={() => toggleModalSettings()}>
              <Settings color={"#999"} size={16} />
            </button>

            {
              showModalSettings ? (
                <div className="dashboard__settings-dropdown dropdown-menu d-inline" x-placement="bottom-end">

                  <div className="dropdown-item">
                    <label className="ui-switch mt-1 mr-2">
                      <input type="checkbox" checked={showTotalClicks} onChange={(e) => handleSettingsCheckbox('showTotalClicks', e.target.checked)} />
                      <i></i>
                    </label>
                    Трафика отдали
                  </div>

                  <div className="dropdown-item">
                    <label className="ui-switch mt-1 mr-2">
                      <input type="checkbox" checked={showTotalReturned} onChange={(e) => handleSettingsCheckbox('showTotalReturned', e.target.checked)} />
                      <i></i>
                    </label>
                    Трафик получили
                  </div>

                  <div className="dropdown-item">
                    <label className="ui-switch mt-1 mr-2">
                      <input type="checkbox" checked={showTotalRatio} onChange={(e) => handleSettingsCheckbox('showTotalRatio', e.target.checked)} />
                      <i></i>
                    </label>
                    Коэффициент
                  </div>

                  <div className="dropdown-item">
                    <label className="ui-switch mt-1 mr-2">
                      <input type="checkbox" checked={showVSTR} onChange={(e) => handleSettingsCheckbox('showVSTR', e.target.checked)} />
                      <i></i>
                    </label>
                    vCTR
                  </div>

                  <div className="dropdown-item">
                    <label className="ui-switch mt-1 mr-2">
                      <input type="checkbox" checked={showIncome} onChange={(e) => handleSettingsCheckbox('showIncome', e.target.checked)} />
                      <i></i>
                    </label>
                    Доход
                  </div>

                  <div className="dropdown-item">
                    <label className="ui-switch mt-1 mr-2">
                      <input type="checkbox" checked={showVSPM} onChange={(e) => handleSettingsCheckbox('showVSPM', e.target.checked)} />
                      <i></i>
                    </label>
                    vCPM
                  </div>

                </div>
              ) : null
            }
            
          </div>
        </div>
        
        
        <div className="dashboard__plates" style={{marginBottom: 0}}>

            {
              showTotalClicks ? (
                <div className="dashboard__plate-item card">
                  <div className="card-body">
                    <div className="text-highlight">
                      Трафика отдали
                    </div>
                    <strong>
                      {
                        formatNumber(mainStat.total_exchange_clicks)
                      }
                    </strong>
                    
                    <span className="plate-item-icon">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6UlEQVR4nO2ZSWsUQRTHx6UJWTzpUeNFsug3iMazCzmYzNXtqp4kjowo5KiYeIoRbwqZeDBqFhFPQoxi0G8QUfBLuBz0Jw+q8Vnprq50V09y6D8MdKrf+8/791S9pVOrVahQoUKF7QTgIHAauAJcNx+5PgX01rYjgCPAFPCVbHwB7gKD2yHwAWAR+MPmIT4vgP6tCHwncBP4lRDYd2AFmAXumc+sWfuRYP8TaApnu4LvBl4lBLIAjMr9DN8xYCnBf9nlGzL499YXfwCGcnAdBdYsrneliTDbxn7y08DuApwRMJPwS4TfTmbPa1wNyD1ucTdDcetsow/stIfPLuAicEGuPewfWAe7L6SARWvPO7eN3Adayqfl4RMBH5XPs5BFSuf5IY9AnrIRshZl+A4r+99Bih0wqUgXMmw7UlJkDLnXkcGxrOzvhBCg24MzDrtO4DXZEJtOB8+Ysv1cNPheq8J2O+rDGyvQbynXGNs0rh6rYu8vIkC6yhgrDrv7VoC3gAn190RCGk7NZMBbZXeyiABpg2O0HHaPld01s/afgIR8/8jBN6fsLhURIE1WjEmH3T7z1EfU2gYBZn3E2O71TBz5ixpww0dAim+iAE/fqVACLvtsoRIEPAm1hbwOcQkCVkMdYp1GJbX1lC0A2GN6oeJp1BDKDBtjtA0C6spvPXfgilAG8BhLbRDwUvndzh24IjxsNXPHyhIAHLeauYHCAgyxvD2IsZbVVRqfc8rnrId9BHxSPvNBgjfk/dbBmvEcQWWYOe8zIgIPrYRxKJiAhKosGA/I3bC4G6G47Seqe3XMGBgV4IysJx+/ntkRNvp/X9hlXn1oyBg4nINLDqze85gutKuU4K3e3/4l4mmr7ip2ptevW6lSP/lyg7e2U9M62PoArppBPn61OGfW0uwbpW2bDCF9wHOTszcL8ZkPnm1yChmUAVxmWI/A16XCBitSoQEckA5S2mD1Dw65PlG4MatQoUKFCrXA+AsNa4tEWIA4nQAAAABJRU5ErkJggg==" />
                    </span>

                  </div>
                </div>
              ) : null
            }
          
            {
              showTotalReturned ? (
                <div className="dashboard__plate-item card">
                  <div className="card-body">
                    <div className="text-highlight">
                      Трафик получили
                    </div>
                    <strong>
                      {
                        formatNumber(mainStat.total_returned)
                      }
                    </strong>

                    <span className="plate-item-icon">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6UlEQVR4nO2Zy2oUURCGW80gubjLUuNGctE3UOPaCygms1V0q64kjoxEyVLJxE1CxG1I4sKoSRRxJcQoBn2DiIIv4WWhnxRWx+LQ09cz4yz6h4amp/6/q7rrVNXpCYISJUqUKNFJAA4CZ4BrwE095Pw0MBB0IoAjwAzwhWR8BqaBkU5wfBhYA36THcJ5Bgz9D8d3A5PAzwjHvgEbwCJwX49FvfY9wv4HUBfNdjnfC7yMcGQVGJPfE7jjwHoE/3kc16fz75wbvweO5tA6Bmw5Wm9bFoSmjfvk54CuApoVYD7iTfhPJ815i+setScc7bovbVtt7IKdS/nGLuuR+ESBB87CHvQZwJqT84lpA1w0nAsp0+mD4Tzx2aRsnU+1YIEpw5lKyRk1nF9emh3QMKKrGXiZAxDoIg5xLygKZzw434YAxg3vU27HVWzA6bC9bQigz+nY+4sEIFNliI2M3FwBCIA3hnsqyAsdg0MstTGAZcO9ktlxI3TLCE23MYCGl6amU2KIRoxdP3AHOJsUAHBObftj9GZ8BXA1TQoBC8ZuolkAwA1zbSFG75GvFEq1iHWos5h0AwBuOzazMXqbvhaxLaNS2vpixuzXjoNfm5yjtpElGdins1DxMqqCsocNMRZj1w28Ihli0x2jUzW224WcV0HZgIdYT7Dd22S3tcMXmwSNF8b+ro8ADjvD3PEUU+XjCOflWiWBe8IZ5oYLB6DC8vUgxFYKR7qkahnOUtIIzt/APxrOihfnVXzIWVjzKTh7dDNzSc5T2D90CsYhbwFENLWdeu9Ju+Zo13xpu1tEO6uj28BKAc2K8+TDzzO7/Hr/74Y9+unDQraBozm0ZMHanEen0J6WOO80LfdNhCWy2qzZmVm/6pRK++Rb67yTTnVnYdsFuKmVJ/y0uKzXmtnXWpY2CYEMAk+1ZmeFcFa8V5ucgYzIBlz2sCkc35YO661J+QZwQCZIGYPNHxxyfrLwYFaiRIkSJQLP+AOKq4tNiRLH0AAAAABJRU5ErkJggg==" />
                    </span>

                  </div>
                </div>
              ) : null
            }
          
            {
              showTotalRatio ? (
                <div className="dashboard__plate-item card">
                  <div className="card-body">
                    <div className="text-highlight">
                      Коэффициент
                    </div>
                    <strong>
                      {
                        roundNumber(mainStat.total_ratio)
                      }
                    </strong>

                    <span className="plate-item-icon plate-item-icon__coeff">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0ElEQVR4nO3YUQqCQBDG8XmpO5Qd0KjTZVeopNLDaO//WNxABMmedrTvB4IwL7OzwyyMmYjIogBr4AiUwCt+N+AQYuYZsAVqxlXAxhxXvua7J7Ayb+jaZqq9eUPX832n2FIZUAxiV/MGaAdJZr3YbhBrzBugmfsBykGSRWyfkPx5Di10YLrcnI7RakLyd5djNAiPVJzzYx5uH7KPUN0w54FLnExt/M/dVl5kyUjMdIDE7O9vQER+o71QKtoLpaa9UGraC6WmvZAH2guJiNjcvAHoosIo0M8iXgAAAABJRU5ErkJggg==" />
                    </span>

                  </div>
                </div>
              ) : null
            }
          
        </div>

        <div className="dashboard__plates">

            {
              showVSTR ? (
                <div className="dashboard__plate-item card">
                  <div className="card-body">
                    <div className="text-highlight">
                      vCTR
                    </div>
                    <strong>
                      {
                        roundNumber(mainStat.vCTR)
                      }
                    </strong>

                    <span className="plate-item-icon plate-item-icon__vctr">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC1UlEQVR4nO2Z32vNYRzHj62JDdluh6KQcseNDP8ApRY3zI82QxaxNb82Hc0FbRFpsqa0/IhLuTK5UEQm2YXFzWrmYkVRKD9fevSceu/YHsfO5zxz6rzrqbPn+3nePzqfzve7zzeRKKCA+AAWAZ3AB+AuMDWRLwC2A58ZjWWJfACwmz9xJy++AWA58EWM9wEr5HoZsB7oBh4Bg8Anvwb9Xg+wFZg1GQFui/mHwHS/XwI0Au/JHK52vzsby/x84KcX/wYs8PsVwBMmjsdAeawAzrjDVWmZZ2mGXgCHgJXAadl3n6uAw8BA2pmnQGmMEGuAemCm/7tDTHwH9gHFUp+U60nZLwYO+DMpnMp5gMA34lA3Rs2YAeT6Trn+FZgXM4Cau5dBTXKcmvtS05Jz4yKsvV+dRYCNUtOXc+NetFTa5wcwO4sAFZ4j1UbTYgRYKsaGAnV/DeAAvJG6JYlIv0YpDAMHx1m9UtcbqBuWutUxAqwjd1gbI8CGHAaojhFgc9qd92SWa0D4NsUIUCeCPQZ8V4Sv1sZlWLBBBLsM+LqFb4+Ny7BgkwieM+A7L3yNNi7Dgi0i2G7A1yF8R2xchgXbRLDNgO+E8B23cRkWbLd8AANaoz5Wu74XwSYDvmbhO2vjMizYJYINBnx7he+ijcuwoJsspLDDgK9e+C7buAwL3hTBGgO+GuG7YeMyLHg9hwGu2bgMC14ybqFdlnf2TATPiOAxA76k8HXYuAwLbhPBW8YTvy02LsOCi0XQzT0rsuAqBz4K30Jbt2OLTgH6RbTVqH2eO25bt5m1kZsmVE2AY5U/G699RLzET6hTcC88aoGiDM4W+X+K9CXJg2iTajEyFxhhNF4CR910Aah0I3i/Kv2eu/Yq7cwIMCeq+bQQbro8UfSnRvWTBmCGux8A7/7B+Fv/GF2W+F/gxu5+1nnBv3567fvcrSG/1+lrfo/oCyiggAISeY9fLbpW23ZC7wgAAAAASUVORK5CYII=" />
                    </span>

                  </div>
                </div>
              ) : null
            }
          
            {
              showIncome ? (
                <div className="dashboard__plate-item card">
                  <div className="card-body">
                    <div className="text-highlight">
                      Доход, ₽
                    </div>
                    <strong>
                      {
                        formatNumber(roundNumber(mainStat.income))
                      }
                    </strong>

                    <span className="plate-item-icon">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGklEQVR4nO2Zy29NURTGb0slaBMTEY+YSFGGxESrIgYew2pCJP0DyrBUJ6ZeZaqIgVAGTRtaxEBEWxKlI0aVlAED4hUiaUn4yWLdZHXn3n3OPWef6uB8yUlu7ln7W+u7e++11t63UMiRI0eOHHMJwGpgL3AY6NRHPu+Rd4W5CGAD0A28IhqTwBmgYS4Evg64CfymcsiYAaD+fwReDXQB0yUCmwKGgWvAOX3k84i+czGty6xqtoJfBAyVCGQQaAEWe8bWAvvKjL8l3LMR/KjjeAxoTMDVBDx1uEYyE6HLxv3lzgM1KThrgJ4SM1EdNvp/zmTNWxwJyN3pcHeG4i46qHc27OWgDgp/fdiZ+CEZLiS5pMoinviWDTAP2AhsMs96YH6M5fTM+OkPWaRsnm+MCP4xpfFVC9hCz/hmY/9LhIcQIBW2iMEI2wZj+16r7gdHyAMR6uG4bWxPhhBg24OWCNstxvag+X65E9h+D0ersXsZojErYspXpHwC9N1SsxSveDjqnIq9Ko0A6SqLGI5hX1aAvv+o7+5F8Iwant1pBEgbXERvGgHAEjMDVyN4rhue9lDFqzupAGCFdp5lZ8cCOGtsuwpZC9Bc/xx4WyILfWImHvqyUGgBh+IsIeAA0fiidSCyWQNuhFpCsTex1oA2Y3/cVOL6qF89q03sptHaCvZAW0KfdU7ftTKxACWUdewtZMAO4DXwzdh+Bz7rcz/uiYuZhWwiVfBKeNoQDpWx2Qa8AN4Y23cqflLPEXEF3DUcJ0IIaHCauaaIxq+I1gS+tjvNXJiW2snhY+XaaWAZ8FPttlboYwEwbvz0BQleydc6G6vHY7sZ2JnAx0UnYaxJHbjj4CgZHfuAYw53Ryhu91AvB26LCzL1KTgXAJcczoHM7oj0WkWuPizkGNiccMOOO1zDvhNbSBHuTKAHllZfsdMiJTZ3SowfyDx4E0iVXoWUu1qUVqDXXC1Ke/zIY98xa1eLjhDpcfo1Z1cKGdMXPNskFCLXJqfkDBsj8AmpsEHvfUJCzrDALmmDzR8c7fpd8vNtjhw5cuQoZIA/uE2ver2NS4IAAAAASUVORK5CYII=" />
                    </span>

                  </div>
                </div>
              ) : null
            }
          
            {
              showVSPM ? (
                <div className="dashboard__plate-item card">
                  <div className="card-body">
                    <div className="text-highlight">
                      vCPM
                    </div>
                    <strong>
                      {
                        roundNumber(mainStat.vCPM)
                      }
                    </strong>

                    <span className="plate-item-icon plate-item-icon__vcpm">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADC0lEQVR4nO2YXUtUQRjHF9PUD5CWvVxUN22mtpv1HXq5yaLrSookLCPbvkHRrfgFuovopoheKLvo5VotsNLoKoMKMjJB0v3FA8/CMM2cOXvcNov5w4Hl7PPyn5lnnvOfyeUiIiIiIiIi/hMATUAXcAQ4C5T0kd99+l9TbjUB2ARcAMaAH4QhNo+BIfH9m8S7gevAEtmxDNwB9tWT+F7gYQKpd8BtYAS4qs+IEpX/fHgA9P5J4q3ANceMl4FHwEmgI0WcjUC/llzZirWkOVprTb4IvHYs/w0pJY9Pu/rJ0+6x6QFuaiwTkqtYK/KHgHkrwVNgh8O2RTf0S0eJTOrGbXb47QSeW/aS8+BKyZ+xSkY6yHmgwUNimjDeAnmHf4MOfsEqqdNZyR+zavQDUPDYCvk50mPONQiBltysYSscjuaqgbQ1ayYmfT1byybNzNt44yonjbnZKsOF1K1Wglrtbtq3CdVelj0rziXEXW/xmPEN2Ha8ZC311oC9a8MuapwOfUr6zsZEIPZ2qzSH02iZL4bD8YC9tEoXSg5bGYQLbYEc8n2p4DPQmGQsfbmCWVe3cWw4F377oOlKuFAM5JDu9NGw715tAyjUcgCNukwVnKhhCV322K4L5BDZUcGnxBJSh2HDQTbQtoC9tFgbi1rzoU08nmITfzPsLyaSN9qotKwKZgJtVORBVgwmxN2QqY0akjnth6xZ5UG1EMG21hNzC/DKkjDVSW09FqaVEvkqpcRXlxj0SAlRqn25LABOAT+tmRjyiLm8yoM0M+9SsmscYk5y92cibwQ+AHy3SDwTAecpJ1GrEw7i4yIdXGUD7AJeWPaSc/+KyBsJCsCUlWBZDyM9Hp829Sv4WiWwG7jlONBMhb4PWQbRoudbs6TQfTKm/Tp4y6BKU0rzieNIKbGvSK6akrcI7AHuJ9T4e+AuMGoc6kf1nfznQhm4V7NjZMqBdOm1ir0iWa5VeutG3HPLMKhXLfbZ2YV5vUIRn+AtRl2hWqoTOAwMGFeLA/quM6hlIiIiIiIiInL/Dn4Bpc403C1rQyYAAAAASUVORK5CYII=" />
                    </span>

                  </div>
                </div>
              ) : null
            }

        </div>


        <div className="dashboard__table">

          <div className="input-group">
            <input type="text" className="form-control form-control-theme form-control-sm search" placeholder="Поиск (ID или Название площадки)" onChange={ e => setSearchItem(e.target.value) } />
            <span className="input-group-append">
              <button className="btn btn-white no-border btn-sm" type="button">
                <span className="d-flex text-muted">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="16" 
                    height="16" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    className="feather feather-search">
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </span>
              </button>
            </span>
          </div>

          <table className="table table-theme table-row v-middle">
            <thead>
              <tr>
                <th className="text-muted">ID</th>
                <th className="text-muted">Название площадки</th>
                <th className="text-muted">Трафика отдали</th>
                <th className="text-muted">Трафик получили</th>
                <th className="text-muted">Коэффициент</th>
                <th className="text-muted">vCPM</th>
                <th className="text-muted">vCTR</th>
                <th className="text-muted">Доход, ₽</th>
              </tr>
            </thead>
            <tbody>

              {
                listForPagination.length ? listForPagination.slice(paginationStart, paginationEnd).map(item => {
                  return (
                    <tr key={item.site_id}>
                      <td>{item.site_id}</td>
                      <td>{item.name}</td>
                      <td>{formatNumber(item.total_exchange_clicks)}</td>
                      <td>{formatNumber(item.total_returned)}</td>
                      <td>{roundNumber(item.total_ratio)}</td>
                      <td>{roundNumber(item.vCPM)}</td>
                      <td>{roundNumber(item.vCTR)}</td>
                      <td>{formatNumber(roundNumber(item.income))}</td>
                    </tr>
                  )
                }) : (
                  <tr>
                    <td colSpan={8}>
                      Нет данных
                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>
        </div>

        <div className="dashboard__pagination-wrap">
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              notPaginateStat={listForPagination}
              setCurrentPage={setCurrentPage}
              size={listForPagination.length}
              indexOfFirst={indexFirst}
              indexOfLast={indexLast}
              key={listForPagination}/>


            <div className="dashboard__pagesPerPage">
              <span style={{marginRight: 10}}>Кол-во материалов на странице: </span>
              <select className="browser-default custom-select" style={{width: '80px'}} onChange={(e)=>changePerPage(e)} defaultValue={postsPerPage}>
                <option defaultValue>10</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
          </div>

      </div>
    </div>
  )
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(Dashboard)