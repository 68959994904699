import React, {useState, useEffect} from 'react'

import './PageExchange.scss'

import Axios from 'axios';

import { connect } from 'react-redux'

import { setAlert } from '../../redux/actions/alertActions';

import { hostDetect } from '../../config/envConfig';

import { ArrowLeft, Link, Trash2 } from "react-feather";

import { useHistory, Link as LinkRoute } from "react-router-dom";

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader';

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import { useSelector } from 'react-redux';

import Pagination from './Pagination/Pagination.js'

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')



const PageExchange = ({setAlert}) => {

  const [apiLoading, setApiLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const [listForPagination, setListForPagination] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(10)
  const [paginationStart, setPaginationStart] = useState(0)
  const [paginationEnd, setPaginationEnd] = useState(0)

  const [sorting, setSorting] = useState('');

  const [articleToDelete, setArticleToDelete] = useState('');

	const site_id = useSelector(state => state.siteInfo.site.id)

	useEffect(()=>{

		getArticles();
		
	}, [site_id])


  const getArticles = () => {
		setApiLoading(true)
		setCurrentPage(1)
		setListForPagination([])

		
		Axios.get(`${hostDetect('api')}/exchange/get-ad-list?site_id=${site_id}`, {withCredentials: true})
		.then(response => {
			let success = response.data.success;

      setApiLoading(false)

			if(success){
				const ads_array = response.data.data.entries;
				const response_type = response.data.data.type;

				if(response_type == 'error'){
					const errorMessage = response.data.data.message
					setAlert('info', errorMessage);
				}

				if(ads_array && ads_array.length){
					setListForPagination(ads_array)
				} else {
					setListForPagination([])
				}
				
			} else {
				setListForPagination([])
				
				const errorMessage = response.data.data.message
				setAlert('info', errorMessage);
			}

		})
		.catch(err => {
      setApiLoading(false)
			setAlert('error', 'Не получилось отправить запрос!');
		})
		
	}


	let history = useHistory();

	const handleBackClick = () => {
		history.goBack()
	}

  let indexFirst = num => {
    setPaginationStart(num)
  }

  let indexLast = num => {
    setPaginationEnd(num)
  }

  let changePerPage = e =>{
    setPostsPerPage(e.target.value)
    setCurrentPage(1)
  }

	// filter list by search query
  let searchQueryArr = listForPagination.filter(article => article.title.toLowerCase().includes(searchQuery.toLowerCase()));

	// sorting ASC|DESC
	if(sorting === 'asc'){
		searchQueryArr = searchQueryArr.sort((a, b) => a.total_clicks - b.total_clicks);
	} else if(sorting === 'desc'){
		searchQueryArr = searchQueryArr.sort((a, b) => b.total_clicks - a.total_clicks);
	}

	let formatLongNumber = valueNumber => {
    return new Intl.NumberFormat('ru-RU').format(valueNumber)
  }

	let formatFixed = (tableValue, fixLength) => {
    if(tableValue) {
      let statNumber = String(tableValue),
          dotPosition = statNumber.indexOf('.');

      if(dotPosition === -1){
        return statNumber + '.00';
      } else {
        let numberBeforeDot = statNumber.slice(0, dotPosition),
            numberAfterDot = statNumber.slice(dotPosition+1);

        return numberBeforeDot + '.' + numberAfterDot.slice(0, fixLength);
      }
    } else {
      return '0'
    }
  }

	// Date format
	const formatDate = (date) => {
		let formattedDate = moment(date).format('DD.MM.YYYY  HH:mm:ss')
		return formattedDate
	}

  const handleDeleteArticle = () => {
		setArticleToDelete('');

		let formData = new FormData();
		formData.append('status', '100');

		Axios.post(`${hostDetect('api')}/ad/update-status?id=${articleToDelete}`, formData, {withCredentials: true})
		.then(response => {
			const success = response.data.success;

			if(success){
				getArticles();

				setAlert('success', 'Статья удалена!');
			} else {

				let errType = response.data.data.name;

				if(errType === 'Forbidden'){
					setAlert('error', 'Не получилось удалить статью! Вы не обладаете правами создателя кампании.');
				} else {
					setAlert('error', 'Не получилось удалить статью! Обновите страницу и попробуйте снова.');
				}
				
			}
		})
		.catch(err => {
			setAlert('error', 'Не получилось отправить запрос!');
		})
		
	}

	const handleClicksSorting = () => {
		if(sorting === ''){
			setSorting('asc')
		} else if(sorting === 'asc'){
			setSorting('desc')
		} else if(sorting === 'desc'){
			setSorting('asc')
		}
	}
	
	return (
		<div className="page-exchange">

			<MobileHeader/>

			<div className="page-exchange__back-btn">
	    	<button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
	      	<ArrowLeft size={15} style={{marginRight: 10}}/>Назад
	      </button>
	    </div>

			<PageHeading mainText={"Обмен трафиком"} mutedText={"Обменивайтесь трафиком с сайтами нашей сети"}/>

			<div className="page-exchange__card">
        <div className="card">
          <div className='page-exchange__card-content'>
            
            <div className="b-b page-exchange__controls-wrap">
              <div className="b-primary bottom">
                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <LinkRoute to={`/exchange/active`} className="nav-link active">Активные</LinkRoute>
                  </li>
                  <li className="nav-item">
                    <LinkRoute to={`/exchange/add`} className="nav-link">Добавление статей</LinkRoute>
                  </li>
                  <li className="nav-item">
                    <LinkRoute to={`/exchange/archive`} className="nav-link">Архив</LinkRoute>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tab-content p-3">
              
              <div className="input-group">
                <input type="text" className="form-control search" value={searchQuery} onChange={(e)=>setSearchQuery(e.target.value)} placeholder="Поиск по заголовку" />
              </div>

              <div className="page-exchange__table-wrap">
                <table className="page-exchange__table table table-theme table-row v-middle">
                  <thead>
                    <tr>
                      <th className="text-muted">Статья</th>
                      <th className="text-muted text-center">Дата создания</th>
                      <th className={`text-muted sortable text-center ${sorting}`} onClick={handleClicksSorting}>Клики</th>
                      <th className="text-muted text-center">Действия</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      searchQueryArr.length ? searchQueryArr.
                      slice(paginationStart, paginationEnd). // pagination
                      map((exchange, index) => {             // mapping result
                      return(
                        <tr key={exchange.id}>
                          <td>
                            <div className="d-flex mb-2">
                              <div className="page-exchange__article-img" style={{backgroundImage: `url(${exchange.image})`}}></div>
                              <div className="page-exchange__article-id-title-wrap">
                                <div className="page-exchange__article-id">
                                  <span className="badge badge-sm badge-pill mx-1">id:</span> {exchange.id}
                                </div>
                                <p className="page-exchange__article-title">
                                  {exchange.title}
                                </p>
                              </div>
                            </div>
                            <div className="page-exchange__article-link">
                              <Link size={12}/>
                              <a href={exchange.url} title={exchange.url}>
                                {exchange.url}
                              </a>
                            </div>
                          </td>
                          <td>
                            <p className="page-exchange__article-created">
                              {formatDate(exchange.created)}
                            </p>
                          </td>
                          <td>
                            <p className="page-exchange__article-clicks">
                              {formatLongNumber(exchange.total_clicks)}
                            </p>
                          </td>
                          <td> 
                            <ul className="page-exchange__article-actions">
                              <li className="page-exchange__article-action text-danger" >
                                <a href="#" className="d-flex align-items-center" data-toggle="modal" data-target="#modalDeleteArticle" onClick={() => setArticleToDelete(exchange.id)}>
                                  <Trash2 size={12}/> Удалить
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        )
                      }) : (
                        <tr>
                          <td colSpan={5}>
                            Статьи отсутствуют
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>

              {
                searchQueryArr.length ? (
                  <div className="page-exchange__bottom-controls">
                    <Pagination
                      currentPage={currentPage}
                      postsPerPage={postsPerPage}
                      notPaginateStat={searchQueryArr}
                      setCurrentPage={setCurrentPage}
                      size={searchQueryArr.length}
                      indexOfFirst={indexFirst}
                      indexOfLast={indexLast}
                      key={searchQueryArr}/>

                    <div className="per-page">
                      <select className="browser-default custom-select" style={{width: '80px'}} onChange={(e)=>changePerPage(e)} value={postsPerPage}>
                        <option defaultValue>10</option>
                        <option>50</option>
                        <option>100</option>
                      </select>
                    </div>
                  </div>
                ) : null
              }

              {
                apiLoading ? <div className="loader-line"></div> : null
              }

              {/* --- modal Delete article ---*/}
              <div id="modalDeleteArticle" className="modal fade" data-backdrop="true">
                <div className="modal-dialog ">
                  <div className="modal-content ">
                    <div className="modal-header ">
                      <div className="modal-title text-md">Удаление статьи</div>
                      <button className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                      <div className="p-2">
                        <p className="text-left mb-0 d-flex align-items-center">
                          Вы уверены, что хотите удалить данную статью? 
                        </p>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleDeleteArticle}>Удалить</button>
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => setArticleToDelete('')}>Отмена</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* --- modal Delete article ---*/}

            </div>
          </div>
        </div>
      </div>
		</div>
	)
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(PageExchange)
