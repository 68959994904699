import React from 'react'

import img_1_1 from './images/1.1_manual.png';
import img_1_3 from './images/1.3_manual.png';
import img_1_4 from './images/1.4_manual.png';
import img_1_5 from './images/1.5_manual.png';
import img_1_6 from './images/1.6_manual.png';
import img_1_7 from './images/1.7_manual.png';
import img_1_8 from './images/1.8_manual.png';
import img_1_8_2 from './images/1.8.2_manual.png';

const StatisticsWatch = () => {
  return (
    <div className="manual__page">
      <h1>Просмотр статистики</h1>
      <p>
        Для просмотра и анализа статистических данных (<span className="marked-text">кол-во загрузок, просмотров, кликов и т.д.</span>) конкретного сайта за 
        определенный период времени Вам необходимо выполнить следующие действия:
      </p>
       <ul className="manual__page-steps-list">
         <li>
            <p>
              1. <strong>Выбрать сайт.</strong> Выбор необходимого сайта производится в выпадающем меню (закреплено в верхней части левого сайдбара).
            </p>
            <figure className="h-300">
              <img src={img_1_1} alt="Меню выбора сайта"/>
              <figcaption>Выбор необходимого сайта</figcaption>
            </figure>
         </li>
         <li>
            <p>
              2. <strong>Перейти в раздел Монетизация.</strong> Убедитесь, что Вы находитесь в разделе <span className="marked-text">Монетизация</span>, 
              или перейдите на него (навигация в левом сайдбаре). Данный раздел отвечает за вывод как суммарной статистики по всем виджетам, так и по конкретному виджету.
            </p>
         </li>
         <li>
            <p>
              3. <strong>Выбрать период.</strong> В выпадающем меню под названием <span className="marked-text">"Выберите период"</span> выбрать начальную и 
              конечную даты (период) за которые будет произведен вывод статистических данных для выбранного ранее сайта. Вы можете выбрать готовый формат вывода
              необходимого периода (Последние 7 дней, Последние 30 дней и т.д.), либо выбрать даты самостоятельно (Выбор даты).
            </p>
           <figure className="h-300">
              <img src={img_1_3} alt="Меню выбора даты"/>
              <figcaption>Выбор даты начала и даты конца статистического отображения</figcaption>
            </figure>
         </li>
         <li>
            <p>
              4. <strong>Выбрать тип группировки.</strong> Выбор группировки осуществляется путем переключения одной из трех кнопок в 
              меню, под названием <span className="marked-text">"Группировать по"</span>. Каждый тип группировки влияет на отображение статистических данных, которые в развернутом виде 
              располагаются в скрывающемся меню "Подробная статистика".
            </p>
           <figure className="h-400">
              <img src={img_1_4} alt="Меню выбора группировки"/>
              <figcaption>Выбор типа группировки</figcaption>
            </figure>
         </li>
         <li>
            <p>
              5. <strong>Выбрать виджет.</strong> В меню <span className="marked-text">"Выберите виджет"</span> отображен список из кликабельных элементов с названиями 
              виджетов (если для данного сайта ранее были созданы виджеты). При клике на любой элемент из этого списка, будет отображена статистика по конкретному виджету.  
              В данном списке также есть элемент "Суммарно", отображающий суммарную статистику по всем установленным виджетам для данного сайта. 
            </p>
           <figure className="h-200">
              <img src={img_1_5} alt="Меню виджета"/>
              <figcaption>Выбор виджета</figcaption>
            </figure>
         </li>
         <li>
           <p>
              6. <strong>Общая статистика.</strong> Блок <span className="marked-text">"Общая статистика"</span> содержит количественные показатели, 
              такие как <span className="marked-text">Загрузки, Видимость, Показы, CTR, CPM, Клики</span> и т.д. в зависимости от выбранного критерия отображения по конкретному виджету
              либо Суммарно по всем виджетам.
           </p>
           <figure className="h-180">
              <img src={img_1_6} alt="Общая статистика."/>
              <figcaption>Общая статистика. Отображение статистических показателей для виджета "Под статьей"</figcaption>
            </figure>
         </li>
         <li>
           <p>
              7. <strong>Подробная статистика.</strong> Блок <span className="marked-text">"Подробная статистика"</span> отображает детальную статистику в зависимости от 
              выбранного критерия отображения - по конкретному виджету либо Суммарно по всем виджетам. Изначально данный блок скрыт, но при нажатии на синюю кнопку "Подробная статистика", блок раскрывается 
              и таблица со статистическими данными принимает развернутый вид.
           </p>
           <figure className="h-400">
              <img src={img_1_7} alt="Подробная статистика."/>
              <figcaption>Подробная статистика</figcaption>
            </figure>
         </li>
         <li>
           <p>
              8. <strong>Графики.</strong> Для визуального отображения статистических данных на данной странице размещены два графика. 
              Числовые показатели графиков изменяются в зависимости от выбранного критерия отображения - по конкретному виджету либо Суммарно по всем виджетам.
              Первый график отображает количественные показатели "Загрузки и Показы" на левой оси и "Клики" на правой.
              Второй график отображает количественные показатели "Доход" на левой оси и "CPM" на правой.
           </p>
           <figure className="h-250">
              <img src={img_1_8} alt="График с отображением данных о Загрузках, Показах и Кликах."/>
              <figcaption>График с отображением данных о Загрузках, Показах и Кликах</figcaption>
            </figure>
            <figure className="h-250">
              <img src={img_1_8_2} alt="График с отображением данных о Доходе и CPM."/>
              <figcaption>График с отображением данных о Доходе и CPM</figcaption>
            </figure>
         </li>
       </ul>
    </div>
  )
}

export default StatisticsWatch

