import React, { useState, useEffect } from 'react';

import Axios from 'axios';

import { connect } from 'react-redux'

import { hostDetect } from '../../../../config/envConfig';

import { Play, Pause, Square, Trash } from "react-feather";

import { setAlert } from '../../../../redux/actions/alertActions';


const CampaignController = ({ status, article_id, updateAdsList, setAlert }) => {
	
	const handleClickStatus = (status) => {
		
		let formData = new FormData();
		formData.append('status', status);

		let apiOptions = {
			withCredentials: true
		}

		Axios.post(`${hostDetect('api')}/ad/update-status/?id=${article_id}`, formData, apiOptions)
		.then(response => {
			return response
		})
		.then(data => {
			const success = data.data.success;
			if(success){
				updateAdsList(article_id, status)
			} else {
				let errMessage = data.data.data.message;
				setAlert('error', errMessage);
			}
		})
		.catch(err => {
			setAlert('error', 'Не получилось отправить запрос!');
		})
	}

	const switchStatus = () => {
		switch (status) {
		  case 30:
				// active
		    return <Play size={14}/>
		  case 50:
				// stopped
		  	return <Square size={14}/>
		  case 100:
				// archived
		  	return <Trash size={14}/>
		}
	}

	const statusShort = () => {
		switch (status) {
		  case 30:
				// active
		    return 'Запущено'
		  case 50:
				// stopped
		  	return 'Остановлено'
		  case 100:
				// deleted
		  	return 'Удалено'
		}
	}

	return (
		<div className="btn-group dropright campaign-controller">
      <button className="btn btn-white dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
      	{switchStatus()}
      </button>
      <div className="dropdown-menu bg-dark" role="menu" x-placement="right-start">
        <a className={'dropdown-item d-flex align-items-center ' + (status === 30 ? 'disabled' : '')} onClick={()=>handleClickStatus(30)}>
          <Play size={14}/> Запустить
        </a>
        <a className={'dropdown-item d-flex align-items-center ' + (status === 50 ? 'disabled' : '')} onClick={()=>handleClickStatus(50)}>
          <Square size={14}/> Остановить
        </a>
        {/* <a className={'dropdown-item d-flex align-items-center ' + (status === 100 ? 'disabled' : '')} onClick={()=>handleClickStatus(100)}>
          <Trash size={14}/> Удалить
        </a> */}
        <li className="dropdown-divider"></li>
        <p className="dropdown-item mb-0" style={{fontSize: '12px'}}>
        	{statusShort()}
        </p>
      </div>
  	</div>
	)
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(CampaignController)