import React, { useState, useEffect } from 'react'

import './DetailsTable.scss'

import Pagination from './Pagination/Pagination'

import { connect } from 'react-redux'

import {HelpCircle} from "react-feather";

import { useLocation } from 'react-router-dom';

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const DetailsTable = (props) => {

  let location = useLocation();

  const [showTable, setShowTable] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(10)
  const [paginationStart, setPaginationStart] = useState(0)
  const [paginationEnd, setPaginationEnd] = useState(0)

  useEffect(()=>{
    setCurrentPage(1)
  }, [props.activeWidgetId, props.groupByLabel, props.activeWidgetStat])

  let checkIfNull = (obj, val, fixLength) =>{
    if(obj != null){
      return obj[val] ? (
        fixLength != undefined ? (

          lengthFormatter(obj[val], fixLength))

          : obj[val])
        : '0'
    } else{
      return '0'
    }
  }

  let lengthFormatter = (stringToFormat, lengthAfterDot) => {
    let statNumber = String(stringToFormat),
        dotPosition = statNumber.indexOf('.'),
        numberBeforeDot = statNumber.slice(0, dotPosition),
        numberAfterDot = statNumber.slice(dotPosition+1);
    return numberBeforeDot + '.' + numberAfterDot.slice(0, lengthAfterDot);
  }

  let formatDate = dateString =>{
    if(props.groupByLabel == 'day'){
      return moment(new Date(dateString)).format('DD.MM.YYYY')
    }
    if(props.groupByLabel == 'week'){
      let [year, week] = dateString.split('W')
      return moment(year).add(week, 'weeks').startOf('isoweek').format('DD.MM.YYYY');
    }
    if(props.groupByLabel == 'month'){
      return moment(dateString).format('MM.YYYY')
    }
    return dateString
  }

  const formatNumber = (numb) => {
    return new Intl.NumberFormat('ru-RU').format(numb)
  }

  let changePerPage = e =>{
    setPostsPerPage(e.target.value)
    setCurrentPage(1)
  }

  let listForPagination = Object.keys(props.activeWidgetStat).map((statDateAsKey, index) =>{
    return [index+1, statDateAsKey, props.activeWidgetStat[statDateAsKey]]
  })

  let indexFirst = num => {
    setPaginationStart(num)
  }

  let indexLast = num => {
    setPaginationEnd(num)
  }

  let detailsTable = (
    <div className="details__table">
      <div className="card">
        <table className="table table-hover table-bordered">
          <thead className="thead-light">
            <tr className="text-center">
              <th scope="col" rowSpan="2">#</th>
              <th scope="col" rowSpan="2">Дата</th>
              <th scope="col" rowSpan="2">Загрузки</th>
              <th scope="col" rowSpan="2">Видимость</th>
              <th scope="col" rowSpan="2">
                <div className="d-flex">
                  Показы <span className="question-marker" title="Показы блока по стандарту MRC Viewable Ad Impression Measurement Guidelines Version 2.0"><HelpCircle size={14} color={'#333'} /></span>
                </div>
              </th>
              <th scope="col" rowSpan="2">CTR</th>
              <th scope="col" colSpan={`${location.pathname !== '/income/banner' ? '3' : '1'}`}>Переходы</th>
              <th scope="col" rowSpan="2">CPM</th>
              <th scope="col" rowSpan="2">Доход</th>
              {
                location.pathname !== '/income/banner' ? (
                  <th scope="col" rowSpan="2">Продвижение</th>
                ) : null
              }
              {
                props.widgetActiveIndex === 0 && location.pathname !== '/income/banner' ? (
                  <>
                    <th scope="col" rowSpan="2">Возврат<span className="question-marker" title="Переходы от Sparrow"><HelpCircle size={14} color={'#333'} /></span></th>
                    <th scope="col" rowSpan="2">Коэффициент<br/>возврата</th>
                  </>
                ) : null
              }
              
              {
                location.pathname !== '/income/banner' ? (
                  <th scope="col" rowSpan="2">Прибыль</th>
                ) : null
              }
            </tr>
            <tr className="table-subhead">
              {
                location.pathname !== '/income/banner' ? (
                  <th className="text-center" scope="col">Органические</th>
                ) : null
              }
              <th className="text-center" scope="col">Промо</th>
              {
                location.pathname !== '/income/banner' ? (
                  <th className="text-center" scope="col">Обменные</th>
                ) : null
              }
            </tr>
          </thead>
          <tbody style={{fontSize: 12, color: '#666'}}>
            {
              listForPagination.slice(paginationStart, paginationEnd).map((statField, index) => {

                return(
                  <tr className="text-capitalize" key={statField[0]}>
                    <td scope="row">{statField[0]}</td>
                    <td className="details__table-date-cell">{formatDate(statField[1])}</td>
                    <td>{formatNumber(checkIfNull(statField[2], 'visits', ))}</td>
                    <td>{checkIfNull(statField[2], 'view_percent', 1)} %</td>
                    <td>{formatNumber(checkIfNull(statField[2], 'views'))}</td>
                    <td>{checkIfNull(statField[2], 'ctr', 2)}</td>
                    {
                      location.pathname !== '/income/banner' ? (
                        <td>{formatNumber(checkIfNull(statField[2], 'organic_clicks'))}</td>
                      ) : null 
                    }
                    <td>{formatNumber(checkIfNull(statField[2], 'ad_clicks'))}</td>
                    {
                      location.pathname !== '/income/banner' ? (
                        <td>{formatNumber(checkIfNull(statField[2], 'exchange_clicks'))}</td>
                      ) : null 
                    }
                    <td>{checkIfNull(statField[2], 'cpm', 2)}</td>
                    <td>{checkIfNull(statField[2], 'income', 2)} ₽</td>
                    {
                      location.pathname !== '/income/banner' ? (
                        <td>0</td>
                      ) : null 
                    }
                    {
                      props.widgetActiveIndex === 0 && location.pathname !== '/income/banner' ? (
                        <>
                          <td>{checkIfNull(statField[2], 'returned')}</td>
                          <td>{checkIfNull(statField[2], 'exchange_mult', 2)}</td>   
                        </>
                      ) : null
                    }
                    {
                      location.pathname !== '/income/banner' ? (
                        <td>{checkIfNull(statField[2], 'profit', 2)} ₽</td>
                      ) : null 
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col">
          <div className="details__pagination-pagesPerPage">
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              notPaginateStat={listForPagination}
              setCurrentPage={setCurrentPage}
              size={listForPagination.length}
              indexOfFirst={indexFirst}
              indexOfLast={indexLast}
              key={listForPagination}/>

            <div className="details__pagesPerPage">
              <span style={{marginRight: 10}}>Кол-во материалов на странице: </span>
              <select className="browser-default custom-select" style={{width: '80px'}} onChange={(e)=>changePerPage(e)} value={postsPerPage}>
                <option defaultValue>10</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="details">

      {/* Statistics button */}
      <div className="text-center mb-2">
        <button type="button" onClick={()=>setShowTable(!showTable)} className="btn btn-primary dropdown-toggle btn-sm">Подробная статистика</button>
      </div>

      {/* Statistics table */}
      {
        showTable ?  detailsTable : ''
      }

    </div>
  )
}

function mapStateToProps(state){
  return{
    activeWidgetStat: state.siteInfo.site.widgetActiveStatistics,
    activeWidgetId: state.siteInfo.site.widgetActive,
    groupByLabel: state.siteInfo.site.groupBy,
    widgetActiveIndex: state.siteInfo.site.widgetActive
  }
}

export default connect(mapStateToProps)(DetailsTable)