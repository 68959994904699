import React from 'react'

import { connect } from 'react-redux';

const Tab1 = ({blockTitle, setBlockTitle, blockType, setBlockType, deviceType, setDeviceType, tabActive, setTabActive, workModel, setWorkModel, userId}) => { 

  const handleTitle = (title) => {
    setBlockTitle(title)
  }

  return (
    <>
      {/* title */}
      <div className="form-group">
        <label>Название</label>
        <input 
          type="text" 
          className="form-control" 
          value={blockTitle} 
          onChange={e => handleTitle(e.target.value)} 
          name="title" 
          placeholder='Например: "Десктоп - под статьей"' />
          {
            blockTitle.length < 3 || blockTitle.length > 64
              ? <small className="form-text text-muted">Название блока должно содержать минимум 3 и максимум 64 знаков</small>
              : null
          }
      </div>

      {/* block type */}
      <div className="form-group">
        <label>Тип блока</label>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="block-type" 
              value="native"
              onChange={e => setBlockType(e.target.value)} 
              checked={blockType === 'native'}/> 
              Нативный блок
          </label>
        </div>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="block-type" 
              value="infinite"
              onChange={e => setBlockType(e.target.value)} 
              checked={blockType === 'infinite'} />
              Бесконечная лента
          </label>
        </div>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="block-type" 
              value="backpage"
              onChange={e => setBlockType(e.target.value)} 
              checked={blockType === 'backpage'} />
              Возвратная страница
          </label>
        </div>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="block-type" 
              value="turbo"
              onChange={e => setBlockType(e.target.value)} 
              checked={blockType === 'turbo'} />
              Яндекс Турбо
          </label>
        </div>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="block-type" 
              value="amp"
              onChange={e => setBlockType(e.target.value)} 
              checked={blockType === 'amp'} />
              АМП
          </label>
        </div>
        {/* <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="block-type" 
              value="banner"
              onChange={e => setBlockType(e.target.value)} 
              checked={blockType === 'banner'} />
              Баннер
          </label>
        </div> */}
        {
          userId == 94068 || userId == 94313 || userId == 94472 || userId == 94568 ? (
            <div className="form-check mb-1">
              <label className="form-check-label">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="block-type" 
                  value="banner"
                  onChange={e => setBlockType(e.target.value)} 
                  checked={blockType === 'banner'} />
                  Баннер
              </label>
            </div>
          ) : null
        }
        
      </div>

      {/* device type */}
      <div className="form-group">
        <label>Тип устройства</label>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="device-type" 
              value="mobile"
              onChange={e => setDeviceType(e.target.value)} 
              checked={deviceType === 'mobile'} /> 
              Мобильный
          </label>
        </div>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="device-type" 
              value="desktop"
              onChange={e => setDeviceType(e.target.value)}
              checked={deviceType === 'desktop'} 
              disabled={blockType === 'turbo'} />
              Десктоп
          </label>
        </div>
      </div>

      {/* block type */}
      {
        blockType !== 'banner' ? (
          <div className="form-group">
            <label>Модель работы</label>
            <div className="form-check mb-1">
              <label className="form-check-label">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="work-model" 
                  value="exchange"
                  onChange={e => setWorkModel(e.target.value)} 
                  checked={workModel === 'exchange'}/> 
                  Обмен
              </label>
            </div>
            <div className="form-check mb-1">
              <label className="form-check-label">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="work-model" 
                  value="income"
                  onChange={e => setWorkModel(e.target.value)} 
                  checked={workModel === 'income'} />
                  Монетизация
              </label>
            </div>
            <div className="form-check mb-1">
              <label className="form-check-label">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="work-model" 
                  value="exchange_income"
                  onChange={e => setWorkModel(e.target.value)} 
                  checked={workModel === 'exchange_income'} />
                  Обмен + Монетизация
              </label>
            </div>
          </div>
        ) : null
      }
      
      <div className="wiz-tab__buttons">
        {
          blockTitle.length < 3 || blockTitle.length > 64
            ? (
              <div className="d-flex flex-column">
                <button className="btn w-sm mb-1 bg-secondary-lt" disabled>Далее</button>
                <small className="form-text text-muted">Необходимо правильно заполнить поля</small>
              </div>
            )
            : <button className="btn w-sm mb-1 btn-primary" onClick={() => setTabActive(2)}>Далее</button>
        }
      </div>
    </>
  )
}


function mapStateToProps(state){
  return{
    userId: state.userInfo.user.id
  }
}

export default connect(mapStateToProps)(Tab1)