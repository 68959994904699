import React, { useState, useEffect } from 'react'

import './ApiAlerts.scss'

import Axios from 'axios'

import { connect } from 'react-redux';

import { hostDetect } from '../../../config/envConfig'

import { setAlert } from '../../../redux/actions/alertActions'


const ApiAlerts = ({setAlert}) => {
  const [showAlerts, setShowAlerts] = useState(false)
  const [alerts, setAlerts] = useState([])

  useEffect(() => {
    getApiAlerts()
  }, [])

  const getApiAlerts = () => {
    Axios.get(`${hostDetect('api')}/info?app=publisher`, {withCredentials: true})
      .then(response => {
        let status = response.data.success,
            alertsArray = response.data.data;

        if(status){
          setShowAlerts(true)
          setAlerts(alertsArray)
        } else {
          let errMessage = response.data.data.message;
          setAlert('error', errMessage);
        }
      })
      .catch(err => {
        setAlert('error', 'Не получилось отправить запрос!');
      })
  }


  const handleCloseAlert = (id) => {
    Axios.get(`${hostDetect('api')}/info/click?message_id=${id}`, {withCredentials: true})
      .then(response => {
        let status = response.data.success;

        if(status){
          getApiAlerts()
        } else {
          let errMessage = response.data.data.message;
          setAlert('error', errMessage);
        }
      })
      .catch(err => {
        setAlert('error', 'Не получилось отправить запрос!');
      })
  }


  return (
    <>
      {
        showAlerts && alerts.length ? (

          alerts.map((item, index) => {
            return (
              <div className={`api-alert alert alert-${item.type} ${item.can_close === '1' ? "alert-dismissible" : ""}`} role="alert" key={index}>
                <div className='d-flex align-items-center justify-content-between'>
                  
                  <span className="mx-2 d-flex align-items-center">
                    {
                      item.type === 'info' ? (
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="16" 
                          height="16" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          strokeWidth="2" 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          className="feather feather-info mr-2">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="16" x2="12" y2="12"></line>
                            <line x1="12" y1="8" x2="12" y2="8"></line>
                        </svg>
                      ) : null
                    }
                    {
                      item.type === 'warning' ? (
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="16" 
                          height="16" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          strokeWidth="2" 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          className="feather feather-alert-circle mr-2">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12" y2="16"></line>
                        </svg>
                      ) : null
                    }
                    { item.title }
                  </span>

                  {
                    item.can_details === '1' ? (
                      <button className="btn-more" data-toggle="modal" data-target={`#modal-${item.id}`}>Подробнее</button>
                    ) : null
                  }
                </div>
                
                {
                  item.can_close === '1' ? (
                    <button className="close" onClick={() => handleCloseAlert(item.id)}>
                      <span>×</span>
                    </button>
                  ) : null
                }
                

                {
                  item.can_details === '1' ? (
                    <div id={`modal-${item.id}`} className="alert-modal modal fade show" data-backdrop="true" aria-modal="true">
                      <div className="modal-dialog ">
                        <div className="modal-content ">
                          <div className="modal-header ">
                            <div className="modal-title text-md">{ item.title }</div>
                            <button className="close" data-dismiss="modal">×</button>
                          </div>
                          <div className="modal-body">
                            {
                              item.image ? (
                                <img src={item.image} />
                              ) : null
                            }
                            <pre>{item.text}</pre>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-dismiss="modal">Закрыть</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                }

              </div>
            )
          })
          

        ) : null
      } 
    </>
  )
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(ApiAlerts)