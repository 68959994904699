import React, { useState, useEffect } from 'react'

import './ChartTable.scss'

import { connect } from 'react-redux'

//apexcharts
import ReactApexChart from "react-apexcharts"

//moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const ApexChart = (props)=> {

  const [series, setSeries] = useState({
   general: [],
   income: []
  })

  // General options - Visits, Views, Clicks, Returned
  const [optionsGeneral, setOptionsGeneral] = useState({
    chart: {
      toolbar: {
        show: true
      },
      height: 350,
      stacked: false,
      id: 'monetization'
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      y:{
        formatter: function(value, series) {
          // use series argument to pull original string from chart data
          return new Intl.NumberFormat('ru-RU').format(value);
        }
      }
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    colors: [
      '#31c971', '#14bae4', '#f4c414', '#f39c12'
    ],
    markers: {
      size: 3,
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    xaxis: {
      labels: {
        rotate: 0,
        rotateAlways: false
      },
      categories: []
    },
    yaxis: [
      {
        seriesName: 'Загрузки',
        showAlways: true,
        showForNullSeries: true,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: "#008ffb",
        },
        labels: {
          style: {
            cssClass: 'chart-title__visits-label'
          }
        },
        title: {
          text: 'Загрузки и показы',
          style: {
            cssClass: 'chart-title__visits-title'
          }
        },
        tooltip: {
          enabled: false
        }
      },{
        seriesName: 'Показы',
        show: false
      },{
        seriesName: 'Клики',
        showAlways: true,
        showForNullSeries: true,
        opposite: true,
        title: {
          text: 'Клики и возвраты',
          style: {
            cssClass: 'chart-title__clicks-title'
          }
        },
        axisBorder: {
          show: true,
          color: "#008ffb"
        },
        labels: {
          style: {
            cssClass: 'chart-title__clicks-label'
          }
        }
      },{
        seriesName: 'Возвраты',
        show: false
      }
    ],
    legend: {
      horizontalAlign: 'left'
    }
  })

  // General options - Income, CPM
  const [optionsIncome, setOptionsIncome] = useState({
    chart: {
      toolbar: {
        show: true
      },
      height: 350,
      stacked: false,
      id: 'income'
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    tooltip: {
      y:{
        formatter: function(value, series) {
          return new Intl.NumberFormat('ru-RU').format(value && value.toFixed(2)) + ' ₽';
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 3,
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    xaxis: {
      labels: {
        rotate: 0,
        rotateAlways: false
      },
      categories: []
    },
    yaxis: [
      {
        seriesName: 'Доход',
        showAlways: true,
        opposite: false,
        title: {
          text: 'Доход, ₽',
          style: {
            cssClass: 'chart-title__income-title'
          }
        },
        axisBorder: {
          show: true,
          color: "#008ffb"
        },
        labels: {
          style: {
            cssClass: 'chart-title__income-label'
          },
          formatter: function(value) {
            return value.toFixed(0);
          }
        }
      }, {
        seriesName: 'CPM',
        showAlways: true,
        opposite: true,
        title: {
          text: 'CPM',
          style: {
            cssClass: 'chart-title__cpm-title'
          }
        },
        axisBorder: {
          show: true,
          color: "#008ffb"
        },
        labels: {
          style: {
            cssClass: 'chart-title__cpm-label'
          },
          formatter: function(value) {
            return value.toFixed(0);
          }
        }
      }
    ],
    legend: {
      horizontalAlign: 'left'
    }
  })



  const formatXaxis = date =>{
    if(props.groupBy === 'day'){
       return moment(new Date(date)).format('DD.MM')
    }
    if(props.groupBy === 'week'){
      let [year, week] = date.split('W')
      return moment(year).add(week, 'weeks').startOf('isoweek').format('DD-MMMM');
    }
    if(props.groupBy === 'month'){
      return moment(date).format('MMMM YYYY')
    }
  }

  
  const setDefaultChartSettings = (statistics, title, subtitle) =>{
    let Xaxis = Object.keys(statistics);

    let XaxisFormatted = Xaxis.map(item =>{
      return formatXaxis(item)
    })

    setOptionsGeneral({...optionsGeneral, xaxis: { categories: XaxisFormatted}, title: {text: title}, subtitle: {text: subtitle}});
    setOptionsIncome({...optionsIncome, xaxis: {categories: XaxisFormatted}});
  }

  useEffect(()=>{
    let activeStatistics = props.widgetActiveStatistics,
        chartTitle = '',
        chartSubtitle = '';

    if(props.widgetActiveId === 0){
      chartTitle = 'Суммарно';
      chartSubtitle = 'Общая статистика';
    } else {
      chartTitle = props.widgets[props.widgetActiveId-1].name;
      chartSubtitle = '#' + props.widgets[props.widgetActiveId-1].id;
    }


    // data - top chart
    let visits = {
      name: 'Загрузки',
      type: 'line',
      data: []
    }

    let views = {
      name: 'Показы',
      type: 'line',
      data: []
    }

    let clicks = {
      name: 'Клики',
      type: 'line',
      data: []
    }

    let returns = {
      name: 'Возврат',
      type: 'line',
      data: []
    }


    // data - bottom chart
    let cpm = {
      name: 'CPM',
      type: 'line',
      data: []
    }

    let income = {
      name: 'Доход',
      type: 'line',
      data: []
    }



    Object.keys(activeStatistics).forEach(item =>{

      if(activeStatistics[item] == null){
        visits.data.push(0)
        views.data.push(0)
        clicks.data.push(0)
        income.data.push(0)
        cpm.data.push(0)
        returns.data.push(0)
      } else {
        visits.data.push(activeStatistics[item].visits)
        views.data.push(activeStatistics[item].views)
        clicks.data.push(activeStatistics[item].clicks)
        income.data.push(activeStatistics[item].income)
        cpm.data.push(activeStatistics[item].cpm)
        if(props.widgetActiveId !== 0){
          returns.data.push(0)
        } else {
          returns.data.push(activeStatistics[item].returned)
        }
        
      }
    })


    setSeries({
      general: [visits, views, clicks, returns],
      income: [income, cpm]
    })

    setDefaultChartSettings(activeStatistics, chartTitle, chartSubtitle);

  }, [props.widgetActiveStatistics])

  


  return (
    <div id="chart">
      <ReactApexChart
        options={optionsGeneral}
        series={series.general}
        height={350}/>
      <ReactApexChart
        options={optionsIncome}
        series={series.income}
        height={350}/>
    </div>
  )
}

function mapStateToProps(state){
  return{
    widgets: state.siteInfo.site.widgets,
    widgetActiveId: state.siteInfo.site.widgetActive,
    widgetActiveStatistics: state.siteInfo.site.widgetActiveStatistics,
    groupBy: state.siteInfo.site.groupBy
  }
}

export default connect(mapStateToProps)(ApexChart)