import React, {useState, useEffect, useCallback} from 'react'

// redux
import { connect } from 'react-redux'

import debounce from 'lodash.debounce';

import './AdvertiserInfo.scss'

import Axios from 'axios';

import { hostDetect } from '../../../config/envConfig';

import { CheckCircle } from 'react-feather'

import { setAlert } from '../../../redux/actions/alertActions';

const AdvertiserInfo = ({setAlert}) => {

  const [advertId, setAdvertId] = useState('');

  // types - ul, fl / ful, ffl
  const [type, setType] = useState('');
  // county - russia, other_country 
  const [country, setCountry] = useState('');
  const [name, setName] = useState('');

  // if russia
  const [inn, setInn] = useState('');
  // if other country
  const [alternative_inn, setAlternative_inn] = useState('');
  const [reg_number, setReg_number] = useState('');
  const [oksm_number, setOksm_number] = useState('');

  // publisher
  const [data_provider_type_publisher, setData_provider_type_publisher] = useState('');
  // advertiser
  const [data_provider_type, setData_provider_type] = useState('');

  const [legal_address, setLegal_address] = useState({city: '', street: '', building: '', postcode: ''});
  const [post_address, setPost_address] = useState({city: '', street: '', building: '', postcode: ''});
  const [addresses_equal, setAddresses_equal] = useState(false);

  const [dataSuccess, setDataSuccess] = useState({status: false, message: ''})
  const [dataError, setDataError] = useState({status: false, message: ''})


  useEffect(()=>{
    Axios.get(`${hostDetect('api')}/ord/get-organization`, {
      withCredentials: true
    })
    .then(response => {
      let success = response.data.success;

      if(success){
        let advertInfo = response.data.data;

        if(!advertInfo.hasOwnProperty('message')){
          let {id, name, type, is_agent, is_rd, is_rr, address, legal_address, post_address, inn, alternative_inn, reg_number, oksm_number} = advertInfo[0];

          setAdvertId(id)
          setName(name)

          // set type - ul,fl / ful, ffl
          if(type === 'ffl'){
            setType('fl')
          } else if(type === 'ful'){
            setType('ul')
          } else {
            setType(type)
          }
          
          // country type - russia/other_country
          if(legal_address && legal_address.country){
            setCountry(legal_address.country)
          }
          // if russia
          if(inn){
            setInn(inn)
          }
          // if other_country
          if(alternative_inn){
            setAlternative_inn(alternative_inn)
          }
          if(reg_number){
            setReg_number(reg_number)
          }
          if(oksm_number){
            setOksm_number(oksm_number)
          }
          // organization type
          if(is_agent){
            setData_provider_type('is_agent')
          } else if(is_rd){
            setData_provider_type('is_rd')
          } 
          
          // organization type - publisher
          if(is_rr){
            setData_provider_type_publisher('is_rr')
          }
          // same address
          if(address && address.type === 'all'){
            let api_same_addr = {
              city: address.city,
              street: address.street,
              building: address.building,
              postcode: address.postcode
            }
            setCountry(address.country)

            setLegal_address(api_same_addr)
            setPost_address(api_same_addr)
          }
          // if different addresses
          if(legal_address){
            let api_legal_addr = {
              city: legal_address.city,
              street: legal_address.street,
              building: legal_address.building,
              postcode: legal_address.postcode
            }
            setLegal_address(api_legal_addr)
          }
          if(post_address){
            let api_post_addr = {
              city: post_address.city,
              street: post_address.street,
              building: post_address.building,
              postcode: post_address.postcode
            }
            setPost_address(api_post_addr)
          }

        }

      } else {
        let message = response.data.data.message;
        setAlert('error', message)
      }
    })
    .catch(err => {
      setAlert('error', 'Не удалось отправить запрос, обновите страницу и попробуйте снова!') 
    })
  },[])

  const sendPost = () => {

    setDataError({status: false, message: ''})
    setDataSuccess({status: false, message: ''})

    let formData = new FormData();
    formData.append('source', 'publisher');
    formData.append('name', name);

    if(country === 'russia'){
      formData.append('type', type);
    } else if(country === 'other_country'){
      if(type === 'ul'){
        formData.append('type', 'ful');
      } else if(type === 'fl'){
        formData.append('type', 'ffl');
      } else {
        formData.append('type', type);
      }
    }
    
    formData.append('is_rr', data_provider_type_publisher == 'is_rr' ? true : false)
    formData.append('is_rd', data_provider_type == 'is_rd' ? true : false)
    formData.append('is_agent', data_provider_type == 'is_agent' ? true : false)

    if(country === 'russia'){
      formData.append('inn', inn);
    } else if(country === 'other_country') {
      formData.append('alternative_inn', alternative_inn);
      formData.append('reg_number', reg_number);
      formData.append('oksm_number', oksm_number);
    }

    let leg_addr = {
      'country': country,
      ...legal_address
    }
    formData.append('legal_address', JSON.stringify(leg_addr));

    if(addresses_equal){
      formData.append('same_address', addresses_equal);
    }

    if(!addresses_equal){
      let post_addr = {
        'country': country,
        ...post_address
      }
      formData.append('post_address', JSON.stringify(post_addr));
    }

    const getOrganizationId = () => {
      if(advertId){
        return `?organization_id=${advertId}`;
      } else {
        return ''
      }
    }


    Axios.post(`${hostDetect('api')}/ord/update-organization${getOrganizationId()}`, formData, {withCredentials: true})
      .then(res => {
        let success = res.data.success;

        if(success){
          let org_id = res.data.data.organization_id;

          setDataSuccess({status: true, message: res.data.data.message})

          setAdvertId(org_id)
        } else {
          let message = res.data.data.message;
          setAlert('error', message)
        }

      }).catch(err=> {
        setAlert('error', 'Не удалось отправить запрос, обновите страницу и попробуйте снова!') 
      })

  }

  const legalAddressSet = (newValue, objItem) => {
    let newState = {...legal_address, [objItem]: newValue};
    setLegal_address(newState)
  }

  const postAddressSet = (newValue, objItem) => {
    let newState = {...post_address, [objItem]: newValue};
    setPost_address(newState)
  }

  // compare addresses
  useEffect(()=> {
    const shallowEqual = (object1, object2) => {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }
      return true;
    }

    let is_address_equal = shallowEqual(legal_address, post_address)

    setAddresses_equal(is_address_equal);
  }, [legal_address, post_address])

  // country radio-button changes
  useEffect(()=>{
    if(country === 'other_country'){
      setInn('')
    } else if(country === 'russia'){
      setAlternative_inn('');
      setReg_number('');
      setOksm_number('');
    }
  },[country])


  // if same address
  useEffect(()=>{
    if(addresses_equal){
      setPost_address(legal_address)
    }
  },[addresses_equal])


  const handleSubmitAdvertiserInfo = () => {
    setDataSuccess({status: true, message: 'Данные обновлены'});

    setTimeout(()=>{
      setDataSuccess({status: false, message: ''});
    },3000)
  }

  const clickOtherCountry = (countryType) => {
    if(type === "ip"){
      setType('ul')
    }
    setCountry(countryType)
  }
 

  return (
    <div className="card advertiser-info">
      <div className="card-header">
        <strong>Информация о площадке</strong>
      </div>
      <div className="card-body">

        {/* country radio-buttons */}
        <div className="form-group">
          <label className="text-muted">Страна организации</label>
          <div className="form-check ml-2">
            <input className="form-check-input" type="radio" name="country_of_organization" id="country_rus" value="russia" checked={country === 'russia'} onChange={(e) => setCountry(e.target.value)} />
            <label className="form-check-label" htmlFor="country_rus">
              Россия
            </label>
          </div>
          <div className="form-check ml-2">
            <input className="form-check-input" type="radio" name="country_of_organization" id="country_other" value="other_country" checked={country === 'other_country'} onChange={(e) => clickOtherCountry(e.target.value)} />
            <label className="form-check-label" htmlFor="country_other">
              Другая страна
            </label>
          </div>
        </div>


        {/* general radio-buttons */}
        <div className="form-group">
          <label className="text-muted">Общая информация</label>
          <div className="form-check ml-2">
            <input className="form-check-input" type="radio" name="general_type" id="ul" value="ul" checked={type === 'ul'} onChange={(e) => setType(e.target.value)} />
            <label className="form-check-label" htmlFor="ul">
              Юридическое лицо
            </label>
          </div>
          <div className="form-check ml-2">
            <input className="form-check-input" type="radio" name="general_type" id="fl" value="fl" checked={type === 'fl'} onChange={(e) => setType(e.target.value)} />
            <label className="form-check-label" htmlFor="fl">
              Физическое лицо
            </label>
          </div>
          <div className="form-check ml-2">
            <input className="form-check-input" type="radio" name="general_type" id="ip" value="ip" checked={type === 'ip'} onChange={(e) => setType(e.target.value)} disabled={country === 'other_country' ? true : false} />
            <label className="form-check-label" htmlFor="ip">
              ИП
            </label>
          </div>
        </div>


        <div className="form-group">
          <input type="text" name="name" className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="Название организации без кавычек и организационно-правовой формы" />
        </div>

        {/* if Russia */}
        {
          country === 'russia' ? (
            <>
              <div className="form-group">
                <input type="number" name="inn" className="form-control" value={inn} onChange={(e) => setInn(e.target.value)} placeholder="ИНН" />
              </div>
            </>
          ) : null
        }

        {/* if Other country */}
        {  
           country === 'other_country' ? (
            <>
              <div className="form-group">
                <input type="number" name="alternative_inn" className="form-control" value={alternative_inn} onChange={(e) => setAlternative_inn(e.target.value)} placeholder="Номер налогоплательщика либо его аналог в стране регистрации" />
              </div>

              <div className="form-group">
                <input type="number" name="reg_number" className="form-control" value={reg_number} onChange={(e) => setReg_number(e.target.value)} placeholder="Регистрационный номер либо его аналог" />
              </div>

              <div className="form-group">
                <input type="text" name="oksm_number" className="form-control" value={oksm_number} onChange={(e) => setOksm_number(e.target.value)} placeholder="Код страны регистрации юридического лица в соответствии с ОКСМ" />
              </div>
            </>
          ) : null
        }

        {/* data provider type */}
        <div className="form-group">
          <label className="text-muted">Тип поставщика данных</label>
          <div className="form-check ml-2">
            <input className="form-check-input" type="radio" name="data_provider_type" id="is_rr" value="is_rr" checked={data_provider_type_publisher === 'is_rr'} onChange={(e) => setData_provider_type_publisher(e.target.value)} />
            <label className="form-check-label" htmlFor="is_rr">
              Рекламораспространитель
            </label>
          </div>
          {/* <div className="form-check ml-2">
            <input className="form-check-input" type="radio" name="data_provider_type" id="is_rd" value="is_rd" checked={data_provider_type === 'is_rd'} onChange={(e) => setData_provider_type(e.target.value)} />
            <label className="form-check-label" htmlFor="is_rd">
              Рекламодатель
            </label>
          </div>
          <div className="form-check ml-2">
            <input className="form-check-input" type="radio" name="data_provider_type" id="is_agent" value="is_agent" checked={data_provider_type === 'is_agent'} onChange={(e) => setData_provider_type(e.target.value)} />
            <label className="form-check-label" htmlFor="is_agent">
              Рекламное агенство
            </label>
          </div> */}
        </div>

        {/* address */}
        <div className="form-group">
          <label className="text-muted">Адрес</label>
          <br />
          {/* legal address */}
          <b className="d-block mb-2">Юридический:</b>
          <div className="form-group">
            <input type="text" name="legal_address__locality" className="form-control" value={legal_address.city} onChange={(e) => legalAddressSet(e.target.value, 'city')} placeholder="Город / населенный пункт" />
          </div>

          <div className="form-group">
            <input type="text" name="legal_address__locality" className="form-control" value={legal_address.street} onChange={(e) => legalAddressSet(e.target.value, 'street')} placeholder="Улица" />
          </div>

          <div className="form-group">
            <input type="text" name="legal_address__house" className="form-control" value={legal_address.building} onChange={(e) => legalAddressSet(e.target.value, 'building')} placeholder="Дом, корп. /стр., офис / кв." />
          </div>

          <div className="form-group">
            <input type="text" name="legal_address__postcode" className="form-control" value={legal_address.postcode} onChange={(e) => legalAddressSet(e.target.value, 'postcode')} placeholder="Почтовый индекс" />
          </div>

          {/* post address */}
          <b className="d-block mb-2">Почтовый:</b>

          <div className="form-check d-flex align-items-center mb-2">
            <input className="form-check-input" type="checkbox" checked={addresses_equal} id="address_equal" onChange={(e) => setAddresses_equal(e.target.checked)} />
            <label className="form-check-label" htmlFor="address_equal">
              Совпадает с юридическим
            </label>
          </div>

          <div className="form-group">
            <input type="text" name="post_address__locality" className="form-control" value={post_address.city} onChange={(e) => postAddressSet(e.target.value, 'city')} placeholder="Город / населенный пункт" />
          </div>

          <div className="form-group">
            <input type="text" name="post_address__locality" className="form-control" value={post_address.street} onChange={(e) => postAddressSet(e.target.value, 'street')} placeholder="Улица" />
          </div>

          <div className="form-group">
            <input type="text" name="post_address__house" className="form-control" value={post_address.building} onChange={(e) => postAddressSet(e.target.value, 'building')} placeholder="Дом, корп. /стр., офис / кв." />
          </div>

          <div className="form-group">
            <input type="text" name="post_address__postcode" className="form-control" value={post_address.postcode} onChange={(e) => postAddressSet(e.target.value, 'postcode')} placeholder="Почтовый индекс" />
          </div>
        </div>

        {
          dataError.status ? <p className="form-text text-danger">{dataError.message}</p> : null
        }

        {
          dataSuccess.status ? <p className="form-text text-success d-flex align-items-center"><CheckCircle color={'#31c971'} size={14} className="mr-2" />{dataSuccess.message}</p> : null
        }

        <button className="btn btn-primary w-sm" onClick={sendPost}>Сохранить</button>

      </div>
    </div>
  )
}

const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(AdvertiserInfo);
