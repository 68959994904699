import React, { useState, useEffect } from 'react'

//apexcharts
import ReactApexChart from "react-apexcharts"
import ApexCharts from 'apexcharts'

//moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const ApexChart = ({detailedStat})=> {

  const [series, setSeries] = useState({
   general: []
  })

  const [optionsGeneral, setOptionsGeneral] = useState({
    chart: {
      // type: 'line',
      toolbar: {
        show: true
      },
      height: 350,
      stacked: false,
      id: 'internal_promotion'
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      y:{
        formatter: function(value, series) {
          // use series argument to pull original string from chart data
          return new Intl.NumberFormat('ru-RU').format(value);
        }
      }
    },
    title: {
      text: 'Общая статистика',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    // labels: true,
    colors: [
      '#F39C12', '#5D96F6', '#4285F4'
    ],
    markers: {
      size: 3,
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    xaxis: {
      labels: {
        rotate: 0,
        rotateAlways: false
      },
      categories: []
    },
    yaxis: [
      {
        seriesName: 'Показы',
        opposite: true,
        title: {
          text: 'Показы',
          style: {
            cssClass: 'chart-title__views-title'
          }
        },
        axisBorder: {
          show: true,
          color: "#5D96F6"
        },
        labels: {
          style: {
            cssClass: 'chart-title__views-label'
          }
        }
      },{
        seriesName: 'Клики',
        opposite: false,
        title: {
          text: 'Клики',
          style: {
            cssClass: 'chart-title__clicks-title'
          }
        },
        axisBorder: {
          show: true,
          color: "#4285F4"
        },
        labels: {
          style: {
            cssClass: 'chart-title__clicks-label'
          }
        }
      }
    ],
    legend: {
      horizontalAlign: 'left'
    }
  })

  useEffect(()=> {
    if(detailedStat && detailedStat.length){
      const dates = detailedStat.map(item => {
        let newLabel = moment(item.date).format('DD.MM');
        return newLabel;
      });
      const views = detailedStat.map(item => item.views);
      const clicks = detailedStat.map(item => item.clicks);

      setSeries({general: [{name: 'Показы', data: views}, {name: 'Клики', data: clicks}]})
      setOptionsGeneral({...optionsGeneral, xaxis: {categories: dates}})
    } else{
      setSeries({general: []})
      setOptionsGeneral({...optionsGeneral, xaxis: {categories: []}})
    }
  }, [detailedStat])

  return (
    <div id="chart">
      <div id="chart-timeline">
        <ReactApexChart
          options={optionsGeneral}
          series={series.general}
          height={350}/>
      </div>
    </div>
  )
}

export default ApexChart