import React, { useState, useEffect } from "react"
import './ExportStatistics.scss'

import { hostDetect } from "../../../../config/envConfig"

const ExportStatistics = ({siteId, startDate, endDate, groupBy, campaignId, detailedStat }) => {
  const [dropdown, setDropdown] = useState(false)
  const [exportEnabled, setExportEnabled] = useState(true)

  const [exportButtonActive, setExportButtonActive] = useState(false)

  useEffect(()=>{
    setDropdown(false)
    if(!campaignId){
      setExportEnabled(false)
    } else {
      setExportEnabled(true)
    }
  }, [siteId, startDate, endDate, groupBy, campaignId])

  useEffect(() => {
    if(detailedStat && detailedStat.length){
      setExportButtonActive(true)
    } else{
      setExportButtonActive(false)
    }
  }, [detailedStat])

  return(
    <div className="btn-group dropdown mt-3">
      <button 
        type="button" 
        className="btn btn-secondary dropdown-toggle"
        onClick={() => setDropdown(prev => !prev)}
        disabled={!exportEnabled || !exportButtonActive}>
        {
          exportButtonActive ? 
            exportEnabled ? 
              "Экспортировать" : "Кампании не созданы"
            : "Нет данных"
        }
      </button>
      {
        dropdown ? (
          <ul className="dropdown-menu-custom">
            <li className="dropdown-item-custom">
                <a href={`${hostDetect('api')}/ad-campaign/get-stats/?campaign_id=${campaignId}&date_from=${startDate}&date_to=${endDate}&group=${groupBy}&download_as=xls`}>
                  <svg 
                    style={{enableBackground: "new 0 0 128 128"}} 
                    version="1.1" 
                    viewBox="0 0 128 128" 
                    xmlSpace="preserve" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink">
                    <rect className="st0" height="29.8" width="49.3" x="78.7" y="34.2" style={{fill: "#21A365"}}/>
                    <rect className="st1" height="29.8" width="49.3" x="78.7" y="64" style={{fill: "#107C41"}}/>
                    <rect className="st1" height="29.8" width="49.2" x="29.5" y="34.2" style={{fill: "#107C41"}}/>
                    <path className="st2" d="M78.7,93.8V64H29.6v29.8v4.3v19.6c0,3.2,2.6,5.8,5.8,5.8h86.7c3.2,0,5.8-2.6,5.8-5.8V93.8H78.7z" style={{fill: "#185B37"}}/>
                    <path className="st3" d="M122.1,4.5H78.6v29.8h49.4V10.3C127.9,7.1,125.3,4.5,122.1,4.5z" style={{fill: "#33C481"}}/>
                    <path className="st0" d="M78.7,4.5H35.5c-3.2,0-5.8,2.6-5.8,5.8v23.9h49.1V4.5z" style={{fill: "#21A365"}}/>
                    <path className="st4" d="M59.5,96.5h-53c-3.5,0-6.4-2.9-6.4-6.4V37.9c0-3.5,2.9-6.4,6.4-6.4h53c3.5,0,6.4,2.9,6.4,6.4v52.2  C65.9,93.6,63.1,96.5,59.5,96.5z" style={{fill: "#17864C"}}/>
                    <g>
                      <path className="st5" d="M40.5,82.4l-3.9-7.1c-1.6-2.8-2.6-4.7-3.7-6.8h-0.1c-0.9,2.1-1.8,4-3.3,6.8L26,82.4h-7.6l10.8-18.1L18.7,46.5   h7.7l3.9,7.4c1.2,2.2,2.1,4,3,6h0.2c1-2.2,1.7-3.8,2.9-6l3.9-7.4h7.6L37.2,64l11.1,18.4H40.5z" style={{fill: "#FFFFFF"}}/>
                    </g>
                    <path className="st48" d="M66.7,37.3c0,0.2,0,0.4,0,0.6v52.2c0,3.5-2.9,6.4-6.4,6.4H30.4v5.7h35.2c3.5,0,6.4-2.9,6.4-6.4V43.6  C72.1,40.4,69.7,37.7,66.7,37.3z" style={{opacity: "0.2"}}/>
                  </svg>
                  Excel (XLS)
                </a>
            </li>
            <li className="dropdown-item-custom">
                <a href={`${hostDetect('api')}/ad-campaign/get-stats/?campaign_id=${campaignId}&date_from=${startDate}&date_to=${endDate}&group=${groupBy}&download_as=xlsx`}>
                  <svg 
                    style={{enableBackground: "new 0 0 128 128"}} 
                    version="1.1" 
                    viewBox="0 0 128 128" 
                    xmlSpace="preserve" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink">
                    <rect className="st0" height="29.8" width="49.3" x="78.7" y="34.2" style={{fill: "#21A365"}}/>
                    <rect className="st1" height="29.8" width="49.3" x="78.7" y="64" style={{fill: "#107C41"}}/>
                    <rect className="st1" height="29.8" width="49.2" x="29.5" y="34.2" style={{fill: "#107C41"}}/>
                    <path className="st2" d="M78.7,93.8V64H29.6v29.8v4.3v19.6c0,3.2,2.6,5.8,5.8,5.8h86.7c3.2,0,5.8-2.6,5.8-5.8V93.8H78.7z" style={{fill: "#185B37"}}/>
                    <path className="st3" d="M122.1,4.5H78.6v29.8h49.4V10.3C127.9,7.1,125.3,4.5,122.1,4.5z" style={{fill: "#33C481"}}/>
                    <path className="st0" d="M78.7,4.5H35.5c-3.2,0-5.8,2.6-5.8,5.8v23.9h49.1V4.5z" style={{fill: "#21A365"}}/>
                    <path className="st4" d="M59.5,96.5h-53c-3.5,0-6.4-2.9-6.4-6.4V37.9c0-3.5,2.9-6.4,6.4-6.4h53c3.5,0,6.4,2.9,6.4,6.4v52.2  C65.9,93.6,63.1,96.5,59.5,96.5z" style={{fill: "#17864C"}}/>
                    <g>
                      <path className="st5" d="M40.5,82.4l-3.9-7.1c-1.6-2.8-2.6-4.7-3.7-6.8h-0.1c-0.9,2.1-1.8,4-3.3,6.8L26,82.4h-7.6l10.8-18.1L18.7,46.5   h7.7l3.9,7.4c1.2,2.2,2.1,4,3,6h0.2c1-2.2,1.7-3.8,2.9-6l3.9-7.4h7.6L37.2,64l11.1,18.4H40.5z" style={{fill: "#FFFFFF"}}/>
                    </g>
                    <path className="st48" d="M66.7,37.3c0,0.2,0,0.4,0,0.6v52.2c0,3.5-2.9,6.4-6.4,6.4H30.4v5.7h35.2c3.5,0,6.4-2.9,6.4-6.4V43.6  C72.1,40.4,69.7,37.7,66.7,37.3z" style={{opacity: "0.2"}}/>
                  </svg>
                  Excel (XLSX)
              </a>
            </li>
            <li className="dropdown-item-custom">
                <a href={`${hostDetect('api')}/ad-campaign/get-stats/?campaign_id=${campaignId}&date_from=${startDate}&date_to=${endDate}&group=${groupBy}&download_as=csv`}>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    version="1.1" 
                    id="Layer_1" 
                    x="0px" 
                    y="0px" 
                    viewBox="0 0 115.28 122.88" 
                    style={{enableBackground: "new 0 0 115.28 122.88"}} 
                    xmlSpace="preserve">
                      <g>
                        <path 
                          className="st0" 
                          style={{fillRule: "evenodd", clipRule: "evenodd"}} 
                          d="M25.38,57h64.88V37.34H69.59c-2.17,0-5.19-1.17-6.62-2.6c-1.43-1.43-2.3-4.01-2.3-6.17V7.64l0,
                        0H8.15 c-0.18,0-0.32,0.09-0.41,0.18C7.59,7.92,7.55,8.05,7.55,8.24v106.45c0,0.14,0.09,0.32,0.18,0.41c0.09,0.14,0.28,
                        0.18,0.41,0.18 c22.78,0,58.09,0,81.51,0c0.18,0,0.17-0.09,0.27-0.18c0.14-0.09,0.33-0.28,0.33-0.41v-11.16H25.38c-4.14,
                        0-7.56-3.4-7.56-7.56 V64.55C17.82,60.4,21.22,57,25.38,57L25.38,57z M45.88,82.35l6.29,1.9c-0.42,1.76-1.09,3.24-2,4.42c-0.91,
                        1.18-2.03,2.08-3.38,2.68 c-1.35,0.6-3.06,0.9-5.14,0.9c-2.53,0-4.59-0.37-6.19-1.1c-1.6-0.74-2.98-2.03-4.14-3.87c-1.16-1.84-1.75-4.21-1.75-7.09 c0-3.84,
                        1.02-6.79,3.06-8.85c2.05-2.06,4.94-3.09,8.68-3.09c2.92,0,5.23,0.59,6.9,1.77c1.67,1.18,2.92,3,3.73,
                        5.45l-6.32,1.4 c-0.22-0.7-0.45-1.22-0.7-1.54c-0.41-0.55-0.9-0.97-1.48-1.26c-0.58-0.3-1.23-0.44-1.95-0.44c-1.63,0-2.88,0.65-3.75,
                        1.96 c-0.65,0.97-0.98,2.49-0.98,4.56c0,2.57,0.39,4.33,1.17,5.29c0.78,0.95,1.88,1.43,3.3,1.43c1.37,0,2.41-0.38,3.11-1.16 C45.06,
                        84.93,45.56,83.82,45.88,82.35L45.88,82.35z M54.47,84.17l6.81-0.43c0.15,1.1,0.45,1.95,0.9,2.52 c0.74,0.94,1.79,1.41,3.17,1.41c1.02,
                        0,1.81-0.24,2.36-0.72c0.56-0.48,0.83-1.04,
                        0.83-1.67c0-0.6-0.26-1.14-0.78-1.62 c-0.52-0.48-1.75-0.92-3.66-1.35c-3.15-0.7-5.38-1.64-6.72-2.82c-1.35-1.17-2.03-2.66-2.03-4.48c0-1.19,0.35-2.31,
                        1.04-3.37 c0.69-1.06,1.73-1.9,3.12-2.5c1.39-0.61,3.29-0.91,5.71-0.91c2.97,0,5.23,0.55,6.78,1.66c1.56,1.1,2.48,2.86,2.78,5.27l-6.75,
                        0.4 c-0.18-1.05-0.56-1.82-1.13-2.3c-0.58-0.48-1.37-0.72-2.38-0.72c-0.83,0-1.46,0.18-1.89,0.53c-0.42,0.35-0.63,0.78-0.63,1.29 c0,0.37,0.17,
                        0.7,0.51,0.99c0.33,0.31,1.13,0.59,2.39,0.85c3.14,0.68,5.38,1.36,6.73,2.05c1.36,0.69,2.35,1.55,2.96,2.57 c0.62,1.02,0.92,2.17,0.92,3.44c0,
                        1.49-0.41,2.86-1.23,4.12c-0.83,1.25-1.97,2.21-3.45,2.86c-1.48,0.65-3.34,0.97-5.58,0.97 c-3.95,0-6.68-0.76-8.2-2.28C55.53,88.44,54.67,86.51,
                        54.47,84.17L54.47,84.17z M76.91,68.63h7.5l5.23,16.71l5.16-16.71h7.28 l-8.62,23.22h-7.77L76.91,68.63L76.91,68.63z M97.79,57h9.93c4.16,0,7.56,
                        3.41,7.56,7.56v31.42c0,4.15-3.41,7.56-7.56,7.56h-9.93 v13.55c0,1.61-0.65,3.04-1.7,4.1c-1.06,1.06-2.49,1.7-4.1,1.7c-29.44,0-56.59,0-86.18,
                        0c-1.61,0-3.04-0.64-4.1-1.7 c-1.06-1.06-1.7-2.49-1.7-4.1V5.85c0-1.61,0.65-3.04,1.7-4.1c1.06-1.06,2.53-1.7,4.1-1.7h58.72C64.66,0,64.8,0,64.94,
                        0 c0.64,0,1.29,0.28,1.75,0.69h0.09c0.09,0.05,0.14,0.09,0.23,0.18l29.99,30.36c0.51,0.51,0.88,1.2,0.88,1.98 c0,0.23-0.05,0.41-0.09,0.65V57L97.79,
                        57z M67.52,27.97V8.94l21.43,21.7H70.19c-0.74,0-1.38-0.32-1.89-0.78 C67.84,29.4,67.52,28.71,67.52,27.97L67.52,27.97z"/>
                      </g>
                      </svg>
                  CSV
                </a>
            </li>
        </ul>
        ) : null
      }
    </div>
  )
}

export default ExportStatistics