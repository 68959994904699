import React from "react"
import {LogOut} from "react-feather";

const ExitLink = () => {
  return(
    <li>
      <a href="#" data-toggle="modal" data-target="#modalExit">
        <span className="nav-icon text-primary">
          <LogOut color={'#666'} size={15}/>
        </span>
        <span className="nav-text ml-0">Выход</span>
      </a>
    </li>
  )
}

export default ExitLink