import React, { useState, useEffect } from 'react'

// daterange picker
import './DateRange.scss'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css';

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')


const DateRange = ({startDate, endDate, setDatesModal}) => {
  const [state, setState] = useState({
    start: moment().startOf('month'),
    end: moment(),
  });
  
  // dates from Parent
  useEffect(()=>{
    if(startDate && endDate){
      setDatesModal({start: startDate, end: endDate})
    }
  }, [startDate, endDate])

  // new dates
  const handleCallback = (start, end) => {
    // setState({ start, end });
    setDatesModal({start: moment(start).format('YYYY-MM-DD'), end: moment(end).format('YYYY-MM-DD')})
  };

  let handleShowCalendar = () => {
    document.querySelector(".drp-buttons .custom").closest(".daterangepicker").classList.add('position-fixed');
  }


  return(
    <div className="date-range">
      <DateRangePicker
        initialSettings={
          { startDate: moment(startDate),
            endDate: moment(endDate),
            ranges: {
              'Сегодня': [moment(), moment()],
              'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
              'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
              'Последние 6 месяцев': [moment().subtract(6, 'month'), moment()],
              'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
              'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
              'customRangeLabel': 'Выбор даты',
              "daysOfWeek": [
                "Вс",
                "Пн",
                "Вт",
                "Ср",
                "Чт",
                "Пт",
                "Сб"
              ],
              "monthNames": [
                "Январь",
                "Февраль",
                "Март",
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь"
              ],
              "firstDay": 1,
              "applyLabel": "Применить",
              "cancelLabel": "Отмена",
            },
            buttonClasses: 'custom btn'
          }
        } onShow={handleShowCalendar} onCallback={handleCallback}>
        <input type="text" className="form-control" />
      </DateRangePicker>
    </div>
  )
}

export default DateRange