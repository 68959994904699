import React, { useState, useRef } from 'react';
import './CropImage.scss';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { CheckCircle, XCircle } from "react-feather";

const CropImage = ({sendCroppedImage}) => {
	const [image, setImage] = useState('');
	const [imageName, setImageName] = useState('Выберите изображение');

	const [imageSize, setImageSize] = useState(null)

	const [croppedImage, setCroppedImage] = useState(null);

	const handleFileInput = e => {
		e.preventDefault();
		setCroppedImage('');
		if(!e.target.files[0]){
      setImage('')
			setCroppedImage(null)
			setImageName('Выберите изображение')
			sendCroppedImage(null)
      setImageSize(null)
			return
		}

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if(files[0] && files[0].size){
      let size_mb = files[0].size/1000000,
          size_mb_rounded = Math.round(size_mb * 100) / 100
      setImageSize(size_mb_rounded)

      // if size bigger than 2 Mb
      if(size_mb_rounded >= 2){
        setImage('')
        setCroppedImage(null)
        setImageName('Выберите изображение')
        sendCroppedImage(null)
        return
      }
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setImageName(files[0].name);
    };
    reader.readAsDataURL(files[0]);
	}

	const cropperRef = useRef(null);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    setImage('');
    sendCroppedImage(cropper.getCroppedCanvas().toDataURL());

    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  };

	return (
		<div className="cropp-block">
      <label className="text-muted">Изображение</label>
      <div className="custom-file">
        <input type="file" className="custom-file-input" id="customFile" onChange={handleFileInput} accept=".jpg, .jpeg, .png"/>
        <label className={"custom-file-label " + (croppedImage ? 'is-valid' : '')} htmlFor="customFile">
        	{
        		imageName
        	}
        </label>

        {
          imageSize ? 
            <small className={`form-text text-muted text-right pb-2 ${imageSize > 2 ? 'text-danger' : 'text-success' }`}>
              {
                imageSize > 2 ?  <XCircle size={14} color={'#f54394'} style={{marginRight: 5}} /> :  <CheckCircle size={14} color={'#31c971'} style={{marginRight: 5}} />
              }
              Размер изображения - { imageSize } Мб
            </small> : 
            <small className="form-text text-muted text-right">Размер изображения не должен превышать 2 Мб.</small>
        }

      </div>
		
		{
			croppedImage ? (
				<div className="cropp-block__preview">
					<img src={croppedImage} alt=""/>
				</div>
			) : null
		}
			

		{
			image ? (
				<React.Fragment>
					<Cropper
			      src={image}
			      style={{ height: "auto", width: "auto", margin: "0 auto 10px" }}
			      // Cropper.js options
			      dragMode="move"
			      initialAspectRatio={4 / 3}
			      guides={true}
						viewMode={1}
						minCropBoxHeight={200}
          	minCropBoxWidth={200}
			      ref={cropperRef}
			      cropBoxResizable={true}
			      movable={false}
			      zoomable={false}
			    />
			    <p>
						<svg 
							xmlns="http://www.w3.org/2000/svg" 
							width="16" 
							height="16" 
							viewBox="0 0 24 24" 
							fill="none" 
							stroke="currentColor" 
							strokeWidth="2" 
							strokeLinecap="round" 
							strokeLinejoin="round" 
							className="feather feather-crop mx-2">
								<path d="M6.13 1L6 16a2 2 0 0 0 2 2h15"></path>
								<path d="M1 6.13L16 6a2 2 0 0 1 2 2v15"></path>
						</svg>
							Выберите необходимую область на изображении и нажмите "Обрезать".
					</p>
			    <button className="btn w-sm mb-1 btn-primary" onClick={onCrop}>Обрезать</button>
	    </React.Fragment>
	    ) : null
	  }
    </div>

	)
}

export default CropImage