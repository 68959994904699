import React, { useState, useEffect } from 'react'

import './InternalPromotion.scss'

import {
  BrowserView,
  MobileView
} from "react-device-detect";

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader';

import { hostDetect } from '../../config/envConfig';

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import GroupbyButtons from './GroupbyButtons/GroupbyButtons.js'
import GroupbyButtonsSelect from './GroupbyButtonsSelect/GroupbyButtons.js'

import DateRange from './DateRange/DateRange.js'

import InfoTable from './InfoTable/InfoTable.js'
import InfoTableMobile from './InfoTableMobile/InfoTableMobile.js'

import DetailsTable from './DetailsTable/DetailsTable.js'

import ApexChart from './ChartTable/ChartTable.js'

import DetailedStatistics from './DetailedStatistics/DetailedStatistics.js'

import { useHistory, Link } from "react-router-dom";

import { ArrowLeft } from "react-feather";

import Axios from 'axios';

import { connect } from 'react-redux'

import { useSelector } from 'react-redux';

import { setAlert } from '../../redux/actions/alertActions.js';


const InternalPromotion = ({setAlert}) => {

	const [apiLoading, setApiLoading] = useState(false)

	const [articlesType, setArticlesType] = useState('all');
	const [campaignId, setCampaignId] = useState(null)

	const [groupBy, setGroupBy] = useState('day')
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [totalStat, setTotalStat] = useState(null)
	const [detailedStat, setDetailedStat] = useState(null)

	// show details in DetailsTable and chart 
	const [showTable, setShowTable] = useState(false)

	const site_id = useSelector(state => state.siteInfo.site.id)

	// get initial data
	useEffect(()=>{
		if(campaignId && groupBy && (startDate && endDate)){

			// show loader while loading
      setApiLoading(true)

			let apiOptions = {
				withCredentials: true
			}		

			campaignId && Axios.get(`${hostDetect('api')}/ad-campaign/get-stats/?campaign_id=${campaignId}&date_from=${startDate}&date_to=${endDate}&group=${groupBy}`, apiOptions)
			.then(response => { 
				const success = response.data.success;
				if(success){
					// hide loader
					setApiLoading(false)

					// total statistics
					const total = response.data.data.total;
					if(total){
						setTotalStat(total)
					}

					// detailed statistics
					const detailed = response.data.data.details
					if(detailed){
						setDetailedStat(detailed)
					} else {
						setDetailedStat([])
					}

				} else {
					// hide loader
					setApiLoading(false)

					let errMessage = response.data.data.message;
          setAlert('error', errMessage);
				}
			})
			.catch(err => {
				// hide loader
        setApiLoading(false)

				setAlert('error', 'Не получилось отправить запрос!');
			})

		} else {
			// hide loader
			setApiLoading(false)
			
			setTotalStat(null)
		}
  }, [campaignId, groupBy, startDate, endDate, site_id])



	let history = useHistory();

	const handleBackClick = () => {
		history.push("/contentUp");
	}

	const handleArticlesSelect = event => {
		if(event.target.value === 'all'){
			setArticlesType(event.target.value);
		} else {
			const status = Number(event.target.value)
			setArticlesType(status);
		}
	}

	

	return(
		<div className="internal-promotion">

			<MobileHeader/>

			{
				apiLoading ? 
					<div className="loader__small">
							Загрузка данных...
					</div> : null
			}
			
			<div className="internal-promotion__back-btn">
	    	<button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
	      	<ArrowLeft size={15} style={{marginRight: 10}}/>Назад
	      </button>
	    </div>

	    <PageHeading mainText={"Внутреннее продвижение"} mutedText={"Продвигайте собственные материалы внутри сайта и отслеживайте эффективность. Просто добавьте необходимую статью по форме в рекомендации и увеличивайте маркетинговые показатели"}/>

	    <div className="internal-promotion__card">
	    	<div className="card">

	    		<div className="p-3">

	    			<div className="internal-promotion__controls mb-3">
							
							<Link to="/contentUp/internalPromotion/addArticle" className="internal-promotion__btn-add-article btn w-sm btn-success">Добавить статью</Link>

	    				<div className="internal-promotion__group-by">
	    					<BrowserView>
	    						<GroupbyButtons groupBy={groupBy} setGroupBy={setGroupBy}/>
	    					</BrowserView>
	    					<MobileView>
	    						<GroupbyButtonsSelect groupBy={groupBy} setGroupBy={setGroupBy}/>
	    					</MobileView>
	    				</div>
	    				<div className="internal-promotion__date-range">
	    					<DateRange setStartDate={setStartDate} setEndDate={setEndDate}/>
	    				</div>
	    			</div>

	    			<div className="internal-promotion__info-table">
	    				<BrowserView>
    						<InfoTable totalStat={totalStat} detailedStat={detailedStat} campaignId={campaignId} groupBy={groupBy} startDate={startDate} endDate={endDate} siteId={site_id}/>
    					</BrowserView>
    					<MobileView>
    						<InfoTableMobile totalStat={totalStat} detailedStat={detailedStat} campaignId={campaignId} groupBy={groupBy} startDate={startDate} endDate={endDate} siteId={site_id}/>
    					</MobileView>
	    			</div>
	    		</div>

	    		<div className="p-3">
	    			<DetailsTable showTable={showTable} setShowTable={setShowTable} detailedStat={detailedStat} groupBy={groupBy} campaignId={campaignId} startDate={startDate} endDate={endDate}/>
	    		</div>
	    		
					{
						showTable ? <ApexChart detailedStat={detailedStat}/> : null
					}
	    		
	    		<div className="card-header">
	    			<div className="r-2x no-r-b"> 
	    				<div className="row">
	    					<div className="col-12 col-sm-6">
		    					<div className="internal-promotion__card-titles">
		    						<p>Статьи в продвижении</p>
								
										<select className="form-control" value={articlesType} onChange={handleArticlesSelect}>
	    								<option value="all">Все</option>
	    								<option value="30">Активные</option>
	    								<option value="50">Остановленные</option>
	    								<option value="100">Архивные</option>
	    							</select>
		    					</div>
									
	    					</div>
	    					<div className="col-12 col-sm-6">
		    					<div className="internal-promotion__card-add-btn"> 
										<Link to="/contentUp/internalPromotion/addArticle" className="internal-promotion__btn-add-article w-sm btn btn-success">Добавить статью</Link>
		    					</div>
	    					</div>
	    				</div>
	    			</div>
	    		</div>

	    		<DetailedStatistics articlesFilterType={articlesType} setCampaignId={setCampaignId} startDate={startDate} endDate={endDate} site_id={site_id}/>

	    	</div>
	    </div>
		</div>
	)
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(InternalPromotion)