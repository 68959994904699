import React, { useState } from 'react'
import './GroupbyButtons.scss'

import { connect } from 'react-redux'

const GroupbyButtons = (props) => {
  const [activeBtn, setActiveBtn] = useState(props.groupBy)

  // console.log('act group', activeBtn);

  const handleClickGroup = query =>{
    setActiveBtn(query)
    props.changeGroupByQuery(query)
  }

  return(
    <div className="groupby">
      <p className="nav-header hidden-folded" style={{fontWeight: 500}}>
        Группировать по:
      </p>
      <div className="groupby__btns">
        <button className={'btn ' + (activeBtn === 'day' ? 'active': '')} onClick={()=>handleClickGroup('day')}>По дням</button>
        <button className={'btn ' + (activeBtn === 'week' ? 'active': '')} onClick={()=>handleClickGroup('week')}>По неделям</button>
        <button className={'btn ' + (activeBtn === 'month' ? 'active': '')} onClick={()=>handleClickGroup('month')}>По месяцам</button>
      </div>
    </div>
  )
}

function mapStateToProps(state){
  return{
    groupBy: state.siteInfo.site.groupBy
  }
}

function mapDispatchToProps(dispatch){
  return {
    changeGroupByQuery: groupByQuery => dispatch({type: 'CHANGE_GROUPBY_QUERY', payload: groupByQuery})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupbyButtons)