import React, {useState, useEffect} from 'react'

const MobileSubtab = ({textPosition, setTextPosition, grid_x, setGrid_x, grid_y, setGrid_y, headerTitle, setHeaderTitle}) => {

  const [showBubble1, setShowBubble1] = useState(false)
  const [showBubble2, setShowBubble2] = useState(false)

  const setBubble = (range, bubble) => {
    const newValue = Number( (range.value - range.min) * 100 / (range.max - range.min) ),
          newPosition = 10 - (newValue * 0.2);
          bubble.innerHTML = `<span>${range.value}</span>`;
          bubble.style.left = `calc(${newValue}% + (${newPosition}px))`;
  }

  useEffect(() => {
    if(grid_x){
      setBubble({value: grid_x, min: 1, max: 2}, document.querySelector('#bubble1'))
    }
  }, [grid_x])

  useEffect(() => {
    if(grid_y){
      setBubble({value: grid_y, min: 1, max: 10}, document.querySelector('#bubble2'))
    }
  }, [grid_y])

  return (
    <>
      {/* text position */}
      <div className="form-group">
        <label>Шаблоны</label>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="text-position" 
              value="bottom"
              onChange={e => setTextPosition(e.target.value)} 
              checked={textPosition === 'bottom'} /> 
              Графический с текстом снизу
          </label>
        </div>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="text-position" 
              value="combined"
              onChange={e => setTextPosition(e.target.value)}
              checked={textPosition === 'combined'} />
              Графический комбинированный
          </label>
        </div>
        <div className="form-check mb-1">
          <label className="form-check-label">
            <input 
              className="form-check-input" 
              type="radio" 
              name="text-position" 
              value="right"
              onChange={e => setTextPosition(e.target.value)}
              checked={textPosition === 'right'} />
              Графический с текстом справа
          </label>
        </div>
      </div>
      
      {/* grid ranges */}
      <label>Сетка</label>
      <div className="form-group">

        <div>
          <small className="d-block m-0 text-muted pl-2">По горизонтали</small>
          <div className="d-flex">
            <small className="text-muted p-2">1</small>
            <div className="range-wrap">
              <input 
                type="range" 
                className="form-range w-100" 
                min="1" 
                max="2" 
                value={grid_x}
                onChange={e => setGrid_x(e.target.value)} 
                onInput={e => setBubble(e.target, document.querySelector('#bubble1'))}
                onFocus={() => setShowBubble1(true)}
                onBlur={() => setShowBubble1(false)}
                disabled={textPosition === 'combined' ? true : false} />
              <output id="bubble1" className={`bubble ${showBubble1 === true ? '' : 'hidden'}`}></output>
            </div>
            <small className="text-muted p-2">2</small>
          </div>
        </div>
        
        <div>
          <small className="d-block m-0 text-muted pl-2">По вертикали</small>
          <div className="d-flex">
            <small className="text-muted p-2">1</small>
            <div className="range-wrap">
              <input 
                type="range" 
                className="form-range w-100" 
                min="1" 
                max="10"
                value={grid_y}
                onChange={e => setGrid_y(e.target.value)} 
                onInput={e => setBubble(e.target, document.querySelector('#bubble2'))} 
                onFocus={() => setShowBubble2(true)}
                onBlur={() => setShowBubble2(false)} />
              <output id="bubble2" className={`bubble ${showBubble2 === true ? '' : 'hidden'}`}></output>
            </div>
            <small className="text-muted p-2">10</small>
          </div>
        </div>

      </div>

      {/* header title */}
      <div className="form-group">
        <label>Заголовок</label>
        <input 
          type="text" 
          className="form-control" 
          value={headerTitle} 
          onChange={e => setHeaderTitle(e.target.value)} 
          placeholder='Например: "Читайте также:"' />
        {
          headerTitle.length < 3 || headerTitle.length > 64
            ? <small className="form-text text-muted">Заголовок должен содержать минимум 3 и максимум 64 знака</small>
            : null
        }
      </div>
    </>
  )
}

export default MobileSubtab