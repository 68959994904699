import React from 'react'

import './Tab3.scss'

const Tab3 = () => {
  return (
    <div className="final-tab">
      <p>Код для добавления на сайт будет доступен после модерации вашего сайта</p>
      <button className="btn w-sm mb-1 btn-primary" >Хорошо</button>
    </div>
  )
}

export default Tab3