import React from 'react'

const WidgetCreation = () => {
  return (
    <div>
      widgets
    </div>
  )
}

export default WidgetCreation