import React, {useEffect, useState} from 'react'
import {BarChart, DollarSign, RefreshCw, TrendingUp, PieChart, PlusCircle, Server, User} from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

import './NavLinks.scss'

const NavLinks = props => {

  let menu = [
    {
      id: 0,
      text: 'Статистика',
      href: '/income',
      icon: BarChart,
      iconColor: '#666',
      iconSize: 15,
      newFeature: false,
      siteStatus: false,
      submenu: [
        {
          id: 0.1,
          text: 'Монетизация и обмен',
          href: '/income/main',
          iconColor: '#666',
          iconSize: 15,
          hasSearchParams: false
        },
        {
          id: 0.2,
          text: 'Баннеры',
          href: '/income/banner?type=30',
          iconColor: '#666',
          iconSize: 15,
          hasSearchParams: true
        }
      ]
    },
    {
      id: 1,
      text: 'Статьи для обмена',
      href: '/exchange',
      icon: RefreshCw,
      iconColor: '#666',
      iconSize: 15,
      newFeature: false,    // красная метка на ссылке - new 
      siteStatus: 'active',  // только для активных сайтов
      submenu: [
        {
          id: 1.1,
          text: 'Активные',
          href: '/exchange/active',
          iconColor: '#666',
          iconSize: 15
        },
        {
          id: 1.2,
          text: 'Добавить статью',
          href: '/exchange/add',
          iconColor: '#666',
          iconSize: 15
        },
        {
          id: 1.3,
          text: 'Архив',
          href: '/exchange/archive',
          iconColor: '#666',
          iconSize: 15
        },
      ]
    },
    {
      id: 2,
      text: 'Продвижение контента',
      href: '/contentUp',
      icon: TrendingUp,
      iconColor: '#666',
      iconSize: 15,
      newFeature: false,
      siteStatus: 'active'
    },
    {
      id: 3,
      text: 'Блоки',
      href: `/settings/widgets`,
      icon: Server,
      iconColor: '#666',
      iconSize: 15,
      newFeature: false,
      siteStatus: 'active'
    },
    // {
    //   id: 4,
    //   text: 'Финансы',
    //   href: `/finance`,
    //   icon: DollarSign,
    //   iconColor: '#666',
    //   iconSize: 15,
    //   newFeature: false,
    //   siteStatus: 'active'
    // },
    {
      id: 5,
      text: 'Профиль пользователя',
      href: `/settings/editPersonData`,
      icon: User,
      iconColor: '#666',
      iconSize: 15,
      newFeature: false,
      siteStatus: 'active'
    }
    // {
    //   id: 3,
    //   text: 'Финансы',
    //   href: '/finance',
    //   icon: DollarSign,
    //   iconColor: '#666',
    //   iconSize: 15,
    //   newFeature: false,
    //   siteStatus: 'active'
    // },
    // {
    //   id: 4,
    //   text: 'Добавить сайт',
    //   href: '/add/sites/createSite',
    //   icon: PlusCircle,
    //   iconColor: '#666',
    //   iconSize: 15,
    //   newFeature: false
    // },
    // {
    //   id: 5,
    //   text: 'Добавить блок',
    //   href: `/add/widgets/createWidget/${props.currentSiteID}`,
    //   icon: Server,
    //   iconColor: '#666',
    //   iconSize: 15,
    //   newFeature: false,
    //   siteStatus: 'active'
    // }
  ];


  const routeLocation = useLocation();

  const checkUrlIncludes = currentUrl => {
    // url из списка
    currentUrl = currentUrl.split('/').slice(1);
    // деструктуризация url -  [add, site] = add/site
    let [currUrlFirst, currUrlSecond] = currentUrl

    // url из браузера
    let urlPaths = routeLocation.pathname.split('/').slice(1);
    // деструктуризация url - [add, site] = add/site
    let [urlPathsFirst, urlPathsSecond] = urlPaths

    // проверка первой части url - add?
    if(urlPathsFirst.includes(currUrlFirst)){

      if(currUrlSecond && urlPathsSecond){
        if(currUrlSecond.includes(urlPathsSecond)){
          return true
        } else {
          return false
        }
      }

      return true
    } else {
      return false
    }
  }

  

  return(
    <React.Fragment>
      {
        (props.userSites.length>0) && menu.map((linkInfo)=>{
          const Icon = linkInfo.icon;
          return (
            <li className={ checkUrlIncludes(linkInfo.href) ? ' active' : ''} key={linkInfo.id}>
              {
                linkInfo.submenu ? (
                  <a className={`${linkInfo.siteStatus && linkInfo.siteStatus !== props.siteStatus ? 'disabled-link' : ''}`}>
                    <span className="nav-icon text-primary">
                      <Icon color={linkInfo.iconColor} size={linkInfo.iconSize}/>
                    </span>
                    <span className={"nav-text ml-0 " + (linkInfo.newFeature === true ? 'new-features' : '')}>{linkInfo.text}</span>
                    {
                      linkInfo.submenu ? <span className="nav-caret"></span> : null
                    }
                  </a>
                ) : (
                  <Link to={linkInfo.href} className={`${linkInfo.siteStatus && linkInfo.siteStatus !== props.siteStatus ? 'disabled-link' : ''}`}>
                    <span className="nav-icon text-primary">
                      <Icon color={linkInfo.iconColor} size={linkInfo.iconSize}/>
                    </span>
                    <span className={"nav-text ml-0 " + (linkInfo.newFeature === true ? 'new-features' : '')}>{linkInfo.text}</span>
                    {
                      linkInfo.submenu ? <span className="nav-caret"></span> : null
                    }
                  </Link>
                )
              }
              
              {
                linkInfo.submenu ? (
                  <ul className="nav-sub nav-mega">
                    {
                      linkInfo.submenu.map(sublink => {

                        // remove this -----------------------------
                        if(sublink.href == '/income/banner?type=30'){
                          if(props.userId == 94068 || props.userId == 94313 || props.userId == 94472 || props.userId == 94568){
                            return (
                              <li className={ checkUrlIncludes(sublink.href) ? ' active' : ''} key={sublink.id}>
                                <Link to={sublink.href}>
                                  <span className="nav-text">{sublink.text}</span>
                                </Link>
                              </li>
                            )
                          } else {
                            return
                          }
                        }
                        // remove this -----------------------------
                       
                        return(
                          <li className={ checkUrlIncludes(sublink.href) ? ' active' : ''} key={sublink.id}>
                            <Link to={sublink.href}>
                              <span className="nav-text">{sublink.text}</span>
                            </Link>
                          </li>
                        )
                      })
                    }
                  </ul>
                ) : null
              }
            </li>
          )
        })
      }
    </React.Fragment>
  )
}

function mapStateToProps(state){
  return{
    userSites: state.userInfo.user.sites,
    currentSiteID: state.siteInfo.site.id,
    siteStatus: state.siteInfo.site.status,
    userId: state.userInfo.user.id
  }
}

export default connect(mapStateToProps)(NavLinks)