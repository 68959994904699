import React, { useEffect, useState } from 'react'

import {
  BrowserView,
  MobileView
} from "react-device-detect";

import { hostDetect } from '../../../../config/envConfig.js';

import GroupByButtons from './GroupByButtons/GroupByButtons.js'
import GroupByButtonsSelect from './GroupByButtonsSelect/GroupByButtons.js'

import DateRange from './DateRange/DateRange.js'

import InfoTable from './InfoTable/InfoTable.js'
import InfoTableMobile from './InfoTableMobile/InfoTableMobile.js'

import DetailsTable from './DetailsTable/DetailsTable.js'

import ApexChart from './ChartTable/ChartTable.js'

import Axios from 'axios';

import { connect } from 'react-redux'

import { setAlert } from '../../../../redux/actions/alertActions.js';

const StatisticsModal = ({closeModal, advId, startDate, endDate, setAlert}) => {
	const [groupBy, setGroupBy] = useState('day')
	const [datesModal, setDatesModal] = useState({
		start: null,
		end: null
	})

	const [totalStat, setTotalStat] = useState(null)
	const [detailedStat, setDetailedStat] = useState([])

	// show details in DetailsTable and chart  
	const [showTable, setShowTable] = useState(false)

	useEffect(()=> {

		if(groupBy && datesModal.start && datesModal.end && advId){
			let apiOptions = {
				withCredentials: true
			}
	
			// advId for test - 170684
			Axios.get(`${hostDetect('api')}/ad/get-stats/?ad_id=${advId}&date_from=${datesModal.start}&date_to=${datesModal.end}&group=${groupBy}`, apiOptions)
			.then(response => {
				const success = response.data.success;

					if(success){
						// total statistics
						const total = response.data.data.total;
						if(total){
							setTotalStat(total)
						}
	
						// detailed statistics
						const detailed = response.data.data.details
						if(detailed){
							setDetailedStat(detailed)
						} else {
							setDetailedStat([])
						}
	
					} else {
						let errMessage = response.data.data.message;
						setAlert('error', errMessage);
					}
			})
			.catch(err => {
				setAlert('error', 'Не получилось отправить запрос!');
			})
		}

	}, [groupBy, datesModal, advId])
	

	
	return(
		<div className="settings-modal">
			<div className="settings-modal__inner modal-content box-shadow">
				<div className="modal-header">
					<div>
						<h5 className="modal-title">Статистика</h5>
						<small className="text-muted">id объявления - #{advId}</small>
					</div>
					<button className="close" onClick={closeModal}>×</button>
				</div>

				<div className="p-3">

					<div className="settings-modal__top-controls statistics">
						<BrowserView>
							<GroupByButtons groupBy={groupBy} setGroupBy={setGroupBy}/>
						</BrowserView>
						<MobileView>
							<GroupByButtonsSelect groupBy={groupBy} setGroupBy={setGroupBy}/>
						</MobileView>

						<DateRange startDate={startDate} endDate={endDate} setDatesModal={setDatesModal}/>
					</div>

					<BrowserView>
						<InfoTable totalStat={totalStat} detailedStat={detailedStat} advId={advId} groupBy={groupBy} datesModal={datesModal}/>
					</BrowserView>
					<MobileView>
						<InfoTableMobile totalStat={totalStat} detailedStat={detailedStat} advId={advId} groupBy={groupBy} datesModal={datesModal}/>
					</MobileView>

					<DetailsTable showTable={showTable} setShowTable={setShowTable} detailedStat={detailedStat} groupBy={groupBy} advId={advId} datesModal={datesModal} />	

					{
						showTable ? <ApexChart detailedStat={detailedStat}/> : null
					}
					
				</div>
			</div>
		</div>
	)
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(StatisticsModal)