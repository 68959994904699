import React from 'react'

import './Preview.scss'

import SparrowLogo from '../../../components/commonComponents/sparrowLogo/SparrowLogo';

const Preview = ({ blockType, headerTitle, grid_x, grid_y, textPosition, infiniteTGBType, deviceType, bannerType }) => {

  let id = 0;

  let articles = [
    {
      img: '0.jpg',
      text: 'Рост цен на такси в Москве'
    },
    {
      img: '1.jpg',
      text: 'Какую косметику выбирают россиянки'
    },
    {
      img: '2.jpg',
      text: 'Топ-5 ресторанов для встречи с друзьями'
    },
    {
      img: '3.jpg',
      text: 'Цифровой рубль в России'
    },
    {
      img: '4.jpg',
      text: 'Рецепт вкусных заготовок'
    },
    {
      img: '5.jpg',
      text: 'Курьезы в спорте - такое бывает'
    },
    {
      img: '6.jpg',
      text: 'Синхронное плаванье становится популярным у молодежи'
    },
    {
      img: '7.jpg',
      text: 'Сколько зарабатывает доставщик еды в Москве: рассказываем'
    },
    {
      img: '8.jpg',
      text: 'Подготовиться к свадьбе легко'
    },
    {
      img: '9.jpg',
      text: 'На чем экономят россияне'
    },
    {
      img: '10.jpg',
      text: 'Рост ставки ЦБ в 24 году - миф или реальность'
    },
    {
      img: '11.jpg',
      text: 'Можно ли устроиться на работу без диплома'
    },
    {
      img: '12.jpg',
      text: 'Новый ЖК на берегу Невы'
    },
    {
      img: '13.jpg',
      text: 'Рейтинг застройщиков 2024 года'
    },
    {
      img: '14.jpg',
      text: 'С этим сталкиваются россияне за рубежом'
    },
    {
      img: '15.jpg',
      text: 'Массовые задержания на Красной площади: это стало причиной'
    },
    {
      img: '16.jpg',
      text: 'На Россию надвигается циклон'
    },
    {
      img: '17.jpg',
      text: 'Помощник для кожи в мороз'
    },
    {
      img: '18.jpg',
      text: 'Любимцы многих: коты как антистресс'
    },
    {
      img: '19.jpg',
      text: 'ГИБДД остановили странную пару: причина'
    },
  ]

  const countFontSize = (count_x) => {
    if(count_x == 1){
      return {fontSize: 14, lineHeight: '18px', 'height': '72px'}
    }
    if(count_x == 2){
      return {fontSize: 14, lineHeight: '18px', 'height': '72px'}
    }
    if(count_x == 3){
      return {fontSize: 14, lineHeight: '18px', 'height': '72px'}
    }
    if(count_x == 4){
      return {fontSize: 12, lineHeight: '16px', 'height': '64px', 'bottom': '22px'}
    }
    if(count_x == 5){
      return {fontSize: 12, lineHeight: '16px', 'height': '64px', 'bottom': '25px'}
    }
    if(count_x == 6){
      return {fontSize: 10, lineHeight: '13px', 'height': '52px', 'bottom': '25px'}
    }
    if(count_x == 7){
      return {fontSize: 9, lineHeight: '12px', 'height': '48px', 'bottom': '20px', 'padding': '0 5px'}
    }
    if(count_x == 8){
      return {fontSize: 9, lineHeight: '12px', 'height': '48px', 'bottom': '20px', 'padding': '0 5px'}
    }
    if(count_x == 9){
      return {fontSize: 9, lineHeight: '12px', 'height': '48px', 'bottom': '15px', 'padding': '0 5px'}
    }
    if(count_x == 10){
      return {fontSize: 7, lineHeight: '10px', 'height': '40px', 'bottom': '15px', 'padding': '0 5px'}
    }
  }

  const countNoticeSize = (count_x) => {
    if(count_x == 1){
      return {fontSize: 11}
    }
    if(count_x == 2){
      return {fontSize: 11}
    }
    if(count_x == 3){
      return {fontSize: 11}
    }
    if(count_x == 4){
      return {fontSize: 10, bottom: '4px'}
    }
    if(count_x == 5){
      return {fontSize: 9, bottom: '4px'}
    }
    if(count_x == 6){
      return {fontSize: 9, bottom: '4px'}
    }
    if(count_x == 7){
      return {fontSize: 8, bottom: '4px', left: '5px'}
    }
    if(count_x == 8){
      return {fontSize: 7, bottom: '4px', left: '5px'}
    }
    if(count_x == 9){
      return {fontSize: 6, bottom: '4px', left: '5px'}
    }
    if(count_x == 10){
      return {fontSize: 6, bottom: '4px', left: '5px'}
    }
  }

  const calcHeight = (count_x) => {
    if(count_x == 1){
      return '220px'
    }
    if(count_x == 2){
      return '215px'
    }
    if(count_x == 3){
      return '180px'
    }
    if(count_x == 4){
      return '155px'
    }
    if(count_x == 5){
      return '140px'
    }
    if(count_x == 6){
      return '122px'
    }
    if(count_x == 7){
      return '100px'
    }
    if(count_x == 8){
      return '100px'
    }
    if(count_x == 9){
      return '90px'
    }
    if(count_x == 10){
      return '80px'
    }
  }


  return (
    <div className="preview">
      <div className="preview__wrap">
        <div className={`preview__wrap-bg ${blockType == 'banner' ? 'banner--wrapper' : ''} ${deviceType == 'desktop' ? 'banner--wrapper-desktop' : 'banner--wrapper-mobile'}`}>

          {
            blockType !== 'banner' ? (
              <div className="preview__header">
                <p className="preview__header-title">{headerTitle}</p>
                <SparrowLogo />
              </div>
            ) : null
          }

          {/* native */}
          {
            blockType === 'native' || blockType === 'amp' ? 
              [...Array(Number(grid_y))].map((e, i) => {
                return (
                  <div className={`preview__row ${grid_x == 2 ? 'two-cell' : ''} ${textPosition === 'right' ? 'article-text-right' : 'article-text-bottom'} ${textPosition === 'combined' ? 'article-text-combined' : ''}`} key={i}>
                    {
                      [...Array(Number(grid_x))].map((ee, ii) => {
                        
                        let article_id = id;

                        if(id === 19){
                          id = 0;
                        } else {
                          id++;
                        }

                        return (
                          <div className="preview-article" key={ii} style={{width: `calc(${100 / grid_x }% - 10px)`, height: calcHeight(grid_x)}}>
                            <div className="preview-article__image">
                              <img src={require(`./images/${articles[article_id].img}`)} />
                            </div>
                            <div className="preview-article__text" style={countFontSize(grid_x)}>
                              {articles[article_id].text}
                            </div>
                            <span className="preview-article__notice" style={countNoticeSize(grid_x)}>sparrow.ru</span>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            : null
          }

          {/* infinite */}
          {
            blockType === 'infinite' ? 
              (
                <div className={`preview__row tgb tgb-${infiniteTGBType}`} >
                  {
                    [...Array(Number(infiniteTGBType))].map((e,i) => {
                      let article_id = id;

                      if(id === 19){
                        id = 0;
                      } else {
                        id++;
                      }

                      let bgImage = require(`./images/${articles[article_id].img}`)

                      return (
                        <div className="preview-article" key={i}>
                          <div className="preview-article__image" style={{backgroundImage: `url('${bgImage}')`}}></div>
                          <div className="preview-article__text">
                            {articles[article_id].text}
                          </div>
                          <span className="preview-article__notice" style={countNoticeSize(grid_x)}>sparrow.ru</span>
                        </div>
                      )
                    })
                  }
                </div>
              ) : null
          }

          {/* backpage */}
          {
            blockType === 'backpage' ? 
              deviceType === 'mobile' ? (
                <>
                  {
                    [...Array(Number(1))].map((e,i) => {
                      return (
                        <div  key={i}>
                          <div className={`preview__row tgb backpage-bg backpage-2 tgb-${infiniteTGBType}`}>
                            {
                              [...Array(Number(2))].map((e,ii) => {
                                let article_id = id;
      
                                if(id === 19){
                                  id = 0;
                                } else {
                                  id++;
                                }
      
                                let bgImage = require(`./images/${articles[article_id].img}`)
      
                                return (
                                  <div className="preview-article" key={ii}>
                                    <div className="preview-article__image" style={{backgroundImage: `url('${bgImage}')`}}></div>
                                    <div className="preview-article__text">
                                      {articles[article_id].text}
                                    </div>
                                    <span className="preview-article__notice" style={countNoticeSize(grid_x)}>sparrow.ru</span>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </>
              ) : (
                <>
                  {
                    [...Array(Number(1))].map((e,i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className={`preview__row tgb backpage-bg backpage-${5}`} >
                            {
                              [...Array(Number(5))].map((e,ii) => {
                                let article_id = id;
      
                                if(id === 19){
                                  id = 0;
                                } else {
                                  id++;
                                }
      
                                let bgImage = require(`./images/${articles[article_id].img}`)
      
                                return (
                                  <div className="preview-article" key={ii}>
                                    <div className="preview-article__image" style={{backgroundImage: `url('${bgImage}')`}}></div>
                                    <div className="preview-article__text">
                                      {articles[article_id].text}
                                    </div>
                                    <span className="preview-article__notice" style={countNoticeSize(grid_x)}>sparrow.ru</span>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </>
              ) 
            : null
          }

          {/* turbo */}
          {
            blockType === 'turbo' ? 
              [...Array(Number(grid_y))].map((e, i) => {
                return (
                  <div className={`preview__row ${grid_x == 2 ? 'two-cell' : ''} article-text-right`} key={i}>
                    {
                      [...Array(Number(grid_x))].map((ee, ii) => {
                        
                        let article_id = id;

                        if(id === 19){
                          id = 0;
                        } else {
                          id++;
                        }

                        return (
                          <div className="preview-article" key={ii} style={{width: `calc(${100 / grid_x }% - 5px)`, height: calcHeight(grid_x)}}>
                            <div className="preview-article__image">
                              <img src={require(`./images/${articles[article_id].img}`)} />
                            </div>
                            <div className="preview-article__text" style={countFontSize(grid_x)}>
                              {articles[article_id].text}
                            </div>
                            <span className="preview-article__notice" style={countNoticeSize(grid_x)}>sparrow.ru</span>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }) 
            : null
          }

          {/* banner */}
          {
            blockType === 'banner' ? (
              <img src={require(`./images/banners/${bannerType}.png`)} />
            ) : null
          }
        </div>
      </div>
    </div>
  )
}

export default Preview