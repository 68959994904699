import React from "react"

import './AddSite.scss'

// redux
import { connect } from 'react-redux'

import { Plus } from "react-feather"

import { Link } from "react-router-dom";

const AddSite = ({ userSites }) => {
  return(
    <>
      {
        userSites.length ? (
          <li className="add-site-button">
            <Link to='/add/sites/createSite' className="btn mb-1 btn-outline-primary p-0">
              <span className="nav-icon text-primary">
                <Plus color={'#448bff'} size={15}/>
              </span>
              <span className="nav-text ml-0 text-left">Добавить сайт</span>
            </Link>
          </li>
        ) : null
      }
    </>
    
  )
}



function mapStateToProps(state){
  return{
    userSites: state.userInfo.user.sites
  }
}

export default connect(mapStateToProps, null)(AddSite)