import React from 'react'

import './SiteStatus.scss'

// redux
import { connect } from 'react-redux'

import {Check, AlertOctagon, Clock} from "react-feather";

const SiteStatus = ({siteStatus}) => {

  const handleStatus = (status) => {
    if(status === 'new'){
      return 'moderated'
    } else if(status === 'active'){
      return 'active'
    } else if(status === 'disabled'){
      return 'disabled'
    }
  }

  return (
    <div className="site-status">
      <p className="nav-header hidden-folded" style={{fontWeight: 500}}>
        Статус сайта :
      </p>
      <div className={`site-status__text ${handleStatus(siteStatus)}`}>

        {
          siteStatus === 'new' ? (
            <>
              <Clock size={14} />
              Новый, на модерации
            </>
          ) : null
        }
        {
          siteStatus === 'active' ? (
            <>
              <Check size={14} />
              Активный, прошел модерацию
            </>
          ) : null
        }
        {
          siteStatus === 'disabled' ? (
            <>
             <AlertOctagon size={14} />
              Заблокирован, не прошел модерацию
            </>
          ) : null
        }
      </div>
    </div>
  )
}


function mapStateToProps(state){
  return{
    siteStatus: state.siteInfo.site.status
  }
}

export default connect(mapStateToProps)(SiteStatus)