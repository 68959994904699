import React from "react";
import "./Pagination_api.scss"
import { usePag, DOTS } from "./usePag";

const Pagination_api = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize
  } = props;

  const paginationRange = usePag({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className="pagination">
      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={onPrevious} key={'back_btn'} >
        <a className="page-link">
          <span aria-hidden="true">«</span>
        </a>
      </li>

      { 
        paginationRange.map((pageNumber, i) => {
          if (pageNumber === DOTS) {
            return <li className="page-item dots" key={`dotts ${i}`}><a className="page-link">&#8230;</a></li>;
          }

          return (
            <li className={`page-item ${pageNumber === currentPage ? 'active' : ''}`} onClick={() => onPageChange(pageNumber)} key={i}>
              <a className="page-link">
                {pageNumber}
              </a>
            </li>
          );
        })
      }

      <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`} onClick={onNext}  key={'next_btn'} >
        <a className="page-link">
          <span aria-hidden="true">»</span>
        </a>
      </li>

    </ul>
  );
};

export default Pagination_api;