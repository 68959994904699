import React, { useState } from 'react'
import './GroupByButtons.scss'

const GroupbyButtons = ({groupBy, setGroupBy}) => {

  return(
    <div className="groupby">
      <div className="groupby__btns">
        <button className={'btn '+'btn-outline-primary '+(groupBy === 'day' ? 'active': '')} onClick={()=>setGroupBy('day')}>По дням</button>
        <button className={'btn '+'btn-outline-primary '+(groupBy === 'week' ? 'active': '')} onClick={()=>setGroupBy('week')}>По неделям</button>
        <button className={'btn '+'btn-outline-primary '+(groupBy === 'month' ? 'active': '')} onClick={()=>setGroupBy('month')}>По месяцам</button>
      </div>
    </div>
  )
}

export default GroupbyButtons