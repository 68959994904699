import React, { useState, useEffect } from 'react'

import './BlockTeaser.scss'

import { hostDetect } from '../../config/envConfig';

import {
  useLocation,
} from "react-router-dom";

import Axios from 'axios';

const BlockTeaser = () => {

  const [siteId, setSideId] = useState('')
  const [teaserId, setTeaserId] = useState('')
  const [adInfo, setAdsInfo] = useState('')

  const [errConnection, setErrConnection] = useState(false)
  const [errMessage, setErrMessage] = useState('')

  const [successMessage, setSuccessMessage] = useState(false)

  const [adBlocked, setAdBlocked] = useState(false)

  const location = useLocation();

  useEffect(()=>{
    if(location.pathname === '/block-teaser'){
      const query = new URLSearchParams(location.search);

      const param_site_id = query.get('site_id');
      setSideId(param_site_id)
      const param_teaser_id = query.get('teaser_id');
      setTeaserId(param_teaser_id)
  
      Axios.get(`${hostDetect('api')}/ad/get/?id=${param_teaser_id}`, { withCredentials: true })
      .then(response => { 
        const success = response.data.success;
          if(success){
            let entry = response.data.data.entry;
            setAdsInfo(entry)
          } else {
            let message = response.data.data.message;
            setErrMessage(message)
          }
      })
      .catch(err => {
        setErrConnection(true)
        console.log(err)
      })
    }
  },[])

  const handleTeaserBlock = () => {
    setErrConnection(false)
    setErrMessage(false)
    setSuccessMessage(false)


    if(siteId && teaserId){
      Axios.get(`${hostDetect('api')}/publisher/block-exchange-teaser?site_id=${siteId}&teaser_id=${teaserId}`, { withCredentials: true })
        .then(response => { 
          const success = response.data.success;

          if(success){
            setSuccessMessage(true)
            setAdBlocked(true)
          } else {
            let message = response.data.data.message;
            setErrMessage(message)
          }
        })
        .catch(err => {
          setErrConnection(true)
          console.log(err)
        })
    }

  }

  return (
    <div className="block-teaser">
      <div className="card">
        <div className="card-header">
          Вы уверены, что хотите заблокировать данный анонс?
        </div>
        <div className="card-body">

          {/* title */}
          {
            !errConnection ? (
              <h5 className="card-title">{ adInfo.title }</h5>): null
          }

          {/* image */}
          {
            !errConnection ? (
              <div className="block-teaser__img media media-16x9" style={{backgroundImage: `url(${adInfo.image})`}}></div>
            ) : null
          }

          {/* error connection */}
          {
            errConnection ? (
              <div className="alert alert-danger" role="alert">
                Не удалось отправить запрос. Обновите страницу и попробуйте еще раз!
              </div>
            ) : null
          }

          {/* error if !success */}
          {
            errMessage.length ? (
              <div className="alert alert-danger" role="alert">
                {
                  errMessage
                }
              </div>
            ) : null
          }

          {/* success message */}
          {
            successMessage ? (
              <div className="alert alert-success" role="alert">
                Анонс заблокирован!
              </div>
            ) : null
          }
          

          <div className="block-teaser__controls">
            <button className="btn w-sm mb-1 btn-primary" onClick={handleTeaserBlock} disabled={ errConnection || adBlocked ? true : false }>Заблокировать</button>
            {/* <button className="btn w-sm mb-1 btn-danger" disabled={ errConnection ? true : false }>Отмена</button> */}
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default BlockTeaser