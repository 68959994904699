import React, { useState, useEffect } from 'react'

import './ChartTableMobile.scss'

import { connect } from 'react-redux'

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//apexcharts
import ReactApexChart from "react-apexcharts"
import ApexCharts from 'apexcharts'

//moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const ApexChartMobile = props => {

  const [series, setSeries] = useState({
    income: [],
    visits: [],
    views: [],
    clicks: [],
    returns: []
  })

  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: true
      },
      height: 350,
      stacked: false
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: '',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    xaxis: {
      labels: {
        rotate: 0,
        rotateAlways: false
      },
      categories: [],
      tickPlacement: 'between'
    },
    yaxis: [{
      opposite: true,
      labels: {
        formatter: function(value) {
          if(!value){
            return 0
          }
          return value.toFixed(2);
        }
      }
    }],
    legend: {
      horizontalAlign: 'left'
    }
  })

  const setDefaultChartSettings = (statistics, title, subtitle) => {
    let Xaxis = Object.keys(statistics)

    let XaxisFormatted = Xaxis.map(item => {
        return formatXaxis(item)
    })
    // console.log(XaxisFormatted);
    setOptions({ ...options, xaxis: { categories: XaxisFormatted } })
  }

  const formatXaxis = date => {
    if (props.groupBy == 'day') {
      return moment(new Date(date)).format('DD.MM')
    }
    if (props.groupBy == 'week') {
      let [year, week] = date.split('W')
      return moment(year).add(week, 'weeks').startOf('isoweek').format('DD-MMMM');
    }
    if (props.groupBy == 'month') {
      return moment(date).format('MMMM YYYY')
    }
  }

  useEffect(() => {
    let activeStatistics = props.widgetActiveStatistics,
        chartTitle = '',
        chartSubtitle = '';

    if(props.widgetActiveId === 0){
      chartTitle = 'Суммарно';
      chartSubtitle = 'Общая статистика';
    } else {
      chartTitle = props.widgets[props.widgetActiveId - 1].name;
      chartSubtitle = '#' + props.widgets[props.widgetActiveId - 1].id;
    }

    let visits = {
      name: 'Загрузки',
      type: 'line',
      data: []
    }

    let views = {
      name: 'Показы',
      type: 'line',
      data: []
    }

    let clicks = {
      name: 'Клики',
      type: 'line',
      data: []
    }

    let income = {
      name: 'Доход',
      type: 'line',
      data: []
    }

    let returns = {
      name: 'Возврат',
      type: 'line',
      data: []
    }



    Object.keys(activeStatistics).forEach(item => {
        if (activeStatistics[item] == null) {
          visits.data.push(0)
          views.data.push(0)
          clicks.data.push(0)
          income.data.push(0)
          returns.data.push(0)
        } else {
          visits.data.push(activeStatistics[item].visits)
          views.data.push(activeStatistics[item].views)
          clicks.data.push(activeStatistics[item].clicks)
          income.data.push(activeStatistics[item].income)
          if(props.widgetActiveId !== 0){
            returns.data.push(0)
          } else {
            returns.data.push(activeStatistics[item].returned)
          }
        }
    })

    setSeries({
        visits: [visits],
        views: [views],
        clicks: [clicks],
        income: [income],
        returns: [returns]
    })

    setDefaultChartSettings(activeStatistics, chartTitle, chartSubtitle);
  }, [props.widgetActiveStatistics])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <div id="chart" className="chart-table-mobile">
    	<div id="chart-timeline">
      	<Slider {...settings}>
          <div>
            <h3 className="chart-table-mobile__chart-title">Доход</h3>
            <ReactApexChart
        			options={options}
        			series={series.income}
        			height={350}/>
          </div>
          <div>
          	<h3 className="chart-table-mobile__chart-title">Загрузки</h3>
          	<ReactApexChart
        			options={options}
        			series={series.visits}
        			height={350}/>
          </div>
          <div>
						<h3 className="chart-table-mobile__chart-title">Показы</h3>
          	<ReactApexChart
        			options={options}
        			series={series.views}
        			height={350}/>
          </div>
          <div>
          	<h3 className="chart-table-mobile__chart-title">Возврат</h3>
          	<ReactApexChart
        			options={options}
        			series={series.returns}
        			height={350}/>
          </div>
          <div>
          	<h3 className="chart-table-mobile__chart-title">Клики</h3>
          	<ReactApexChart
        			options={options}
        			series={series.clicks}
        			height={350}/>
          </div>
        </Slider>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    widgets: state.siteInfo.site.widgets,
    widgetActiveId: state.siteInfo.site.widgetActive,
    widgetActiveStatistics: state.siteInfo.site.widgetActiveStatistics,
    groupBy: state.siteInfo.site.groupBy
  }
}

export default connect(mapStateToProps)(ApexChartMobile)