import React, { useState } from 'react';
import './Manual.scss';

import StatisticsWatch from './pages/StatisticsWatch/StatisticsWatch.js';
import WidgetCreation from './pages/WidgetCreation/WidgetCreation.js';

const Manual = () => {

  const [active, setActive] = useState('0');

  let switchPageById = id => {
    switch (id) {
      case "0":
        return <StatisticsWatch/>
      case "1":
        return <WidgetCreation/>
      default:
        break;
    }
  }

  return (
    <div className="manual">
      <div className="card">
        <div className="card-header">
          <h1 className="manual__heading">
            Руководство по работе с SVK-Native
          </h1>
        </div>
        <div className="card-body">
          <div className="manual__body-wrap">
            <div className="manual__links">
             <nav className="manual__nav">
               <p className={"manual__nav-item " + (active==='0' ? 'active' : '')} onClick={() => setActive('0')}>
                 Просмотр статистики
               </p>
               <p className={"manual__nav-item " + (active==='1' ? 'active' : '')} onClick={() => setActive('1')}>
                 Создание виджета
               </p>
               <p className={"manual__nav-item " + (active==='2' ? 'active' : '')} onClick={() => setActive('2')}>
                 Создание сайта
               </p>
             </nav>
            </div>

            <div className="manual__pages">
              {
                switchPageById(active)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Manual

