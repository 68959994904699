import React, { useState, useEffect } from 'react'

import './LentaAddCard.scss'

import { hostDetect } from '../../config/envConfig';

import axios from 'axios';

import { useHistory, useParams, Link } from "react-router-dom";

import { connect } from 'react-redux';

import { ArrowLeft } from "react-feather";

import { setAlert } from '../../redux/actions/alertActions';

const LentaAddCard = props => {
	const [submitDisable, setSubmitDisable] = useState(true);
	const [nameValidation, setNameValidation] = useState({valid: false, class: '', text: ''});
 	const [cardName, setCardName] = useState('');
 	const [cardCSS, setCardCSS] = useState('');
 	const [cardHTML, setCardHTML] = useState('');
 	const [cardJS, setCardJS] = useState('');
 	const [repeatMarker, setRepeatMarker] = useState(0);

	let {siteId, widgetId, cardType} = useParams();

	let history = useHistory();

	useEffect(()=>{
		window.scrollTo(0, 0);
	}, [])

	useEffect(()=>{
    if(siteId !== String(props.siteId)){
      history.push("/settings/widgets");
    }
  }, [props.siteId])

	useEffect(()=>{
 		if(nameValidation.valid){
 			setSubmitDisable(false)
 		} else {
 			setSubmitDisable(true)
 		}
 	})

  const handleBackClick = () => {
  	history.push(`/settings/widgets/widgetInfo/${siteId}/${widgetId}`);
  }

  const handleNameInput = event => {
 		let name = event.target.value;
		
		setCardName(name)

 		if(name.length === 0){
 			setNameValidation({valid: false, class: '', text: ''})
 		} else if(name.length <= 2){
 			setNameValidation({valid: false, class:'is-invalid', text: 'Слишком короткое имя...'})
 		} else if(name.length > 2){
 			setNameValidation({valid: true, class: 'is-valid', text: ''})
 		}
 	}

 	const handleRepeatMarkerUse = event => {
		if(event.target.checked){
			setRepeatMarker(1)
		} else {
			setRepeatMarker(0)
		}
 	}

 	const handleCardCSS = event => {
 		setCardCSS(event.target.value);
 	}

 	const handleCardHTML = event => {
 		setCardHTML(event.target.value);
 	}

 	const handleCardJS = event => {
 		setCardJS(event.target.value);
 	}

 	const submitCardEdit = () => {
 		const formData = new FormData();
 		formData.append("name", cardName);
 		formData.append("css", cardCSS);
 		formData.append("do_not_repeat", String(repeatMarker));
 		formData.append("js_field", cardJS);
 		formData.append("type", cardType);
 		formData.append("widgetId", widgetId);
 		formData.append("html_field", cardHTML);

 		axios.post(`${hostDetect('api')}/lenta/card-save?site_id=${siteId}&widget_id=${widgetId}&type=${cardType}`, formData, { withCredentials: true })
      .then(res => {
        if(res.data.success){
        	history.push(`/settings/widgets/widgetInfo/${siteId}/${widgetId}`);
        } else {
					const errorMessage = res.data.data.message
          props.setAlert('error', errorMessage);
				}
      })
      .catch(err => {
        props.setAlert('error', 'Не получилось отправить запрос!');
      })
 	}

	return(
		<div className="lenta-add-card">
			<div className="lenta-add-card__back-btn">
	    	<button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
	      	<ArrowLeft size={15} style={{marginRight: 10}}/>Назад
	      </button>
	    </div>

	    <div className="row">
 				<div className="col-md-6">
 					<div className="card">
 						<div className="card-header d-flex justify-content-between align-items-center">
 							<strong>Добавить карточку</strong>
 							<strong style={{fontSize: 12}}>
 								{cardType}
 							</strong>
 						</div>
 						<div className="card-body">

 							<div className="form-group">
 								<label className="text-muted">Название</label>
								<input 
									type="text" 
									className={"form-control " + nameValidation.class} 
									onChange={handleNameInput} 
									value={cardName} 
									maxLength="35"
									placeholder="Введите название"/>
								{
									nameValidation.valid === false ? 
										<ul className="list-unstyled text-sm mt-1 text-muted filled person-data__input-error-text">
											<li className="parsley-required">{nameValidation.text}</li>
										</ul> : ''
								}
 							</div>

 							<div className="form-group">
		          	<label className="text-muted">CSS стили</label>	                    
                <div>
                	<textarea 
					        	className="form-control"  
							    	rows="7" 
              			style={{marginTop: "0px", marginBottom: "0px", height: "171px", fontSize: 13}} 
                    placeholder="*Ваш CSS код*"
                    value={cardCSS}
                    onChange={handleCardCSS}></textarea>
								</div>
							</div>

							{
								cardType === 'SVK_CustomHTML' ? (
									<React.Fragment>
										<div className="form-group">
					          	<label className="text-muted">HTML код</label>	                    
			                <div>
			                	<textarea 
								        	className="form-control"  
										    	rows="7" 
			              			style={{marginTop: "0px", marginBottom: "0px", height: "171px", fontSize: 13}} 
			                    placeholder="*Ваш HTML код*"
			                    value={cardHTML}
			                    onChange={handleCardHTML}></textarea>
											</div>
										</div>
										<div className="form-group">
					          	<label className="text-muted">JavaScript код</label>	                    
			                <div>
			                	<textarea 
								        	className="form-control"  
										    	rows="7" 
			              			style={{marginTop: "0px", marginBottom: "0px", height: "171px", fontSize: 13}} 
			                    placeholder="*Ваш JavaScript код*"
			                    value={cardJS}
			                    onChange={handleCardJS}></textarea>
											</div>
										</div>
									</React.Fragment>
								) : ''
							}

							<div className="form-group row">
                <label className="col-sm-8 col-form-label">Не повторять карточку</label>
                <div className="col-sm-4 d-flex align-items-center justify-content-end">
                  <label className="ui-switch ui-switch-md info m-t-xs">
                    <input type="checkbox" checked={repeatMarker} onChange={handleRepeatMarkerUse}/>
                    <i></i>
                  </label>
                </div>
              </div>

              <div className="lenta-add-card__save">
 								<button className="btn w-sm mb-1 btn-success" disabled={submitDisable} onClick={submitCardEdit}>Сохранить</button>
 							</div>

 						</div>
 					</div>
 				</div>
 			</div>
		</div>
	)
} 

function mapStateToProps(state){
  return {
    siteId: state.siteInfo.site.id
  }
}

function mapDispatchToProps(dispatch){
  return {
    setAlert: (type, text) => dispatch(setAlert(type, text))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LentaAddCard)