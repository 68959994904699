import React, { useState, useEffect, memo } from 'react'

import Axios from 'axios';

import { hostDetect } from '../../../../../config/envConfig';

import { connect } from 'react-redux'

import { setAlert } from '../../../../../redux/actions/alertActions';

import './Limits.scss'



const Limits = ({advId, setAlert}) => {
  const [limitsSelect, setLimitsSelect] = useState('views');
	const [limitsDay, setLimitsDay] = useState(0);
	const [limitsTotal, setLimitsTotal] = useState(0);

  // profile update status
	const [profileUpdateSuccess, setProfileUpdateSuccess] = useState(false)

  useEffect(()=>{
    let apiOptions = {
			withCredentials: true
		}

		Axios.get(`${hostDetect('api')}/ad/get/?id=${advId}`, apiOptions)
		.then(response => { 
			const success = response.data.success;
				if(success){
					// get limits
					const limits = response.data.data.entry.limits
					if(limits){
						const { daily, total, type } = limits

						type && setLimitsSelect(type)
						daily && setLimitsDay(daily)
						total && setLimitsTotal(total)
					}
				} else {
          let errMessage = response.data.data.message;
				  setAlert('error', errMessage);
        }
		})
		.catch(err => {
      setAlert('error', 'Не получилось отправить запрос!');
    })
  }, [])

  // limits
	let handleLimitsSelect = e => {
		setLimitsSelect(e.target.value);
	}
	let handleLimitsDay = e => {
		setLimitsDay(Number(e.target.value));
	}
	let handleLimitsTotal = e => {
		setLimitsTotal(Number(e.target.value));
	}
	const handleSubmitLimits = () => {
		let formData = new FormData();
		formData.append('type', limitsSelect);
		formData.append('daily', limitsDay);
		formData.append('total', limitsTotal);

		let apiOptions = {
			withCredentials: true
		}

		Axios.post(`${hostDetect('api')}/ad/set-limits/?id=${advId}`, formData, apiOptions)
			.then(response => {
				const success = response.data.success;
				if(success){
					handleUpdateSuccess();
				} else {
          let errMessage = response.data.data.message;
				  setAlert('error', errMessage);
        }
			})
			.catch(err => {
        setAlert('error', 'Не получилось отправить запрос!');
      })
	}

  // profile update status
	const handleUpdateSuccess = () => {
		setProfileUpdateSuccess(true);

		setTimeout(()=> {
			setProfileUpdateSuccess(false);
		}, 3000)
	}

  return (
    <div className="settings-modal__field-card limits-field">

      <div className="row">
        <div className="col-12 col-sm-6">
          <div className="form-group">
            <select className="form-control" value={limitsSelect} onChange={handleLimitsSelect}>
              <option value="views">По показам</option>
              <option value="clicks">По кликам</option>
              <option value="unset">Без лимитов</option>
            </select>
          </div>
        </div>
      </div>

      {
        limitsSelect !== 'unset' ? (
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label className="text-muted" htmlFor="dayLimit">Дневной лимит</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id="dayLimit" 
                  placeholder="Введите значение"
                  value={limitsDay.toString()}
                  onChange={handleLimitsDay}/>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label className="text-muted" htmlFor="totalLimit">Общий лимит</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id="totalLimit" 
                  placeholder="Введите значение"
                  value={limitsTotal.toString()}
                  onChange={handleLimitsTotal}/>
              </div>
            </div>
          </div>
        ) : null
      }

      <button className="btn w-sm mb-2 btn-success" onClick={handleSubmitLimits}>Сохранить</button>

      {
        profileUpdateSuccess && (
        <div className="alert alert-success mb-0 process-status" role="alert">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="16" height="16" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="feather feather-check">
              <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
          <span className="mx-2">Профиль успешно обновлен</span>
        </div>
        )
      }
      
    </div>
  )
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(memo(Limits))