import React, { useState, useEffect, useReducer } from 'react'
import './DetailedStatistics.scss'

import { hostDetect } from '../../../config/envConfig'

import CampaignController from './CampaignController/CampaignController'
import Pagination from './Pagination/Pagination.js'

import { Settings, Edit, TrendingUp, Link, Trash2 } from "react-feather";

import SettingsModal from './SettingsModal/SettingsModal.js'
import EditModal from './EditModal/EditModal.js'
import StatisticsModal from './StatisticsModal/StatisticsModal.js'
import Axios from 'axios';
import { connect } from 'react-redux'
import { setAlert } from '../../../redux/actions/alertActions.js'
import { useSelector } from 'react-redux';

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const DetailedStatistics = ({articlesFilterType, setCampaignId, startDate, endDate, setAlert}) => {

	const [listForPagination, setListForPagination] = useState([])

	const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(10)
  const [paginationStart, setPaginationStart] = useState(0)
  const [paginationEnd, setPaginationEnd] = useState(0)

  const [settingsModal, setSettingsModal] = useState({show: false, advId: null})
  const [editModal, setEditModal] = useState({show: false, advId: null})
  const [statisticsModal, setStatisticsModal] = useState({show: false, advId: null})

  const [scrollTop, setScrollTop] = useState(0)

  const [searchQuery, setSearchQuery] = useState('')

	const [articleToDelete, setArticleToDelete] = useState('')

	const site_id = useSelector(state => state.siteInfo.site.id)

	useEffect(()=>{
		setListForPagination([])

		Axios.get(`${hostDetect('api')}/pinned/list/?site_id=${site_id}`, { withCredentials: true })
		.then(response => {
			let success = response.data.success
			
			if(success){
				const ads_array = response.data.data.entries;

				if(ads_array.length){
					setListForPagination(ads_array)
					
					// set campaign_id. Send to parent component
					const { campaign_id } = ads_array[0];
					if(campaign_id){
						setCampaignId(campaign_id)
					}

				} else {
					setListForPagination([])
					setCampaignId(null)
				}
			} else {
				let errMessage = response.data.data.message;
				setAlert('error', errMessage);
			}

		})
		.catch(err => {
			setAlert('error', 'Не получилось отправить запрос!');
		})
	}, [site_id])

	// force update parent component after child component api call
	const updateAdsList = (id, status) => {
		setListForPagination(prev => {
			return prev.map(item => {
				if(item.id === id){
					item.status = status
				}
				return item
			})
		})
	}

	let indexFirst = num => {
    setPaginationStart(num)
  }

  let indexLast = num => {
    setPaginationEnd(num)
  }

  let changePerPage = e =>{
    setPostsPerPage(e.target.value)
    setCurrentPage(1)
  }

  let handleSearchArticle = e => {
  	setSearchQuery(e.target.value);
  }

  let handleSettingsModal = (advId) => {
		if(settingsModal.show === false){
			setSettingsModal({show: true, advId})
		} else {
			setSettingsModal({show: false, advId: null})
		}
  }

  let handleEditModal = (advId) => {
  	if(editModal.show === false){
			setEditModal({show: true, advId})
		} else {
			setEditModal({show: false, advId: null})
		}
  }

  let handleStatisticsModal = (advId) => {
		if(statisticsModal.show === false){
  		setStatisticsModal({show: true, advId})
		} else {
			setStatisticsModal({show: false, advId: null})
		}
  }

	let addFixedToBody = ()=> {
  	let scrollTop = window.scrollY;
  	window.scroll = scrollTop;
  	document.body.classList.add('modal-opened');
  	document.body.style.top = -scrollTop + 'px';
  }

  let removeFixedBody = ()=> {
  	document.body.classList.remove('modal-opened');
  	window.scrollTo(0, window.scroll);
  }

	// add|remove fixed class to body id modal open|closed
  useEffect(()=>{
  	settingsModal.show && addFixedToBody();
  	!settingsModal.show && removeFixedBody();
  	return ()=>{
  		document.body.classList.remove('modal-opened');
  	}
  }, [settingsModal])

  useEffect(()=>{
  	editModal.show && addFixedToBody();
  	!editModal.show && removeFixedBody();
  	return ()=>{
  		document.body.classList.remove('modal-opened');
  	}
  }, [editModal])

  useEffect(()=>{
  	statisticsModal.show && addFixedToBody();
  	!statisticsModal.show && removeFixedBody();
  	return ()=>{
  		document.body.classList.remove('modal-opened');
  	}
  }, [statisticsModal])

	useEffect(()=>{
		setSettingsModal({show: false, advId: null})
		setEditModal({show: false, advId: null})
		setStatisticsModal({show: false, advId: null})
	}, [site_id])

	// filter list by search query
  let searchQueryArr = listForPagination.filter(article => (article.title.toLowerCase().includes(searchQuery.toLowerCase())) && (articlesFilterType === 'all' ? true : article.status == articlesFilterType));

	// hide deleted items
	searchQueryArr = searchQueryArr.filter(item => item.status !== 100);

	// filter by dates. Created date must be between FROM and TO
	searchQueryArr = searchQueryArr.filter(item => {

		if(startDate && endDate){
			let createdDate = moment(item.created).format('YYYY-MM-DD'),
			endDateFormatted = moment(endDate);

			return moment(createdDate).isSameOrBefore(endDateFormatted)
		}
		
		return true
	})

	// sort PLAY top, STOP to bottom
	searchQueryArr = searchQueryArr.sort((a,b)=>a.status > b.status ? 1 : -1);  

	// status 100 DELETED by  click in setting modal
	const handleDeleteArticle = () => {
		setArticleToDelete('');

		let formData = new FormData();
		formData.append('status', '100');

		let apiOptions = {
			withCredentials: true
		}

		Axios.post(`${hostDetect('api')}/ad/update-status/?id=${articleToDelete}`, formData, apiOptions)
		.then(response => {
			const success = response.data.success;
			if(success){
				updateAdsList(articleToDelete, 100)
			} else {
				let errMessage = response.data.data.message;
				setAlert('error', errMessage);
			}
		})
		.catch(err => {
			setAlert('error', 'Не получилось отправить запрос!');
		})
	}

	if(listForPagination.length === 0){
		return (
			<div className="p-3">
				Нет созданных рекламных объявлений.
			</div>
		)
	}


	// update pagination list, after changes, inited in EditModal
	const updatePaginationList = (updatedAdvItem) => {
		const prevList = [...listForPagination];
		const newList = prevList.map(item => {
			if(item.id === updatedAdvItem.id){
				item.url = updatedAdvItem.url;
				item.title = updatedAdvItem.title;
				item.description = updatedAdvItem.description;
				item.image = updatedAdvItem.image;
			}
			return item
		})
		setListForPagination(newList)
	}


	// Formatting cell views
	let formatFixed = (valueNumber, fixLength) => {
    if(valueNumber) {
      return Number(valueNumber).toFixed(fixLength)
    } else {
      return '0'
    }
  }
	let formatLongNumber = valueNumber => {
    return new Intl.NumberFormat('ru-RU').format(valueNumber)
  }

	// Date format
	const formatDate = (date) => {
		let formattedDate = moment(date).format('DD.MM.YYYY | HH:mm:ss')
		return formattedDate
	}

	return(
		<div className="detailed-statistics-inn">

			{
				settingsModal.show ? <SettingsModal closeModal={handleSettingsModal} advId={settingsModal.advId}/> : null
			}

			{
				editModal.show ? <EditModal closeModal={handleEditModal} advId={editModal.advId} updatePaginationList={updatePaginationList}/> : null
			}

			{
				statisticsModal.show ? <StatisticsModal closeModal={handleStatisticsModal} advId={statisticsModal.advId} startDate={startDate} endDate={endDate}/> : null
			}

			<div className="input-group" style={{background: "#e9ecef", padding: 5}}>
				<input 
					type="text" 
					className="form-control form-control-theme search" 
					placeholder="Введите название заголовка статьи"
					value={searchQuery}
					onChange={(e) => handleSearchArticle(e)}/>
      </div>

      <div className="detailed-statistics-inn__table-wrap">
      	<table className="table main-statistics-table">
					  <thead className="thead-light">
					    <tr>
					      <th scope="col">Статья</th>
					      <th scope="col" className="text-center">Статус</th>
					      <th scope="col" className="text-center">Показы</th>
					      <th scope="col" className="text-center">Клики</th>
					      <th scope="col" className="text-center">CTR</th>
					      <th scope="col" className="text-center">Действия</th>
					    </tr>
					  </thead>
					  <tbody>

					  	{
			          searchQueryArr.
									slice(paginationStart, paginationEnd). // pagination
									map((statField, index) => {            // mapping result
			            return(
			              <tr key={statField.id}>
			                <td>
			                	<div className="d-flex mb-2">
			                		<div className="detailed-statistics-inn__article-img" style={{backgroundImage: `url(${statField.image})`}}></div>
													<div className="detailed-statistics-inn__article-id-title-wrap">
														<div className="detailed-statistics-inn__article-id">
															<span className="badge badge-sm badge-pill mx-1">id:</span> {statField.id}
														</div>
														<p className="detailed-statistics-inn__article-title">
															{statField.title}
														</p>
													</div>
			                	</div>
			                	<div className="detailed-statistics-inn__article-link">
			                		<Link size={12}/>
			                		<a href={statField.url} title={statField.url}>
				                		{statField.url}
				                	</a>
			                	</div>
												<div className="detailed-statistics-inn__article-created">
													<span className="badge badge-sm badge-pill mx-1">Дата создания:</span>
													<small className="text-muted">{formatDate(statField.created)}</small>
												</div>
			                </td>
			                <td>
			                	<CampaignController status={statField.status} article_id={statField.id} updateAdsList={updateAdsList}/>
			                </td>
			                <td>
			                	<p className="detailed-statistics-inn__article-views">
			                		{formatLongNumber(statField.total_shown)}
			                	</p>
			                </td>
			                <td>
			                	<p className="detailed-statistics-inn__article-clicks">
			                		{formatLongNumber(statField.total_clicks)}
			                	</p>
			                </td>
			                <td>
			                	<p className="detailed-statistics-inn__article-ctr">
			                		{formatFixed(statField.ctr, 1)}
			                	</p>
			                </td>
			                <td>
			                	<ul className="detailed-statistics-inn__article-actions">
			                		<li className="detailed-statistics-inn__article-action" onClick={() => handleSettingsModal(statField.id)}>
			                			{/* <Settings size={12}/> Настройки */}
			                		</li>
			                		<li className="detailed-statistics-inn__article-action" onClick={() => handleEditModal(statField.id)}>
			                			<Edit size={12}/> Редактировать
			                		</li>
			                		<li className="detailed-statistics-inn__article-action" onClick={() => handleStatisticsModal(statField.id)}>
			                			<TrendingUp size={12}/> Статистика
			                		</li>
													<li className="detailed-statistics-inn__article-action" >
														<a href="#" data-toggle="modal" data-target="#modalDeleteArticle" onClick={() => setArticleToDelete(statField.id)}>
															<Trash2 size={12}/> Удалить
														</a>
			                		</li>
			                	</ul>
			                </td>
			              </tr>
			            )
			          })
			        }
				  </tbody>
				</table>
      </div>
			

		<div className="detailed-statistics-inn__bottom-controls">
			<Pagination
        currentPage={currentPage}
        postsPerPage={postsPerPage}
        notPaginateStat={searchQueryArr}
        setCurrentPage={setCurrentPage}
        size={searchQueryArr.length}
        indexOfFirst={indexFirst}
        indexOfLast={indexLast}
        key={searchQueryArr}/>

			<div className="per-page">
        <span style={{marginRight: 10}}>Кол-во материалов на странице: </span>
        <select className="browser-default custom-select" style={{width: '80px'}} onChange={(e)=>changePerPage(e)} value={postsPerPage}>
          <option defaultValue>10</option>
          <option>50</option>
          <option>100</option>
        </select>
      </div>
		</div>

		{/* --- modal Delete article ---*/}
		<div id="modalDeleteArticle" className="modal fade" data-backdrop="true">
			<div className="modal-dialog ">
				<div className="modal-content ">
					<div className="modal-header ">
						<div className="modal-title text-md">Удаление статьи</div>
						<button className="close" data-dismiss="modal">×</button>
					</div>
					<div className="modal-body">
						<div className="p-2">
							<p className="text-left mb-0 d-flex align-items-center">
								Вы уверены, что хотите удалить данную статью? 
							</p>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleDeleteArticle}>Удалить</button>
						<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => setArticleToDelete('')}>Отмена</button>
					</div>
				</div>
			</div>
		</div>
		{/* --- modal Delete article ---*/}


		</div>
	)
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null, mapDispatchToProps)(DetailedStatistics)