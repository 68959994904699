import React from 'react'

import './PageExchange.scss'

import { ArrowLeft } from "react-feather";

import { useHistory, Link as LinkRoute } from "react-router-dom";

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading.js'

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader';

import TeaserArchived from './TeasersArchived/TeaserArchived.js';



const PageExchangeArchive = () => {

	let history = useHistory();

	const handleBackClick = () => {
		history.goBack()
	}

	
	return (
		<div className="page-exchange">

			<MobileHeader/>

			<div className="page-exchange__back-btn">
	    	<button className="btn w-sm mb-1 btn-secondary" onClick={handleBackClick}>
	      	<ArrowLeft size={15} style={{marginRight: 10}}/>Назад
	      </button>
	    </div>

			<PageHeading mainText={"Обмен трафиком"} mutedText={"Обменивайтесь трафиком с сайтами нашей сети"}/>

			<div className="page-exchange__card">
        <div className="card">
          <div className='page-exchange__card-content'>

            <div className="b-b page-exchange__controls-wrap">
              <div className="b-primary bottom">
                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <LinkRoute to={`/exchange/active`} className="nav-link">Активные</LinkRoute>
                  </li>
                  <li className="nav-item">
                    <LinkRoute to={`/exchange/add`} className="nav-link">Добавление статей</LinkRoute>
                  </li>
                  <li className="nav-item">
                    <LinkRoute to={`/exchange/archive`} className="nav-link active">Архив</LinkRoute>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="tab-content p-3">
              <TeaserArchived />
            </div>
            
          </div>
        </div>
      </div>

		</div>
	)
}

export default PageExchangeArchive
