import React from 'react'
import './GroupbyButtons.scss'

const GroupbyButtons = ({groupBy, setGroupBy}) => {

  const handleClickGroup = query =>{
    setGroupBy(query)
  }

  return(
    <div className="groupby">
      <div className="groupby__btns">
        <button className={'btn '+'btn-outline-primary '+(groupBy === 'day' ? 'active': '')} onClick={()=>handleClickGroup('day')}>По дням</button>
        <button className={'btn '+'btn-outline-primary '+(groupBy === 'week' ? 'active': '')} onClick={()=>handleClickGroup('week')}>По неделям</button>
        <button className={'btn '+'btn-outline-primary '+(groupBy === 'month' ? 'active': '')} onClick={()=>handleClickGroup('month')}>По месяцам</button>
      </div>
    </div>
  )
}

export default GroupbyButtons