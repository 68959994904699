import React from "react"

import './Dashboard.scss'

import { PieChart } from "react-feather";

import { Link } from "react-router-dom";

const Dashboard = () => {
  return(
    <li className="dashboard-link">
      <Link to='/dashboard'>
        <span className="nav-icon text-primary">
          <PieChart color={'#666'} size={15}/>
        </span>
        <span className="nav-text ml-0">Показатели</span>
      </Link>
    </li>
  )
}

export default Dashboard

