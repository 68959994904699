import React from 'react'

import './FlashAlert.scss'

import { CheckCircle, AlertCircle, Info } from 'react-feather'

// redux
import { connect } from 'react-redux'

// Call from action
// import { setAlert } from '../../redux/actions/alertActions';

// Use - setAlert('success', 'the campaign was successfully created');

// Types - success, error, info

const FlashAlert = ({flashArray}) => {

  const iconType = (iconType) => {
    if(iconType === 'success'){
      return <CheckCircle color={"#31c971"} />
    }

    if(iconType === 'error'){
      return <AlertCircle color={"#f54394"} />
    }

    if(iconType === 'info'){
      return <Info color={"#146c8c"} />
    }

    return ''
  }

  return (
    <>
      {
        flashArray && flashArray.length ? (
          <div className="flash-wrap">
            {
              flashArray.map((item, index) => {
                return (
                  <div className={`flash-alert ${item.type}`} key={index}>
                    <div className="d-flex h-100 align-items-center">
                      <div className="flash-alert__icon">
                        {
                          iconType(item.type)
                        }
                      </div>
                      <div className="flash-alert__text">
                        {
                          item.text
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        ) : null
      }

    </>
  )

}

function mapStateToProps(state){
  return{
    flashArray: state.appAlerts.alertArray
  }
}

export default connect(mapStateToProps, null)(FlashAlert)
