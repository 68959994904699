import React, {useEffect, useState} from 'react'

import axios from 'axios'

import { connect } from 'react-redux'

import { hostDetect } from '../../config/envConfig'

import './Finance.scss'

import MobileHeader from '../../components/commonComponents/mobileHeader/MobileHeader'

import PageHeading from '../../components/commonComponents/pageHeading/pageHeading'

import { setAlert } from '../../redux/actions/alertActions'

import DateRange from './DateRange/DateRange'

import Pagination_api from './Pagination_api/Pagination_api'

// moment js
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const Finance = ({ setAlert }) => {

  const [balanceLoading, setBalanceLoading] = useState(false)
  const [balance, setBalance] = useState(0)
  const [onHold, setOnHold] = useState(0)

  const [dates, setDates] = useState({
    start: moment().startOf('month'),
    end: moment(),
  });

  let startFormatted = dates.start.format('YYYY-MM-DD'),
      endFormatted = dates.end.format('YYYY-MM-DD');
  
  const [historyLoading, seHistoryApiLoading] = useState(false)

  const [historyList, setHistoryList] = useState([]);
  const [historyLength, setHistoryLength] = useState(0);
  
  // pagination
  const [paginationCurrent, setPaginationCurrent] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(10);

  useEffect(() => {
    getMainBalance()
    getHistory(startFormatted, endFormatted, 0, paginationLimit)
  }, [])



  const getMainBalance = () => {

    setBalanceLoading(true)

    axios.get(`${hostDetect('api')}/publisher-finance/get-balance`, { withCredentials: true })
    .then(response => {
      setBalanceLoading(false)

      let success = response.data.success
      if(success){
        let { balance, on_hold } = response.data.data
        setBalance(balance)
        setOnHold(on_hold)
      } else {
        let errMessage = response.data.data.message;
        setAlert('error', errMessage);
      }
    })
    .catch(err => {
      setBalanceLoading(false)
      setAlert('error', 'Не получилось отправить запрос!');
    })
  }

  const getHistory = (dateStart, dateEnd, start, limit) => {

    seHistoryApiLoading(true)

    axios.get(`${hostDetect('api')}/publisher-finance/get-history?start_date=${dateStart}&end_date=${dateEnd}&start=${start}&limit=${limit}`, { withCredentials: true })
    .then(response => {
			const success = response.data.success;

			if(success){
        setHistoryLength(response.data.data.count)
        setHistoryList(response.data.data.history)
			} else {
				let errMessage = response.data.data.message;
        setAlert('error', errMessage);
			}

      seHistoryApiLoading(false)
		})
		.catch(err => {
      seHistoryApiLoading(false)

			setAlert('error', 'Не получилось отправить запрос!');
		})
  }

  const handleControlsSubmit = () => {
    setPaginationCurrent(1)
    getHistory(startFormatted, endFormatted, 0, paginationLimit)
  }


  const formatNumbers = ( numbers ) => {
    if(!numbers){
      return '0'
    }
    // return new Intl.NumberFormat("ru", {maximumFractionDigits: 3}).format(numbers)
    return new Intl.NumberFormat().format(numbers)
  }

  const checkStatus = (status) => {
    switch (status) {
      case 'Новая' : {
        return 'badge badge-primary text-uppercase'
      }
      case 'В обработке' : {
        return 'badge badge-warning text-uppercase'
      }
      case 'Выполнена' : {
        return 'badge badge-success text-uppercase'
      }
      case 'Отменена' : {
        return 'badge badge-danger text-uppercase'
      }
      default: {
        return ''
      }
    }
  }


  const handlePageClick = (page) => {
    let newStart = (page - 1) * paginationLimit
    setPaginationCurrent(page)
    getHistory(startFormatted, endFormatted, newStart, paginationLimit)
  }

  const handlePaginationSelect = (limit) => {
    setPaginationLimit(limit)
    setPaginationCurrent(1)
    getHistory(startFormatted, endFormatted, 0, limit)
  }


  return (
    <div className="finance">

      <MobileHeader/>

      <PageHeading mainText={"Финансы"} mutedText={"Текущий баланс и история транзакций"}/>

      <div className="row">
        <div className="col-md-12 col-lg-7">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="card">
                <div className="card-body d-flex align-items-center justify-content-between">
                  <small className="text-muted mr-2" style={{lineHeight: 1}}>Баланс:</small>
                  <div className="text-highlight font-weight-500">
                    {
                      balanceLoading ? (
                        <div className="spinner-border spinner-border-sm text-primary ml-2" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          { formatNumbers(balance) } ₽
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="card">
                <div className="card-body d-flex align-items-center justify-content-between">
                  <small className="text-muted mr-2" style={{lineHeight: 1}}>Ожидаемый доход:</small>
                  <div className="text-highlight font-weight-500">
                    {
                      balanceLoading ? (
                        <div className="spinner-border spinner-border-sm text-primary ml-2" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          { formatNumbers(onHold) } ₽
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-5"></div>
      </div>
     
      <h5 className="mb-4">История транзакций</h5>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="finance-controls__left col-12 col-md-8 col-lg-8">
              <div className="finance-controls__date-wrap d-flex">
                <DateRange dates={dates} setDates={setDates} />
                <button className="finance-controls__submit-btn btn w-sm ml-2 btn-primary" onClick={() => handleControlsSubmit()}>Применить</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="finance-table">
        <table className="table table-theme table-row v-middle mb-4">
          <thead>
            <tr>
              <th className="text-muted">ID</th>
              <th className="text-muted">Создана</th>
              <th className="text-muted">Обновлена</th>
              <th className="text-muted">Статус</th>
              <th className="text-muted">Тип</th>
              <th className="text-muted">Сумма, ₽</th>
              <th className="text-muted">Баланс</th>
              <th className="text-muted">Сайт</th>
            </tr>  
          </thead>
          <tbody>

            {
              historyList.length ? (
                historyList.map((item, i) => {
                  return (
                    <tr className=" v-middle" key={item.operation_id}>
                      <td>
                        <span className="item-amount text-sm text-muted">{item.operation_id}</span>
                      </td>
                      <td>
                        <span className="item-amount text-sm text-muted date-cell">{item.created}</span>
                      </td>
                      <td>
                        <span className="item-amount text-sm text-muted date-cell">{item.updated}</span>
                      </td>
                      <td>
                        <span className={`item-amount ${checkStatus(item.status)}`}>{item.status}</span>
                      </td>
                      <td>
                        <span className="item-amount text-sm text-muted type-cell">{item.type}</span>
                      </td>
                      <td>
                        <span className="item-amount text-sm font-weight-500 balance-cell">{item.amount}</span>
                      </td>
                      <td>
                        <span className="item-amount text-sm font-weight-500 balance-cell">{item.balanceBefore}</span>
                      </td>
                      <td>
                        <span className="item-amount text-sm ">
                          <a href={item.url} target="_blank">{item.url}</a>
                        </span>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr className=" v-middle">
                  <td colSpan="8" className="text-sm text-muted">Записи отсутствуют</td>
                </tr>
              )
            }

          </tbody>
        </table>
      </div>

      <div className="finance__controls">
        <div className="finance__pagination">
          <Pagination_api 
            currentPage={paginationCurrent}
            totalCount={historyLength}
            pageSize={paginationLimit}
            onPageChange={page => handlePageClick(page)} />
        </div>

        <div className="finance__list-limit">
          <select className="form-control" value={paginationLimit} onChange={e => handlePaginationSelect(e.target.value)}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>


      {
        historyLoading ? 
          <div className="loader__small">
              Загрузка данных...
          </div> : null
      }

    </div>
  )
}


const mapDispatchToProps = {
  setAlert
}

export default connect(null,mapDispatchToProps)(Finance);