import React, { memo } from 'react'
import './InfoTable.scss'

import ExportStatistics from './ExportStatistics/ExportStatistics'

const InfoTable = ({totalStat, detailedStat, siteId, startDate, endDate, groupBy, campaignId})=>{
  let formatLongNumber = valueNumber => {
    return new Intl.NumberFormat('ru-RU').format(valueNumber)
  }

  let formatFixed = (valueNumber, fixLength) => {
    if(valueNumber) {
      return valueNumber.toFixed(fixLength)
    } else {
      return '0'
    }
  }

  return (
    <div style={{textAlign: 'end'}}>
      <div className="card" style={{marginBottom: 0}}>
        <div className="table-responsive info-tables">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr className="text-center">
                {/* <th scope="col">Загрузки</th> */}
                {/* <th scope="col">Видимость</th> */}
                <th scope="col">Показы</th>
                <th scope="col">Клики</th>
                <th scope="col">CTR</th>
                {/* <th scope="col">Доход</th> */}
                {/* <th scope="col">Потрачено</th> */}
                {/* <th scope="col">Прибыль</th> */}
              </tr>
            </thead>
            <tbody>
              <tr className="text-center font-weight-bold">
                {/* <td>0</td> */}
                {/* <td>0 %</td> */}
                <td>{totalStat ? formatLongNumber(totalStat.views) : 0}</td>
                <td>{totalStat ? formatLongNumber(totalStat.clicks) : 0}</td>
                <td>{totalStat ? formatLongNumber(formatFixed(totalStat.ctr, 2)) : 0}</td>
                {/* <td>0 ₽</td> */}
                {/* <td>{totalStat ? formatLongNumber(formatFixed(totalStat.spent, 0)) : 0} ₽</td> */}
                {/* <td>0 ₽</td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <ExportStatistics siteId={siteId} campaignId={campaignId} groupBy={groupBy} startDate={startDate} endDate={endDate} detailedStat={detailedStat}/>

    </div>
  )
}

export default memo(InfoTable)